import React, { Component } from 'react';

import Table from 'component/table';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { FormattedMessage } from 'react-intl';

import commonStyles from 'design/styles/common.module.css';
import tabStyles from 'design/styles/react-tabs.module.css';
import styles from './ProductErrors.module.css';

class ProductErrors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    Header: 'Error Description',
                    accessor: 'errorDescription',
                },
                {
                    Header: 'Country',
                    accessor: 'country',
                },
                {
                    Header: 'Affected Items',
                    accessor: 'affectedItems',
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                },
            ],
        };
    }

    mapData = (data, { isCritical = false } = {}) =>
        data.map(item => ({
            errorDescription: isCritical ? (
                <span className={styles['critical-error-color']}>
                    {item.description}
                </span>
            ) : (
                item.description
            ),
            country: item.country,
            affectedItems: item.itemsCount,
            actions: (
                <a target="_blank" rel="noopener noreferrer" href="about:blank">
                    Resolve
                </a>
            ),
        }));

    renderTable = (data, columns, loading) => {
        return (
            <Table
                data={data}
                columns={columns}
                loading={loading}
                showCounter
            />
        );
    };

    render() {
        const { data, tabIndex, onTabSelect } = this.props;

        const mappedData = {
            critical: [],
            error: [],
            suggestion: [],
        };

        data.forEach(item => {
            mappedData[item.name] = item.data
                ? this.mapData(item.data, {
                      isCritical: item.name === 'critical',
                  })
                : [];
        });

        return (
            <div>
                <h2
                    className={[commonStyles.header2, styles.header2].join(' ')}
                >
                    <FormattedMessage id="products.title" />
                </h2>
                <Tabs
                    selectedIndex={tabIndex}
                    onSelect={onTabSelect}
                    selectedTabClassName={tabStyles['react-tabs--selected']}
                >
                    <TabList
                        className={[
                            tabStyles['react-tabs__tab-list'],
                            styles['tab-list'],
                        ].join(' ')}
                    >
                        <Tab className={tabStyles['react-tabs__tab']}>
                            <FormattedMessage id="productErrors.tab.critical" />{' '}
                            - {mappedData.critical.length}
                        </Tab>
                        <Tab className={tabStyles['react-tabs__tab']}>
                            <FormattedMessage id="productErrors.tab.error" /> -{' '}
                            {mappedData.error.length}
                        </Tab>
                        <Tab className={tabStyles['react-tabs__tab']}>
                            <FormattedMessage id="productErrors.tab.suggestion" />{' '}
                            - {mappedData.suggestion.length}
                        </Tab>
                        <Tab className={tabStyles['react-tabs__tab']}>
                            <FormattedMessage id="productErrors.tab.published" />{' '}
                            - 0
                        </Tab>
                    </TabList>
                    <p
                        className={[
                            commonStyles[`font-medium`],
                            styles['description-text'],
                        ].join(' ')}
                    >
                        <FormattedMessage id="productErrors.description" />
                    </p>
                    <TabPanel>
                        {this.renderTable(
                            mappedData.critical,
                            this.state.columns,
                            this.props.loading,
                        )}
                    </TabPanel>
                    <TabPanel>
                        {this.renderTable(
                            mappedData.error,
                            this.state.columns,
                            this.props.loading,
                        )}
                    </TabPanel>
                    <TabPanel>
                        {this.renderTable(
                            mappedData.suggestion,
                            this.state.columns,
                            this.props.loading,
                        )}
                    </TabPanel>
                    <TabPanel>
                        {this.renderTable(
                            [],
                            this.state.columns,
                            this.props.loading,
                        )}
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

export default ProductErrors;
