import { ActionCreator } from 'redux';

import {
    IAdsSetCountryAction,
    IAdsSetCurrencyAction,
    IAdsSetTimeZoneAction,
} from '../../reducers/adsSettingsReducer';
import {
    ADS_SET_COUNTRY,
    ADS_SET_CURRENCY,
    ADS_SET_TIMEZONE,
} from '../../store/actionConsts';

export const saveCountryToStore: ActionCreator<IAdsSetCountryAction> = (
    country: string,
) => ({
    payload: {
        country,
    },
    type: ADS_SET_COUNTRY,
});

export const saveCurrencyToStore: ActionCreator<IAdsSetCurrencyAction> = (
    currency: string,
) => ({
    payload: {
        currency,
    },
    type: ADS_SET_CURRENCY,
});

export const saveTimeZoneToStore: ActionCreator<IAdsSetTimeZoneAction> = (
    timezone: string,
) => ({
    payload: {
        timezone,
    },
    type: ADS_SET_TIMEZONE,
});
