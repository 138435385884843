import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

import { RadioButton } from 'component/form';
import Tooltip from 'component/Tooltip';
import { classnames } from '../../../../../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './CreateNewAttributeOption.module.css';

export default class CreateNewAttributeOption extends Component {
    radioButtonChangeHandler = e => {
        const id = e.target.id;
        this.props.setActiveRadioButtonId(id);
        this.props.saveAttributeToStore(id, true);
    };

    componentDidUpdate(prevProps, prevState) {
        const prevActiveId = prevProps.activeRadioButtonId;
        const currActiveId = this.props.activeRadioButtonId;
        const { id } = this.props.radioButtonsConfig;

        if (prevActiveId !== currActiveId && currActiveId !== id) {
            this.props.saveAttributeToStore(id, false);
        }
    }

    render() {
        const { id, label } = this.props.radioButtonsConfig;

        const { optionContentStyle } = this.props;

        const isRadioChecked = this.props.activeRadioButtonId === id;

        const descriptionClasses = classnames([
            commonStyles['font-medium'],
            optionContentStyle,
            styles.description,
            !isRadioChecked && styles.hidden,
        ]);

        return (
            <React.Fragment>
                <div className={commonStyles['flex-container']}>
                    <RadioButton
                        id={id}
                        onChange={this.radioButtonChangeHandler}
                        checked={isRadioChecked}
                        labelFor={id}
                        label={label}
                    />
                    <Tooltip
                        direction="left"
                        link="sales-channels/google-ads/attribute-mapping.html"
                    >
                        <FormattedMessage id="help.attributeMapping.createNewYuiAttribute" />
                    </Tooltip>
                </div>
                {isRadioChecked ? (
                    <div className={descriptionClasses}>
                        <FormattedMessage id="attributeMapping.categoryAttribute.radio_3.description" />
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}
