import update from 'immutability-helper';
import { Action, Reducer } from 'redux';

import {
    ADS_CONSENT_ADD,
    ADS_CONSENT_ERROR,
    ADS_CONSENT_REMOVE,
    ADS_SET_COUNTRY,
    ADS_SET_CURRENCY,
    ADS_SET_TIMEZONE,
    RESET_ADS_SETTINGS_STATE,
} from '../store/actionConsts';

export interface IAdsSettings
    extends Readonly<{
        consent: 'error' | string | null;
        country: string;
        currency: string;
        timezone: string;
    }> {}

export const initialState: IAdsSettings = {
    consent: null,
    country: '',
    currency: '',
    timezone: '',
};

function adsSettingsReducer(
    state: IAdsSettings = initialState,
    action:
        | IAdsConsentAddAction
        | IAdsConsentErrorAction
        | IAdsConsentRemoveAction
        | IAdsSetCountryAction
        | IAdsSetCurrencyAction
        | IAdsSetTimeZoneAction
        | IResetAdsSettingsStateAction,
) {
    switch (action.type) {
        case RESET_ADS_SETTINGS_STATE: {
            return initialState;
        }
        case ADS_CONSENT_ADD: {
            return update(state, {
                consent: {
                    $set: new Date()
                        .toISOString()
                        .split('T')
                        .join(' ')
                        .split('.')[0],
                },
            });
        }
        case ADS_CONSENT_REMOVE: {
            return update(state, {
                consent: { $set: null },
            });
        }
        case ADS_CONSENT_ERROR: {
            return update(state, {
                consent: { $set: 'error' },
            });
        }
        case ADS_SET_COUNTRY: {
            return update(state, {
                country: { $set: action.payload.country },
            });
        }
        case ADS_SET_CURRENCY: {
            return update(state, {
                currency: { $set: action.payload.currency },
            });
        }
        case ADS_SET_TIMEZONE: {
            return update(state, {
                timezone: { $set: action.payload.timezone },
            });
        }
        default: {
            return state;
        }
    }
}

export default adsSettingsReducer as Reducer;

export interface IAdsConsentAddAction extends Action {
    type: typeof ADS_CONSENT_ADD;
}

export interface IAdsConsentRemoveAction extends Action {
    type: typeof ADS_CONSENT_REMOVE;
}

export interface IAdsConsentErrorAction extends Action {
    type: typeof ADS_CONSENT_ERROR;
}

export interface IAdsSetCountryAction extends Action {
    payload: {
        country: string;
    };
    type: typeof ADS_SET_COUNTRY;
}

export interface IAdsSetCurrencyAction extends Action {
    payload: {
        currency: string;
    };
    type: typeof ADS_SET_CURRENCY;
}

export interface IAdsSetTimeZoneAction extends Action {
    payload: {
        timezone: string;
    };
    type: typeof ADS_SET_TIMEZONE;
}

export interface IResetAdsSettingsStateAction extends Action {
    type: typeof RESET_ADS_SETTINGS_STATE;
}
