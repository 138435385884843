import { ActionCreator } from 'redux';

import {
    IRemoveShippingCostSetOnGmc,
    IRemoveShippingTaxVatIncludedAction,
    ISaveOperateStatesAction,
    ISaveShippingCostSetOnGmcAction,
    ISaveShippingTaxVatIncludedAction,
    ISetShippingCostActiveRadioAction,
    ISetShippingTaxActiveRadioAction,
} from '../../reducers/websiteConfigReducer';
import {
    REMOVE_SHIPPING_COST_SET_ON_GMC,
    REMOVE_SHIPPING_TAX_VAT_INCLUDED,
    SAVE_OPERATE_STATES,
    SAVE_SHIPPING_COST_SET_ON_GMC,
    SAVE_SHIPPING_TAX_VAT_INCLUDED,
    SET_SHIPPING_COST_ACTIVE_RADIO,
    SET_SHIPPING_TAX_ACTIVE_RADIO,
} from '../../store/actionConsts';

export const saveActiveRadioButtonIdToStore: ActionCreator<
    ISetShippingCostActiveRadioAction
> = (radioId: string) => ({
    payload: {
        radioId,
    },
    type: SET_SHIPPING_COST_ACTIVE_RADIO,
});

export const saveActiveTaxRadioIdToStore: ActionCreator<
    ISetShippingTaxActiveRadioAction
> = (radioId: string) => ({
    payload: {
        radioId,
    },
    type: SET_SHIPPING_TAX_ACTIVE_RADIO,
});

export const saveSetupCostOnGmc: ActionCreator<
    ISaveShippingCostSetOnGmcAction
> = () => ({
    payload: {
        value: true,
    },
    type: SAVE_SHIPPING_COST_SET_ON_GMC,
});

export const removeSetCostOnGmc: ActionCreator<
    IRemoveShippingCostSetOnGmc
> = () => ({
    payload: {
        value: false,
    },
    type: REMOVE_SHIPPING_COST_SET_ON_GMC,
});

export const saveOperateStatesToStore: ActionCreator<
    ISaveOperateStatesAction
> = (value: string[], isValid: boolean) => ({
    payload: {
        isValid,
        value,
    },
    type: SAVE_OPERATE_STATES,
});

export const saveVatIncluded: ActionCreator<
    ISaveShippingTaxVatIncludedAction
> = () => ({
    payload: {
        value: true,
    },
    type: SAVE_SHIPPING_TAX_VAT_INCLUDED,
});

export const removeVatIncluded: ActionCreator<
    IRemoveShippingTaxVatIncludedAction
> = () => ({
    payload: {
        value: false,
    },
    type: REMOVE_SHIPPING_TAX_VAT_INCLUDED,
});
