import { ActionCreator } from 'redux';

import { ISaveStoreViewLanguageToStoreAction } from '../../../../../../../../reducers/websiteConfigReducer';
import { SAVE_STORE_VIEW_LANGUAGE_TO_STORE } from '../../../../../../../../store/actionConsts';

export const saveStoreViewLanguageToStore: ActionCreator<
    ISaveStoreViewLanguageToStoreAction
> = (payload: { label: string; uniqueRowId: string; value: string }) => ({
    payload,
    type: SAVE_STORE_VIEW_LANGUAGE_TO_STORE,
});
