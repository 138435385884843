import selectStyles from 'component/form/Select/ConfigurableSelect.styles';

const customSelectStyles = { ...selectStyles };

customSelectStyles.control = base => ({
    ...selectStyles.control(base),
    width: 646,
    '&:not(last-child)': {
        marginBottom: '20px',
    },
});

customSelectStyles.menu = base => ({
    ...selectStyles.menu(base),
    width: 611,
    top: 'calc(100% + 6px)',
});

customSelectStyles.multiValueLabel = base => ({
    ...selectStyles.multiValueLabel(base),
    fontWeight: 'normal',
});

export default customSelectStyles;
