import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from 'component/modal';

import { classnames } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './ResendingProductsModal.module.css';

export const ResendingProductsModal = ({ isVisible, isSuccess, onClose }) => {
    const headerStyles = classnames([commonStyles['header2'], styles.header2]);

    return (
        <Modal
            modalContainerStyle={styles['modal-container']}
            onOverlayClick={null}
            showCloseSign={true}
            handleCloseModal={onClose}
            isVisible={isVisible}
        >
            <h2 className={headerStyles}>
                <FormattedMessage
                    id={`settings.resendingProductsModal.${
                        isSuccess ? 'successHeader' : 'failureHeader'
                    }`}
                />
            </h2>
            <div className={styles['text-block']}>
                {isSuccess ? (
                    <React.Fragment>
                        <p className={styles.text}>
                            <FormattedMessage id="settings.resendingProductsModal.successText_1" />
                        </p>
                        <p className={styles.text}>
                            <FormattedMessage id="settings.resendingProductsModal.successText_2" />
                        </p>
                    </React.Fragment>
                ) : (
                    <p className={styles.text}>
                        <FormattedMessage id="settings.resendingProductsModal.failureText" />
                    </p>
                )}
            </div>
            <button className={styles.button} onClick={onClose}>
                <FormattedMessage id="settings.resendingProductsModal.buttonText" />
            </button>
        </Modal>
    );
};

ResendingProductsModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ResendingProductsModal;
