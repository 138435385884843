import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { classnames } from '../../../../util';

import { AdultOnlyAttribute } from './components';

import commonStyles from 'design/styles/common.module.css';
import styles from './AdultOnlyForm.module.css';

class AdultOnlyForm extends Component {
    static propTypes = {
        saveAttributeToStore: PropTypes.func.isRequired,
        validateAttribute: PropTypes.func.isRequired,
    };
    render() {
        const tableStyles = classnames([
            commonStyles.table,
            commonStyles['data-grid'],
            styles['adult-only-data-grid'],
        ]);
        const tableHeaderStyles = classnames([
            commonStyles['data-grid-th'],
            commonStyles['font-small'],
            commonStyles['font-semibold'],
        ]);
        const {
            props: { saveAttributeToStore, validateAttribute },
        } = this;

        return (
            <React.Fragment>
                <table className={tableStyles}>
                    <thead>
                        <tr>
                            <th className={tableHeaderStyles}>
                                <FormattedMessage id="attributeMapping.GoogleAttributesHeader" />
                            </th>
                            <th className={tableHeaderStyles}>
                                <FormattedMessage id="attributeMapping.YuiAttributesHeader" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <AdultOnlyAttribute
                            saveAttributeToStore={saveAttributeToStore}
                            validateAttribute={validateAttribute}
                        />
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default AdultOnlyForm;
