import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MiddleButton from 'component/MiddleButton';

import Modal from 'component/modal';
import { classnames } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './UpdateModal.module.css';

const getFeatures = (from, to) => {
    const features = [];

    for (let i = from; i <= to; i++) {
        features.push(
            <li key={i} className={styles['list-item']}>
                <span className={styles['list-item_text']}>
                    <FormattedMessage
                        id={`dashboardLanding.updateModal.feature_${i}`}
                    />
                </span>
            </li>,
        );
    }

    return features;
};

const UpdateModal = ({ isVisible, onClose }) => {
    const headerStyles = classnames([commonStyles['header2'], styles.header2]);

    return (
        <Modal
            modalContainerStyle={styles['modal-container']}
            showCloseSign={false}
            isVisible={isVisible}
        >
            <h2 className={headerStyles}>
                <FormattedMessage id="dashboardLanding.updateModal.header" />
            </h2>
            <div className={styles['text-block']}>
                <p className={styles.text}>
                    <FormattedMessage id="dashboardLanding.updateModal.text_1" />
                </p>
            </div>
            <ul className={styles['list-block']}>{getFeatures(1, 4)}</ul>
            <div className={styles['text-block']}>
                <p className={styles.text}>
                    <FormattedMessage id="dashboardLanding.updateModal.text_2" />
                </p>
            </div>
            <ul className={styles['list-block']}>{getFeatures(5, 7)}</ul>
            <a
                href="https://account.yui.sk"
                className={classnames([styles['button-base'], styles.button])}
                rel="noopener noreferrer"
            >
                <FormattedMessage id="dashboardLanding.updateModal.button_text" />
            </a>
            <MiddleButton
                className={classnames([styles['button-base'], styles.cancel])}
                onClick={onClose}
            >
                Cancel
            </MiddleButton>
        </Modal>
    );
};

UpdateModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default UpdateModal;
