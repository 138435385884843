import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { compact } from 'lodash';

import styles from './Modal.module.css';

class Modal extends Component {
    static propTypes = {
        isVisible: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.modalRoot = document.getElementById('modal_root');
        this.modalContainer = document.createElement('div');
    }

    componentDidMount() {
        this.modalRoot.appendChild(this.modalContainer);
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.modalContainer);
    }

    createModal = () => {
        return (
            <div
                className={styles['modal-overlay']}
                onClick={this.props.onOverlayClick}
            >
                <div
                    className={compact([
                        styles['modal-container-main'],
                        this.props.modalContainerStyle
                            ? this.props.modalContainerStyle
                            : '',
                    ]).join(' ')}
                >
                    {this.props.children}
                    {this.props.showCloseSign && (
                        <span
                            className={styles.close}
                            onClick={this.props.handleCloseModal}
                        />
                    )}
                </div>
            </div>
        );
    };

    render() {
        return (
            this.props.isVisible &&
            ReactDOM.createPortal(this.createModal(), this.modalContainer)
        );
    }
}

export default Modal;
