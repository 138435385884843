import React, { Component } from 'react';

import commonStyles from 'design/styles/common.module.css';
import styles from './RadioButton.module.css';

class RadioButton extends Component {
    render() {
        return (
            <React.Fragment>
                <input
                    type="radio"
                    className={styles['radio-button']}
                    id={this.props.id}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    checked={this.props.checked}
                />
                <label
                    className={commonStyles['font-regular']}
                    htmlFor={this.props.labelFor}
                >
                    {this.props.label}
                </label>
            </React.Fragment>
        );
    }
}

export default RadioButton;
