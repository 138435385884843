import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { find, get } from 'lodash';
import { classnames } from '../../../../util';

import Tooltip from 'component/Tooltip';

import Input from '../../../../component/form/Input';
import commonStyles from 'design/styles/common.module.css';
import styles from './CampaignName.module.css';

export class CampaignNameField extends Component {
    static propTypes = {
        isEditMode: PropTypes.bool.isRequired,
        fieldId: PropTypes.string.isRequired,
        campaignNameError: PropTypes.bool.isRequired,
        setFieldError: PropTypes.func.isRequired,
        unsetFieldError: PropTypes.func.isRequired,
        saveCampaignNameToStore: PropTypes.func.isRequired,
    };
    state = {
        value: '',
        isDuplicate: false,
    };

    componentDidMount() {
        this.prefillSelectedValue();
    }

    prefillSelectedValue = () => {
        const {
            isEditMode,
            adsCreation: { campaignName, editCampaignData },
        } = this.props;
        const value = isEditMode ? editCampaignData.name : campaignName;

        if (value) {
            this.setState({ value });

            if (isEditMode) {
                this.props.saveCampaignNameToStore(value);
            }
        }
    };

    validateDuplicate = value => {
        const {
            adsCreation: { allCampaignNames, editCampaignData },
            isEditMode,
        } = this.props;
        const editName = get(editCampaignData, 'name') || '';
        const isEditDuplicate =
            isEditMode &&
            value.trim().toLowerCase() === editName.trim().toLowerCase();

        const isDuplicate = find(
            allCampaignNames,
            campaignName =>
                value.trim().toLowerCase() ===
                campaignName.trim().toLowerCase(),
        );

        this.setState({ isDuplicate });

        if (isDuplicate) {
            this.props.setFieldError(this.props.fieldId);
        }

        return isDuplicate && !isEditDuplicate;
    };

    saveValue = value => {
        const isDuplicate = this.validateDuplicate(value);
        const isCorrectValue = !isDuplicate && (value || value.length === 0);

        if (isCorrectValue) {
            this.props.saveCampaignNameToStore(value);
            this.props.unsetFieldError(this.props.fieldId);
        } else {
            this.props.saveCampaignNameToStore('');
        }

        this.setState({ value });
    };

    inputChangeHandler = event => {
        let { value } = event.target;

        this.saveValue(value);
    };

    render() {
        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['medium-field-header-right-required'],
            commonStyles['font-semibold'],
        ]);
        const {
            props: { campaignNameError, fieldId },
            state: { value },
        } = this;

        return (
            <React.Fragment>
                <div className={styles['header-wrapper']}>
                    <span className={fieldHeadingStyles}>
                        <FormattedMessage id="smartShoppingCampaign.campaignNameInput.title" />
                    </span>
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/create-campaign.html"
                        className={commonStyles['cs-tooltip-left-margin']}
                    >
                        <FormattedMessage id="help.attributeMapping.adForm.name" />
                    </Tooltip>
                </div>
                <div className={styles['input-container']}>
                    <Input
                        {...{
                            errorMessage: campaignNameError && (
                                <FormattedMessage
                                    id={`smartShoppingCampaign.campaignNameInput.${
                                        this.state.isDuplicate
                                            ? 'duplicateError'
                                            : 'errorMessage'
                                    }`}
                                />
                            ),
                            maxLength: 128,
                            name: fieldId,
                            onChange: this.inputChangeHandler,
                            //@TODO: remove comments after fixing placeholder for input component
                            /* placeholder: (
                            <FormattedMessage id={[
                                'smartShoppingCampaign',
                                'campaignNameInput',
                                'placeholder',
                            ].join('.')} />
                        ), */
                            renderInput: input => (
                                <div className={styles['input-wrapper']}>
                                    {input}
                                </div>
                            ),
                            value,
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adsCreation: state.adsCreation,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(CampaignNameField);
