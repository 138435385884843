import React, { Component } from 'react';
import styles from './BackArrow.module.css';

/**
 * Big grey arrow component. Usually reflects back action.
 */
class BackArrow extends Component {
    render() {
        return (
            <button className={styles.button} {...this.props}>
                {this.props.children}
            </button>
        );
    }
}

export default BackArrow;
