import { ActionCreator } from 'redux';

import {
    IChannelsListData,
    IEditCampaignData,
    IResetAdsCreationStateAction,
    ISaveChannelIdAction,
    ISaveChannelsAction,
    ISaveEditCampaignDataToStore,
} from '../../reducers/adsCreationReducer';
import {
    IEmailSetAction,
    IYuiKeySetAction,
} from '../../reducers/websiteConfigReducer';

import {
    ISetReleaseNotes,
    ISetReleaseNotesCheckbox,
} from '../../reducers/releaseNotesReducer';
import {
    EMAIL_SET,
    MAGENTO_KEY_SET,
    RESET_ADS_CREATION_STATE,
    SAVE_CHANNEL_ID,
    SAVE_CHANNELS_LIST,
    SAVE_EDIT_CAMPAIGN_DATA_TO_STORE,
    SET_RELEASE_NOTES,
    SET_RELEASE_NOTES_HIDDEN,
} from '../../store/actionConsts';

export const saveChannels: ActionCreator<ISaveChannelsAction> = (
    channels: IChannelsListData[],
) => ({
    payload: {
        channels,
    },
    type: SAVE_CHANNELS_LIST,
});

export const setYuiKey: ActionCreator<IYuiKeySetAction> = (
    yuiKey: string,
) => ({
    payload: {
        yuiKey,
    },
    type: MAGENTO_KEY_SET,
});

export const setGoogleEmail: ActionCreator<IEmailSetAction> = (
    email: string,
) => ({
    payload: {
        email,
    },
    type: EMAIL_SET,
});

export const resetAdsCreationState: ActionCreator<
    IResetAdsCreationStateAction
> = () => ({
    type: RESET_ADS_CREATION_STATE,
});

export const saveEditCampaignDataToStore: ActionCreator<
    ISaveEditCampaignDataToStore
> = (payload: IEditCampaignData[]) => ({
    payload,
    type: SAVE_EDIT_CAMPAIGN_DATA_TO_STORE,
});

export const saveChannelIdToStore: ActionCreator<ISaveChannelIdAction> = (
    id: string,
) => ({
    payload: {
        id,
    },
    type: SAVE_CHANNEL_ID,
});

export const setReleaseNotes: ActionCreator<ISetReleaseNotes> = (payload: {
    id: string;
    features: ReadonlyArray<string>;
}) => ({
    payload,
    type: SET_RELEASE_NOTES,
});

export const setReleaseNotesCheckbox: ActionCreator<
    ISetReleaseNotesCheckbox
> = (payload: { hideReleaseNotesChecked: boolean }) => ({
    payload,
    type: SET_RELEASE_NOTES_HIDDEN,
});
