import { useEffect } from 'react';
import { get } from 'lodash';

const MAX_MAGENTO_VERSION_USING_ANALYTICS = '2.3.2';

const shouldSkipOurAnalytics = version1 => {
    const v1 = version1.split('.');
    const v2 = MAX_MAGENTO_VERSION_USING_ANALYTICS.split('.');
    if (!v1) return true;

    return !v1.every((v, i) => v <= v2[i]);
};

export default function OmegaTracking() {
    const yuiVersion = get(window, 'googleAppConfig.metadata.version');
    if (shouldSkipOurAnalytics(yuiVersion)) return null;
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `//assets.yui.sk/launch-${
            process.env.NODE_ENV === 'development'
                ? 'ENb2e48a6cce2f4ff995c4899e9e52785b-development'
                : process.env.REACT_APP_ENV === 'staging'
                ? 'EN8cb33f759edb4214a4f1fc182f72db3d-staging'
                : 'ENb7819db714284e61bb3556843bd1c52a'
        }.min.js`;
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return null;
}
