import React from 'react';
import { FormattedMessage } from 'react-intl';

import customStyles from './DescriptionText.module.css';

const DescriptionText = function(props) {
    const styles = props.styles;
    const headerStyles = [styles.header2, customStyles.header2];

    return (
        <React.Fragment>
            <h2 className={headerStyles.join(' ')}>
                <FormattedMessage id="adsTerms.header_1" />
            </h2>
            <p className={styles[`font-medium`]}>
                <FormattedMessage id="adsTerms.text_1" />
                <a
                    href="https://support.google.com/merchants/answer/6149970?hl=en"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FormattedMessage id="adsTerms.link_1" />
                </a>
                <FormattedMessage id="adsTerms.text_2" />
                <a
                    href="https://support.google.com/merchants/answer/6149970?hl=en"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FormattedMessage id="adsTerms.link_2" />
                </a>
                <FormattedMessage id="adsTerms.text_3" />
                <a
                    href="https://billing.google.com/payments/u/0/paymentsinfofinder?hostOrigin=aHR0cHM6Ly9iaWxsaW5nLmdvb2dsZS5jb206NDI2OA..&style=:md&sri=-21"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FormattedMessage id="adsTerms.link_3" />
                </a>
            </p>
            <p>
                <a
                    href="https://support.google.com/adspolicy/answer/6008942?hl=en"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FormattedMessage id="adsTerms.link_4" />
                </a>
            </p>
        </React.Fragment>
    );
};

export default DescriptionText;
