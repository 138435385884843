const quoteStart = `'|"|‘|“|‹|«`;
const quoteEnd = `'|"|’|”|›|»`;
export function unquote(value: string) {
    if (typeof value !== 'string' || !value.length) {
        return value;
    }

    let result = value;
    let { length } = result;

    if (result.charAt(0).match(new RegExp(quoteStart))) {
        result = result.substring(1, length--);
    }

    if (result.charAt(--length).match(new RegExp(quoteEnd))) {
        result = result.substring(0, length);
    }

    return result;
}
