import { ActionCreator } from 'redux';

import {
    IAdsConsentAddAction,
    IAdsConsentErrorAction,
    IAdsConsentRemoveAction,
} from '../../reducers/adsSettingsReducer';
import {
    ADS_CONSENT_ADD,
    ADS_CONSENT_ERROR,
    ADS_CONSENT_REMOVE,
} from '../../store/actionConsts';

export const addAdsConsent: ActionCreator<IAdsConsentAddAction> = () => ({
    type: ADS_CONSENT_ADD,
});

export const removeAdsConsent: ActionCreator<IAdsConsentRemoveAction> = () => ({
    type: ADS_CONSENT_REMOVE,
});

export const errorAdsConsent: ActionCreator<IAdsConsentErrorAction> = () => ({
    type: ADS_CONSENT_ERROR,
});
