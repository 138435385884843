import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';

import Modal from 'component/modal';
import urlConfig from 'config/urls';

import { OverviewContent } from './components';
import styles from './OverviewModal.module.css';

export default class OverviewModal extends Component {
    static propTypes = {
        isVisible: PropTypes.bool.isRequired,
    };

    state = {
        isPortalRendered: false,
    };

    constructor(props) {
        super(props);
        this.modalRoot = document.getElementById('modal_root');
        this.iframeContainer = document.createElement('div');
    }

    componentDidMount() {
        this.modalRoot.appendChild(this.iframeContainer);
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.iframeContainer);
    }

    proceedNextStep = () => {
        this.setState({ isPortalRendered: true });
    };

    render() {
        return (
            <Modal
                modalContainerStyle={styles['modal-container']}
                isVisible={this.props.isVisible}
            >
                <OverviewContent proceedNextStep={this.proceedNextStep} />
                {this.state.isPortalRendered && this.renderPortal()}
            </Modal>
        );
    }

    renderPortal() {
        /* istanbul ignore next */
        if (process.env.NODE_ENV === 'development') {
            const email = process.env.REACT_APP_GOOGLE_LOGIN_EMAIL;
            if (email) {
                return <Redirect to={`/google-callback/${email}`} />;
            }
        }
        const { href } = window.location;
        const finalUrl = encodeURIComponent(`${href}google-callback/`);
        return ReactDOM.createPortal(
            <iframe
                src={`${urlConfig.yuiGoogleUrl}?redirect=${finalUrl}`}
                title="Connect to Google"
                width="0"
                height="0"
            />,
            this.iframeContainer,
        );
    }
}
