import { ActionCreator } from 'redux';

import {
    IConsentAddAction,
    IConsentErrorAction,
    IConsentRemoveAction,
} from '../../reducers/websiteConfigReducer';
import {
    CONSENT_ADD,
    CONSENT_ERROR,
    CONSENT_REMOVE,
} from '../../store/actionConsts';

export const addConsent: ActionCreator<IConsentAddAction> = () => ({
    type: CONSENT_ADD,
});

export const removeConsent: ActionCreator<IConsentRemoveAction> = () => ({
    type: CONSENT_REMOVE,
});

export const errorConsent: ActionCreator<IConsentErrorAction> = () => ({
    type: CONSENT_ERROR,
});
