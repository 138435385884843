import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ConfigurableSelect from 'component/form/Select';
import { parseAttributesByDataType } from 'helpers/attributeMapping';

import { classnames } from '../../../../../../util';

import commonStyles from 'design/styles/common.module.css';
import tableStyles from '../tableStyles.module.css';
import styles from './ColorAttribute.module.css';

export class ColorAttribute extends Component {
    static propTypes = {
        attributeMapping: PropTypes.object.isRequired,
        saveAttributeToStore: PropTypes.func.isRequired,
        validateAttribute: PropTypes.func.isRequired,
    };
    static selectConfig = {
        name: 'color',
        requiredDataType: ['text', 'select'],
    };
    state = {
        options: [],
        selectedOption: [],
    };

    componentDidMount() {
        this.createOptions();
        this.prefillSelectedOptions();
    }

    createOptions() {
        this.setState({
            options: parseAttributesByDataType(
                ColorAttribute.selectConfig.requiredDataType,
            ),
        });
    }

    prefillSelectedOptions() {
        const { name } = ColorAttribute.selectConfig;
        const { data } = this.props.attributeMapping.attributes[name];

        if (data) {
            this.setState({
                selectedOption: data,
            });
        }
    }

    selectChangeHandler = selectedOption => {
        const attributeName = ColorAttribute.selectConfig.name;
        const payload = selectedOption;
        const required = this.props.attributeMapping.attributes[attributeName]
            .required;

        if (required) {
            this.props.validateAttribute(selectedOption, attributeName);
        }

        this.props.saveAttributeToStore(attributeName, payload);
        this.setState({ selectedOption });
    };

    render() {
        const descriptionClasses = classnames([
            commonStyles['font-medium'],
            styles.description,
        ]);
        const {
            state: { selectedOption, options },
        } = this;
        const { name } = ColorAttribute.selectConfig;
        const currentAttribute = this.props.attributeMapping.attributes[name];

        return (
            <React.Fragment>
                <tr>
                    <td className={tableStyles['table-data-grid-td']}>
                        <h2 className={commonStyles.header2}>
                            <FormattedMessage id="attributeMapping.colorAttribute.header" />
                        </h2>
                        <p className={descriptionClasses}>
                            <FormattedMessage id="attributeMapping.colorAttribute.descriptionText" />
                        </p>
                    </td>
                    <td className={tableStyles['table-data-grid-td']}>
                        <div className={styles['select-left-margin']}>
                            <ConfigurableSelect
                                onChange={this.selectChangeHandler}
                                value={selectedOption}
                                options={options}
                                showError={currentAttribute.error}
                                leftRequiredLayout={currentAttribute.required}
                                {...{
                                    name,
                                    placeholder: (
                                        <FormattedMessage id="attributeMapping.select.placeholder" />
                                    ),
                                    isMulti: true,
                                    isSearchable: true,
                                    errorMessage: (
                                        <FormattedMessage id="attributeMapping.select.errorMessage" />
                                    ),
                                }}
                            />
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    attributeMapping: state.attributeMapping,
});

export default connect(mapStateToProps)(ColorAttribute);
