import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { concat, reduce, find, map, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { RadioButton, ConfigurableSelect } from 'component/form';

import commonStyles from 'design/styles/common.module.css';
import styles from './SelectUsingFilters.module.css';
import customSelectStyles from './selectComponent.styles';

export default class SelectUsingFilters extends Component {
    state = {
        brandsOptions: [],
        categoriesOptions: [],
        selectedBrandsOptions: [],
        selectedCategoriesOptions: [],
    };

    static propTypes = {
        isEditMode: PropTypes.bool.isRequired,
        adsCreation: PropTypes.object.isRequired,
        radioButtonsConfig: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }).isRequired,
        setActiveRadioButtonId: PropTypes.func.isRequired,
        showError: PropTypes.bool.isRequired,
    };

    radioButtonChangeHandler = e =>
        this.props.setActiveRadioButtonId(e.target.id);

    brandsSelectChangeHandler = options => {
        this.setState({
            selectedBrandsOptions: options,
        });
        this.props.saveBrandsFilterToStore(options);

        if (!isEmpty(options)) {
            this.props.unsetFieldError('productFilter');
        }
    };

    categoriesSelectChangeHandler = options => {
        this.setState({
            selectedCategoriesOptions: options,
        });
        this.props.saveCategoriesFilterToStore(options);

        if (!isEmpty(options)) {
            this.props.unsetFieldError('productFilter');
        }
    };

    mapBrands = brands => {
        return reduce(
            brands,
            (result, value, key) =>
                concat(
                    result,
                    value.map(item => ({
                        label: item.label,
                        value: item.value,
                        brandType: key,
                    })),
                ),
            [],
        );
    };

    mapCategories = categories =>
        map(categories, ({ label, value }) => ({
            label: `${label} (Group ID - ${value})`,
            value,
            defaultLabel: label,
        }));

    componentDidMount = () => {
        this.prefillRadio();
        this.prefillSelectedOptions();
    };

    prefillRadio() {
        const {
            isEditMode,
            adsCreation: {
                editCampaignData: { filterType },
            },
            radioButtonsConfig: { id },
        } = this.props;
        const isSelected = isEditMode && filterType === 'brandCategory';

        if (isSelected) {
            this.props.setActiveRadioButtonId(id);
        }
    }

    prefillSelectedOptions() {
        const {
            props: {
                isEditMode,
                adsCreation: {
                    editCampaignData: { filters, filterType },
                    selectedFilterBrands,
                    selectedFilterCategories,
                },
            },
        } = this;
        const { brands, categories } = window.googleAppConfig;
        const mappedBrands = this.mapBrands(brands);
        const mappedCategories = this.mapCategories(categories);
        const isSelectProductsUsingFilters = filterType === 'brandCategory';

        if (isEditMode && !isEmpty(filters) && isSelectProductsUsingFilters) {
            this.prefillEditOptions(mappedBrands, mappedCategories);
        } else {
            this.setState({
                brandsOptions: mappedBrands,
                categoriesOptions: mappedCategories,
                selectedBrandsOptions: selectedFilterBrands || [],
                selectedCategoriesOptions: selectedFilterCategories || [],
            });
        }
    }

    prefillEditOptions(mappedBrands, mappedCategories) {
        const {
            props: {
                adsCreation: {
                    editCampaignData: { filters },
                },
            },
        } = this;
        const brandType = 'brand';
        const categoryTypes = ['category', 'custom_label_0'];
        const editBrandsOptions = map(filters, filter => {
            return find(
                mappedBrands,
                option =>
                    filter.type === brandType && option.label === filter.value,
            );
        }).filter(item => item !== undefined);
        const editCategoriesOptions = map(filters, filter => {
            return find(
                mappedCategories,
                option =>
                    categoryTypes.includes(filter.type) &&
                    option.value === filter.value,
            );
        }).filter(item => item !== undefined);

        this.setState({
            brandsOptions: mappedBrands,
            categoriesOptions: mappedCategories,
            selectedBrandsOptions: editBrandsOptions,
            selectedCategoriesOptions: editCategoriesOptions,
        });
        this.props.saveBrandsFilterToStore(editBrandsOptions);
        this.props.saveCategoriesFilterToStore(editCategoriesOptions);
    }

    render() {
        const {
            adsCreation: { activeRadioButtonId },
            radioButtonsConfig: { id, label },
            showError,
        } = this.props;

        const isRadioChecked = activeRadioButtonId === id;

        return (
            <React.Fragment>
                <RadioButton
                    id={id}
                    onChange={this.radioButtonChangeHandler}
                    checked={isRadioChecked}
                    labelFor={id}
                    label={label}
                />
                <p
                    className={[
                        commonStyles[`font-medium`],
                        styles['filters-descr'],
                    ].join(' ')}
                >
                    <FormattedMessage id="smartShoppingCampaign.filters.text_1" />
                </p>
                <ConfigurableSelect
                    styles={customSelectStyles}
                    onChange={this.brandsSelectChangeHandler}
                    value={this.state.selectedBrandsOptions}
                    isMulti={true}
                    isSearchable={true}
                    name="brands"
                    placeholder={
                        <FormattedMessage id="smartShoppingCampaign.select.brands_placeholder" />
                    }
                    options={this.state.brandsOptions}
                    isDisabled={!isRadioChecked}
                />
                <ConfigurableSelect
                    styles={customSelectStyles}
                    onChange={this.categoriesSelectChangeHandler}
                    value={this.state.selectedCategoriesOptions}
                    isMulti={true}
                    isSearchable={true}
                    name="categories"
                    placeholder={
                        <FormattedMessage id="smartShoppingCampaign.select.categories_placeholder" />
                    }
                    options={this.state.categoriesOptions}
                    isDisabled={!isRadioChecked}
                />

                {showError && (
                    <p className={commonStyles['bottom-error']}>
                        <FormattedMessage id="smartShoppingCampaign.filters.error" />
                    </p>
                )}
            </React.Fragment>
        );
    }
}
