import { combineReducers } from 'redux';

import adsCreation, { IAdsCreation } from '../reducers/adsCreationReducer';
import adsSettings, { IAdsSettings } from '../reducers/adsSettingsReducer';
import attributeMapping, {
    IAttributeMapping,
} from '../reducers/attributeMappingReducer';
import releaseNotes, { ReleaseNotes } from '../reducers/releaseNotesReducer';
import settings, { ISettings } from '../reducers/settingsReducer';
import websiteConfig, {
    IWebsiteConfig,
} from '../reducers/websiteConfigReducer';

export interface ReducersState {
    adsCreation?: IAdsCreation;
    adsSettings?: IAdsSettings;
    attributeMapping?: IAttributeMapping;
    settings?: ISettings;
    websiteConfig?: IWebsiteConfig;
    releaseNotes?: ReleaseNotes;
}

export default combineReducers<ReducersState>({
    adsCreation,
    adsSettings,
    attributeMapping,
    releaseNotes,
    settings,
    websiteConfig,
});
