import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { classnames } from '../../../../util';

import TitleAttribute from './components/TitleAttribute';
import DescriptionAttribute from './components/DescriptionAttribute';
import BrandAttribute from './components/BrandAttribute';
import GtinAttribute from './components/GtinAttribute';
import MpnAttribute from './components/MpnAttribute';
import ConditionAttribute from './components/ConditionAttribute';
import CategoryAttribute from './components/CategoryAttribute';
import IdentifierExistsAttribute from './components/IdentifierExistsAttribute';
import Tooltip from 'component/Tooltip';

import commonStyles from 'design/styles/common.module.css';
import styles from './GeneralAttributesForm.module.css';

class GeneralAttributesForm extends Component {
    render() {
        const tableStyles = classnames([
            commonStyles.table,
            commonStyles['data-grid'],
            styles['general-attributes-form'],
            styles['general-attributes-data-grid'],
        ]);
        const tableHeaderStyles = classnames([
            commonStyles['data-grid-th'],
            commonStyles['font-small'],
            commonStyles['font-semibold'],
        ]);
        const headerStyles = classnames([
            commonStyles.header3,
            commonStyles['font-semibold'],
            styles.header3,
        ]);
        const descriptionStyles = classnames([
            commonStyles['font-medium'],
            commonStyles['general-attributes-text'],
        ]);

        return (
            <React.Fragment>
                <h3 className={headerStyles}>
                    <FormattedMessage id="attributeMapping.header_2" />
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/attribute-mapping.html"
                    >
                        <FormattedMessage id="help.attributeMapping.generalAttributes" />
                    </Tooltip>
                </h3>
                <p className={descriptionStyles}>
                    <FormattedMessage id="attributeMapping.text_3" />
                </p>
                <table className={tableStyles}>
                    <thead>
                        <tr>
                            <th className={tableHeaderStyles}>
                                <FormattedMessage id="attributeMapping.GoogleAttributesHeader" />
                            </th>
                            <th className={tableHeaderStyles}>
                                <FormattedMessage id="attributeMapping.YuiAttributesHeader" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <TitleAttribute
                            isEditMode={this.props.isEditMode}
                            savePredefinedAttributeValue={
                                this.props.savePredefinedAttributeValue
                            }
                            saveAttributeToStore={
                                this.props.saveAttributeToStore
                            }
                            validateAttribute={this.props.validateAttribute}
                            attributeMapping={this.props.attributeMapping}
                        />
                        <DescriptionAttribute
                            isEditMode={this.props.isEditMode}
                            savePredefinedAttributeValue={
                                this.props.savePredefinedAttributeValue
                            }
                            saveAttributeToStore={
                                this.props.saveAttributeToStore
                            }
                            validateAttribute={this.props.validateAttribute}
                            attributeMapping={this.props.attributeMapping}
                        />
                        <CategoryAttribute
                            saveAttributeToStore={
                                this.props.saveAttributeToStore
                            }
                            validateAttribute={this.props.validateAttribute}
                            attributeMapping={this.props.attributeMapping}
                            setAttributeAsRequired={
                                this.props.setAttributeAsRequired
                            }
                            unsetAttributeAsRequired={
                                this.props.unsetAttributeAsRequired
                            }
                        />
                        <ConditionAttribute
                            saveAttributeToStore={
                                this.props.saveAttributeToStore
                            }
                            validateAttribute={this.props.validateAttribute}
                            attributeMapping={this.props.attributeMapping}
                            setAttributeAsRequired={
                                this.props.setAttributeAsRequired
                            }
                            unsetAttributeAsRequired={
                                this.props.unsetAttributeAsRequired
                            }
                        />
                        <BrandAttribute
                            saveAttributeToStore={
                                this.props.saveAttributeToStore
                            }
                            validateAttribute={this.props.validateAttribute}
                            attributeMapping={this.props.attributeMapping}
                        />
                        <GtinAttribute
                            saveAttributeToStore={
                                this.props.saveAttributeToStore
                            }
                            validateAttribute={this.props.validateAttribute}
                            attributeMapping={this.props.attributeMapping}
                        />
                        <MpnAttribute
                            saveAttributeToStore={
                                this.props.saveAttributeToStore
                            }
                            validateAttribute={this.props.validateAttribute}
                            attributeMapping={this.props.attributeMapping}
                        />
                        <IdentifierExistsAttribute
                            saveAttributeToStore={
                                this.props.saveAttributeToStore
                            }
                            validateAttribute={this.props.validateAttribute}
                            attributeMapping={this.props.attributeMapping}
                            setAttributeAsRequired={
                                this.props.setAttributeAsRequired
                            }
                            unsetAttributeAsRequired={
                                this.props.unsetAttributeAsRequired
                            }
                        />
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default GeneralAttributesForm;
