import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonStyles from 'design/styles/common.module.css';
import styles from './CancelModalContent.module.css';

export default function CancelModalContent(props) {
    const headerStyles = [
        commonStyles['header2'],
        styles.header2,
        commonStyles['font-semibold'],
    ];
    const textStyles = [commonStyles['font-medium'], styles['font-medium']];
    const cancelButtonStyles = [
        commonStyles['font-semibold'],
        styles.button,
        styles['cancel-button'],
    ];
    const confirmButtonStyles = [
        commonStyles['font-semibold'],
        styles.button,
        styles['confirm-button'],
    ];

    return (
        <React.Fragment>
            <h2 className={headerStyles.join(' ')}>
                <FormattedMessage id="smartShoppingCampaign.skipModal.title" />
            </h2>
            <p className={textStyles.join(' ')}>
                <FormattedMessage id="smartShoppingCampaign.skipModal.text" />
            </p>
            <button
                className={confirmButtonStyles.join(' ')}
                onClick={props.handleConfirmModal}
            >
                <FormattedMessage id="smartShoppingCampaign.skipModal.confirmButton" />
            </button>
            <button
                className={cancelButtonStyles.join(' ')}
                onClick={props.handleCloseModal}
            >
                <FormattedMessage id="smartShoppingCampaign.skipModal.cancelButton" />
            </button>
        </React.Fragment>
    );
}
