import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import { FormattedMessage } from 'react-intl';
import { get, reduce, isEmpty, pickBy } from 'lodash';

import ConfigurableSelect from 'component/form/Select';
import customSelectStyles from '../../StoreViewSelect.style';

export class LanguageField extends Component {
    static propTypes = {
        tableData: PropTypes.array.isRequired,
        data: PropTypes.object.isRequired,
        saveStoreViewLanguageToStore: PropTypes.func.isRequired,
        showDuplicateError: PropTypes.func.isRequired,
        hideDuplicateError: PropTypes.func.isRequired,
        hideStoreViewRequiredError: PropTypes.func.isRequired,
        hideStoreViewsLengthError: PropTypes.func.isRequired,
        isEdit: PropTypes.bool.isRequired,
    };
    state = {
        options: [],
        selectedOption: [],
        isPrefilled: false,
    };

    componentDidMount() {
        this.prefillSelectedOption();
    }

    componentDidUpdate(prevProps) {
        this.updateOptionsOnCountryChange(prevProps);
    }

    prefillSelectedOption() {
        const options = get(this.props.data, 'languages') || [];
        const selectedOption = get(this.props.data, 'language') || [];
        const language = get(selectedOption, 'value') || '';

        this.setState(
            pickBy({
                options,
                selectedOption: language && selectedOption,
                isPrefilled: !!language,
            }),
        );
    }

    updateOptionsOnCountryChange(prevProps) {
        const prevCountry = get(prevProps.data.country, 'value') || '';
        const country = get(this.props.data.country, 'value') || '';
        const languages = get(this.props.data, 'languages') || [];

        if (prevCountry !== country) {
            this.setState({
                options: languages,
                selectedOption: [],
            });
        }
    }

    validateDuplicate(value) {
        const { data, tableData } = this.props;

        return !isEmpty(
            reduce(
                tableData,
                (errors, rowData) => {
                    const { code, country, language, uniqueRowId } = rowData;
                    const isCodeDuplicate =
                        !isEmpty(code.value) && data.code.value === code.value;
                    const isCountryDuplicate =
                        data.country.value === country.value;
                    const isLanguageDuplicate = value === language.value;
                    const isCurrentRow = data.uniqueRowId === uniqueRowId;
                    const isDuplicateInput =
                        isCodeDuplicate &&
                        isCountryDuplicate &&
                        isLanguageDuplicate;

                    if (!isCurrentRow && isDuplicateInput) {
                        errors.push(rowData);
                        this.props.showDuplicateError();
                        return errors;
                    }
                    return errors;
                },
                [],
            ),
        );
    }

    selectChangeHandler = selectedOption => {
        const value = get(selectedOption, 'value') || '';
        const label = get(selectedOption, 'label') || '';
        const { uniqueRowId } = this.props.data;
        const payload = {
            value,
            label,
            uniqueRowId,
        };
        const isDuplicate = this.validateDuplicate(value);

        if (!isDuplicate) {
            this.setState({
                selectedOption,
                isPrefilled: false,
            });
            this.props.saveStoreViewLanguageToStore(payload, uniqueRowId);
            this.props.hideDuplicateError();
        }
        this.props.hideStoreViewRequiredError();
        this.props.hideStoreViewsLengthError();
    };

    render() {
        const {
            selectChangeHandler,
            state: { options, selectedOption, isPrefilled },
            props: { isEdit },
        } = this;

        return (
            <div>
                <ConfigurableSelect
                    styles={customSelectStyles}
                    options={options}
                    onChange={selectChangeHandler}
                    value={selectedOption}
                    {...{
                        isMulti: false,
                        isSearchable: true,
                        blurInputOnSelect: true,
                        name: 'storeViewLanguage',
                        placeholder: (
                            <FormattedMessage id="urlVerification.storeViewsTable.selectPlaceholder" />
                        ),
                        isDisabled: (isEdit && isPrefilled) || !options.length,
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tableData:
        state.websiteConfig.new.urlVerification.websiteStoreViews.tableData,
    isEdit: state.websiteConfig.new.isEdit,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(LanguageField);
