import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import { isEmpty, reduce, some } from 'lodash';

import {
    GeneralAttributesForm,
    ApparelAndAccessoriesForm,
    AdultOnlyForm,
} from './components';
import Accordion from 'component/accordion';

import styles from './AttributeMappingForm.module.css';

export class AttributeMappingForm extends Component {
    static propTypes = {
        attributeMapping: PropTypes.object.isRequired,
        validateAttribute: PropTypes.func.isRequired,
        validateSpecificYuiAttribute: PropTypes.func.isRequired,
        setAttributeAsRequired: PropTypes.func.isRequired,
        unsetAttributeAsRequired: PropTypes.func.isRequired,
        setSpecificMappingTableError: PropTypes.func.isRequired,
        unsetSpecificMappingTableError: PropTypes.func.isRequired,
        saveAttributeToStore: PropTypes.func.isRequired,
        savePredefinedAttributeValue: PropTypes.func.isRequired,
        isSaving: PropTypes.bool.isRequired,
        confirmSaving: PropTypes.func.isRequired,
        rejectSaving: PropTypes.func.isRequired,
    };

    validateRequiredAttributes() {
        const attributes = this.props.attributeMapping.attributes;
        const specificAttributes = ['ageGroup', 'gender', 'condition'];

        return isEmpty(
            reduce(
                attributes,
                (result, attribute, attributeName) => {
                    const isSpecificAttribute = some(
                        specificAttributes,
                        name => name === attributeName,
                    );
                    const isAttributeError =
                        attribute.required && isEmpty(attribute.data);

                    if (!isSpecificAttribute && isAttributeError) {
                        this.props.validateAttribute(
                            attribute.data,
                            attributeName,
                        );
                        return [...result, attributeName];
                    }
                    if (isSpecificAttribute && attribute.required) {
                        return !this.isSpecificAttributeValid(attributeName)
                            ? [...result, attributeName]
                            : result;
                    }
                    return result;
                },
                [],
            ),
        );
    }

    isSpecificAttributeValid(attributeName) {
        const { attributes } = this.props.attributeMapping;
        const radioName = attributes[`${attributeName}AttrRadioId`].data;

        if (isEmpty(radioName)) {
            this.props.validateAttribute(
                radioName,
                `${attributeName}AttrRadioId`,
            );
            return false;
        }

        return this.validateSpecificAttribute(attributeName, radioName);
    }

    validateSpecificAttribute(attributeName, radioName) {
        const { attributes } = this.props.attributeMapping;
        const specificAttribute = attributes[attributeName];

        switch (radioName) {
            case 'ageGroupCommon':
            case 'genderCommon':
            case 'conditionCommon':
                return this.validateCommonSpecificMapping(
                    specificAttribute,
                    attributeName,
                );
            case 'ageGroupMultiple':
            case 'genderMultiple':
            case 'conditionMultiple':
                return this.validateMultipleSpecificMapping(
                    specificAttribute,
                    attributeName,
                );
            default:
                return true;
        }
    }

    validateCommonSpecificMapping(specificAttribute, attributeName) {
        const { data } = specificAttribute;
        const payload = !isEmpty(data) ? [data] : [];

        this.props.validateAttribute(payload, attributeName);

        return !isEmpty(payload);
    }

    validateMultipleSpecificMapping(specificAttribute, attributeName) {
        this.props.validateSpecificYuiAttribute(
            attributeName,
            specificAttribute.specificData,
        );
        !isEmpty(specificAttribute.specificOptions)
            ? this.props.setSpecificMappingTableError(attributeName)
            : this.props.unsetSpecificMappingTableError(attributeName);

        return (
            !isEmpty(specificAttribute.specificData) &&
            isEmpty(specificAttribute.specificOptions)
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isSaving !== this.props.isSaving && this.props.isSaving) {
            const isAttributesValid = this.validateRequiredAttributes();

            if (isAttributesValid) {
                this.props.confirmSaving();
            } else {
                this.props.rejectSaving();
            }
        }
    }

    render() {
        const {
            savePredefinedAttributeValue,
            saveAttributeToStore,
            validateAttribute,
            setAttributeAsRequired,
            unsetAttributeAsRequired,
            attributeMapping,
        } = this.props;

        return (
            <React.Fragment>
                <GeneralAttributesForm
                    isEditMode={attributeMapping.isEditMode}
                    savePredefinedAttributeValue={savePredefinedAttributeValue}
                    saveAttributeToStore={saveAttributeToStore}
                    validateAttribute={validateAttribute}
                    attributeMapping={attributeMapping}
                    setAttributeAsRequired={setAttributeAsRequired}
                    unsetAttributeAsRequired={unsetAttributeAsRequired}
                />
                <section className={styles.attributesSection}>
                    <Accordion
                        header={
                            <FormattedMessage id="attributeMapping.apparelAndAccessoriesForm.header" />
                        }
                        text={
                            <FormattedMessage id="attributeMapping.apparelAndAccessoriesForm.description" />
                        }
                    >
                        <ApparelAndAccessoriesForm
                            saveAttributeToStore={saveAttributeToStore}
                            validateAttribute={validateAttribute}
                            setAttributeAsRequired={setAttributeAsRequired}
                            unsetAttributeAsRequired={unsetAttributeAsRequired}
                        />
                    </Accordion>
                </section>
                <section className={styles.attributesSection}>
                    <Accordion
                        header={
                            <FormattedMessage id="attributeMapping.adultOnlyForm.header" />
                        }
                        text={
                            <FormattedMessage id="attributeMapping.adultOnlyForm.description" />
                        }
                    >
                        <AdultOnlyForm
                            saveAttributeToStore={saveAttributeToStore}
                            validateAttribute={validateAttribute}
                        />
                    </Accordion>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    attributeMapping: state.attributeMapping,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(AttributeMappingForm);
