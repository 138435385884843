// tslint:disable-next-line:no-any
export type Listener = (...args: any[]) => void;

type RegistryItems = Map<
    Date,
    {
        listener: Listener;
        times: number;
    }
>;

export default class EventEmitter {
    private registry = new Map<string, RegistryItems>();

    /**
     * Synchronously calls each of the listeners registered for the event named
     * `eventName`, in the order they were registered, passing the supplied
     * arguments to each.
     * @returns `true` if the event had listeners, `false` otherwise.
     */
    // tslint:disable-next-line:no-any
    public emit(eventName: string, ...rest: any[]) {
        const items = this.registry.get(eventName);
        if (!items) {
            // tslint:disable-next-line:no-console
            console.warn(`Nothing listening to event name: ${eventName}.`);
            return false;
        }
        items.forEach((item, key) => {
            if (--item.times === 0) {
                this.off(items, key, eventName);
            }
            item.listener(...rest);
        });
        return true;
    }

    /**
     * Adds the `listener` function to the end of the listeners map for the
     * event named `eventName`.
     * - No checks are made to see if the `listener` has
     * already been added.
     * - Multiple calls passing the same combination of `eventName` and
     * `listener` will result in the `listener` being added and called multiple
     * times.
     */
    public on(
        eventName: string,
        /**
         * The callback function.
         */
        listener: Listener,
        /**
         * options
         */
        {
            times,
        }: {
            /**
             * Number of times `emit` may be called before the listener is removed.
             */
            times: number;
        } = {
            times: Number.POSITIVE_INFINITY,
        },
    ) {
        const items =
            this.registry.get(eventName) || (new Map() as RegistryItems);
        const key = new Date();
        if (items.set(key, { listener, times }).size === 1) {
            this.registry.set(eventName, items);
        }
        /**
         * Removes the `listener` from the `eventName` map without removing
         * similar listeners (i.e., the same `eventName` + `listener` combo).
         */
        const off = () => this.off(items, key, eventName);
        return off;
    }

    /**
     * Adds a *one-time* `listener` function for the event named `eventName`.
     * The next time `eventName` is triggered, this listener is removed and then invoked.
     */
    public once(eventName: string, listener: Listener) {
        return this.on(eventName, listener, { times: 1 });
    }

    private off(items: RegistryItems, key: Date, eventName: string) {
        const isDeleted = items.delete(key);
        if (items.size === 0) {
            this.registry.delete(eventName);
        }
        return isDeleted;
    }
}
