import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    GmcAccountField,
    AdvertiseCountryField,
    CampaignNameField,
    DailyBudgetField,
    ProductsFilters,
} from './components';

export default class AdForms extends Component {
    static propTypes = {
        isEditMode: PropTypes.bool.isRequired,
        campaignMainFormFieldIds: PropTypes.object.isRequired,
        setFieldError: PropTypes.func.isRequired,
        unsetFieldError: PropTypes.func.isRequired,
        gmcAccountError: PropTypes.bool.isRequired,
        countryToAdvertiseError: PropTypes.bool.isRequired,
        campaignNameError: PropTypes.bool.isRequired,
        dailyBudgetError: PropTypes.bool.isRequired,
        productFilterError: PropTypes.bool.isRequired,
        showNotification: PropTypes.func,
        showLoader: PropTypes.func.isRequired,
        hideLoader: PropTypes.func.isRequired,
    };

    render() {
        const {
            props: {
                campaignMainFormFieldIds,
                setFieldError,
                unsetFieldError,
                gmcAccountError,
                countryToAdvertiseError,
                campaignNameError,
                dailyBudgetError,
                isEditMode,
                productFilterError,
                showNotification,
                showLoader,
                hideLoader,
            },
        } = this;

        return (
            <React.Fragment>
                <GmcAccountField
                    isEditMode={isEditMode}
                    fieldId={campaignMainFormFieldIds.gmcAccount}
                    unsetFieldError={unsetFieldError}
                    gmcAccountError={gmcAccountError}
                    showLoader={showLoader}
                    hideLoader={hideLoader}
                    showNotification={showNotification}
                />
                <AdvertiseCountryField
                    isEditMode={isEditMode}
                    fieldId={campaignMainFormFieldIds.countryToAdvertise}
                    unsetFieldError={unsetFieldError}
                    countryToAdvertiseError={countryToAdvertiseError}
                />
                <CampaignNameField
                    isEditMode={isEditMode}
                    fieldId={campaignMainFormFieldIds.campaignName}
                    setFieldError={setFieldError}
                    unsetFieldError={unsetFieldError}
                    campaignNameError={campaignNameError}
                />
                <DailyBudgetField
                    isEditMode={isEditMode}
                    fieldId={campaignMainFormFieldIds.dailyBudget}
                    unsetFieldError={unsetFieldError}
                    dailyBudgetError={dailyBudgetError}
                />
                <ProductsFilters
                    isEditMode={isEditMode}
                    productFilterError={productFilterError}
                    unsetFieldError={unsetFieldError}
                />
            </React.Fragment>
        );
    }
}
