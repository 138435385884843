import React from 'react';
import styles from './MiddleButton.module.css';

import { classnames } from '../util';

export default function MiddleButton(props) {
    return (
        <button
            {...props}
            className={classnames([styles.middleButton, props.className])}
        />
    );
}
