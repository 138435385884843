import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty, find, get } from 'lodash';
import { countryOfSales } from 'config/countryOfSales';
import PropTypes from 'prop-types';

import Tooltip from 'component/Tooltip';

import ConfigurableSelect from 'component/form/Select';
import commonStyles from 'design/styles/common.module.css';
import styles from './CountryField.module.css';
import customSelectStyles from './CountryField.styles';

import { classnames } from '../../../../../../util';

export default class CountryField extends Component {
    static propTypes = {
        country: PropTypes.string.isRequired,
        saveCountryToStore: PropTypes.func.isRequired,
        saveCurrencyToStore: PropTypes.func.isRequired,
        showCountryError: PropTypes.func.isRequired,
        hideCountryError: PropTypes.func.isRequired,
        countryError: PropTypes.bool.isRequired,
    };

    state = {
        selectedOption: null,
        currency: '',
    };

    componentDidMount() {
        this.checkPrefilledOption();
    }

    checkPrefilledOption() {
        const countryCode = this.props.country;

        const configCountryCode = get(
            window.googleAppConfig.websites.find(website => website.id <= 0),
            'country',
            '',
        );

        this.selectChangeHandler(
            find(countryOfSales, { value: countryCode || configCountryCode }),
        );
    }

    setCountryOption(selectedOption) {
        this.setState({ selectedOption });
    }

    setCurrencyOption(currency) {
        this.setState({ currency });
    }

    selectChangeHandler = selectedOption => {
        const hasError = this.props.countryError;
        const country = get(selectedOption, 'value', '');
        const currency = get(selectedOption, 'currencyValue', '');

        this.props.saveCountryToStore(country);
        this.props.saveCurrencyToStore(currency);
        this.setCountryOption(selectedOption);
        this.setCurrencyOption(currency);

        if (hasError && !isEmpty(selectedOption)) {
            this.props.hideCountryError();
        }
    };

    render() {
        const {
            state: { currency, selectedOption },
            props: { countryError },
        } = this;

        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['medium-field-header-right-required'],
            commonStyles['font-semibold'],
        ]);
        const currencyHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['font-semibold'],
            styles['currency-field'],
        ]);

        return (
            <div className={styles['field-wrapper']}>
                <div className={styles['field-header-wrapper']}>
                    <span className={fieldHeadingStyles}>
                        <FormattedMessage id="accountSettings.select.countryHeader" />
                    </span>
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/acct-settings.html"
                        className={commonStyles['cs-tooltip-left-margin']}
                    >
                        <FormattedMessage id="help.attributeMapping.accountSettings.country" />
                    </Tooltip>
                </div>
                <ConfigurableSelect
                    styles={customSelectStyles}
                    onChange={this.selectChangeHandler}
                    value={selectedOption}
                    isMulti={false}
                    isSearchable={true}
                    name="country"
                    placeholder={
                        <FormattedMessage id="accountSettings.select.placeholder" />
                    }
                    options={countryOfSales}
                    showError={countryError}
                    errorMessage={
                        <FormattedMessage id="accountSettings.select.countryError" />
                    }
                    blurInputOnSelect={true}
                />
                <div className={currencyHeadingStyles}>
                    <FormattedMessage id="accountSettings.currencyField.text" />
                    {currency || '—'}
                </div>
            </div>
        );
    }
}
