import React from 'react';
import { FormattedMessage } from 'react-intl';

import { classnames } from '../../../../../../util';

import commonStyles from 'design/styles/common.module.css';

export default function TableHead() {
    const tableHeaderStyles = classnames([
        commonStyles['data-grid-th'],
        commonStyles['font-small'],
    ]);

    return (
        <thead>
            <tr>
                <th className={tableHeaderStyles}>
                    <FormattedMessage id="urlVerification.storeViewsTable.viewNameHeader" />
                </th>
                <th className={tableHeaderStyles}>
                    <FormattedMessage id="urlVerification.storeViewsTable.countryHeader" />
                </th>
                <th className={tableHeaderStyles}>
                    <FormattedMessage id="urlVerification.storeViewsTable.languageHeader" />
                </th>
            </tr>
        </thead>
    );
}
