import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RadioButton from 'component/form/RadioButton';
import { FormattedMessage } from 'react-intl';

import FlatRateTable from './components';
import Tooltip from 'component/Tooltip';

export class ShippingCost extends Component {
    static propTypes = {
        removeShippingCostFlatRateError: PropTypes.func.isRequired,
        setActiveRadioButtonState: PropTypes.func.isRequired,
        removeSetCostOnGmc: PropTypes.func.isRequired,
        radioButtonIds: PropTypes.object.isRequired,
        activeRadioButtonId: PropTypes.string.isRequired,
    };
    constructor(props) {
        super(props);
        this.radioButtonConfig = {
            label: <FormattedMessage id="shippingAndTax.cost.radio_1" />,
            id: this.props.radioButtonIds.flatRateCost,
        };
    }

    radioButtonChangeHandler = event => {
        this.props.setActiveRadioButtonState(event.target.id);
        this.props.removeSetCostOnGmc();
    };

    render() {
        const isRadioChecked =
            this.props.activeRadioButtonId === this.radioButtonConfig.id;
        return (
            <div>
                <RadioButton
                    id={this.radioButtonConfig.id}
                    name={this.radioButtonConfig.id}
                    onChange={this.radioButtonChangeHandler}
                    checked={isRadioChecked}
                    labelFor={this.radioButtonConfig.id}
                    label={this.radioButtonConfig.label}
                />
                <Tooltip
                    direction="right"
                    link="sales-channels/google-ads/shipping-tax.html"
                >
                    <FormattedMessage id="help.shippingAndTax.flatRate" />
                </Tooltip>
                <FlatRateTable
                    isRadioChecked={isRadioChecked}
                    removeShippingCostFlatRateError={
                        this.props.removeShippingCostFlatRateError
                    }
                    flatRateFieldError={this.props.flatRateFieldError}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    websiteUrl: state.websiteConfig.new.urlVerification.websiteUrl.value,
});

export default connect(mapStateToProps)(ShippingCost);
