import { compact, find, floor, get, toNumber } from 'lodash';

import currencies from './config/currencies';

export function classnames(classList: string[]) {
    return compact(classList).join(' ');
}

export function createYuiDocsLink(topicLink: string) {
    const metadata = get(window.googleAppConfig, 'metadata');

    const base = 'https://docs.yui.sk/';

    if (!metadata || !metadata.version || !metadata.edition) {
        return base;
    }

    const convertedVersion = extractMajorMinor(metadata.version);
    const convertedEdition = shortenYuiEdition(metadata.edition);

    return `${base}m2/${convertedVersion}/${convertedEdition}/user_guide/${topicLink}`;
}

export function fromBudget(value: string | number) {
    return floor(toNumber(value) / 1000000, 2) || 0;
}

export function extractMajorMinor(version: string) {
    return version.split('.', 2).join('.');
}

const editions = {
    B2B: 'b2b',
    Community: 'ce',
    Enterprise: 'ee',
};

export function shortenYuiEdition<T extends keyof typeof editions>(
    edition: T,
) {
    return editions[edition];
}

export function isNumeric(n: string | number) {
    return !isNaN(parseFloat(n as string)) && isFinite(n as number);
}

export function toBudget(value: string | number, currencyCode = 'USD') {
    if (!isNumeric(value) || !currencyCode) {
        return '';
    }

    const minimumUnit = get(
        find(currencies, { code: currencyCode }),
        'minimumUnit',
        10000,
    );

    return (
        minimumUnit *
        (minimumUnit === 1000000
            ? floor(value as number)
            : floor(value as number, 2) * 100)
    ).toFixed();
}
