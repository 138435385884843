import React, { Component } from 'react';
import Select from 'react-select';
import commonStyles from 'design/styles/common.module.css';
import customStyles from './ConfigurableSelect.module.css';
import selectStyles from './ConfigurableSelect.styles';

class ConfigurableSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuIsOpen: false,
        };
    }

    createSelectElement() {
        const customStyles = this.props.styles;
        const styles = customStyles ? customStyles : selectStyles;

        return (
            <Select
                className="select-container"
                classNamePrefix="select"
                styles={styles}
                onChange={this.props.onChange}
                value={this.props.value}
                isMulti={this.props.isMulti}
                isSearchable={this.props.isSearchable}
                name={this.props.name}
                placeholder={this.props.placeholder}
                options={this.props.options}
                onBlur={this.hideMenu}
                onFocus={this.showMenu}
                menuIsOpen={this.state.menuIsOpen}
                openMenuOnClick={false}
                closeMenuOnSelect={this.props.closeMenuOnSelect}
                blurInputOnSelect={this.props.blurInputOnSelect}
                isDisabled={this.props.isDisabled}
            />
        );
    }

    showMenu = () => {
        this.setState(prevState => ({
            menuIsOpen: !prevState.menuIsOpen,
        }));
    };

    hideMenu = () => {
        this.setState(prevState => ({
            menuIsOpen: !prevState.menuIsOpen,
        }));
    };

    render() {
        const mainStyles = [
            customStyles['configurable-select'],
            this.props.leftRequiredLayout
                ? customStyles['configurable-select-left-required']
                : '',
            this.props.isDisabled
                ? customStyles['configurable-select-disabled']
                : '',
        ];
        const selectElement = this.createSelectElement();

        return (
            <div className={mainStyles.join(' ')}>
                {selectElement}
                {this.props.showError && (
                    <span className={commonStyles['bottom-error']}>
                        {this.props.errorMessage}
                    </span>
                )}
            </div>
        );
    }
}

export default ConfigurableSelect;
