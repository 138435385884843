import { ActionCreator } from 'redux';

import {
    ISaveBrandsFilterAction,
    ISaveCategoriesFilterAction,
    ISaveChannelIdAction,
    ISaveExcludedProductsAction,
    ISaveFiltersActiveRadioAction,
    ISaveIncludedProductsAction,
    ISaveSelectAllOptionAction,
    IToggleTableSelectAllAction,
} from '../../../../reducers/adsCreationReducer';
import {
    SAVE_BRANDS_FILTER,
    SAVE_CATEGORIES_FILTER,
    SAVE_CHANNEL_ID,
    SAVE_EXCLUDED_PRODUCTS,
    SAVE_FILTERS_ACTIVE_RADIO,
    SAVE_INCLUDED_PRODUCTS,
    SAVE_SELECT_ALL_OPTION,
    TOGGLE_TABLE_SELECT_ALL,
} from '../../../../store/actionConsts';

export const saveChannelIdToStore: ActionCreator<ISaveChannelIdAction> = (
    id: string,
) => ({
    payload: {
        id,
    },
    type: SAVE_CHANNEL_ID,
});

export const saveFiltersActiveRadioToStore: ActionCreator<
    ISaveFiltersActiveRadioAction
> = (id: string) => ({
    payload: {
        id,
    },
    type: SAVE_FILTERS_ACTIVE_RADIO,
});

export const saveSelectAllOptionToStore: ActionCreator<
    ISaveSelectAllOptionAction
> = (selectedAll: boolean) => ({
    payload: {
        selectedAll,
    },
    type: SAVE_SELECT_ALL_OPTION,
});

export const saveBrandsFilterToStore: ActionCreator<ISaveBrandsFilterAction> = (
    brands: string[],
) => ({
    payload: {
        brands,
    },
    type: SAVE_BRANDS_FILTER,
});

export const saveCategoriesFilterToStore: ActionCreator<
    ISaveCategoriesFilterAction
> = (categories: string[]) => ({
    payload: {
        categories,
    },
    type: SAVE_CATEGORIES_FILTER,
});

export const toggleTableSelectAllInStore: ActionCreator<
    IToggleTableSelectAllAction
> = (selectAll: boolean) => ({
    payload: {
        selectAll,
    },
    type: TOGGLE_TABLE_SELECT_ALL,
});

export const saveIncludedProductsToStore: ActionCreator<
    ISaveIncludedProductsAction
> = (includedProducts: string[]) => ({
    payload: {
        includedProducts,
    },
    type: SAVE_INCLUDED_PRODUCTS,
});

export const saveExcludedProductsToStore: ActionCreator<
    ISaveExcludedProductsAction
> = (excludedProducts: string[]) => ({
    payload: {
        excludedProducts,
    },
    type: SAVE_EXCLUDED_PRODUCTS,
});
