import React, { Component } from 'react';

import { DescriptionText, AutoTaxEstimation, VatIncluded } from './components';
import commonStyles from 'design/styles/common.module.css';
import styles from './ShippingTaxForm.module.css';

export class ShippingTaxForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeRadioButtonId: '',
        };
    }

    componentDidMount() {
        this.checkActiveRadioButton();
    }

    checkActiveRadioButton() {
        const { activeRadioButtonId } = this.props.shippingTax;

        if (activeRadioButtonId) {
            this.setActiveTaxRadio(activeRadioButtonId);
        }
    }

    setActiveTaxRadioState = activeRadioButtonId => {
        if (this.props.shippingTaxError) {
            this.props.removeShippingTaxError();
        }

        this.setActiveTaxRadio(activeRadioButtonId);
        this.props.saveActiveTaxRadioIdToStore(activeRadioButtonId);
    };

    setActiveTaxRadio(activeRadioButtonId) {
        this.setState({
            activeRadioButtonId,
        });
    }

    render() {
        return (
            <React.Fragment>
                <DescriptionText
                    commonStyles={commonStyles}
                    styles={styles}
                    shippingTaxError={this.props.shippingTaxError}
                />
                <div>
                    <AutoTaxEstimation
                        autoTax={this.props.shippingTax.radioButtons.autoTax}
                        radioButtonIds={this.props.radioButtonIds}
                        fieldIds={this.props.fieldIds}
                        activeRadioButtonId={this.state.activeRadioButtonId}
                        setActiveTaxRadioState={this.setActiveTaxRadioState}
                        saveOperateStatesToStore={
                            this.props.saveOperateStatesToStore
                        }
                        statesError={this.props.statesError}
                        removeShippingTaxStatesError={
                            this.props.removeShippingTaxStatesError
                        }
                        removeVatIncluded={this.props.removeVatIncluded}
                    />
                    <VatIncluded
                        radioButtonIds={this.props.radioButtonIds}
                        activeRadioButtonId={this.state.activeRadioButtonId}
                        setActiveTaxRadioState={this.setActiveTaxRadioState}
                        saveVatIncluded={this.props.saveVatIncluded}
                        removeShippingTaxStatesError={
                            this.props.removeShippingTaxStatesError
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default ShippingTaxForm;
