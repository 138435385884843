import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { RadioButton, ConfigurableSelect } from 'component/form';
import Tooltip from 'component/Tooltip';
import customSelectStyles from './selectComponent.styles';
import commonStyles from 'design/styles/common.module.css';

import googleCategories from 'config/googleCategories.json';

export default class SellOneCategoryOption extends Component {
    state = {
        selectedOption: [],
    };

    radioButtonChangeHandler = e =>
        this.props.setActiveRadioButtonId(e.target.id);

    selectChangeHandler = option => {
        const { id } = this.props.radioButtonsConfig;

        this.setState({ selectedOption: option });
        this.props.saveAttributeToStore(id, option);
        this.props.validateAttribute([option], 'googleCategoryAttr');
    };

    componentDidMount() {
        const { id } = this.props.radioButtonsConfig;
        const { data } = this.props.attributeMapping.attributes[id];

        if (!isEmpty(data)) {
            this.setState({ selectedOption: data });
        }
    }

    render() {
        const {
            props: {
                activeRadioButtonId,
                optionContentStyle,
                radioButtonsConfig,
                attributeMapping: {
                    attributes: { googleCategoryAttr },
                },
            },
        } = this;
        const { id, label } = radioButtonsConfig;
        const isRadioChecked = activeRadioButtonId === id;
        const error = isRadioChecked && googleCategoryAttr.error;

        return (
            <React.Fragment>
                <div className={commonStyles['flex-container']}>
                    <RadioButton
                        id={id}
                        onChange={this.radioButtonChangeHandler}
                        checked={isRadioChecked}
                        labelFor={id}
                        label={label}
                    />
                    <Tooltip
                        direction="left"
                        link="sales-channels/google-ads/attribute-mapping.html"
                    >
                        <FormattedMessage id="help.attributeMapping.sellOneCategoryOption" />
                    </Tooltip>
                </div>
                <div className={optionContentStyle}>
                    <ConfigurableSelect
                        styles={customSelectStyles}
                        onChange={this.selectChangeHandler}
                        value={this.state.selectedOption}
                        {...{
                            isMulti: false,
                            isSearchable: true,
                            placeholder: (
                                <FormattedMessage id="attributeMapping.googleCategoriesSelect.placeholder" />
                            ),
                            errorMessage: (
                                <FormattedMessage id="attributeMapping.googleCategoriesSelect.error" />
                            ),
                            options: googleCategories,
                            showError: error,
                            isDisabled: !isRadioChecked,
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}
