import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get, find, has, isEmpty, map, partialRight, values } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { setEditType } from '../../../../actions/settings/actions';

import Table from 'component/table';
import Notification from 'component/notification';
import { countryOfSales } from 'config/countryOfSales';
import { exceptionalProductErrors } from 'config/exceptionalProductErrors';

import { unquote } from 'helpers/string';
import { redirectToYuiLogin } from 'helpers/redirectToYuiLogin';
import { yuiRequest } from 'api';

import { classnames, isNumeric } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './ProductErrorResolver.module.css';

export class ProductErrorResolver extends Component {
    static propTypes = {
        handleCloseSidebar: PropTypes.func.isRequired,
        setEditType: PropTypes.func.isRequired,
        errorData: PropTypes.object.isRequired,
        refreshTime: PropTypes.string.isRequired,
        channelId: PropTypes.string.isRequired,
        settings: PropTypes.shape({
            editType: PropTypes.string,
        }).isRequired,
    };

    state = {
        data: [],
        urlsMap: {},
        loading: false,
        page: 0,
        pageSize: 5,
        numberOfPages: 1,
        productsNumber: 0,
        isErrorNotificationActive: false,
    };

    tableFetchHandler = async tableState => {
        const { page, pageSize } = tableState;
        const { channelId, errorData } = this.props;

        this.setState({ loading: true });

        try {
            const tableResponse = await yuiRequest({
                method: 'get',
                uri: `channels/${channelId}/productValidationData?page=${page +
                    1}&size=${pageSize}&errorId=${errorData.errorId}`,
            });

            const respData = JSON.parse(tableResponse.body);
            const data = respData.productsValidationData;
            const numberOfPages = parseInt(respData.numberOfPages);
            const skus = map(data, item => unquote(item.entityId));
            const errors = values(exceptionalProductErrors);
            let urlsMap = {};

            if (!errors.includes(this.props.errorData.description)) {
                const formData = new FormData();
                formData.append('form_key', window.FORM_KEY);
                formData.append('skus', JSON.stringify(skus));

                const linksResponse = await fetch(
                    window.googleAppConfig.productEditUrl + '?isAjax=true',
                    {
                        method: 'POST',
                        body: formData,
                    },
                );

                if (!linksResponse.ok) {
                    throw linksResponse.json();
                }

                urlsMap = await linksResponse.json();
            }

            this.setState({
                data: data || [],
                page,
                pageSize,
                numberOfPages: isNumeric(numberOfPages) ? numberOfPages : 1,
                urlsMap: !isEmpty(urlsMap) ? urlsMap : {},
                productsNumber: respData.total || 0,
            });
        } catch (error) {
            const isSessionExpired = has(error, 'ajaxExpired');

            isSessionExpired
                ? redirectToYuiLogin()
                : this.setState({
                      isErrorNotificationActive: true,
                  });
        } finally {
            this.setState({
                loading: false,
                page,
                pageSize,
            });
        }
    };

    mapData = partialRight(map, item => {
        if (!item) {
            return;
        }

        const entityId = unquote(item.entityId);
        const country = find(countryOfSales, {
            value: unquote(item.country),
        });
        const language = find(get(country, 'languages'), {
            value: unquote(item.language),
        });

        return {
            title: unquote(item.title),
            country: get(country, 'label', '—'),
            language: get(language, 'label', '—'),
            entityId,
            action: this.state.urlsMap[entityId],
        };
    });

    handleSettingsLinkClick = () => this.props.setEditType('shipping');

    renderEditLink = url => {
        const {
            missingShippingError,
            unclaimedWebsite,
            unavailableMobileLandingPage,
            unavailableDesktopLandingPage,
        } = exceptionalProductErrors;

        switch (this.props.errorData.description) {
            case missingShippingError:
                return (
                    <Link to="settings" onClick={this.handleSettingsLinkClick}>
                        <FormattedMessage id="productErrorResolver.table.edit" />
                    </Link>
                );
            case unclaimedWebsite:
            case unavailableMobileLandingPage:
            case unavailableDesktopLandingPage:
                return (
                    <span className={styles['edit-link_disabled']}>
                        <FormattedMessage id="productErrorResolver.table.edit" />
                    </span>
                );
            default:
                return (
                    <a target="_blank" rel="noopener noreferrer" href={url}>
                        <FormattedMessage id="productErrorResolver.table.edit" />
                    </a>
                );
        }
    };

    render() {
        const {
            data,
            pageSize,
            numberOfPages,
            loading,
            productsNumber,
            isErrorNotificationActive,
        } = this.state;
        const { errorData, handleCloseSidebar, refreshTime } = this.props;
        const columns = [
            {
                Header: 'Product Name',
                accessor: 'title',
            },
            {
                Header: 'Country',
                accessor: 'country',
            },
            {
                Header: 'Language',
                accessor: 'language',
            },
            {
                Header: 'Item ID',
                accessor: 'entityId',
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: row => this.renderEditLink(row.value),
            },
        ];

        const mainActionsStyles = classnames([
            commonStyles['main-actions-panel'],
            commonStyles.clearfix,
        ]);
        const backButtonStyles = classnames([
            styles.arrow,
            styles['cancel-button'],
        ]);
        const headerStyles = classnames([commonStyles.header2, styles.header]);
        const header3Styles = classnames([
            commonStyles.header3,
            styles.header3,
        ]);
        const textStyles = classnames([
            commonStyles['font-medium'],
            styles.text,
        ]);

        return (
            <div className={styles.wrapper}>
                <div className={headerStyles}>
                    <FormattedMessage id="productErrorResolver.header" />
                </div>
                <div className={mainActionsStyles}>
                    <div className={styles['action-controls']}>
                        <button
                            className={backButtonStyles}
                            onClick={handleCloseSidebar}
                        >
                            <FormattedMessage id="productErrorResolver.controlPanel.backButton" />
                        </button>
                    </div>
                </div>
                <Notification
                    type="error"
                    defaultMessageType="requestError"
                    isActive={isErrorNotificationActive}
                />
                <h3 className={header3Styles}>
                    <FormattedMessage id="productErrorResolver.header3" />
                    {errorData.description}
                </h3>
                <div className={textStyles}>
                    {errorData.detail ? `${errorData.detail}. ` : ''}
                    {errorData.documentation ? (
                        <FormattedMessage id="productErrorResolver.text" />
                    ) : null}
                    {errorData.documentation ? (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={errorData.documentation}
                        >
                            <FormattedMessage id="productErrorResolver.link.text" />
                        </a>
                    ) : null}
                </div>
                <div className={styles['product-status']}>
                    <div className={styles['affected-number']}>
                        <FormattedMessage id="productErrorResolver.affectedNumberText" />
                        {productsNumber}
                    </div>
                    <div className={styles['last-refresh-time']}>
                        <FormattedMessage id="lastRefreshed" />
                        {refreshTime}
                    </div>
                </div>
                <div className={commonStyles['font-medium']}>
                    <FormattedMessage id="productErrorResolver.text_2" />
                    <br />
                    <FormattedMessage id="productErrorResolver.text_3" />
                </div>
                <Table
                    manual
                    data={this.mapData(data)}
                    {...{
                        columns,
                        loading,
                        pageSize,
                    }}
                    pages={numberOfPages}
                    onFetchData={this.tableFetchHandler}
                    sortable={false}
                    showPageSizeOptions={false}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

export default connect(
    mapStateToProps,
    {
        setEditType,
    },
)(ProductErrorResolver);
