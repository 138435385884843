import { get } from 'lodash';

/**
 * Generic function for creating and processing yui api calls.
 */
export default async function yuiRequest<P extends object = {}>({
    action,
    init,
    method,
    payload,
    uri,
}: {
    action?: string;
    init?: RequestInit;
    method?: string;
    payload?: P;
    uri?: string;
} = {}) {
    const formData = new FormData();
    formData.append('form_key', window.FORM_KEY);
    if (method) {
        formData.append('method', method);
    }
    if (uri) {
        formData.append('uri', uri);
    }
    if (payload) {
        formData.append('payload', JSON.stringify(payload));
    }
    if (action) {
        formData.append('action', action);
    }

    const response = await fetch(
        `${window.googleAppConfig.yuiMiddlewareUrl}?isAjax=true`,
        {
            body: formData,
            method: 'POST',
            ...init,
        },
    );

    if (response.ok) {
        const json = await response.json();

        if (get(json, 'code') !== 200 || get(json, 'ajaxExpired')) {
            return Promise.reject(json);
        }

        return Promise.resolve(json);
    }

    throw new Error(
        `Server responded with ${response.status}: ${response.statusText}.`,
    );
}
