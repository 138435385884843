import { omit } from 'lodash';
import React, { Component } from 'react';

import commonStyles from '../../../design/styles/common.module.css';
import styles from './Input.module.css';

export interface InputProps {
    errorMessage?: React.ReactNode;
    renderInput?: (input: JSX.Element) => React.ReactNode;
}

export default class Input extends Component<
    InputProps &
        React.DetailedHTMLProps<
            React.InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        >
> {
    public static defaultProps: InputProps = {
        errorMessage: '',
        renderInput: input => <div>{input}</div>,
    };

    public render() {
        const { errorMessage, renderInput } = this.props;
        return (
            <React.Fragment>
                {renderInput!(
                    <input
                        className={styles.input}
                        type="text"
                        {...omit(this.props, ['errorMessage', 'renderInput'])}
                    />,
                )}
                {errorMessage && (
                    <span className={commonStyles['bottom-error']}>
                        {errorMessage}
                    </span>
                )}
            </React.Fragment>
        );
    }
}
