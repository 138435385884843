import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import FixedHeader from 'component/FixedHeader';
import Wizard from 'component/Wizard';
import WizardActions from 'component/WizardActions';

import UrlVerificationForm from 'component/UrlVerificationForm';
import DescriptionText from './components/DescriptionText';
import commonStyles from 'design/styles/common.module.css';

/**
 * An url verification page scene.
 */
export default class UrlVerification extends Component {
    constructor(props) {
        super(props);
        this.wizardConfig = {
            activeElementIndex: 1,
            elements: [
                {
                    caption: <FormattedMessage id="onboarding.wizard.terms" />,
                    link: '/terms',
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.url_verification" />
                    ),
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.shipping_and_tax" />
                    ),
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.attribute_mapping" />
                    ),
                },
            ],
            backButton: {
                caption: <FormattedMessage id="wizard.back" />,
                link: '/terms',
            },
            nextButton: {
                caption: <FormattedMessage id="wizard.next" />,
            },
        };
        this.state = {
            isSaving: false,
        };
    }

    handleNext = () => {
        this.setState({
            isSaving: true,
        });
    };

    createWizardActions = () => {
        return (
            <WizardActions
                back={this.wizardConfig.backButton}
                next={this.wizardConfig.nextButton}
                nextAction={this.handleNext}
            />
        );
    };

    createWizard() {
        return (
            <Wizard
                elements={this.wizardConfig.elements}
                activeElementIndex={this.wizardConfig.activeElementIndex}
            />
        );
    }

    confirmSaving = () => {
        this.props.history.push('/shipping-and-tax');
    };

    rejectSaving = () => {
        this.setState({
            isSaving: false,
        });
    };

    render() {
        const wizard = this.createWizard();

        return (
            <React.Fragment>
                <FixedHeader right={this.createWizardActions}>
                    {wizard}
                </FixedHeader>
                <div className={commonStyles.sceneMainContent}>
                    <DescriptionText styles={commonStyles} />
                    <UrlVerificationForm
                        isSaving={this.state.isSaving}
                        confirmSaving={this.confirmSaving}
                        rejectSaving={this.rejectSaving}
                    />
                </div>
            </React.Fragment>
        );
    }
}
