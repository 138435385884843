import { ActionCreator } from 'redux';

import { IResetConfigStateAction } from '../../reducers/websiteConfigReducer';
import { RESET_CONFIG_STATE } from '../../store/actionConsts';

export const resetConfigState: ActionCreator<IResetConfigStateAction> = () => ({
    type: RESET_CONFIG_STATE,
});

export {
    resetAttributesState,
} from '../../component/AttributeMappingForm/actions';
