import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfigurableSelect from 'component/form/Select';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import commonStyles from 'design/styles/common.module.css';

class TitleAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selectedOption: [],
            requiredError: false,
        };
        this.selectConfig = {
            name: 'title',
            placeholder: (
                <FormattedMessage id="attributeMapping.select.placeholder" />
            ),
            isMulti: true,
            isSearchable: true,
            selectRequiredError: (
                <FormattedMessage id="attributeMapping.select.errorMessage" />
            ),
            requiredDataType: ['text'],
        };
    }

    componentDidMount() {
        this.parseAttributesData();
    }

    async parseAttributesData() {
        const googleAppAttributes = window.googleAppConfig.attributes;
        const options = [];

        map(this.selectConfig.requiredDataType, dataType => {
            options.push(
                ...filter(
                    googleAppAttributes[dataType],
                    item => item.label !== null,
                ),
            );
        });

        this.createOptions(options);
        !this.props.isEditMode &&
            (await this.addPredefinedAttributesToStore(options));
        this.prefillSelectedOptions();
    }

    createOptions(options) {
        this.setState({
            options,
        });
    }

    addPredefinedAttributesToStore(options) {
        const attributeName = this.selectConfig.name;
        const predefinedValues = this.props.attributeMapping.attributes[
            this.selectConfig.name
        ].predefinedValues;
        const storeAttributeData = this.props.attributeMapping.attributes[
            this.selectConfig.name
        ].data;
        let isAttributeInStore = false;

        map(predefinedValues, item => {
            isAttributeInStore = !isEmpty(
                find(
                    storeAttributeData,
                    storedAttribute => storedAttribute.value === item.value,
                ),
            );

            if (!isAttributeInStore && !item.predefined) {
                const selectedOption = [find(options, { value: item.value })];
                this.props.savePredefinedAttributeValue(
                    attributeName,
                    selectedOption,
                );
            }
        });
    }

    prefillSelectedOptions() {
        const attributes = this.props.attributeMapping.attributes;

        if (attributes[this.selectConfig.name].data) {
            this.setState({
                selectedOption: attributes[this.selectConfig.name].data,
            });
        }
    }

    setSelectedOptions(selectedOption) {
        this.setState({
            selectedOption,
        });
    }

    selectChangeHandler = selectedOption => {
        const attributeName = this.selectConfig.name;
        const payload = selectedOption;
        const required = this.props.attributeMapping.attributes[attributeName]
            .required;

        if (required) {
            this.props.validateAttribute(selectedOption, attributeName);
        }

        this.props.saveAttributeToStore(attributeName, payload);
        this.setSelectedOptions(selectedOption);
    };

    render() {
        const currentAttribute = this.props.attributeMapping.attributes[
            this.selectConfig.name
        ];

        return (
            <React.Fragment>
                <tr>
                    <td>
                        <h2 className={commonStyles.header2}>
                            <FormattedMessage id="attributeMapping.titleAttribute.header" />
                        </h2>
                        <p className={commonStyles['font-medium']}>
                            <b>
                                <FormattedMessage id="attributeMapping.attribute.descriptionHeader" />
                            </b>
                            <FormattedMessage id="attributeMapping.titleAttribute.descriptionText" />
                        </p>
                    </td>
                    <td>
                        <ConfigurableSelect
                            onChange={this.selectChangeHandler}
                            value={this.state.selectedOption}
                            isMulti={this.selectConfig.isMulti}
                            isSearchable={this.selectConfig.isSearchable}
                            name={this.selectConfig.name}
                            placeholder={this.selectConfig.placeholder}
                            options={this.state.options}
                            leftRequiredLayout={currentAttribute.required}
                            showError={currentAttribute.error}
                            errorMessage={this.selectConfig.selectRequiredError}
                        />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

export default TitleAttribute;
