import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as AllActionCreators from './actions';
import PropTypes from 'prop-types';
import { isNumeric } from '../../../../../../../../../../util';

import Input from '../../../../../../../../../../component/form/Input';
import styles from './FlatRateRow.module.css';

export class FlatRateRow extends Component {
    static propTypes = {
        isRadioChecked: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        saveFlatRateCostToStore: PropTypes.func.isRequired,
        removeShippingCostFlatRateError: PropTypes.func.isRequired,
    };

    inputChangeHandler = event => {
        const { uniqueRowId } = this.props.data;
        const cost = event.target.value;

        if (cost !== '' && !isNumeric(cost)) return;

        this.props.saveFlatRateCostToStore(uniqueRowId, cost);
        this.props.removeShippingCostFlatRateError();
    };

    inputBlurHandler = event => {
        const { uniqueRowId } = this.props.data;
        const cost = event.target.value.replace(/\.+$/, '');

        this.props.saveFlatRateCostToStore(uniqueRowId, cost);
        this.props.removeShippingCostFlatRateError();
    };

    render() {
        const {
            props: {
                isRadioChecked,
                data: { countryName, rate },
            },
        } = this;

        return (
            <tr className={styles['flat-rate-form-tr']}>
                <td className={styles['flat-rate-form-data-grid-td']}>
                    <p className={styles['flat-rate-form-country-name']}>
                        {countryName}
                    </p>
                </td>
                <td className={styles['flat-rate-form-data-grid-td']}>
                    <Input
                        {...{
                            disabled: !isRadioChecked,
                            name: countryName,
                            onBlur: this.inputBlurHandler,
                            onChange: this.inputChangeHandler,
                            value: rate,
                        }}
                    />
                </td>
            </tr>
        );
    }
}

const mapStateToProps = state => ({
    urlVerification: state.websiteConfig.new.urlVerification,
});

export default connect(
    mapStateToProps,
    AllActionCreators,
)(FlatRateRow);
