import { reduce, map } from 'lodash';
import uuidv1 from 'uuid/v1';

export default class ChannelDataProcessor {
    createPayload(storeData) {
        const {
            email,
            consent,
            urlVerification: { storeName, websiteUrl },
            websiteId,
        } = storeData;

        return {
            channel: {
                channelId: uuidv1(),
                channelType: 'Google',
                channelName: storeName.value,
                baseUrl: websiteUrl.value,
                email,
                dateOfConsent: consent,
                channelAttributes: {
                    channelType: 'ONLINE',
                    webSiteId: String(websiteId),
                    storeSettings: this.getStoreSettings(storeData),
                    taxSettings: this.getShippingTaxSettings(storeData),
                    shipmentSettings: this.getShippingCostSettings(storeData),
                },
            },
        };
    }

    getStoreSettings(storeData) {
        const {
            urlVerification: {
                websiteStoreViews: { tableData },
            },
        } = storeData;

        return {
            ...reduce(
                tableData,
                (prevStoreData, store) => {
                    prevStoreData[store.code.value] = {
                        country: store.country.value,
                        language: store.language.value,
                    };
                    return prevStoreData;
                },
                {},
            ),
        };
    }

    getShippingCostSettings = storeData => {
        const {
            shippingCost: { activeRadioButtonId, radioButtons },
        } = storeData;

        return activeRadioButtonId === 'flatRateCost'
            ? map(radioButtons[activeRadioButtonId].tableData, shipment => ({
                  name: `Shipment ${shipment.countryName}`,
                  currency: shipment.currencyCode,
                  price: shipment.rate,
                  deliveryCountry: shipment.countryCode,
                  minTransitDays: 1,
                  maxTransitDays: 7,
              }))
            : [];
    };

    getShippingTaxSettings = storeData => {
        const {
            shippingTax: { activeRadioButtonId, radioButtons },
        } = storeData;
        const locations = radioButtons.autoTax.value;

        return activeRadioButtonId === 'autoTax'
            ? map(locations, location => ({
                  shippingTaxed: true,
                  locationId: Number(location.value),
                  useGlobalRate: true,
              }))
            : [];
    };
}
