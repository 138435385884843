import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { RadioButton } from 'component/form';

export default class SelectAll extends Component {
    static propTypes = {
        isEditMode: PropTypes.bool.isRequired,
        radioButtonsConfig: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }).isRequired,
        setActiveRadioButtonId: PropTypes.func.isRequired,
    };

    radioButtonChangeHandler = e => {
        const id = e.target.id;
        this.props.setActiveRadioButtonId(id);
        this.props.saveSelectAllOptionToStore(true);
    };

    componentDidMount() {
        this.prefillRadio();
    }

    prefillRadio() {
        const {
            isEditMode,
            adsCreation: {
                editCampaignData: { filterType },
            },
            radioButtonsConfig: { id },
        } = this.props;
        const isSelected = isEditMode && filterType === 'all';

        if (isSelected) {
            this.props.setActiveRadioButtonId(id);
            this.props.saveSelectAllOptionToStore(true);
        }
    }

    componentDidUpdate(prevProps) {
        const prevActiveId = prevProps.activeRadioButtonId;
        const currActiveId = this.props.activeRadioButtonId;
        const { id } = this.props.radioButtonsConfig;

        if (prevActiveId !== currActiveId && currActiveId !== id) {
            this.props.saveSelectAllOptionToStore(false);
        }
    }

    render() {
        const {
            adsCreation: { activeRadioButtonId },
            radioButtonsConfig: { id, label },
        } = this.props;

        const isRadioChecked = activeRadioButtonId === id;

        return (
            <RadioButton
                id={id}
                onChange={this.radioButtonChangeHandler}
                checked={isRadioChecked}
                labelFor={id}
                label={label}
            />
        );
    }
}
