import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ConfigurableSelect from 'component/form/Select';
import { parseAttributesByDataType } from 'helpers/attributeMapping';

import { classnames } from '../../../../../../util';
import Tooltip from 'component/Tooltip';

import commonStyles from 'design/styles/common.module.css';
import tableStyles from '../tableStyles.module.css';
import styles from './AdultOnlyAttribute.module.css';

export class AdultOnlyAttribute extends Component {
    static propTypes = {
        attributeMapping: PropTypes.object.isRequired,
        saveAttributeToStore: PropTypes.func.isRequired,
        validateAttribute: PropTypes.func.isRequired,
    };
    static selectConfig = {
        name: 'adult',
        requiredDataType: ['text', 'select'],
    };
    state = {
        options: [],
        selectedOption: [],
    };

    componentDidMount() {
        this.createOptions();
        this.prefillSelectedOptions();
    }

    createOptions() {
        this.setState({
            options: parseAttributesByDataType(
                AdultOnlyAttribute.selectConfig.requiredDataType,
            ),
        });
    }

    prefillSelectedOptions() {
        const { name } = AdultOnlyAttribute.selectConfig;
        const { data } = this.props.attributeMapping.attributes[name];

        if (data) {
            this.setState({
                selectedOption: data,
            });
        }
    }

    selectChangeHandler = selectedOption => {
        const attributeName = AdultOnlyAttribute.selectConfig.name;
        const payload = selectedOption;
        const required = this.props.attributeMapping.attributes[attributeName]
            .required;

        if (required) {
            this.props.validateAttribute(selectedOption, attributeName);
        }

        this.props.saveAttributeToStore(attributeName, payload);
        this.setState({ selectedOption });
    };

    render() {
        const descriptionClasses = classnames([
            commonStyles['font-medium'],
            styles.description,
        ]);
        const selectClasses = classnames([
            commonStyles['flex-container'],
            styles['select-left-margin'],
        ]);
        const {
            state: { selectedOption, options },
        } = this;
        const { name } = AdultOnlyAttribute.selectConfig;
        const currentAttribute = this.props.attributeMapping.attributes[name];

        return (
            <React.Fragment>
                <tr>
                    <td className={tableStyles['table-data-grid-td']}>
                        <h2 className={commonStyles.header2}>
                            <FormattedMessage id="attributeMapping.adultAttribute.header" />
                        </h2>
                        <p className={descriptionClasses}>
                            <FormattedMessage id="attributeMapping.adultAttribute.descriptionText" />
                        </p>
                        <p className={commonStyles['font-medium']}>
                            <b>
                                <FormattedMessage id="attributeMapping.adultAttribute.requirementsHeader" />
                            </b>
                            <FormattedMessage id="attributeMapping.adultAttribute.requirementsText" />
                        </p>
                    </td>
                    <td className={tableStyles['table-data-grid-td']}>
                        <div className={selectClasses}>
                            <ConfigurableSelect
                                onChange={this.selectChangeHandler}
                                value={selectedOption}
                                options={options}
                                showError={currentAttribute.error}
                                leftRequiredLayout={currentAttribute.required}
                                {...{
                                    name,
                                    placeholder: (
                                        <FormattedMessage id="attributeMapping.select.placeholder" />
                                    ),
                                    isMulti: true,
                                    isSearchable: true,
                                    errorMessage: (
                                        <FormattedMessage id="attributeMapping.select.errorMessage" />
                                    ),
                                }}
                            />
                            <Tooltip
                                direction="left"
                                link="sales-channels/google-ads/attribute-mapping.html"
                            >
                                <FormattedMessage id="help.attributeMapping.adultOnly" />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    attributeMapping: state.attributeMapping,
});

export default connect(mapStateToProps)(AdultOnlyAttribute);
