import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { StoreViewNameField, CountryField, LanguageField } from './components';
import styles from './StoreViewRow.module.css';

export default class StoreViewRow extends Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        showDuplicateError: PropTypes.func.isRequired,
        hideDuplicateError: PropTypes.func.isRequired,
        hideStoreViewRequiredError: PropTypes.func.isRequired,
        hideStoreViewsLengthError: PropTypes.func.isRequired,
        storeViewNameOptions: PropTypes.array.isRequired,
        updateViewNameOptionList: PropTypes.func.isRequired,
        isEdit: PropTypes.bool.isRequired,
    };

    removeRowHandler = () => {
        this.props.removeRow(this.props.data.uniqueRowId);
        this.props.hideDuplicateError();
        this.props.hideStoreViewRequiredError();
    };

    render() {
        const {
            props: {
                index,
                data,
                showDuplicateError,
                hideDuplicateError,
                hideStoreViewRequiredError,
                hideStoreViewsLengthError,
                storeViewNameOptions,
                updateViewNameOptionList,
                isEdit,
            },
        } = this;

        return (
            <tr>
                <td className={styles['store-view-name-cell']}>
                    <StoreViewNameField
                        data={data}
                        storeViewNameOptions={storeViewNameOptions}
                        updateViewNameOptionList={updateViewNameOptionList}
                        showDuplicateError={showDuplicateError}
                        hideDuplicateError={hideDuplicateError}
                        hideStoreViewRequiredError={hideStoreViewRequiredError}
                        hideStoreViewsLengthError={hideStoreViewsLengthError}
                    />
                </td>
                <td className={styles['store-view-cell']}>
                    <CountryField
                        data={data}
                        hideStoreViewRequiredError={hideStoreViewRequiredError}
                        hideStoreViewsLengthError={hideStoreViewsLengthError}
                    />
                </td>
                <td className={styles['store-view-cell']}>
                    <LanguageField
                        data={data}
                        showDuplicateError={showDuplicateError}
                        hideDuplicateError={hideDuplicateError}
                        hideStoreViewRequiredError={hideStoreViewRequiredError}
                        hideStoreViewsLengthError={hideStoreViewsLengthError}
                    />
                    {(index > 0 || isEdit) && (
                        <span
                            className={styles['remove-button']}
                            onClick={this.removeRowHandler}
                        />
                    )}
                </td>
            </tr>
        );
    }
}
