import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedPlural } from 'react-intl';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import './Table.css';

import { isEmpty } from 'lodash';

import { classnames, isNumeric } from '../../util';

export class Table extends Component {
    static defaultProps = {
        noDataText: <FormattedMessage id="table.noDataText" />,
    };

    getPaginationProps() {
        return {
            previousText: '',
            nextText: '',
        };
    }

    getTdProps = (dashedColumns, state) => ({
        className:
            dashedColumns && state.data.length !== 0 ? 'dashed-border' : '',
    });

    noDataCallback = state => ({
        style: {
            display: state.loading ? 'none' : 'block',
            top: state.showPaginationTop
                ? 'calc(50% + 46px)'
                : 'calc(50% + 19.5px)',
        },
    });

    renderCounterElement = number => (
        <FormattedPlural
            value={number}
            one={
                <FormattedMessage
                    id="table.rowsCounterText_one"
                    values={{
                        count: number,
                    }}
                />
            }
            other={
                <FormattedMessage
                    id="table.rowsCounterText_many"
                    values={{
                        count: number,
                    }}
                />
            }
        />
    );

    render() {
        const {
            data,
            showPaginationTop,
            showPaginationBottom,
            getPaginationProps,
            defaultPageSize,
            className,
            pageSizeOptions,
            totalRowsCount,
            dashedColumns,
            disabled,
            noDataText,
            resizable,
            ...otherProps // check react-table documentation to see full list of props
        } = this.props;

        const defPageSize = defaultPageSize || 10;

        const pageSize = isEmpty(pageSizeOptions)
            ? [5, 10, 20, 25, 50, 100]
            : pageSizeOptions;

        const tableClassNames = classnames([
            data.length && '-striped -highlight',
            disabled && 'ReactTable_disabled',
            className,
        ]);

        return (
            <ReactTable
                data={data}
                showPaginationTop={
                    showPaginationTop === undefined ||
                    showPaginationTop === null
                        ? true
                        : showPaginationTop
                }
                showPaginationBottom={showPaginationBottom || false}
                getPaginationProps={this.getPaginationProps}
                getTdProps={state => this.getTdProps(dashedColumns, state)}
                noDataText={noDataText}
                defaultPageSize={defPageSize}
                className={tableClassNames}
                pageSizeOptions={pageSize}
                getNoDataProps={this.noDataCallback}
                resizable={resizable || false}
                {...otherProps}
            >
                {(state, makeTable, instance) => {
                    const counterBlockStyle = state.showPaginationTop
                        ? { top: `0` }
                        : {
                              position: 'static',
                              height: '30px',
                          };

                    return (
                        <div className="table-container">
                            {this.props.showCounter ? (
                                <p
                                    className="counter"
                                    style={counterBlockStyle}
                                >
                                    {this.props.manual
                                        ? data && isNumeric(totalRowsCount)
                                            ? this.renderCounterElement(
                                                  totalRowsCount,
                                              )
                                            : null
                                        : data
                                        ? this.renderCounterElement(data.length)
                                        : null}
                                </p>
                            ) : null}
                            {makeTable()}
                        </div>
                    );
                }}
            </ReactTable>
        );
    }
}

Table.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    totalRowsCount: PropTypes.number,
    showCounter: PropTypes.bool,
    dashedColumns: PropTypes.bool,
    disabled: PropTypes.bool,
    noDataText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Table;
