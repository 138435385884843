import React from 'react';
import { FormattedMessage } from 'react-intl';
import commonStyles from 'design/styles/common.module.css';
import { createYuiDocsLink } from '../../../../util';

export default function DescriptionText() {
    return (
        <React.Fragment>
            <h2 className={commonStyles.header2}>
                <FormattedMessage id="smartShoppingCampaign.header_1" />
            </h2>
            <p className={commonStyles['font-medium']}>
                <FormattedMessage id="smartShoppingCampaign.mainDescriptionText" />
            </p>
            <a
                href={createYuiDocsLink(
                    'sales-channels/google-ads/ads-management.html',
                )}
                rel="noopener noreferrer"
                target="_blank"
            >
                <FormattedMessage id="smartShoppingCampaign.mainDescriptionText.link" />
            </a>
        </React.Fragment>
    );
}
