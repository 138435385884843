import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfigurableSelect from 'component/form/Select';
import map from 'lodash/map';
import filter from 'lodash/filter';

import commonStyles from 'design/styles/common.module.css';
import styles from './MpnAttribute.module.css';

class MpnAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selectedOption: [],
        };
        this.selectConfig = {
            name: 'mpn',
            placeholder: (
                <FormattedMessage id="attributeMapping.select.placeholder" />
            ),
            isMulti: true,
            isSearchable: true,
            selectRequiredError: (
                <FormattedMessage id="attributeMapping.select.errorMessage" />
            ),
            requiredDataType: ['text'],
        };
    }

    componentDidMount() {
        this.parseAttributesData();
        this.prefillSelectedOptions();
    }

    parseAttributesData() {
        const googleAppAttributes = window.googleAppConfig.attributes;
        const options = [];

        map(this.selectConfig.requiredDataType, dataType => {
            options.push(
                ...filter(
                    googleAppAttributes[dataType],
                    item => item.label !== null,
                ),
            );
        });

        this.createOptions(options);
    }

    createOptions(options) {
        this.setState({
            options,
        });
    }

    prefillSelectedOptions() {
        const attributes = this.props.attributeMapping.attributes;

        if (attributes[this.selectConfig.name].data) {
            this.setState({
                selectedOption: attributes[this.selectConfig.name].data,
            });
        }
    }

    setSelectedOptions(selectedOption) {
        this.setState({
            selectedOption,
        });
    }

    selectChangeHandler = selectedOption => {
        const attributeName = this.selectConfig.name;
        const payload = selectedOption;
        const required = this.props.attributeMapping.attributes[attributeName]
            .required;

        if (required) {
            this.props.validateAttribute(selectedOption, attributeName);
        }

        this.props.saveAttributeToStore(attributeName, payload);
        this.setSelectedOptions(selectedOption);
    };

    render() {
        return (
            <React.Fragment>
                <tr>
                    <td>
                        <h2 className={commonStyles.header2}>
                            <FormattedMessage id="attributeMapping.mpnAttribute.header" />
                        </h2>
                        <p className={commonStyles['font-medium']}>
                            <b>
                                <FormattedMessage id="attributeMapping.attribute.descriptionHeader" />
                            </b>
                            <FormattedMessage id="attributeMapping.mpnAttribute.descriptionText" />
                        </p>
                        <p className={commonStyles['font-medium']}>
                            <b>
                                <FormattedMessage id="attributeMapping.attribute.requirementsHeader" />
                            </b>
                            <FormattedMessage id="attributeMapping.mpnAttribute.requirementsText" />
                        </p>
                    </td>
                    <td>
                        <div className={styles['select-left-margin']}>
                            <ConfigurableSelect
                                onChange={this.selectChangeHandler}
                                value={this.state.selectedOption}
                                isMulti={this.selectConfig.isMulti}
                                isSearchable={this.selectConfig.isSearchable}
                                name={this.selectConfig.name}
                                placeholder={this.selectConfig.placeholder}
                                options={this.state.options}
                                errorMessage={
                                    this.selectConfig.selectRequiredError
                                }
                            />
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

export default MpnAttribute;
