import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { yuiRequest } from 'api';
import { find, map, isEmpty } from 'lodash';
import { classnames } from '../../../../util';

import Tooltip from 'component/Tooltip';

import ConfigurableSelect from 'component/form/Select';
import commonStyles from 'design/styles/common.module.css';
import styles from './GmcAccountField.module.css';
import customSelectStyles from './GmcAccountSelect.style';

export class GmcAccountField extends Component {
    static propTypes = {
        adsCreation: PropTypes.object.isRequired,
        isEditMode: PropTypes.bool.isRequired,
        editData: PropTypes.object,
        fieldId: PropTypes.string.isRequired,
        gmcAccountError: PropTypes.bool.isRequired,
        saveGmcAccountUrlToStore: PropTypes.func.isRequired,
        saveChannelIdToStore: PropTypes.func.isRequired,
        saveChannels: PropTypes.func.isRequired,
        showLoader: PropTypes.func.isRequired,
        hideLoader: PropTypes.func.isRequired,
        showNotification: PropTypes.func,
    };
    state = {
        options: [],
        selectedOption: [],
    };

    componentDidMount() {
        const { channelsList } = this.props.adsCreation;

        if (isEmpty(channelsList)) {
            this.channelsRequest();
        } else {
            this.createOptions(channelsList).then(options => {
                this.setPrefilledOption(options);
            });
        }
    }

    async channelsRequest() {
        this.props.showLoader();

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: 'channels',
            });
            const channels = JSON.parse(response.body);

            if (!isEmpty(channels)) {
                this.props.saveChannels(channels);
                this.createOptions(channels).then(options => {
                    this.setPrefilledOption(options);
                });
            }
        } catch (error) {
            this.props.showNotification(error);
        } finally {
            this.props.hideLoader();
        }
    }

    async createOptions(channelsList) {
        const options = await map(channelsList, channel => ({
            label: `${channel.baseUrl} | GMC ID: ${channel.merchantId}`,
            value: channel.merchantId,
            channelId: channel.channelId,
            url: channel.baseUrl,
        }));

        this.setState({ options });

        return options;
    }

    setPrefilledOption(accounts) {
        const {
            adsCreation: {
                gmcAccountId,
                editCampaignData: { gmcId },
            },
            isEditMode,
        } = this.props;
        const gmcAccountValue = isEditMode ? gmcId : gmcAccountId;

        const selectedOption = find(accounts, { value: gmcAccountValue });

        if (selectedOption) {
            this.setState({ selectedOption });
            this.props.saveGmcAccountUrlToStore(selectedOption.url);
            this.props.saveGmcAccountIdToStore(selectedOption.value);
            this.props.saveChannelIdToStore(selectedOption.channelId);
        } else {
            const firstOption = this.state.options[0];

            this.setState({ selectedOption: firstOption ? [firstOption] : [] });
            firstOption && this.props.saveGmcAccountUrlToStore(firstOption.url);
            firstOption &&
                this.props.saveGmcAccountIdToStore(firstOption.value);
            firstOption &&
                this.props.saveChannelIdToStore(firstOption.channelId);
        }
    }

    selectChangeHandler = selectedOption => {
        const { url, value, channelId } = selectedOption;

        if (url && this.props.gmcAccountError) {
            this.props.unsetFieldError(this.props.fieldId);
        }

        this.setState({ selectedOption });
        this.props.saveGmcAccountUrlToStore(url);
        this.props.saveGmcAccountIdToStore(value);
        this.props.saveChannelIdToStore(channelId);
    };

    render() {
        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['medium-field-header-right-required'],
            commonStyles['font-semibold'],
        ]);
        const {
            props: { isEditMode, gmcAccountError, fieldId },
            state: { options, selectedOption },
        } = this;

        return (
            <React.Fragment>
                <div className={styles['header-wrapper']}>
                    <span className={fieldHeadingStyles}>
                        <FormattedMessage id="smartShoppingCampaign.gmcAccountSelect.title" />
                    </span>
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/create-campaign.html"
                        className={commonStyles['cs-tooltip-left-margin']}
                    >
                        <FormattedMessage id="help.attributeMapping.adForm.GMCAccount" />
                    </Tooltip>
                </div>
                <div className={styles['field-wrapper']}>
                    <ConfigurableSelect
                        styles={customSelectStyles}
                        onChange={this.selectChangeHandler}
                        value={selectedOption}
                        {...{
                            name: fieldId,
                            placeholder: (
                                <FormattedMessage id="smartShoppingCampaign.select.placeholder" />
                            ),
                            isMulti: false,
                            isSearchable: true,
                            blurInputOnSelect: true,
                            closeMenuOnSelect: true,
                            errorMessage: (
                                <FormattedMessage id="smartShoppingCampaign.gmcAccountSelect.errorMessage" />
                            ),
                        }}
                        options={options}
                        showError={gmcAccountError}
                        isDisabled={isEditMode}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adsCreation: state.adsCreation,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(GmcAccountField);
