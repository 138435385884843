import React, { Component } from 'react';
import styles from './WizardActions.module.css';
import { Link } from 'react-router-dom';
import PrimaryArrow from './PrimaryArrow';
import MiddleButton from './MiddleButton';
import PropTypes from 'prop-types';
import BackArrow from './BackArrow';

/**
 * Customizable back and next buttons for the wizard
 */
class WizardActions extends Component {
    render() {
        const getBackArrow = caption => <BackArrow>{caption}</BackArrow>;
        const getMiddleButton = caption => (
            <MiddleButton>{caption}</MiddleButton>
        );
        const getPrimaryArrow = caption => (
            <PrimaryArrow>{caption}</PrimaryArrow>
        );
        const getLinkObject = (params, action, children) => {
            if (params.link) {
                return (
                    <Link
                        className={styles.allUnset}
                        to={params.link}
                        onClick={action}
                    >
                        {children}
                    </Link>
                );
            } else {
                return (
                    <a
                        className={styles.allUnset}
                        href={params.link}
                        onClick={action}
                    >
                        {children}
                    </a>
                );
            }
        };

        const backButton = this.props.back
            ? getLinkObject(
                  this.props.back,
                  this.props.backAction,
                  getBackArrow(this.props.back.caption),
              )
            : '';

        const skipButton = this.props.skip
            ? getLinkObject(
                  this.props.skip,
                  this.props.skipAction,
                  getMiddleButton(this.props.skip.caption),
              )
            : '';

        const nextButton = this.props.next
            ? getLinkObject(
                  this.props.next,
                  this.props.nextAction,
                  getPrimaryArrow(this.props.next.caption),
              )
            : '';

        return (
            <div className={styles.actions}>
                <div className={styles.actionWrap}>{backButton}</div>
                {skipButton && (
                    <div className={styles.actionWrap}>{skipButton}</div>
                )}
                <div className={styles.actionWrap}>{nextButton}</div>
            </div>
        );
    }
}

const buttonShape = {
    caption: PropTypes.any.isRequired,
    link: PropTypes.string,
};
WizardActions.propTypes = {
    back: PropTypes.shape(buttonShape),
    skip: PropTypes.shape(buttonShape),
    next: PropTypes.shape(buttonShape),
    backAction: PropTypes.func,
    skipAction: PropTypes.func,
    nextAction: PropTypes.func,
};

export default WizardActions;
