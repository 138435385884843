import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { classnames } from '../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './ModalContent.module.css';

export default function ModalContent(props) {
    const headerStyles = classnames([
        commonStyles['header2'],
        styles.header2,
        commonStyles['font-semibold'],
    ]);
    const textStyles = classnames([
        commonStyles['font-medium'],
        styles['font-medium'],
    ]);
    const cancelButtonStyles = classnames([
        styles.button,
        styles['cancel-button'],
    ]);
    const confirmButtonStyles = classnames([
        styles.button,
        styles['confirm-button'],
    ]);

    const {
        handleCloseModal,
        confirmAction,
        header,
        text,
        confirmButtonText,
        cancelButtonText,
    } = props;

    return (
        <React.Fragment>
            <h2 className={headerStyles}>{header}</h2>
            <p className={textStyles}>{text}</p>
            <button className={confirmButtonStyles} onClick={confirmAction}>
                {confirmButtonText}
            </button>
            <button className={cancelButtonStyles} onClick={handleCloseModal}>
                {cancelButtonText}
            </button>
        </React.Fragment>
    );
}

ModalContent.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    confirmAction: PropTypes.func.isRequired,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    confirmButtonText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    cancelButtonText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
};

ModalContent.defaultProps = {
    confirmButtonText: <FormattedMessage id="modalContent.defaultConfirm" />,
    cancelButtonText: <FormattedMessage id="modalContent.defaultCancel" />,
};
