export const countryOfSales = [
    {
        label: 'Argentina',
        value: 'AR',
        languages: [
            {
                label: 'Spanish',
                value: 'es',
                criterionId: 1003,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Argentinian Peso',
        currencyValue: 'ARS',
    },
    {
        label: 'Australia',
        value: 'AU',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
            {
                label: 'Chinese (simplified)',
                value: 'zh_CN',
                criterionId: 1017,
            },
        ],
        currencyLabel: 'Australian Dollar',
        currencyValue: 'AUD',
    },
    {
        label: 'Austria',
        value: 'AT',
        languages: [
            {
                label: 'German',
                value: 'de',
                criterionId: 1001,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Euro',
        currencyValue: 'EUR',
    },
    {
        label: 'Belgium',
        value: 'BE',
        languages: [
            {
                label: 'French',
                value: 'fr',
                criterionId: 1002,
            },
            {
                label: 'Dutch',
                value: 'nl',
                criterionId: 1010,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Euro',
        currencyValue: 'EUR',
    },
    {
        label: 'Brazil',
        value: 'BR',
        languages: [
            {
                label: 'Portuguese',
                value: 'pt',
                criterionId: 1014,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Brazilian Real',
        currencyValue: 'BRL',
    },
    {
        label: 'Canada',
        value: 'CA',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
            {
                label: 'French',
                value: 'fr',
                criterionId: 1002,
            },
            {
                label: 'Chinese (simplified)',
                value: 'zh_CN',
                criterionId: 1017,
            },
        ],
        currencyLabel: 'Canadian Dollar',
        currencyValue: 'CAD',
    },
    {
        label: 'Chile',
        value: 'CL',
        languages: [
            {
                label: 'Spanish',
                value: 'es',
                criterionId: 1003,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Chilean Peso',
        currencyValue: 'CLP',
    },
    {
        label: 'Colombia',
        value: 'CO',
        languages: [
            {
                label: 'Spanish',
                value: 'es',
                criterionId: 1003,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Colombian Peso',
        currencyValue: 'COP',
    },
    {
        label: 'Czechia',
        value: 'CZ',
        languages: [
            {
                label: 'Czech',
                value: 'cs',
                criterionId: 1021,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Czechian Koruna',
        currencyValue: 'CZK',
    },
    {
        label: 'Denmark',
        value: 'DK',
        languages: [
            {
                label: 'Danish',
                value: 'da',
                criterionId: 1009,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Danish Krone',
        currencyValue: 'DKK',
    },
    {
        label: 'France',
        value: 'FR',
        languages: [
            {
                label: 'French',
                value: 'fr',
                criterionId: 1002,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Euro',
        currencyValue: 'EUR',
    },
    {
        label: 'Germany',
        value: 'DE',
        languages: [
            {
                label: 'German',
                value: 'de',
                criterionId: 1001,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Euro',
        currencyValue: 'EUR',
    },
    {
        label: 'Hong Kong',
        value: 'HK',
        languages: [
            {
                label: 'Chinese (traditional)',
                value: 'zh_TW',
                criterionId: 1018,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Hong Kong Dollar',
        currencyValue: 'HKD',
    },
    {
        label: 'India',
        value: 'IN',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Indian Rupee',
        currencyValue: 'INR',
    },
    {
        label: 'Indonesia',
        value: 'ID',
        languages: [
            {
                label: 'Indonesian',
                value: 'id',
                criterionId: 1025,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Indonesian Rupiah',
        currencyValue: 'IDR',
    },
    {
        label: 'Ireland',
        value: 'IE',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Euro',
        currencyValue: 'EUR',
    },
    {
        label: 'Israel**',
        value: 'IL',
        languages: [
            {
                label: 'Hebrew',
                value: 'iw',
                criterionId: 1027,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Israeli New Shekel',
        currencyValue: 'ILS',
    },
    {
        label: 'Italy',
        value: 'IT',
        languages: [
            {
                label: 'Italian',
                value: 'it',
                criterionId: 1004,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Euro',
        currencyValue: 'EUR',
    },
    {
        label: 'Japan',
        value: 'JP',
        languages: [
            {
                label: 'Japanese',
                value: 'ja',
                criterionId: 1005,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Japanese Yen',
        currencyValue: 'JPY',
    },
    {
        label: 'Malaysia',
        value: 'MY',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
            {
                label: 'Chinese (simplified)',
                value: 'zh_CN',
                criterionId: 1017,
            },
        ],
        currencyLabel: 'Malaysia Ringgit',
        currencyValue: 'MYR',
    },
    {
        label: 'Mexico',
        value: 'MX',
        languages: [
            {
                label: 'Spanish',
                value: 'es',
                criterionId: 1003,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Mexican Peso',
        currencyValue: 'MXN',
    },
    {
        label: 'Netherlands',
        value: 'NL',
        languages: [
            {
                label: 'Dutch',
                value: 'nl',
                criterionId: 1010,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Euro',
        currencyValue: 'EUR',
    },
    {
        label: 'New Zealand',
        value: 'NZ',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'New Zealand Dollar',
        currencyValue: 'NZD',
    },
    {
        label: 'Norway',
        value: 'NO',
        languages: [
            {
                label: 'Norwegian',
                value: 'no',
                criterionId: 1013,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Norwegian Krone',
        currencyValue: 'NOK',
    },
    {
        label: 'Philippines',
        value: 'PH',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Philippine Peso',
        currencyValue: 'PHP',
    },
    {
        label: 'Poland',
        value: 'PL',
        languages: [
            {
                label: 'Polish',
                value: 'pl',
                criterionId: 1030,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Poland Z?oty',
        currencyValue: 'PLN',
    },
    {
        label: 'Portugal',
        value: 'PT',
        languages: [
            {
                label: 'Portuguese',
                value: 'pt',
                criterionId: 1014,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Euro',
        currencyValue: 'EUR',
    },
    {
        label: 'Russia',
        value: 'RU',
        languages: [
            {
                label: 'Russian',
                value: 'ru',
                criterionId: 1031,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Russian Ruble',
        currencyValue: 'RUB',
    },
    {
        label: 'Saudi Arabia**',
        value: 'SA',
        languages: [
            {
                label: 'Arabic',
                value: 'ar',
                criterionId: 1019,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Saudi Riyal',
        currencyValue: 'SAR',
    },
    {
        label: 'Singapore',
        value: 'SG',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
            {
                label: 'Chinese (simplified)',
                value: 'zh_CN',
                criterionId: 1017,
            },
        ],
        currencyLabel: 'Singapore Dollar',
        currencyValue: 'SGD',
    },
    {
        label: 'South Africa',
        value: 'ZA',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'South African Rand',
        currencyValue: 'ZAR',
    },
    {
        label: 'South Korea**',
        value: 'KR',
        languages: [
            {
                label: 'Korean',
                value: 'ko',
                criterionId: 1012,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'South Korean Won',
        currencyValue: 'KRW',
    },
    {
        label: 'Spain',
        value: 'ES',
        languages: [
            {
                label: 'Spanish',
                value: 'es',
                criterionId: 1003,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Euro',
        currencyValue: 'EUR',
    },
    {
        label: 'Sweden',
        value: 'SE',
        languages: [
            {
                label: 'Swedish',
                value: 'sv',
                criterionId: 1015,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Swedish Krona',
        currencyValue: 'SEK',
    },
    {
        label: 'Switzerland',
        value: 'CH',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
            {
                label: 'German',
                value: 'de',
                criterionId: 1001,
            },
            {
                label: 'French',
                value: 'fr',
                criterionId: 1002,
            },
            {
                label: 'Italian',
                value: 'it',
                criterionId: 1004,
            },
        ],
        currencyLabel: 'Swiss Franc',
        currencyValue: 'CHF',
    },
    {
        label: 'Taiwan',
        value: 'TW',
        languages: [
            {
                label: 'Chinese (traditional)',
                value: 'zh_TW',
                criterionId: 1018,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'New Taiwan Dollar',
        currencyValue: 'TWD',
    },
    {
        label: 'Thailand**',
        value: 'TH',
        languages: [
            {
                label: 'Thai',
                value: 'th',
                criterionId: 1044,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Thai Baht',
        currencyValue: 'THB',
    },
    {
        label: 'Turkey',
        value: 'TR',
        languages: [
            {
                label: 'Turkish',
                value: 'tr',
                criterionId: 1037,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Turkish Lira',
        currencyValue: 'TRY',
    },
    {
        label: 'Ukraine**',
        value: 'UA',
        languages: [
            {
                label: 'Ukrainian',
                value: 'uk',
                criterionId: 1036,
            },
            {
                label: 'Russian',
                value: 'ru',
                criterionId: 1031,
            },
        ],
        currencyLabel: 'Ukrainian Hryvnia',
        currencyValue: 'UAH',
    },
    {
        label: 'United Arab Emirates',
        value: 'AE',
        languages: [
            {
                label: 'Arabic',
                value: 'ar',
                criterionId: 1019,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'United Arab Emirates Dirham',
        currencyValue: 'AED',
    },
    {
        label: 'United Kingdom',
        value: 'GB',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'British Pound',
        currencyValue: 'GBP',
    },
    {
        label: 'United States',
        value: 'US',
        languages: [
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
            {
                label: 'Spanish',
                value: 'es',
                criterionId: 1003,
            },
            {
                label: 'Chinese (simplified)',
                value: 'zh_CN',
                criterionId: 1017,
            },
        ],
        currencyLabel: 'US Dollar',
        currencyValue: 'USD',
    },
    {
        label: 'Vietnam**',
        value: 'VN',
        languages: [
            {
                label: 'Vietlabelse',
                value: 'vi',
                criterionId: 1040,
            },
            {
                label: 'English',
                value: 'en',
                criterionId: 1000,
            },
        ],
        currencyLabel: 'Vietlabelse Dong',
        currencyValue: 'VND',
    },
];
