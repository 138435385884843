import React from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { AnyAction, Store } from 'redux';
import OmegaTracking from './component/scripts/OmegaTracking';
import WootricSurvey from './component/scripts/WootricSurvey';
import routes from './routes/routes';
import { ReducersState } from './store/reducers';
import messages from './translations/en.json';

addLocaleData([...en]);

const App = ({ store }: { store: Store<ReducersState, AnyAction> }) => (
    <IntlProvider
        locale="en"
        messages={messages}
        textComponent={React.Fragment}
    >
        <ReduxProvider store={store}>
            <React.Fragment>
                <OmegaTracking />
                <WootricSurvey />
                <Router>{routes}</Router>
            </React.Fragment>
        </ReduxProvider>
    </IntlProvider>
);

export default App;
