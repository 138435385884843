import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { isEmpty } from 'lodash';

import { classnames } from '../../../../util';

import styles from './NavControls.module.css';

export default class NavControls extends Component {
    handleDisabledClick = e => e.preventDefault();
    handleEnabledClick = e => e.stopPropagation();

    render() {
        const { isCreateAdButtonDisabled } = this.props;

        const settingsButtonStyles = classnames([
            styles.link,
            styles['settings-button'],
        ]);
        const backButtonStyles = classnames([
            styles.link,
            styles.arrow,
            styles['back-button'],
        ]);

        const createAdButtonStyles = classnames([
            styles.link,
            styles['create-ad-button'],
            isCreateAdButtonDisabled && styles['create-ad-button_disabled'],
        ]);

        const isAdAccountCreated = !isEmpty(window.googleAppConfig.adConfig);
        const adCreationLink = !isAdAccountCreated
            ? '/google-ads-terms'
            : '/create-ad';

        return (
            <div className={styles['nav-container']}>
                <Link to="/" className={backButtonStyles}>
                    <FormattedMessage id="gmcDetails.controlPanel.backButton" />
                </Link>
                <Link to="settings" className={settingsButtonStyles}>
                    <FormattedMessage id="gmcDetails.controlPanel.settingsButton" />
                </Link>
                <Link
                    to={adCreationLink}
                    onClick={
                        isCreateAdButtonDisabled
                            ? this.handleDisabledClick
                            : this.handleEnabledClick
                    }
                    className={createAdButtonStyles}
                >
                    <FormattedMessage id="gmcDetails.controlPanel.createAdButton" />
                </Link>
            </div>
        );
    }
}
