import { applyMiddleware, createStore as createReduxStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers, { ReducersState } from './reducers';

export type RootState = Readonly<ReducersState>;

export default function createStore(initialState: RootState = {}) {
    return createReduxStore(
        reducers,
        initialState,
        applyMiddleware(ReduxThunk),
    );
}
