import { ActionCreator } from 'redux';

import { ISetCampaignName } from '../../../../reducers/adsCreationReducer';
import { SET_CAMPAIGN_NAME } from '../../../../store/actionConsts';

export const saveCampaignNameToStore: ActionCreator<ISetCampaignName> = (
    name: string,
) => ({
    payload: {
        name,
    },
    type: SET_CAMPAIGN_NAME,
});
