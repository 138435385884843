import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { classnames } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './DashboardLandingActions.module.css';

export default function DashboardLandingActions(props) {
    const { isCreateAdButtonEnabled, isAdwordAccountCreated } = props;
    const adCreationLink = !isAdwordAccountCreated
        ? '/google-ads-terms'
        : '/create-ad';
    const actionsPanelStyles = classnames([
        commonStyles['main-actions-panel'],
        commonStyles.clearfix,
    ]);
    const settingsButtonStyles = classnames([
        styles.link,
        styles['settings-button'],
    ]);
    const createGmcButtonStyles = classnames([
        styles.link,
        styles['create-gmc-button'],
    ]);
    const createAdButtonStyles = classnames([
        styles.link,
        styles['create-ad-button'],
        !isCreateAdButtonEnabled ? styles['create-ad-button_disabled'] : '',
    ]);

    return (
        <div className={actionsPanelStyles}>
            <div className={styles['action-controls']}>
                <Link to="settings" className={settingsButtonStyles}>
                    <FormattedMessage id="dashboardLanding.controlPanel.settingsButton" />
                </Link>
                <Link to="terms" className={createGmcButtonStyles}>
                    <FormattedMessage id="dashboardLanding.controlPanel.createGmcButton" />
                </Link>
                <Link to={adCreationLink} className={createAdButtonStyles}>
                    <FormattedMessage id="dashboardLanding.controlPanel.createAdButton" />
                </Link>
            </div>
        </div>
    );
}
