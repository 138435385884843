import {
    compact,
    find,
    has,
    includes,
    isEmpty,
    map,
    reduce,
    filter,
    some,
    flatten,
    forEach,
    get,
} from 'lodash';
import googleCategories from 'config/googleCategories.json';

export function parseAttributesByDataType(requiredDataType) {
    const googleAppAttributes = window.googleAppConfig.attributes;

    return reduce(
        requiredDataType,
        (attributes, dataType) => {
            attributes.push(
                ...filter(
                    googleAppAttributes[dataType],
                    item => item.label !== null,
                ),
            );
            return attributes;
        },
        [],
    );
}

export const mapAttributeMappingResponseData = responseData => {
    if (isEmpty(responseData)) {
        return {};
    }

    const specificAttributes = ['condition', 'ageGroup', 'gender'];
    const excludedAttributes = [
        'googleProductCategory',
        'identifierExists',
        ...specificAttributes,
    ];

    const attributesDataTypeMap = {
        adult: ['text', 'select'],
        brand: ['text', 'select'],
        color: ['text', 'select'],
        description: ['text', 'textarea'],
        gtin: ['text'],
        mpn: ['text'],
        identifierExists: ['text', 'select'],
        sizes: ['text', 'select'],
        title: ['text'],
        googleProductCategory: ['text', 'select'],
        condition: ['text', 'select'],
        ageGroup: ['text', 'select'],
        gender: ['text', 'select'],
    };

    return reduce(
        responseData,
        (mappedData, item) => {
            const {
                channelAttributeCode: respAttributeCode,
                yuiAttributes: respAttributesList,
            } = item;
            const dataTypes = attributesDataTypeMap[respAttributeCode];

            if (!dataTypes) {
                return mappedData;
            }

            if (!includes(excludedAttributes, respAttributeCode)) {
                mappedData[respAttributeCode] = {
                    data: convertAttributeArrays(respAttributesList, dataTypes),
                };

                return mappedData;
            }
            if (respAttributeCode === 'googleProductCategory') {
                if (has(item, 'channelAttributeValue')) {
                    return {
                        ...mappedData,
                        categoryAttrRadioId: {
                            data: 'googleCategoryAttr',
                        },
                        googleCategoryAttr: {
                            data:
                                find(googleCategories, {
                                    label: item.channelAttributeValue,
                                }) || [],
                        },
                    };
                }

                return {
                    ...mappedData,
                    categoryAttrRadioId: {
                        data: 'yuiCategoryAttr',
                    },
                    yuiCategoryAttr: {
                        data: convertAttributeArrays(
                            respAttributesList,
                            dataTypes,
                        ),
                    },
                };
            }
            if (respAttributeCode === 'identifierExists') {
                if (has(item, 'channelAttributeValue')) {
                    return {
                        ...mappedData,
                        identifierExistsAttrRadioId: {
                            data: 'noAttributesForProducts',
                        },
                    };
                }

                return {
                    ...mappedData,
                    identifierExistsAttrRadioId: {
                        data: 'identifyYuiAttributes',
                    },
                    identifierExists: {
                        data: convertAttributeArrays(
                            respAttributesList,
                            dataTypes,
                        ),
                    },
                };
            }
            if (includes(specificAttributes, respAttributeCode)) {
                if (has(item, 'channelAttributeValue')) {
                    return {
                        ...mappedData,
                        [`${respAttributeCode}AttrRadioId`]: {
                            data: `${respAttributeCode}Common`,
                        },
                        [`${respAttributeCode}`]: {
                            data: {
                                label: `${item.channelAttributeValue[0].toUpperCase()}${item.channelAttributeValue.slice(
                                    1,
                                )}`,
                                value: item.channelAttributeValue,
                            },
                        },
                    };
                }

                const specificData = convertAttributeArrays(
                    respAttributesList,
                    dataTypes,
                    'value',
                );

                const specificMappingData = reduce(
                    respAttributesList,
                    (result, attributeItem) => {
                        const { yuiAttributeCode, options } = attributeItem;

                        const attributeOptions = get(
                            find(specificData, {
                                value: yuiAttributeCode,
                            }),
                            'attributeOptions',
                            [],
                        );

                        forEach(options, optionItem => {
                            const {
                                channelOptionValue,
                                yuiOptions,
                            } = optionItem;

                            if (!has(result, channelOptionValue)) {
                                result[channelOptionValue] = { data: [] };
                            }

                            result[channelOptionValue].data = [
                                ...result[channelOptionValue].data,
                                ...convertAttributeOptionsArrays(
                                    yuiOptions,
                                    attributeOptions,
                                    'label',
                                ),
                            ];
                        });

                        return result;
                    },
                    {},
                );

                return {
                    ...mappedData,
                    [`${respAttributeCode}AttrRadioId`]: {
                        data: `${respAttributeCode}Multiple`,
                    },
                    [`${respAttributeCode}`]: {
                        ...specificMappingData,
                        specificData,
                    },
                };
            }
        },
        {},
    );
};

export const convertAttributeArrays = (
    attributes,
    dataTypes,
    searchParam = 'value',
) => {
    const sourceAttributes = parseAttributesByDataType(dataTypes);

    return compact(
        map(attributes, item =>
            find(sourceAttributes, {
                [searchParam]: item.yuiAttributeCode,
            }),
        ),
    );
};

export const convertAttributeOptionsArrays = (
    respOptions,
    sourceOptions,
    searchParam = 'value',
) =>
    compact(
        map(respOptions, item =>
            find(sourceOptions, {
                [searchParam]: item.yuiOptionValue,
            }),
        ),
    );

export const createSpecificAttributeOptions = (
    useYuiAttributesSelectConfig,
    selectedOptions,
    props,
) => {
    const { name } = useYuiAttributesSelectConfig;
    const attributeOptions =
        props.attributeMapping.attributes[name].specificData;
    const specificAttributeOptionsMap = flatten(
        map(attributeOptions, 'attributeOptions'),
    );

    return !isEmpty(selectedOptions)
        ? reduce(
              specificAttributeOptionsMap,
              (result, option) =>
                  !some(
                      selectedOptions,
                      selectedOption => option.label === selectedOption.label,
                  )
                      ? [...result, option]
                      : result,
              [],
          )
        : specificAttributeOptionsMap;
};

export const getSelectedSpecificAttributeOptions = (
    useYuiAttributesSelectConfig,
    props,
) => {
    const { name, specificAttributeNames } = useYuiAttributesSelectConfig;
    const attributes = props.attributeMapping.attributes[name];

    return flatten(
        map(
            specificAttributeNames,
            specificAttribute => attributes[specificAttribute].data,
        ),
    );
};

export const updateSelectedSpecificAttributeValues = (
    useYuiAttributesSelectConfig,
    updatedMap,
    props,
) => {
    const { name, specificAttributeNames } = useYuiAttributesSelectConfig;
    const attributes = props.attributeMapping.attributes[name];

    specificAttributeNames.forEach(specificAttributeName => {
        const options = reduce(
            attributes[specificAttributeName].data,
            (result, selectedOption) =>
                some(
                    updatedMap,
                    specificOption =>
                        specificOption.label === selectedOption.label,
                )
                    ? [...result, selectedOption]
                    : result,
            [],
        );
        props.saveSpecificAttributeOptionToStore(
            name,
            specificAttributeName,
            options,
        );
    });
};
