import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { isEmpty } from 'lodash';
import Tooltip from 'component/Tooltip';
import { RadioButton, ConfigurableSelect } from 'component/form';

import customSelectStyles from './selectComponent.styles';
import commonStyles from 'design/styles/common.module.css';
import { parseAttributesByDataType } from 'helpers/attributeMapping';

export default class SellMultipleCategoriesOption extends Component {
    static propTypes = {
        attributeMapping: PropTypes.shape({
            attributes: PropTypes.shape({
                yuiCategoryAttr: PropTypes.shape({
                    error: PropTypes.bool,
                    data: PropTypes.oneOfType([
                        PropTypes.array,
                        PropTypes.object,
                    ]),
                }),
            }),
        }).isRequired,
        activeRadioButtonId: PropTypes.string.isRequired,
        optionContentStyle: PropTypes.string.isRequired,
        radioButtonsConfig: PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
        setActiveRadioButtonId: PropTypes.func.isRequired,
        saveAttributeToStore: PropTypes.func.isRequired,
        validateAttribute: PropTypes.func.isRequired,
    };
    state = {
        options: [],
        selectedOptions: [],
    };
    static selectConfig = {
        requiredDataType: ['text', 'select'],
    };

    radioButtonChangeHandler = e =>
        this.props.setActiveRadioButtonId(e.target.id);

    selectChangeHandler = options => {
        const { id } = this.props.radioButtonsConfig;

        this.setState({ selectedOptions: options });
        this.props.saveAttributeToStore(id, options);
        this.props.validateAttribute(options, 'yuiCategoryAttr');
    };

    componentDidMount() {
        const { id } = this.props.radioButtonsConfig;
        const {
            data: selectedOptions,
        } = this.props.attributeMapping.attributes[id];

        this.setState({
            options: parseAttributesByDataType(
                SellMultipleCategoriesOption.selectConfig.requiredDataType,
            ),
            selectedOptions: !isEmpty(selectedOptions) ? selectedOptions : [],
        });
    }

    render() {
        const {
            props: {
                activeRadioButtonId,
                optionContentStyle,
                radioButtonsConfig,
                attributeMapping: {
                    attributes: { yuiCategoryAttr },
                },
            },
            state: { options },
        } = this;
        const { id, label } = radioButtonsConfig;
        const isRadioChecked = activeRadioButtonId === id;
        const error = isRadioChecked && yuiCategoryAttr.error;

        return (
            <React.Fragment>
                <div className={commonStyles['flex-container']}>
                    <RadioButton
                        id={id}
                        onChange={this.radioButtonChangeHandler}
                        checked={isRadioChecked}
                        labelFor={id}
                        label={label}
                    />
                    <Tooltip
                        direction="left"
                        link="sales-channels/google-ads/attribute-mapping.html"
                    >
                        <FormattedMessage id="help.attributeMapping.sellMultipleCategoryOption" />
                    </Tooltip>
                </div>
                <div className={optionContentStyle}>
                    <ConfigurableSelect
                        styles={customSelectStyles}
                        onChange={this.selectChangeHandler}
                        value={this.state.selectedOptions}
                        {...{
                            isMulti: true,
                            isSearchable: true,
                            placeholder: (
                                <FormattedMessage id="attributeMapping.yuiAttributesSelect.placeholder" />
                            ),
                            errorMessage: (
                                <FormattedMessage id="attributeMapping.select.errorMessage" />
                            ),
                            options,
                            showError: error,
                            isDisabled: !isRadioChecked,
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}
