import selectStyles from 'component/form/Select/ConfigurableSelect.styles';

const customSelectStyles = { ...selectStyles };

customSelectStyles.control = base => ({
    ...selectStyles.control(base),
    width: 450,
});

customSelectStyles.menu = base => ({
    ...selectStyles.menu(base),
    width: 532,
});

customSelectStyles.menuList = base => ({
    ...selectStyles.menuList(base),
    'padding-right': 0,
});

customSelectStyles.option = (base, state) => ({
    ...selectStyles.option(base, state),
    font: '600 14px Open Sans, serif',
    padding: '7px',
    height: 36,
});

export default customSelectStyles;
