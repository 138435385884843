import { ActionCreator } from 'redux';

import {
    IResetAdsCreationStateAction,
    ISaveCampaignNamesToStore,
} from '../../reducers/adsCreationReducer';
import { IResetAdsSettingsStateAction } from '../../reducers/adsSettingsReducer';
import {
    RESET_ADS_CREATION_STATE,
    RESET_ADS_SETTINGS_STATE,
    SAVE_CAMPAIGN_NAMES_TO_STORE,
} from '../../store/actionConsts';

export const resetAdsCreationState: ActionCreator<
    IResetAdsCreationStateAction
> = () => ({
    type: RESET_ADS_CREATION_STATE,
});

export const resetAdsSettingsState: ActionCreator<
    IResetAdsSettingsStateAction
> = () => ({
    type: RESET_ADS_SETTINGS_STATE,
});

export const saveAllCampaignNamesToStore: ActionCreator<
    ISaveCampaignNamesToStore
> = (payload: { allCampaignNames: string[] }) => ({
    payload,
    type: SAVE_CAMPAIGN_NAMES_TO_STORE,
});
