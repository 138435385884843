import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Terms from 'scenes/Terms';
import AdsTerms from 'scenes/AdsTerms';
import AccountSettings from 'scenes/AccountSettings';
import CreateAd from 'scenes/CreateAd';
import UrlVerification from 'scenes/UrlVerification';
import ShippingAndTax from 'scenes/ShippingAndTax';
import AttributeMapping from 'scenes/AttributeMapping';
import DashboardLanding from 'scenes/DashboardLanding';
import GoogleCallback from '../scenes/DashboardLanding/GoogleCallback';
import GMCDetails from 'scenes/GMCDetails';
import Dashboard from 'scenes/Dashboard';
import Settings from 'scenes/Settings';
import ProtectedRoute from 'component/ProtectedRoute';
import * as routeguards from './routeguards';

/**
 * Collection of routes for the project.
 */
const routes = (
    //We use switch to avoid multiple route rendering
    <Switch>
        <Route exact path="/" component={DashboardLanding} />
        {/*Terms and conditions page route*/}
        <ProtectedRoute
            exact
            path="/terms"
            component={Terms}
            guards={[routeguards.emailGuard]}
        />
        {/*Url verification page route*/}
        <ProtectedRoute
            exact
            path="/url-verification"
            component={UrlVerification}
            guards={[routeguards.consentGuard]}
        />
        {/*Shipping and tax page route*/}
        <ProtectedRoute
            exact
            path="/shipping-and-tax"
            component={ShippingAndTax}
            guards={[routeguards.consentGuard]}
        />
        {/*Attribute mapping page route*/}
        <ProtectedRoute
            exact
            path="/attribute-mapping"
            component={AttributeMapping}
            guards={[routeguards.consentGuard]}
        />
        {/*Main dashboard page route*/}
        <Route exact path="/dashboard" component={Dashboard} />
        {/*Google Ads terms and conditions page route*/}
        <Route exact path="/google-ads-terms" component={AdsTerms} />
        {/*Account verification page route*/}

        {/* (Yegor_F) Commented protected route for testing wizard. Must be uncommented when implementing this page */}
        {/* <ProtectedRoute exact path="/account-settings" component={AccountSettings} guards={[routeguards.consentGuard]}/> */}

        <Route exact path="/account-settings" component={AccountSettings} />
        {/*Create ad page route*/}

        {/* (Yegor_F) Commented protected route for testing wizard. Must be uncommented when implementing this page */}
        {/* <ProtectedRoute exact path="/create-ad" component={ShippingAndTax} guards={[routeguards.consentGuard]}/> */}

        <Route exact path="/create-ad" component={CreateAd} />
        <Route exact path="/gmc-details" component={GMCDetails} />
        <Route exact path="/settings" component={Settings} />

        <Route path="/google-callback" component={GoogleCallback} />

        {/*Renders when no route is found*/}
        <Route render={() => <div id="noMatch">No match.</div>} />
    </Switch>
);

export default routes;
