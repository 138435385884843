import React from 'react';
import { FormattedMessage } from 'react-intl';

import { classnames } from '../../../../../../util';

import styles from './MainActionsPanel.module.css';
import commonStyles from 'design/styles/common.module.css';

export default function MainActionsPanel(props) {
    const actionControlsStyles = classnames([styles['action-controls']]);
    const publishButtonStyles = classnames([
        styles['publish-button'],
        commonStyles['font-semibold'],
        !props.isButtonActive && styles['publish-button_disabled'],
    ]);
    const editButtonStyles = classnames([
        styles['edit-button'],
        commonStyles['font-semibold'],
    ]);
    const mainActionsStyles = classnames([
        commonStyles['main-actions-panel'],
        commonStyles.clearfix,
    ]);

    return (
        <div className={mainActionsStyles}>
            <div className={actionControlsStyles}>
                <button
                    className={editButtonStyles}
                    onClick={props.handleCloseSidebar}
                >
                    <FormattedMessage id="reviewCampaign.button.editCampaign" />
                </button>
                <button
                    className={publishButtonStyles}
                    onClick={props.publishCampaignHandler}
                    disabled={!props.isButtonActive}
                >
                    <FormattedMessage id="reviewCampaign.button.publishCampaign" />
                </button>
            </div>
        </div>
    );
}
