import React, { Component } from 'react';
import RadioButton from 'component/form/RadioButton';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'component/Tooltip';

export class SetCostOnGmc extends Component {
    constructor(props) {
        super(props);
        this.radioButtonConfig = {
            label: <FormattedMessage id="shippingAndTax.cost.radio_2" />,
            id: this.props.radioButtonIds.setCostOnGmc,
        };
    }

    radioButtonChangeHandler = event => {
        this.props.setActiveRadioButtonState(event.target.id);
        this.props.saveSetupCostOnGmc();
        this.props.removeShippingCostFlatRateError();
    };

    render() {
        const isRadioChecked =
            this.props.activeRadioButtonId === this.radioButtonConfig.id;

        return (
            <React.Fragment>
                <RadioButton
                    id={this.radioButtonConfig.id}
                    name={this.radioButtonConfig.name}
                    onChange={this.radioButtonChangeHandler}
                    checked={isRadioChecked}
                    labelFor={this.radioButtonConfig.id}
                    label={this.radioButtonConfig.label}
                />
                <Tooltip
                    direction="right"
                    link="sales-channels/google-ads/shipping-tax.html"
                >
                    <FormattedMessage id="help.shippingAndTax.costMerchantService" />
                </Tooltip>
            </React.Fragment>
        );
    }
}

export default SetCostOnGmc;
