import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as allActionCreators from './actions';
import Modal from 'component/modal';
import PropTypes from 'prop-types';
import { compact, forEach, get, has, map, isEmpty, some } from 'lodash';

import Notification from 'component/notification';
import Loader from 'component/Loader';
import { AdForms } from 'component/AdForms';
import { Sidebar } from 'component/sidebar';
import {
    DescriptionText,
    CancelModalContent,
    ReviewCampaignContent,
    NavControls,
} from './components';
import styles from './CreateAd.module.css';

import { operationStatuses } from 'config/operationStatuses';
import { yuiRequest, OperationRequest } from 'api';

import { redirectToYuiLogin } from 'helpers/redirectToYuiLogin';

/**
 * Create an Ad page scene.
 */
export class CreateAd extends Component {
    static propTypes = {
        adsCreation: PropTypes.object.isRequired,
        resetAdsCreationState: PropTypes.func.isRequired,
        resetAdsSettingsState: PropTypes.func.isRequired,
        saveAllCampaignNamesToStore: PropTypes.func.isRequired,
    };
    state = {
        showWizard: false,
        gmcAccountError: false,
        countryToAdvertiseError: false,
        productFilterError: false,
        campaignNameError: false,
        dailyBudgetError: false,
        isModalVisible: false,
        isSidebarOpened: false,
        redirectToLanding: false,
        redirectToAdsTerms: false,
        productsNumber: 0,
        isProductsNumberLoaded: false,
        isLoaderActive: false,
        isPublishButtonEnabled: false,
        isErrorNotificationActive: false,
        isReviewCampaignErrorActive: false,
        sidebarErrorMessage: '',
    };

    loadersStack = [];
    operationRequestsStack = [];

    constructor(props) {
        super(props);
        this.campaignMainFormFieldIds = {
            gmcAccount: 'gmcAccount',
            countryToAdvertise: 'countryToAdvertise',
            campaignName: 'campaignName',
            dailyBudget: 'dailyBudget',
        };
    }

    showLoader = () => {
        this.loadersStack.push('loading');
        this.setState({ isLoaderActive: true });
    };

    hideLoader = () => {
        const { loadersStack } = this;
        loadersStack.pop();

        if (isEmpty(loadersStack)) {
            this.setState({
                isLoaderActive: false,
            });
        }
    };

    showNotification = error => {
        const isSessionExpired = has(error, 'ajaxExpired');

        isSessionExpired
            ? redirectToYuiLogin()
            : this.setState({
                  isErrorNotificationActive: true,
              });
    };

    showReviewCampaignNotification = error => {
        const isSessionExpired = has(error, 'ajaxExpired');

        isSessionExpired
            ? redirectToYuiLogin()
            : this.setState({
                  isReviewCampaignErrorActive: true,
              });
    };

    async componentDidMount() {
        const { currency, timezone } = this.props.adsSettings;
        const { allCampaignNames } = this.props.adsCreation;

        if (currency !== '' && timezone !== '') {
            this.setState({ showWizard: true });
            return;
        }

        if (!isEmpty(window.googleAppConfig.adConfig)) {
            isEmpty(allCampaignNames) && (await this.fetchCampaignsData());

            return;
        }

        this.showLoader();

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: 'adwords/account',
            });

            const adsData = JSON.parse(response.body);

            if (isEmpty(adsData)) {
                this.setState({ redirectToAdsTerms: true });
            } else {
                window.googleAppConfig.adConfig = adsData;

                isEmpty(allCampaignNames) && (await this.fetchCampaignsData());
            }
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.hideLoader();
        }
    }

    componentWillUnmount() {
        forEach(this.operationRequestsStack, operation =>
            operation.poller.stop(false),
        );
    }

    fetchCampaignsData = async () => {
        this.showLoader();

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: 'adwords/advertisingpage/campaigntable',
            });
            const campaignNames = {
                allCampaignNames: map(
                    compact(get(JSON.parse(response.body), 'campaigns', [])),
                    campaign => campaign.name,
                ),
            };

            this.props.saveAllCampaignNamesToStore(campaignNames);
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    setFieldError = fieldId => {
        this.setState({
            [`${fieldId}Error`]: true,
        });
    };

    unsetFieldError = fieldId => {
        this.setState({
            [`${fieldId}Error`]: false,
        });
    };

    validateCampaignProductsFilterFields() {
        const { adsCreation } = this.props;
        const isSelectAllRadioSelected =
            adsCreation.activeRadioButtonId !== 'selectUsingFilters';

        if (
            isSelectAllRadioSelected ||
            some(
                ['selectedFilterCategories', 'selectedFilterBrands'],
                id => !isEmpty(adsCreation[id]),
            )
        ) {
            this.unsetFieldError('productFilter');
            return true;
        }
        this.setFieldError('productFilter');
        return false;
    }

    validateCampaignMainFormFields() {
        const errors = [];

        map(this.campaignMainFormFieldIds, fieldId => {
            if (!isEmpty(this.props.adsCreation[fieldId])) {
                this.unsetFieldError(fieldId);
            } else {
                this.setFieldError(fieldId);
                errors.push(fieldId);
            }
        });

        return !errors.length;
    }

    handleNext = () => {
        const isMainFormValid = this.validateCampaignMainFormFields();
        const isProductFilterValid = this.validateCampaignProductsFilterFields();

        if (isMainFormValid && isProductFilterValid) {
            this.setState({ isSidebarOpened: true });
        }
    };

    redirectToDashboard = () => {
        this.props.resetAdsCreationState();
        this.props.resetAdsSettingsState();
        this.setState({ redirectToLanding: true });
    };

    confirmSkipHandler = () => {
        this.hideModal();

        this.adwordsCreationRequest(
            this.skipCampaignSuccessHandler,
            this.skipCampaignFailureHandler,
            this.skipCampaignErrorHandler,
            this.showNotification,
        );
    };

    skipCampaignSuccessHandler = () => {
        const { country, currency, timezone } = this.props.adsSettings;
        if (isEmpty(window.googleAppConfig.adConfig)) {
            window.googleAppConfig.adConfig = {
                country,
                currency,
                timezone,
                paymentConfigured: false,
            };
        }

        this.hideLoader();
        this.redirectToDashboard();
    };

    skipCampaignFailureHandler = () => {
        this.setState({ isErrorNotificationActive: true });
        this.hideLoader();
    };

    skipCampaignErrorHandler = error => {
        this.showNotification(error);
        this.hideLoader();
    };

    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    hideModal = () => {
        this.setState({ isModalVisible: false });
    };

    handleCloseSidebar = () => {
        this.setState({
            isSidebarOpened: false,
            isProductsNumberLoaded: false,
            isPublishButtonEnabled: false,
            isReviewCampaignErrorActive: false,
        });
    };

    makeOperationRequest = config => {
        this.showLoader();
        this.operationRequestsStack.push(new OperationRequest(config));
    };

    publishAdwordsSuccessHandler = () => {
        const { country, currency, timezone } = this.props.adsSettings;
        if (isEmpty(window.googleAppConfig.adConfig)) {
            window.googleAppConfig.adConfig = {
                country,
                currency,
                timezone,
                paymentConfigured: false,
            };
        }
        this.hideLoader();
        this.campaignCreationRequest();
    };

    publishCampaignSuccessHandler = () => {
        sessionStorage.setItem('isCampaignCreated', 'true');
        this.props.resetAdsCreationState();
        this.props.resetAdsSettingsState();
        this.hideLoader();
        this.setState({ redirectToLanding: true });
    };

    publishCampaignFailureHandler = error => {
        this.setState({
            isReviewCampaignErrorActive: true,
            sidebarErrorMessage: get(error, 'comments', ''),
        });
        this.hideLoader();
    };

    publishCampaignErrorHandler = error => {
        this.showReviewCampaignNotification(error);
        this.hideLoader();
    };

    adwordsCreationRequest = async (
        operationRequestSuccessHandler,
        operationRequestFailureStatusHandler,
        operationRequestErrorHandler,
        adwordsCreationErrorHandler,
    ) => {
        const { country, currency, timezone } = this.props.adsSettings;
        const { adConfig } = window.googleAppConfig;

        const {
            adAccountCreationSuccess,
            adAccountCreationFail,
        } = operationStatuses;

        this.showLoader();

        try {
            const response = await yuiRequest({
                method: 'post',
                uri: 'adwords/account',
                payload: {
                    country: country || get(adConfig, 'country', ''),
                    currency: currency || get(adConfig, 'currency', ''),
                    timeZone: timezone || get(adConfig, 'timezone', ''),
                },
            });

            const operationId = get(
                JSON.parse(response.body),
                'operationId',
                '',
            );

            this.makeOperationRequest({
                operationId,
                delay: 2000,
                successStatusHandler: operationRequestSuccessHandler,
                failureStatusHandler: operationRequestFailureStatusHandler,
                requestErrorHandler: operationRequestErrorHandler,
                successStatus: adAccountCreationSuccess,
                failureStatuses: [adAccountCreationFail],
            });
        } catch (error) {
            adwordsCreationErrorHandler(error);
        } finally {
            this.hideLoader();
        }
    };

    campaignCreationRequest = async () => {
        const {
            channelId,
            countryToAdvertise,
            campaignName,
            dailyBudget,
            activeRadioButtonId,
            selectedFilterBrands,
            selectedFilterCategories,
            allSKUsAreSelected,
            includedProductsBySKU,
            excludedProductsBySKU,
        } = this.props.adsCreation;

        const {
            adCampaignCreationSuccess,
            adCampaignCreationFail,
        } = operationStatuses;

        const currencyCode =
            get(this.props.adsSettings, 'currency', '') ||
            get(window.googleAppConfig.adConfig, 'currency', '');

        const radioIdsMap = {
            selectAll: 'all',
            selectUsingFilters: 'brandCategory',
            selectUsingSKU: 'sku',
        };

        this.showLoader();

        try {
            const filters = [
                ...map(selectedFilterBrands, item => ({
                    type: 'brand',
                    value: item.label,
                })),
                ...map(selectedFilterCategories, item => ({
                    type: 'category',
                    value: item.value,
                })),
            ];

            const response = await yuiRequest({
                method: 'post',
                uri: `channels/${channelId}/campaigns`,
                payload: {
                    adwordsCampaignUserData: {
                        targetCountry: countryToAdvertise,
                        name: campaignName,
                        budget: dailyBudget,
                        currencyCode,
                        filterType: radioIdsMap[activeRadioButtonId],
                        filters:
                            activeRadioButtonId === 'selectUsingFilters'
                                ? filters
                                : [],
                        skuSelectedAll:
                            activeRadioButtonId === 'selectUsingSKU'
                                ? allSKUsAreSelected
                                : '',
                        productList:
                            activeRadioButtonId === 'selectUsingSKU'
                                ? !allSKUsAreSelected
                                    ? includedProductsBySKU
                                    : excludedProductsBySKU
                                : [],
                    },
                },
            });

            const operationId = get(
                JSON.parse(response.body),
                'operationId',
                '',
            );

            this.makeOperationRequest({
                operationId,
                delay: 2000,
                successStatusHandler: this.publishCampaignSuccessHandler,
                failureStatusHandler: this.publishCampaignFailureHandler,
                requestErrorHandler: this.publishCampaignErrorHandler,
                successStatus: adCampaignCreationSuccess,
                failureStatuses: [adCampaignCreationFail],
            });
        } catch (error) {
            this.showReviewCampaignNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    publishCampaignHandler = () => {
        const { adConfig } = window.googleAppConfig;

        if (isEmpty(adConfig)) {
            this.adwordsCreationRequest(
                this.publishAdwordsSuccessHandler,
                this.publishCampaignFailureHandler,
                this.publishCampaignErrorHandler,
                this.showReviewCampaignNotification,
            );
        } else {
            this.campaignCreationRequest();
        }
    };

    fetchProductsNumber = async () => {
        const {
            activeRadioButtonId: id,
            channelId,
            selectedFilterBrands,
            selectedFilterCategories,
            allSKUsAreSelected,
            includedProductsBySKU,
            excludedProductsBySKU,
        } = this.props.adsCreation;

        const payloadMap = {
            selectAll: {
                filters: [],
            },
            selectUsingFilters: {
                filters: [
                    ...map(selectedFilterBrands, item => ({
                        type: 'brand',
                        value: item.label,
                    })),
                    ...map(selectedFilterCategories, item => ({
                        type: 'category',
                        value: item.value,
                    })),
                ],
            },
            selectUsingSKU: {
                filters: [],
            },
        };

        if (id === 'selectUsingSKU' && !allSKUsAreSelected) {
            this.setState({
                productsNumber: includedProductsBySKU.length,
                isProductsNumberLoaded: true,
                isPublishButtonEnabled: true,
            });

            return;
        }

        try {
            const response = await yuiRequest({
                method: 'post',
                uri: `channels/${channelId}/feed/total`,
                payload: payloadMap[id],
            });

            const count = parseFloat(response.body);

            if (id === 'selectUsingSKU') {
                this.setState({
                    productsNumber: count - excludedProductsBySKU.length,
                    isPublishButtonEnabled: true,
                    isProductsNumberLoaded: true,
                });

                return;
            }

            this.setState({
                productsNumber: count,
                isPublishButtonEnabled: true,
                isProductsNumberLoaded: true,
            });
        } catch (error) {
            this.showReviewCampaignNotification(error);
            this.setState({
                isPublishButtonEnabled: false,
                isProductsNumberLoaded: true,
            });
        }
    };

    render() {
        const {
            state: {
                showWizard,
                campaignNameError,
                countryToAdvertiseError,
                dailyBudgetError,
                gmcAccountError,
                redirectToLanding,
                redirectToAdsTerms,
                isModalVisible,
                productsNumber,
                isProductsNumberLoaded,
                productFilterError,
                isPublishButtonEnabled,
                isErrorNotificationActive,
                isReviewCampaignErrorActive,
                sidebarErrorMessage,
            },
        } = this;

        if (redirectToLanding) {
            return <Redirect to="/" />;
        }
        if (redirectToAdsTerms) {
            return <Redirect to="/google-ads-terms" />;
        }

        return (
            <React.Fragment>
                <Loader isVisible={this.state.isLoaderActive} />
                <NavControls
                    showWizard={showWizard}
                    skipAction={this.showModal}
                    nextAction={this.handleNext}
                    cancelAction={this.redirectToDashboard}
                />
                <Notification
                    type="error"
                    defaultMessageType="requestError"
                    isActive={isErrorNotificationActive}
                />
                <DescriptionText />
                <AdForms
                    isEditMode={false}
                    campaignMainFormFieldIds={this.campaignMainFormFieldIds}
                    setFieldError={this.setFieldError}
                    unsetFieldError={this.unsetFieldError}
                    gmcAccountError={gmcAccountError}
                    countryToAdvertiseError={countryToAdvertiseError}
                    campaignNameError={campaignNameError}
                    dailyBudgetError={dailyBudgetError}
                    productFilterError={productFilterError}
                    showNotification={this.showNotification}
                    showLoader={this.showLoader}
                    hideLoader={this.hideLoader}
                />
                <Modal
                    modalContainerStyle={styles['modal-container']}
                    onOverlayClick={null}
                    showCloseSign={true}
                    handleCloseModal={this.hideModal}
                    isVisible={isModalVisible}
                >
                    <CancelModalContent
                        handleCloseModal={this.hideModal}
                        handleConfirmModal={this.confirmSkipHandler}
                    />
                </Modal>
                <Sidebar
                    isActive={this.state.isSidebarOpened}
                    onOverlayClick={this.handleCloseSidebar}
                    position="right"
                >
                    <ReviewCampaignContent
                        handleCloseSidebar={this.handleCloseSidebar}
                        publishCampaignHandler={this.publishCampaignHandler}
                        fetchProductsNumber={this.fetchProductsNumber}
                        productsNumber={productsNumber}
                        isDataLoaded={isProductsNumberLoaded}
                        isButtonActive={isPublishButtonEnabled}
                        isReviewCampaignErrorActive={
                            isReviewCampaignErrorActive
                        }
                        sidebarErrorMessage={sidebarErrorMessage}
                    />
                </Sidebar>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adsCreation: state.adsCreation,
    adsSettings: state.adsSettings,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(CreateAd);
