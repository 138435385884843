import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { classnames } from '../../util';

import styles from './Notification.module.css';

export default class Notification extends Component {
    static propTypes = {
        defaultMessageType: PropTypes.string,
        isActive: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
        children: PropTypes.node,
    };

    render() {
        const {
            children,
            type,
            defaultMessageType,
            isActive,
            messageValues,
        } = this.props;
        const notificationStyles = classnames([
            styles.notification,
            styles[type],
        ]);
        const message = defaultMessageType ? (
            <p>
                <FormattedMessage
                    id={`notification.${defaultMessageType}`}
                    values={messageValues || {}}
                />
            </p>
        ) : null;

        return (
            isActive && (
                <div className={notificationStyles}>
                    {children ? <div>{children}</div> : message}
                </div>
            )
        );
    }
}
