import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { classnames } from '../../util';

import styles from './Accordion.module.css';

export default class Accordion extends Component {
    static propTypes = {
        header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        children: PropTypes.node,
    };

    state = {
        isOpened: false,
    };

    handleClick = () => {
        this.setState(prevState => ({
            isOpened: !prevState.isOpened,
        }));
    };

    render() {
        const { isOpened } = this.state;
        const { header, text } = this.props;
        const containerStyles = classnames([
            styles.accordion,
            isOpened && styles['accordion_opened'],
        ]);

        return (
            <div className={containerStyles}>
                <div className={styles.title} onClick={this.handleClick}>
                    <div className={styles.header}>{header}</div>
                    <div className={styles.text}>{text}</div>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.content}>{this.props.children}</div>
                </div>
            </div>
        );
    }
}
