import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as allActionCreators from './actions';

import FixedHeader from 'component/FixedHeader';
import Wizard from 'component/Wizard';
import WizardActions from 'component/WizardActions';
import DescriptionText from './components';
import CheckBox from 'component/form/CheckBox';
import commonStyles from 'design/styles/common.module.css';
import customStyles from './AdsTerms.module.css';

/**
 * A terms and conditions page scene.
 */
class AdsTerms extends Component {
    constructor(props) {
        super(props);
        this.wizardConfig = {
            activeElementIndex: 0,
            elements: [
                {
                    caption: <FormattedMessage id="onboarding.wizard.terms" />,
                    isActive: true,
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.account_settings" />
                    ),
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.create_an_ad" />
                    ),
                },
            ],
            nextButton: {
                caption: <FormattedMessage id="wizard.next" />,
            },
        };
        this.checkboxConfig = {
            value: true,
            id: 'adsConsent',
            labelText: <FormattedMessage id="adsTerms.label" />,
            errorMessage: <FormattedMessage id="adsTerms.consent_error" />,
        };
    }

    handleNext = () => {
        const consent = this.props.adsSettingsConsent;

        if (consent && consent !== 'error') {
            this.props.history.push('/account-settings');
        } else {
            this.props.errorAdsConsent();
        }
    };

    createWizardActions = () => {
        return (
            <WizardActions
                next={this.wizardConfig.nextButton}
                nextAction={this.handleNext}
            />
        );
    };

    createWizard = () => {
        return (
            <Wizard
                elements={this.wizardConfig.elements}
                activeElementIndex={this.wizardConfig.activeElementIndex}
            />
        );
    };

    handleConsent = event => {
        if (event.target.checked) {
            this.props.addAdsConsent();
        } else {
            this.props.removeAdsConsent();
        }
    };

    render() {
        const wizard = this.createWizard();
        const consent = this.props.adsSettingsConsent;

        return (
            <React.Fragment>
                <FixedHeader right={this.createWizardActions}>
                    {wizard}
                </FixedHeader>
                <div className={commonStyles.sceneMainContent}>
                    <DescriptionText styles={commonStyles} />
                    <div className={customStyles['checkbox-container']}>
                        <CheckBox
                            id={this.checkboxConfig.id}
                            value={this.checkboxConfig.value}
                            onChange={this.handleConsent}
                            checked={
                                consent && consent !== 'error' ? 'checked' : ''
                            }
                            labelText={this.checkboxConfig.labelText}
                            showError={consent === 'error'}
                            errorMessage={this.checkboxConfig.errorMessage}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adsSettingsConsent: state.adsSettings.consent,
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(allActionCreators, dispatch),
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdsTerms);
