import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { classnames } from '../../../util';

import styles from './MultiCheck.module.css';

export default class MultiCheck extends Component {
    state = {
        isOpened: false,
    };

    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                action: PropTypes.func,
            }),
        ).isRequired,
        selectedPart: PropTypes.bool,
        selectedAll: PropTypes.bool,
    };

    clickHandler = () => {
        this.setState(prevState => ({
            isOpened: !prevState.isOpened,
        }));
    };

    render() {
        const { items, selectedPart, selectedAll } = this.props;

        const labelClasses = classnames([
            styles['multicheck-label'],
            selectedPart && styles['multicheck-label_part'],
            selectedAll && styles['multicheck-label_all'],
        ]);

        const buttonClasses = classnames([
            styles['multicheck-button'],
            this.state.isOpened && styles['multicheck-button_reversed'],
        ]);

        const listClasses = classnames([
            styles['multicheck-list'],
            this.state.isOpened && styles['multicheck-list_opened'],
        ]);

        return (
            <div className={styles['multicheck']} onClick={this.clickHandler}>
                <label className={labelClasses} />
                <button className={buttonClasses} />

                <ul className={listClasses}>
                    {items.map(item => (
                        <li
                            key={item.title}
                            className={styles['multicheck-item']}
                            onClick={item.action}
                        >
                            {item.title}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
