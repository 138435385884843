import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import * as allActionCreators from './actions';

import FixedHeader from 'component/FixedHeader';
import Wizard from 'component/Wizard';
import WizardActions from 'component/WizardActions';
import CheckBox from 'component/form/CheckBox';
import styles from './Terms.module.css';

/**
 * A terms and conditions page scene.
 */
class Terms extends Component {
    constructor(props) {
        super(props);
        this.checkboxConfig = {
            value: true,
            id: 'consent',
            labelText: <FormattedMessage id="terms.label" />,
            errorMessage: <FormattedMessage id="terms.consent_error" />,
        };
    }
    //handles the click on the consent checkbox and modifies the global state in an appropriate way
    handleConsent = event => {
        if (event.target.checked) {
            this.props.addConsent();
        } else {
            this.props.removeConsent();
        }
    };

    //handles the click on the next arrow and allows it only if user has accepted the consent
    handleNext = () => {
        const consent = this.props.merchantCenterConsent;
        if (consent && consent !== 'error') {
            this.props.history.push('/url-verification');
        } else {
            this.props.errorConsent();
        }
    };

    render() {
        const consent = this.props.merchantCenterConsent;

        return (
            <div>
                <FixedHeader
                    right={() => (
                        <WizardActions
                            next={{
                                caption: <FormattedMessage id="wizard.next" />,
                            }}
                            nextAction={this.handleNext}
                        />
                    )}
                >
                    <Wizard
                        elements={[
                            {
                                caption: (
                                    <FormattedMessage id="onboarding.wizard.terms" />
                                ),
                                isActive: true,
                            },
                            {
                                caption: (
                                    <FormattedMessage id="onboarding.wizard.url_verification" />
                                ),
                            },
                            {
                                caption: (
                                    <FormattedMessage id="onboarding.wizard.shipping_and_tax" />
                                ),
                            },
                            {
                                caption: (
                                    <FormattedMessage id="onboarding.wizard.attribute_mapping" />
                                ),
                            },
                        ]}
                        activeElementIndex={0}
                    />
                </FixedHeader>
                {/*Here goes the content of the scene*/}
                <div className={styles.leftMargin}>
                    <div className={styles.header1}>
                        <FormattedMessage id="terms.heading_1" />
                    </div>
                    <div className={styles.text1}>
                        <FormattedMessage id="terms.text_1" />
                    </div>
                    <div className={styles.link}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://support.google.com/merchants/answer/160173?hl=en"
                        >
                            <FormattedMessage id="terms.link_1" />
                        </a>
                    </div>
                    <div className={styles.header2}>
                        <FormattedMessage id="terms.heading_2" />
                    </div>
                    <div className={styles.text2}>
                        <FormattedMessage id="terms.text_2" />
                    </div>
                    <div className={styles.link}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://support.google.com/merchants/answer/6149970?hl=en"
                        >
                            <FormattedMessage id="terms.link_2" />
                        </a>
                    </div>
                    <div className={styles.consent}>
                        <CheckBox
                            id={this.checkboxConfig.id}
                            value={this.checkboxConfig.value}
                            onChange={this.handleConsent}
                            checked={
                                consent && consent !== 'error' ? 'checked' : ''
                            }
                            labelText={this.checkboxConfig.labelText}
                            showError={consent === 'error'}
                            errorMessage={this.checkboxConfig.errorMessage}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

//mapping consent state to component properties
const mapStateToProps = state => ({
    merchantCenterConsent: state.websiteConfig.new.consent,
});

//mapping actions that could be made on consent
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(allActionCreators, dispatch),
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Terms);
