import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { Action, Reducer } from 'redux';

import {
    CONSENT_ADD,
    CONSENT_ERROR,
    CONSENT_REMOVE,
    EMAIL_SET,
    MAGENTO_KEY_SET,
    REMOVE_SHIPPING_COST_SET_ON_GMC,
    REMOVE_SHIPPING_TAX_VAT_INCLUDED,
    RESET_CHANNEL_DATA,
    RESET_CONFIG_STATE,
    RESET_STORE_VIEW_LANGUAGE_IN_STORE,
    RESET_TABLE_DATA_IN_STORE,
    SAVE_COUNTRY_DATA_TO_STORE,
    SAVE_FLAT_RATE_TABLE_DATA,
    SAVE_OPERATE_STATES,
    SAVE_SHIPPING_COST_SET_ON_GMC,
    SAVE_SHIPPING_FLAT_RATE_COST,
    SAVE_SHIPPING_TAX_VAT_INCLUDED,
    SAVE_STORE_VIEW_LANGUAGE_TO_STORE,
    SAVE_STORE_VIEW_NAME_CODE,
    SAVE_TABLE_DATA_TO_STORE,
    SAVE_URL_VERIFY_FIELD_VALUE,
    SAVE_WEBSITE_ID,
    SAVE_WEBSITE_STORES_TO_STORE,
    SET_SHIPPING_COST_ACTIVE_RADIO,
    SET_SHIPPING_TAX_ACTIVE_RADIO,
    SET_STORE_VIEW_REQUIRED_ERROR,
    SET_STORE_VIEWS_TABLE_REQUIRED,
    SET_URL_VERIFY_FIELD_ERROR,
    TOGGLE_EDIT_MODE,
    UNSET_STORE_VIEW_REQUIRED_ERROR,
    UNSET_STORE_VIEWS_TABLE_REQUIRED,
    UNSET_URL_VERIFY_FIELD_ERROR,
} from '../store/actionConsts';

export interface ITableData
    extends ReadonlyArray<{
        code?: Readonly<{
            label: string;
            value: string;
        }>;
        country?: Readonly<{
            label: string;
            value: string;
        }>;
        language?: Readonly<{
            label: string;
            value: string;
        }>;
        languages?: ReadonlyArray<string>;
        uniqueRowId: string;
    }> {}

export interface IFlatRateTableData
    extends ReadonlyArray<{
        countryCode: string;
        countryName: string;
        currencyCode: string;
        uniqueRowId: string;
        rate: string;
    }> {}

interface IConfig
    extends Readonly<{
        consent: 'error' | string | null;
        email: string | null;
        isEdit: boolean;
        yuiKey: string | null;
        shippingCost: Readonly<{
            activeRadioButtonId: string;
            radioButtons: Readonly<{
                flatRateCost: Readonly<{
                    tableData: IFlatRateTableData;
                }>;
                setCostOnGmc: Readonly<{
                    value: boolean;
                }>;
            }>;
        }>;
        shippingTax: Readonly<{
            activeRadioButtonId: string;
            radioButtons: Readonly<{
                autoTax: Readonly<{
                    isValid: boolean;
                    value: ReadonlyArray<string>;
                }>;
                vatIncluded: Readonly<{
                    value: boolean;
                }>;
            }>;
        }>;
        urlVerification: Readonly<{
            storeName: Readonly<{
                error: boolean;
                isValid: boolean;
                required: boolean;
                value: string;
            }>;
            websiteStoreViews: Readonly<{
                required: boolean;
                requiredError: boolean;
                tableData: ITableData;
                websiteStores: ReadonlyArray<string>;
            }>;
            websiteUrl: Readonly<{
                error: boolean;
                isValid: boolean;
                required: boolean;
                value: string;
            }>;
        }>;
        websiteId: string | null;
    }> {}

export interface IWebsiteConfig
    extends Readonly<{
        new: IConfig;
        [website: string]: IConfig;
    }> {}

export const initialState: IWebsiteConfig = {
    new: {
        consent: null,
        email: null,
        isEdit: false,
        yuiKey: null,
        shippingCost: {
            activeRadioButtonId: '',
            radioButtons: {
                flatRateCost: {
                    tableData: [],
                },
                setCostOnGmc: {
                    value: false,
                },
            },
        },
        shippingTax: {
            activeRadioButtonId: '',
            radioButtons: {
                autoTax: {
                    isValid: false,
                    value: [],
                },
                vatIncluded: {
                    value: false,
                },
            },
        },
        urlVerification: {
            storeName: {
                error: false,
                isValid: false,
                required: true,
                value: '',
            },
            websiteStoreViews: {
                required: false,
                requiredError: false,
                tableData: [],
                websiteStores: [],
            },
            websiteUrl: {
                error: false,
                isValid: false,
                required: true,
                value: '',
            },
        },
        websiteId: null,
    },
};

function websiteConfig(
    state: IWebsiteConfig = initialState,
    action:
        | IConsentAddAction
        | IConsentErrorAction
        | IConsentRemoveAction
        | IEmailSetAction
        | IYuiKeySetAction
        | IRemoveShippingCostSetOnGmc
        | IRemoveShippingTaxVatIncludedAction
        | IResetConfigStateAction
        | IResetStoreViewLanguageInStoreAction
        | IResetTableDataInStoreAction
        | ISaveCountryDataToStoreAction
        | ISaveFlatRateTableData
        | ISaveOperateStatesAction
        | ISaveShippingCostSetOnGmcAction
        | ISaveShippingFlatRateCostAction
        | ISaveShippingTaxVatIncludedAction
        | ISaveStoreViewLanguageToStoreAction
        | ISaveStoreViewNameCodeAction
        | ISaveTableDataToStoreAction
        | ISaveUrlVerifyFieldValueAction
        | ISaveWebsiteIdAction
        | ISaveWebsiteStoresToStoreAction
        | ISetShippingCostActiveRadioAction
        | ISetShippingTaxActiveRadioAction
        | ISetStoreViewRequiredErrorAction
        | ISetStoreViewsTableRequiredAction
        | ISetUrlVerifyFieldErrorAction
        | IUnsetStoreViewRequiredErrorAction
        | IUnsetStoreViewsTableRequiredAction
        | IUnsetUrlVerifyFieldErrorAction
        | IToggleEditModeAction
        | IResetChannelDataAction,
) {
    const website = action.website || 'new';
    switch (action.type) {
        case RESET_CONFIG_STATE: {
            const { yuiKey, email } = state[website];
            return update(initialState, {
                [website]: {
                    $merge: {
                        email,
                        yuiKey,
                    },
                },
            });
        }
        case RESET_CHANNEL_DATA: {
            return update(state, {
                [website]: {
                    isEdit: { $set: initialState[website].isEdit },
                    shippingCost: { $set: initialState[website].shippingCost },
                    shippingTax: { $set: initialState[website].shippingTax },
                    urlVerification: {
                        $set: initialState[website].urlVerification,
                    },
                },
            });
        }
        case MAGENTO_KEY_SET: {
            return update(state, {
                [website]: {
                    yuiKey: { $set: action.payload.yuiKey },
                },
            });
        }
        case EMAIL_SET: {
            return update(state, {
                [website]: {
                    email: { $set: action.payload.email },
                },
            });
        }
        case CONSENT_ADD: {
            return update(state, {
                [website]: {
                    consent: {
                        $set: new Date()
                            .toISOString()
                            .split('T')
                            .join(' ')
                            .split('.')[0],
                    },
                },
            });
        }
        case CONSENT_REMOVE: {
            return update(state, {
                [website]: {
                    consent: { $set: null },
                },
            });
        }
        case CONSENT_ERROR: {
            return update(state, {
                [website]: {
                    consent: { $set: 'error' },
                },
            });
        }
        case SAVE_URL_VERIFY_FIELD_VALUE: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        [action.fieldName]: {
                            value: { $set: action.value },
                        },
                    },
                },
            });
        }
        case SAVE_WEBSITE_ID: {
            return update(state, {
                [website]: {
                    websiteId: { $set: action.payload.id },
                },
            });
        }
        case SET_URL_VERIFY_FIELD_ERROR: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        [action.fieldName]: {
                            $merge: {
                                error: true,
                                isValid: false,
                            },
                        },
                    },
                },
            });
        }
        case UNSET_URL_VERIFY_FIELD_ERROR: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        [action.fieldName]: {
                            $merge: {
                                error: false,
                                isValid: true,
                            },
                        },
                    },
                },
            });
        }
        case SAVE_WEBSITE_STORES_TO_STORE: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            websiteStores: {
                                $set: action.payload.websiteStores,
                            },
                        },
                    },
                },
            });
        }
        case SAVE_TABLE_DATA_TO_STORE: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            tableData: { $set: action.payload.tableData },
                        },
                    },
                },
            });
        }
        case SAVE_STORE_VIEW_NAME_CODE: {
            const { uniqueRowId, value, label } = action.payload;
            const { tableData } = state[
                website
            ].urlVerification.websiteStoreViews;
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            tableData: {
                                [findIndex(tableData, { uniqueRowId })]: {
                                    code: {
                                        $merge: {
                                            label,
                                            value,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            });
        }
        case RESET_TABLE_DATA_IN_STORE: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            tableData: { $set: action.payload.tableData },
                        },
                    },
                },
            });
        }
        case SAVE_COUNTRY_DATA_TO_STORE: {
            const { value, label, languages, uniqueRowId } = action.payload;
            const { tableData } = state[
                website
            ].urlVerification.websiteStoreViews;
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            tableData: {
                                [findIndex(tableData, { uniqueRowId })]: {
                                    country: {
                                        $merge: {
                                            label,
                                            value,
                                        },
                                    },
                                    languages: { $set: languages },
                                },
                            },
                        },
                    },
                },
            });
        }
        case SAVE_FLAT_RATE_TABLE_DATA: {
            return update(state, {
                [website]: {
                    shippingCost: {
                        radioButtons: {
                            flatRateCost: {
                                tableData: { $set: action.payload },
                            },
                        },
                    },
                },
            });
        }
        case SAVE_STORE_VIEW_LANGUAGE_TO_STORE: {
            const { value, label, uniqueRowId } = action.payload;
            const { tableData } = state[
                website
            ].urlVerification.websiteStoreViews;
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            tableData: {
                                [findIndex(tableData, { uniqueRowId })]: {
                                    language: {
                                        $merge: {
                                            label,
                                            value,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            });
        }
        case RESET_STORE_VIEW_LANGUAGE_IN_STORE: {
            const { value, label, uniqueRowId } = action.payload;
            const { tableData } = state[
                website
            ].urlVerification.websiteStoreViews;
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            tableData: {
                                [findIndex(tableData, { uniqueRowId })]: {
                                    language: {
                                        $merge: {
                                            label,
                                            value,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            });
        }
        case SET_STORE_VIEW_REQUIRED_ERROR: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            requiredError: { $set: true },
                        },
                    },
                },
            });
        }
        case UNSET_STORE_VIEW_REQUIRED_ERROR: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            requiredError: { $set: false },
                        },
                    },
                },
            });
        }
        case SET_STORE_VIEWS_TABLE_REQUIRED: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            required: { $set: true },
                        },
                    },
                },
            });
        }
        case UNSET_STORE_VIEWS_TABLE_REQUIRED: {
            return update(state, {
                [website]: {
                    urlVerification: {
                        websiteStoreViews: {
                            required: { $set: false },
                        },
                    },
                },
            });
        }
        case SET_SHIPPING_COST_ACTIVE_RADIO: {
            return update(state, {
                [website]: {
                    shippingCost: {
                        activeRadioButtonId: { $set: action.payload.radioId },
                    },
                },
            });
        }
        case SAVE_SHIPPING_FLAT_RATE_COST: {
            const { id, rate } = action.payload;
            const { tableData } = state[
                website
            ].shippingCost.radioButtons.flatRateCost;
            return update(state, {
                [website]: {
                    shippingCost: {
                        radioButtons: {
                            flatRateCost: {
                                tableData: {
                                    [findIndex(tableData, {
                                        uniqueRowId: id,
                                    })]: {
                                        rate: { $set: rate },
                                    },
                                },
                            },
                        },
                    },
                },
            });
        }
        case SAVE_SHIPPING_COST_SET_ON_GMC: {
            return update(state, {
                [website]: {
                    shippingCost: {
                        radioButtons: {
                            setCostOnGmc: {
                                value: { $set: action.payload.value },
                            },
                        },
                    },
                },
            });
        }
        case REMOVE_SHIPPING_COST_SET_ON_GMC: {
            return update(state, {
                [website]: {
                    shippingCost: {
                        radioButtons: {
                            setCostOnGmc: {
                                value: { $set: action.payload.value },
                            },
                        },
                    },
                },
            });
        }
        case SET_SHIPPING_TAX_ACTIVE_RADIO: {
            return update(state, {
                [website]: {
                    shippingTax: {
                        activeRadioButtonId: { $set: action.payload.radioId },
                    },
                },
            });
        }
        case SAVE_OPERATE_STATES: {
            return update(state, {
                [website]: {
                    shippingTax: {
                        radioButtons: {
                            autoTax: {
                                $merge: {
                                    isValid: action.payload.isValid,
                                    value: action.payload.value,
                                },
                            },
                        },
                    },
                },
            });
        }
        case SAVE_SHIPPING_TAX_VAT_INCLUDED: {
            return update(state, {
                [website]: {
                    shippingTax: {
                        radioButtons: {
                            vatIncluded: {
                                value: { $set: action.payload.value },
                            },
                        },
                    },
                },
            });
        }
        case REMOVE_SHIPPING_TAX_VAT_INCLUDED: {
            return update(state, {
                [website]: {
                    shippingTax: {
                        radioButtons: {
                            vatIncluded: {
                                value: { $set: action.payload.value },
                            },
                        },
                    },
                },
            });
        }
        case TOGGLE_EDIT_MODE: {
            return update(state, {
                [website]: {
                    isEdit: { $set: action.value },
                },
            });
        }
        default:
            return state;
    }
}

export default websiteConfig as Reducer;

export interface IConsentAddAction extends IWebsiteConfigAction {
    type: typeof CONSENT_ADD;
}

export interface IConsentRemoveAction extends IWebsiteConfigAction {
    type: typeof CONSENT_REMOVE;
}

export interface IConsentErrorAction extends IWebsiteConfigAction {
    type: typeof CONSENT_ERROR;
}

export interface IEmailSetAction extends IWebsiteConfigAction {
    payload: {
        email: string;
    };
    type: typeof EMAIL_SET;
}

export interface IYuiKeySetAction extends IWebsiteConfigAction {
    payload: {
        yuiKey: string;
    };
    type: typeof MAGENTO_KEY_SET;
}

export interface IRemoveShippingCostSetOnGmc extends IWebsiteConfigAction {
    payload: {
        value: boolean;
    };
    type: typeof REMOVE_SHIPPING_COST_SET_ON_GMC;
}

export interface IRemoveShippingTaxVatIncludedAction
    extends IWebsiteConfigAction {
    payload: {
        value: boolean;
    };
    type: typeof REMOVE_SHIPPING_TAX_VAT_INCLUDED;
}

export interface IResetConfigStateAction extends IWebsiteConfigAction {
    type: typeof RESET_CONFIG_STATE;
}

export interface IResetStoreViewLanguageInStoreAction
    extends IWebsiteConfigAction {
    payload: {
        label: string;
        uniqueRowId: string;
        value: string;
    };
    type: typeof RESET_STORE_VIEW_LANGUAGE_IN_STORE;
}

export interface IResetTableDataInStoreAction extends IWebsiteConfigAction {
    payload: {
        tableData: Array<{
            language: {
                label: string;
                value: string;
            };
            uniqueRowId: string;
        }>;
    };
    type: typeof RESET_TABLE_DATA_IN_STORE;
}

export interface ISaveCountryDataToStoreAction extends IWebsiteConfigAction {
    payload: {
        label: string;
        languages: ReadonlyArray<string>;
        uniqueRowId: string;
        value: string;
    };
    type: typeof SAVE_COUNTRY_DATA_TO_STORE;
}

export interface ISaveFlatRateTableData extends IWebsiteConfigAction {
    payload: IFlatRateTableData;
    type: typeof SAVE_FLAT_RATE_TABLE_DATA;
}

export interface ISaveOperateStatesAction extends IWebsiteConfigAction {
    payload: {
        isValid: boolean;
        value: ReadonlyArray<string>;
    };
    type: typeof SAVE_OPERATE_STATES;
}

export interface ISaveShippingCostSetOnGmcAction extends IWebsiteConfigAction {
    payload: {
        value: boolean;
    };
    type: typeof SAVE_SHIPPING_COST_SET_ON_GMC;
}

export interface ISaveShippingFlatRateCostAction extends IWebsiteConfigAction {
    payload: {
        id: string;
        rate: string;
    };
    type: typeof SAVE_SHIPPING_FLAT_RATE_COST;
}

export interface ISaveShippingTaxVatIncludedAction
    extends IWebsiteConfigAction {
    payload: {
        value: boolean;
    };
    type: typeof SAVE_SHIPPING_TAX_VAT_INCLUDED;
}

export interface ISaveStoreViewLanguageToStoreAction
    extends IWebsiteConfigAction {
    payload: {
        label: string;
        uniqueRowId: string;
        value: string;
    };
    type: typeof SAVE_STORE_VIEW_LANGUAGE_TO_STORE;
}

export interface ISaveStoreViewNameCodeAction extends IWebsiteConfigAction {
    payload: {
        label: string;
        uniqueRowId: string;
        value: string;
    };
    type: typeof SAVE_STORE_VIEW_NAME_CODE;
}

export interface ISaveTableDataToStoreAction extends IWebsiteConfigAction {
    payload: {
        tableData: ITableData;
    };
    type: typeof SAVE_TABLE_DATA_TO_STORE;
}

export interface ISaveUrlVerifyFieldValueAction extends IWebsiteConfigAction {
    fieldName: string;
    type: typeof SAVE_URL_VERIFY_FIELD_VALUE;
    value: string;
}

export interface ISaveWebsiteIdAction extends IWebsiteConfigAction {
    payload: {
        id: string;
    };
    type: typeof SAVE_WEBSITE_ID;
}

export interface ISaveWebsiteStoresToStoreAction extends IWebsiteConfigAction {
    payload: {
        websiteStores: ReadonlyArray<string>;
    };
    type: typeof SAVE_WEBSITE_STORES_TO_STORE;
}

export interface ISetShippingCostActiveRadioAction
    extends IWebsiteConfigAction {
    payload: {
        radioId: string;
    };
    type: typeof SET_SHIPPING_COST_ACTIVE_RADIO;
}

export interface ISetShippingTaxActiveRadioAction extends IWebsiteConfigAction {
    payload: {
        radioId: string;
    };
    type: typeof SET_SHIPPING_TAX_ACTIVE_RADIO;
}

export interface ISetStoreViewRequiredErrorAction extends IWebsiteConfigAction {
    type: typeof SET_STORE_VIEW_REQUIRED_ERROR;
}

export interface ISetStoreViewsTableRequiredAction
    extends IWebsiteConfigAction {
    type: typeof SET_STORE_VIEWS_TABLE_REQUIRED;
}

export interface ISetUrlVerifyFieldErrorAction extends IWebsiteConfigAction {
    fieldName: string;
    type: typeof SET_URL_VERIFY_FIELD_ERROR;
}

export interface IUnsetStoreViewRequiredErrorAction
    extends IWebsiteConfigAction {
    type: typeof UNSET_STORE_VIEW_REQUIRED_ERROR;
}

export interface IUnsetStoreViewsTableRequiredAction
    extends IWebsiteConfigAction {
    type: typeof UNSET_STORE_VIEWS_TABLE_REQUIRED;
}

export interface IUnsetUrlVerifyFieldErrorAction extends IWebsiteConfigAction {
    fieldName: string;
    type: typeof UNSET_URL_VERIFY_FIELD_ERROR;
}

export interface IToggleEditModeAction extends IWebsiteConfigAction {
    value: boolean;
    type: typeof TOGGLE_EDIT_MODE;
}

export interface IResetChannelDataAction extends IWebsiteConfigAction {
    type: typeof RESET_CHANNEL_DATA;
}

interface IWebsiteConfigAction extends Action {
    website?: string;
}
