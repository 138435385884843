import { ActionCreator } from 'redux';

import { ISetCountryToAdvertiseAction } from '../../../../reducers/adsCreationReducer';
import { SET_COUNTRY_TO_ADVERTISE } from '../../../../store/actionConsts';

export const saveAdvertiseCountryToStore: ActionCreator<
    ISetCountryToAdvertiseAction
> = (country: string) => ({
    payload: {
        country,
    },
    type: SET_COUNTRY_TO_ADVERTISE,
});
