import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { classnames, createYuiDocsLink } from '../../util';

import './Tooltip.css';

export interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    direction: 'left' | 'right';
    link: string;
}

interface TooltipState {
    opened: boolean;
}

export default class Tooltip extends Component<TooltipProps, TooltipState> {
    public state: TooltipState = {
        opened: false,
    };

    public render() {
        const { opened } = this.state;
        const { link, direction, className } = this.props;
        const url = createYuiDocsLink(link);
        const containerStyles = classnames([
            'sc-tooltip-container',
            opened ? 'sc-tooltip-active' : '',
            `sc-tooltip-container-${direction}`,
        ]);
        const tooltipStyles = classnames([
            className || 'cs-tooltip-default-margin',
            'sc-tooltip',
        ]);

        return (
            <div
                className={tooltipStyles}
                onClick={this.toggleToolbar}
                onMouseOut={this.handleMouseOut}
            >
                <div className={containerStyles}>
                    <div className="sc-tooltip-container-inner">
                        {this.renderChildren()}
                        {link && (
                            <a
                                className="sc-tooltip-link"
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FormattedMessage id="help.linkText" />
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private toggleToolbar = () => this.setState({ opened: !this.state.opened });
    private handleMouseOut = () => this.setState({ opened: false });

    private renderChildren() {
        return React.Children.map(this.props.children, child => <p>{child}</p>);
    }
}
