import React, { Component } from 'react';
import styles from './Wizard.module.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import shortid from 'shortid';

class Wizard extends Component {
    render() {
        const activeElementIndex = this.props.activeElementIndex;
        let propElements = Object.assign([], this.props.elements);
        //building elements array
        const elements = propElements.map((element, index) => {
            if (!element.id) {
                element.id = shortid.generate();
            }

            //calculating li tag class name
            const liClassName =
                styles.wizLi +
                (index && !(index > activeElementIndex)
                    ? ' ' + styles.wizLiPast
                    : '');
            //calculating a tag class name
            const aClassName =
                styles.wizA +
                (activeElementIndex === index ? ' ' + styles.wizAActive : '') +
                (index > activeElementIndex ? ' ' + styles.wizAInactive : '');

            const link = element.link ? (
                <Link className={aClassName} to={element.link}>
                    {element.caption}
                </Link>
            ) : (
                <span className={aClassName}>{element.caption}</span>
            );
            //building element markup
            return (
                <li className={liClassName} key={element.id}>
                    {link}
                </li>
            );
        });
        return (
            //rendering all elements
            <ul className={styles.wizUl}>{elements}</ul>
        );
    }
}

//We are defining wizard element shape and that elements prop is required
const elementShape = PropTypes.shape({
    caption: PropTypes.any.isRequired,
    link: PropTypes.string,
    id: PropTypes.string,
});
Wizard.propTypes = {
    elements: PropTypes.arrayOf(elementShape).isRequired,
    activeElementIndex: PropTypes.number.isRequired,
};

export default Wizard;
