import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import { classnames } from '../../../../util';

import { FormattedMessage } from 'react-intl';
import { SelectAll, SelectUsingFilters } from './components';
import Tooltip from 'component/Tooltip';

import commonStyles from 'design/styles/common.module.css';
import styles from './ProductsFilters.module.css';

export class ProductsFilters extends Component {
    static propTypes = {
        isEditMode: PropTypes.bool.isRequired,
        productFilterError: PropTypes.bool.isRequired,
        unsetFieldError: PropTypes.func.isRequired,
        saveFiltersActiveRadioToStore: PropTypes.func.isRequired,
    };

    renderLabel(textId) {
        return (
            <span className={styles['font-semibold']}>
                <FormattedMessage id={textId} />
            </span>
        );
    }

    setActiveRadioButtonId = id => {
        this.props.saveFiltersActiveRadioToStore(id);
        id === 'selectAll' && this.props.unsetFieldError('productFilter');
    };

    render() {
        const headerStyles = classnames([
            commonStyles.header3,
            commonStyles['font-semibold'],
            styles.header3,
        ]);
        const {
            isEditMode,
            adsCreation,
            saveSelectAllOptionToStore,
            saveBrandsFilterToStore,
            saveCategoriesFilterToStore,
            productFilterError,
            unsetFieldError,
        } = this.props;

        return (
            <React.Fragment>
                <h3 className={headerStyles}>
                    <FormattedMessage id="smartShoppingCampaign.header_2" />
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/create-campaign.html"
                    >
                        <FormattedMessage id="help.attributeMapping.adForm.selectProducts" />
                    </Tooltip>
                </h3>

                <div className={styles['option-block']}>
                    <SelectAll
                        isEditMode={isEditMode}
                        radioButtonsConfig={{
                            id: 'selectAll',
                            label: this.renderLabel(
                                'smartShoppingCampaign.filters.radio_1',
                            ),
                        }}
                        setActiveRadioButtonId={this.setActiveRadioButtonId}
                        {...{
                            adsCreation,
                            saveSelectAllOptionToStore,
                        }}
                    />
                </div>

                <div className={styles['option-block']}>
                    <SelectUsingFilters
                        unsetFieldError={unsetFieldError}
                        isEditMode={isEditMode}
                        showError={productFilterError}
                        radioButtonsConfig={{
                            id: 'selectUsingFilters',
                            label: this.renderLabel(
                                'smartShoppingCampaign.filters.radio_2',
                            ),
                        }}
                        setActiveRadioButtonId={this.setActiveRadioButtonId}
                        {...{
                            adsCreation,
                            saveBrandsFilterToStore,
                            saveCategoriesFilterToStore,
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adsCreation: state.adsCreation,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(ProductsFilters);
