import { ActionCreator } from 'redux';

import {
    IResetAttributesStateAction,
    ISaveAttributeValueAction,
    ISavePredefinedAttributeValueAction,
    ISetAttributeAsRequiredAction,
    ISetAttributeErrorAction,
    ISetSpecificAttributeErrorAction,
    ISetSpecificAttributeTableErrorAction,
    IToggleMappingEditModeAction,
    IUnsetAttributeAsRequiredAction,
    IUnsetAttributeErrorAction,
    IUnsetSpecificAttributeErrorAction,
    IUnsetSpecificAttributeTableErrorAction,
} from '../../reducers/attributeMappingReducer';
import {
    RESET_ATTRIBUTES_STATE,
    SAVE_ATTRIBUTE_VALUE,
    SAVE_PREDEFINED_ATTRIBUTE_VALUE,
    SET_ATTRIBUTE_AS_REQUIRED,
    SET_ATTRIBUTE_ERROR,
    SET_SPECIFIC_ATTRIBUTE_ERROR,
    SET_SPECIFIC_ATTRIBUTE_TABLE_ERROR,
    TOGGLE_MAPPING_EDIT_MODE,
    UNSET_ATTRIBUTE_AS_REQUIRED,
    UNSET_ATTRIBUTE_ERROR,
    UNSET_SPECIFIC_ATTRIBUTE_ERROR,
    UNSET_SPECIFIC_ATTRIBUTE_TABLE_ERROR,
} from '../../store/actionConsts';

export const resetAttributesState: ActionCreator<
    IResetAttributesStateAction
> = () => ({
    type: RESET_ATTRIBUTES_STATE,
});

export const toggleMappingEditMode: ActionCreator<
    IToggleMappingEditModeAction
> = (value: boolean) => ({
    type: TOGGLE_MAPPING_EDIT_MODE,
    value,
});

export const saveAttributeToStore: ActionCreator<ISaveAttributeValueAction> = (
    attributeName: string,
    payload: string,
) => ({
    attributeName,
    payload,
    type: SAVE_ATTRIBUTE_VALUE,
});

export const setAttributeAsRequired: ActionCreator<
    ISetAttributeAsRequiredAction
> = (attributeName: string) => ({
    attributeName,
    type: SET_ATTRIBUTE_AS_REQUIRED,
});

export const unsetAttributeAsRequired: ActionCreator<
    IUnsetAttributeAsRequiredAction
> = (attributeName: string) => ({
    attributeName,
    type: UNSET_ATTRIBUTE_AS_REQUIRED,
});

export const validateAttribute: ActionCreator<
    IUnsetAttributeErrorAction | ISetAttributeErrorAction
> = (selectedOption: Array<{ value: string }>, attributeName: string) =>
    selectedOption.length
        ? {
              attributeName,
              type: UNSET_ATTRIBUTE_ERROR,
          }
        : {
              attributeName,
              type: SET_ATTRIBUTE_ERROR,
          };

export const validateSpecificYuiAttribute: ActionCreator<
    ISetSpecificAttributeErrorAction | IUnsetSpecificAttributeErrorAction
> = (attributeName: string, selectedOption: Array<{ value: string }>) =>
    selectedOption.length
        ? {
              attributeName,
              type: UNSET_SPECIFIC_ATTRIBUTE_ERROR,
          }
        : {
              attributeName,
              type: SET_SPECIFIC_ATTRIBUTE_ERROR,
          };

export const setSpecificMappingTableError: ActionCreator<
    ISetSpecificAttributeTableErrorAction
> = (attributeName: string) => ({
    attributeName,
    type: SET_SPECIFIC_ATTRIBUTE_TABLE_ERROR,
});

export const unsetSpecificMappingTableError: ActionCreator<
    IUnsetSpecificAttributeTableErrorAction
> = (attributeName: string) => ({
    attributeName,
    type: UNSET_SPECIFIC_ATTRIBUTE_TABLE_ERROR,
});

export const savePredefinedAttributeValue: ActionCreator<
    ISavePredefinedAttributeValueAction
> = (
    attributeName: string,
    payload: Array<{
        predefined: boolean;
        value: string;
    }>,
) => ({
    attributeName,
    payload,
    type: SAVE_PREDEFINED_ATTRIBUTE_VALUE,
});
