import { ActionCreator } from 'redux';

import { ISaveCampaignNamesToStore } from '../../../../reducers/adsCreationReducer';

import { SAVE_CAMPAIGN_NAMES_TO_STORE } from '../../../../store/actionConsts';

export const saveAllCampaignNamesToStore: ActionCreator<
    ISaveCampaignNamesToStore
> = (payload: { allCampaignNames: string[] }) => ({
    payload,
    type: SAVE_CAMPAIGN_NAMES_TO_STORE,
});
