import selectStyles from 'component/form/Select/ConfigurableSelect.styles';

const customSelectStyles = { ...selectStyles };

customSelectStyles.control = base => ({
    ...selectStyles.control(base),
    width: 300,
});

export default customSelectStyles;
