import update from 'immutability-helper';
import { Action, Reducer } from 'redux';

import { CLEAR_EDIT_TYPE, SET_EDIT_TYPE } from '../store/actionConsts';

export interface ISettings
    extends Readonly<{
        editType: '' | 'url' | 'shipping' | 'mapping';
    }> {}

export const initialState: ISettings = {
    editType: '',
};

function settingsReducer(
    state: ISettings = initialState,
    action: ISetEditType | IClearEditType,
) {
    switch (action.type) {
        case SET_EDIT_TYPE: {
            return update(state, {
                editType: { $set: action.payload.editType },
            });
        }
        case CLEAR_EDIT_TYPE: {
            return update(state, {
                editType: { $set: '' },
            });
        }
        default: {
            return state;
        }
    }
}

export default settingsReducer as Reducer;

export interface ISetEditType extends Action {
    payload: {
        editType: '' | 'url' | 'shipping' | 'mapping';
    };
    type: typeof SET_EDIT_TYPE;
}

export interface IClearEditType extends Action {
    type: typeof CLEAR_EDIT_TYPE;
}
