import { ActionCreator } from 'redux';

import {
    IResetStoreViewLanguageInStoreAction,
    ISaveCountryDataToStoreAction,
} from '../../../../../../../../reducers/websiteConfigReducer';
import {
    RESET_STORE_VIEW_LANGUAGE_IN_STORE,
    SAVE_COUNTRY_DATA_TO_STORE,
} from '../../../../../../../../store/actionConsts';

export const saveCountryDataToStore: ActionCreator<
    ISaveCountryDataToStoreAction
> = (payload: {
    label: string;
    languages: string[];
    uniqueRowId: string;
    value: string;
}) => ({
    payload,
    type: SAVE_COUNTRY_DATA_TO_STORE,
});

export const resetStoreViewLanguageInStore: ActionCreator<
    IResetStoreViewLanguageInStoreAction
> = (payload: { label: string; uniqueRowId: string; value: string }) => ({
    payload,
    type: RESET_STORE_VIEW_LANGUAGE_IN_STORE,
});
