import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, has } from 'lodash';

import { FormattedMessage } from 'react-intl';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import {
    AccountErrors,
    ProductDisapprovals,
    ProductErrorResolver,
    NavControls,
    AttributeCreationErrorNotify,
} from './components';
import Loader from 'component/Loader';
import Notification from 'component/notification';
import { Sidebar } from 'component/sidebar';
import FixedHeader from 'component/FixedHeader';

import { yuiRequest } from 'api';

import { classnames } from '../../util';
import { toLocalTime } from 'helpers/toLocalTime';
import { redirectToYuiLogin } from 'helpers/redirectToYuiLogin';

import tabStyles from 'design/styles/react-tabs.module.css';
import styles from './GMCDetails.module.css';

export class GMCDetails extends Component {
    static propTypes = {
        adsCreation: PropTypes.object.isRequired,
    };

    state = {
        prodErrData: [],
        accErrData: [],
        totalProducts: 0,
        approvedProducts: 0,
        disapprovedProducts: 0,
        prodRefreshTime: '—',
        accRefreshTime: '—',
        loading: false,
        selectedErrorData: {},
        isSidebarOpened: false,
        isLoaderActive: false,
        isErrorNotificationActive: false,
        isNewAttributeCreationErrorActive: false,
        isAccountErrorNotificationActive: false,
        isProductPendingNotificationActive: false,
    };

    componentDidMount = () => {
        this.fetchData();
    };

    fetchData = async () => {
        const { channelId } = this.props.adsCreation;
        localStorage.getItem('isNewAttributeCreationError') &&
            this.setState({ isNewAttributeCreationErrorActive: true });

        if (!channelId) {
            return;
        }

        this.setState({
            loading: true,
            isLoaderActive: true,
        });

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: `channels/${channelId}/status`,
            });

            const data = JSON.parse(response.body);

            this.setState({
                ...(data
                    ? {
                          accErrData: data.accountValidationsData || [],
                          prodErrData: data.productValidationsData || [],
                          totalProducts: data.totalProducts || 0,
                          approvedProducts: data.totalApprovedProducts || 0,
                          disapprovedProducts:
                              data.totalDisaprovedProducts || 0,
                          prodRefreshTime:
                              toLocalTime(data.productsUpdatedAt) || '—',
                          accRefreshTime:
                              toLocalTime(data.accountUpdatedAt) || '—',
                          isAccountErrorNotificationActive: !!find(
                              data.accountValidationsData,
                              { severity: 'critical' },
                          ),
                          isProductPendingNotificationActive: !!(
                              data.totalProducts &&
                              !data.totalApprovedProducts &&
                              !data.totalDisaprovedProducts
                          ),
                      }
                    : {}),
                loading: false,
                isLoaderActive: false,
            });
        } catch (error) {
            const isSessionExpired = has(error, 'ajaxExpired');

            isSessionExpired
                ? redirectToYuiLogin()
                : this.setState({
                      isErrorNotificationActive: true,
                      loading: false,
                      isLoaderActive: false,
                  });
        }
    };

    componentWillUnmount() {
        localStorage.removeItem('isNewAttributeCreationError');
    }

    resolveActionClick = data => {
        this.setState({
            selectedErrorData: data,
            isSidebarOpened: true,
        });
    };

    handleCloseSidebar = () => {
        this.setState({ isSidebarOpened: false });
        this.fetchData();
    };

    handleDisabledClick = e => e.preventDefault();
    handleEnabledClick = e => e.stopPropagation();

    renderNavControls = () => {
        const isCreateAdButtonDisabled = !this.props.adsCreation.channelId;

        return <NavControls {...{ isCreateAdButtonDisabled }} />;
    };

    render() {
        const {
            prodErrData,
            accErrData,
            totalProducts,
            approvedProducts,
            disapprovedProducts,
            prodRefreshTime,
            accRefreshTime,
            loading,
            selectedErrorData,
            isSidebarOpened,
            isLoaderActive,
            isErrorNotificationActive,
            isNewAttributeCreationErrorActive,
            isAccountErrorNotificationActive,
            isProductPendingNotificationActive,
        } = this.state;

        const { channelId } = this.props.adsCreation;

        if (!channelId) {
            return <Redirect to="/" />;
        }

        const tabListClasses = classnames([
            tabStyles['react-tabs__tab-list'],
            styles['tab-list'],
        ]);
        const tabClasses = classnames([
            tabStyles['react-tabs__tab'],
            tabStyles['react-tabs__tab--big'],
        ]);
        const selectedTabClasses = classnames([
            tabStyles['react-tabs--selected'],
            tabStyles['react-tabs--selected-big'],
        ]);

        return (
            <React.Fragment>
                <Loader isVisible={isLoaderActive} />
                <FixedHeader right={this.renderNavControls} />
                <Notification
                    type="error"
                    defaultMessageType="requestError"
                    isActive={isErrorNotificationActive}
                />
                <AttributeCreationErrorNotify
                    isActive={isNewAttributeCreationErrorActive}
                />
                <Notification
                    type="warning"
                    isActive={isAccountErrorNotificationActive}
                >
                    <FormattedMessage id="gmcDetails.notification.accountErrors" />
                </Notification>
                <Notification
                    type="warning"
                    isActive={isProductPendingNotificationActive}
                >
                    <FormattedMessage
                        id="gmcDetails.notification.productErrors.text"
                        values={{
                            link: (
                                <a
                                    href="https://merchants.google.com"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <FormattedMessage id="gmcDetails.notification.productErrors.link" />
                                </a>
                            ),
                        }}
                    />
                </Notification>
                <Tabs selectedTabClassName={selectedTabClasses}>
                    <TabList className={tabListClasses}>
                        <Tab className={tabClasses}>
                            <FormattedMessage id="gmcDetails.tabs.disapprovedProducts" />
                            {prodErrData.length > 0 && (
                                <span className={styles['error-icon']} />
                            )}
                        </Tab>
                        <Tab className={tabClasses}>
                            <FormattedMessage id="gmcDetails.tabs.accountErrors" />
                            {accErrData.length > 0 && (
                                <span className={styles['error-icon']} />
                            )}
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <ProductDisapprovals
                            data={prodErrData}
                            loading={loading}
                            totalProducts={totalProducts}
                            approvedProducts={approvedProducts}
                            disapprovedProducts={disapprovedProducts}
                            lastRefresh={prodRefreshTime}
                            actionClickHandler={this.resolveActionClick}
                        />
                    </TabPanel>
                    <TabPanel>
                        <AccountErrors
                            data={accErrData}
                            loading={loading}
                            lastRefresh={accRefreshTime}
                        />
                    </TabPanel>
                </Tabs>
                <Sidebar
                    isActive={isSidebarOpened}
                    position="right"
                    onOverlayClick={this.handleCloseSidebar}
                >
                    <ProductErrorResolver
                        handleCloseSidebar={this.handleCloseSidebar}
                        errorData={selectedErrorData}
                        refreshTime={prodRefreshTime}
                        channelId={channelId}
                    />
                </Sidebar>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adsCreation: state.adsCreation,
});

export default connect(mapStateToProps)(GMCDetails);
