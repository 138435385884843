import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as AllActionCreators from './actions';
import PropTypes from 'prop-types';
import { yuiRequest } from 'api';
import { attempt, isEmpty, get, has, reduce } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { redirectToYuiLogin } from 'helpers/redirectToYuiLogin';
import { version } from 'config/version';
import Urls from 'config/urls.json';

import { Sidebar } from 'component/sidebar';
import Loader from 'component/Loader';
import Notification from 'component/notification';

import {
    DashboardLandingActions,
    AdCampaignTable,
    GMCAccountsTable,
    EditAdCampaign,
    StatusBar,
    OverviewModal,
    PaymentNotification,
    IndexerInvalidationNotification,
    ApiKeyInstructionModal,
    UpdateModal,
    WhatsNewModal,
} from './components';

import styles from './DashboardLanding.module.css';

const RELEASE_NOTES_MODAL_24_HOUR_GAP = 86400000;

export class DashboardLanding extends Component {
    static propTypes = {
        adsCreation: PropTypes.object.isRequired,
        yuiKey: PropTypes.string,
        releaseNotes: PropTypes.shape({
            dontShowChecked: PropTypes.bool,
            id: PropTypes.string,
            features: PropTypes.arrayOf(PropTypes.string),
        }),
        resetAdsCreationState: PropTypes.func.isRequired,
        saveEditCampaignDataToStore: PropTypes.func.isRequired,
        setGoogleEmail: PropTypes.func.isRequired,
        saveChannels: PropTypes.func.isRequired,
        setReleaseNotes: PropTypes.func.isRequired,
        setReleaseNotesCheckbox: PropTypes.func.isRequired,
    };
    static loadersStack = [];
    state = {
        isSidebarOpened: false,
        shouldUpdateCampaignTable: false,
        shouldUpdateGMCTable: false,
        shouldUpdateStatusBar: false,
        gmcSuccessNotificationActive: false,
        isLoaderActive: false,
        isOverviewModalVisible: false,
        isWhatsNewModalVisible: false,
        isUpdateModalVisible: true,
        isCreateAdButtonEnabled: false,
        isPaymentNoticeActive: false,
        isSuccessNotificationActive: false,
        isErrorNotificationActive: false,
        isIndexerFailWarningActive: false,
        channelsWarnings: [],
    };

    componentDidMount() {
        this.verifyCampaignSuccessNotification();
        this.setState({
            isIndexerFailWarningActive: !!localStorage.getItem(
                'indexerInvalidationError',
            ),
            gmcSuccessNotificationActive: !!localStorage.getItem(
                'gmcAccountSuccess',
            ),
        });

        this.fetchAdwordsData();
        this.handleReleaseNotes();
        const { yuiKey } = this.props;
        const isUserLoggedIn =
            !isEmpty(get(window.googleAppConfig, 'key')) ||
            !isEmpty(yuiKey);

        isUserLoggedIn
            ? this.toggleUpdateGMCTable()
            : this.setState({
                  isOverviewModalVisible: true,
              });
    }

    async handleReleaseNotes() {
        this.props.setReleaseNotes(
            await fetch(Urls.releaseNotesUrl).then(res => res.json()),
        );
        if (this.shouldShowWhatsNewModal()) {
            this.setState({ isWhatsNewModalVisible: true });
        }
    }

    componentWillUnmount() {
        localStorage.removeItem('gmcAccountSuccess');
    }

    verifyCampaignSuccessNotification = payment => {
        if (!payment) return;

        this.setState({
            isSuccessNotificationActive: !!sessionStorage.getItem(
                'isCampaignCreated',
            ),
        });
        sessionStorage.removeItem('isCampaignCreated');
    };

    showNotification = error => {
        const isSessionExpired = has(error, 'ajaxExpired');

        isSessionExpired
            ? redirectToYuiLogin()
            : this.setState({
                  isErrorNotificationActive: true,
              });
    };

    processChannelsWarnings = channels => {
        const channelsWarnings = reduce(
            channels,
            (result, channel) => {
                return !channel.hasAttributesMapping
                    ? [
                          ...result,
                          <Notification
                              key={channel.channelName}
                              type="warning"
                              defaultMessageType="channelAttributesMappingWarning"
                              isActive={true}
                              messageValues={{
                                  channelName: channel.channelName,
                                  settings: (
                                      <Link to={'settings'}>
                                          <FormattedMessage id="notification.channelAttributesMappingWarning.settingsLink" />
                                      </Link>
                                  ),
                              }}
                          />,
                      ]
                    : result;
            },
            [],
        );

        if (!isEmpty(channelsWarnings)) {
            this.setState({ channelsWarnings });
        }
    };

    processChannelData = channels => {
        this.setDashboardControlsState(channels);

        if (!isEmpty(channels)) {
            this.props.saveChannels(channels);
            this.processChannelsWarnings(channels);
        } else {
            this.setState({
                isOverviewModalVisible: true,
            });
        }
    };

    fetchAdwordsData = async () => {
        this.showLoader();

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: 'adwords/account',
            });

            const adsData = JSON.parse(response.body);

            if (!isEmpty(adsData)) {
                window.googleAppConfig.adConfig = adsData;
                this.setPaymentMethodStatus(adsData);
                this.toggleUpdateCampaignTable();
                this.toggleUpdateStatusBar();
                this.verifyCampaignSuccessNotification(
                    adsData.paymentConfigured,
                );
            }
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    setPaymentMethodStatus = adsData => {
        const { paymentConfigured } = adsData;

        this.setState({
            isPaymentNoticeActive: !paymentConfigured,
        });
    };

    setDashboardControlsState = accounts => {
        const email = get(accounts[0], 'email') || '';
        const isCreateAdButtonEnabled = !isEmpty(accounts);

        this.props.setGoogleEmail(email);
        this.setState({ isCreateAdButtonEnabled });
    };

    showLoader = () => {
        DashboardLanding.loadersStack.push('loading');
        this.setState({ isLoaderActive: true });
    };

    hideLoader = () => {
        const { loadersStack } = DashboardLanding;
        loadersStack.pop();

        if (isEmpty(loadersStack)) {
            this.setState({
                isLoaderActive: false,
            });
        }
    };

    toggleUpdateGMCTable = () => {
        this.setState(prevState => ({
            shouldUpdateGMCTable: !prevState.shouldUpdateGMCTable,
        }));
    };

    toggleUpdateCampaignTable = () => {
        this.setState(prevState => ({
            shouldUpdateCampaignTable: !prevState.shouldUpdateCampaignTable,
        }));
    };

    toggleUpdateStatusBar = () => {
        this.setState(prevState => ({
            shouldUpdateStatusBar: !prevState.shouldUpdateStatusBar,
        }));
    };

    handleCloseSidebar = () => {
        this.setState({
            isSidebarOpened: false,
            shouldUpdateCampaignTable: false,
        });
        this.props.resetAdsCreationState();
    };

    editCampaignRequest = async data => {
        const { id, channelId, status } = data;
        this.showLoader();

        try {
            await this.props.saveChannelIdToStore(channelId);
            const response = await yuiRequest({
                method: 'get',
                uri: `channels/${channelId}/campaigns/${id}`,
            });
            const data = attempt(JSON.parse, response.body);

            data.campaignId = id;
            data.status = status;

            this.processEditCampaignData(data);
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    processEditCampaignData(payload) {
        try {
            this.props.saveEditCampaignDataToStore(payload);
        } catch (error) {
            throw new Error(error);
        }

        this.setState({
            isSidebarOpened: true,
        });
    }

    indexerInvalidationRequest = async event => {
        event.preventDefault();
        const { invalidateIndexesUrl } = window.googleAppConfig;
        this.showLoader();

        try {
            const response = await fetch(invalidateIndexesUrl, {
                method: 'get',
            });

            response.ok
                ? this.hideIndexerInvalidationError()
                : this.showIndexerInvalidationError();
        } catch (e) {
            this.showIndexerInvalidationError();
        } finally {
            this.hideLoader();
        }
    };

    showIndexerInvalidationError() {
        localStorage.setItem('indexerInvalidationError', 'true');
        this.setState({
            isIndexerFailWarningActive: true,
        });
    }

    hideIndexerInvalidationError() {
        localStorage.removeItem('indexerInvalidationError');
        this.setState({
            isIndexerFailWarningActive: false,
        });
    }

    handleCloseWhatsNewModal = () => {
        const {
            releaseNotes: { id, dontShowChecked },
        } = this.props;
        const whatsNewModal = {
            id,
            lastSeen: Date.now(),
            userHidden: dontShowChecked,
        };
        localStorage.setItem('whatsNewModal', JSON.stringify(whatsNewModal));
        this.setState({
            isWhatsNewModalVisible: false,
        });
    };

    handleCloseUpdateModal = () => {
        this.setState({
            isUpdateModalVisible: false,
        });
    };

    shouldShowWhatsNewModal = () => {
        const {
            releaseNotes: { id },
        } = this.props;
        if (!id) {
            return false;
        }
        const modalString = localStorage.getItem('whatsNewModal');
        if (!modalString) {
            return true;
        }
        const modal = JSON.parse(modalString);
        if (id !== modal.id) {
            return true;
        }
        return (
            !modal.userHidden &&
            Date.now() - modal.lastSeen > RELEASE_NOTES_MODAL_24_HOUR_GAP
        );
    };

    handleWhatsNewCheck = e => {
        const { setReleaseNotesCheckbox } = this.props;
        setReleaseNotesCheckbox({ dontShowChecked: e.target.checked });
    };

    render() {
        const {
            state: {
                gmcSuccessNotificationActive,
                isSidebarOpened,
                isOverviewModalVisible,
                isWhatsNewModalVisible,
                isUpdateModalVisible,
                shouldUpdateCampaignTable,
                shouldUpdateStatusBar,
                shouldUpdateGMCTable,
                isCreateAdButtonEnabled,
                isPaymentNoticeActive,
                isSuccessNotificationActive,
                isErrorNotificationActive,
                isIndexerFailWarningActive,
                channelsWarnings,
            },
            props: { releaseNotes },
        } = this;

        const yuiExtensionVersion = get(
            window.googleAppConfig,
            'metadata.moduleVersion',
            '',
        );
        const showUpdateModal =
            isUpdateModalVisible &&
            yuiExtensionVersion &&
            version &&
            parseFloat(yuiExtensionVersion) !== parseFloat(version);

        const isAdwordAccountCreated = !isEmpty(
            get(window.googleAppConfig, 'adConfig'),
        );

        const isKeyValid = get(window.googleAppConfig, 'keyIsValid', false);
        const isKeySet = !isEmpty(get(window.googleAppConfig, 'key', ''));
        const isOverviewActive =
            !showUpdateModal &&
            isOverviewModalVisible &&
            isKeySet &&
            isKeyValid;
        const isApiKeyInstructionModalVisible =
            !showUpdateModal && (!isKeySet || !isKeyValid);
        const showWhatsNewModal =
            !isOverviewActive &&
            !isApiKeyInstructionModalVisible &&
            isWhatsNewModalVisible;

        return (
            <React.Fragment>
                <Loader isVisible={this.state.isLoaderActive} />
                <OverviewModal isVisible={!!isOverviewActive} />
                <ApiKeyInstructionModal
                    isVisible={isApiKeyInstructionModalVisible}
                />
                <UpdateModal
                    isVisible={!!showUpdateModal}
                    onClose={this.handleCloseUpdateModal}
                />
                <WhatsNewModal
                    isVisible={showWhatsNewModal}
                    onClose={this.handleCloseWhatsNewModal}
                    features={releaseNotes.features}
                    checked={releaseNotes.dontShowChecked}
                    onCheck={this.handleWhatsNewCheck}
                />
                <DashboardLandingActions
                    isCreateAdButtonEnabled={isCreateAdButtonEnabled}
                    isAdwordAccountCreated={isAdwordAccountCreated}
                />
                {!isOverviewModalVisible ? (
                    <React.Fragment>
                        <Notification
                            type="success"
                            defaultMessageType="adCampaignSuccess"
                            isActive={isSuccessNotificationActive}
                        />
                        <Notification
                            type="success"
                            defaultMessageType="gmcSuccess"
                            isActive={gmcSuccessNotificationActive}
                        />
                        <Notification
                            type="error"
                            defaultMessageType="requestError"
                            isActive={isErrorNotificationActive}
                        />
                        <IndexerInvalidationNotification
                            isActive={isIndexerFailWarningActive}
                            indexerInvalidationRequest={
                                this.indexerInvalidationRequest
                            }
                        />
                        {channelsWarnings}
                    </React.Fragment>
                ) : null}
                <div className={styles['notifications-container']}>
                    <PaymentNotification isActive={isPaymentNoticeActive} />
                </div>
                <StatusBar
                    showLoader={this.showLoader}
                    hideLoader={this.hideLoader}
                    showNotification={this.showNotification}
                    shouldUpdateStatusBar={shouldUpdateStatusBar}
                    toggleUpdateStatusBar={this.toggleUpdateStatusBar}
                />
                <div className={styles['tables-block']}>
                    <AdCampaignTable
                        editCampaignHandler={this.editCampaignRequest}
                        setPaymentMethodStatus={this.setPaymentMethodStatus}
                        shouldUpdateTable={shouldUpdateCampaignTable}
                        toggleUpdateCampaignTable={
                            this.toggleUpdateCampaignTable
                        }
                        showLoader={this.showLoader}
                        hideLoader={this.hideLoader}
                        showNotification={this.showNotification}
                    />
                    <h1 className={styles.header}>
                        <FormattedMessage id="dashboardLanding.GMCAccountsTable.header" />
                    </h1>
                    <GMCAccountsTable
                        showLoader={this.showLoader}
                        hideLoader={this.hideLoader}
                        processChannelData={this.processChannelData}
                        shouldUpdateGMCTable={shouldUpdateGMCTable}
                        toggleUpdateGMCTable={this.toggleUpdateGMCTable}
                        showNotification={this.showNotification}
                    />
                </div>
                <Sidebar
                    isActive={isSidebarOpened}
                    position="right"
                    onOverlayClick={this.handleCloseSidebar}
                >
                    <EditAdCampaign
                        handleCloseSidebar={this.handleCloseSidebar}
                        toggleUpdateCampaignTable={
                            this.toggleUpdateCampaignTable
                        }
                        showLoader={this.showLoader}
                        hideLoader={this.hideLoader}
                    />
                </Sidebar>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    yuiKey: state.websiteConfig.new.yuiKey,
    adsCreation: state.adsCreation,
    releaseNotes: state.releaseNotes,
});

export default connect(
    mapStateToProps,
    AllActionCreators,
)(DashboardLanding);
