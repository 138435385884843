import EventEmitter, { Listener } from './EventEmitter';

export default class Poller<T extends () => boolean | Promise<boolean>> {
    private emitter = new EventEmitter();
    private timer: number | null = null;

    public get running() {
        return this.timer !== null;
    }

    constructor(
        /**
         * This function will keep getting called until it returns or resolves
         * `true`, which cancels/stops the polling.
         */
        private iteratee: T,
        /**
         * The delay in milliseconds between each function call, plus any time
         * it takes the `iteratee` to complete, async or not. This also prevents
         * overlapping timers.
         */
        private delay: number,
    ) {
        this.start();
    }

    /**
     * @emits Poller#on('tick')
     * @emits Poller#on('done')
     * @emits Poller#on('error')
     */
    public start() {
        this.timer = window.setTimeout(() => this.tick(), this.delay);
    }

    /**
     * Clears timer and prevents recursive starts from creating new ones.
     * @emits Poller#on('stop')
     */
    // tslint:disable-next-line:no-any
    public stop(shouldEmit: boolean = true, ...args: any[]) {
        clearTimeout(this.timer as number);
        this.timer = null;

        if (shouldEmit) {
            this.emitter.emit('stop', ...args);
        }
    }

    /**
     * Adds the `listener` to the `eventName` set.
     * @returns a function that removes the `listener` from the `eventName` set.
     */
    // tslint:disable-next-line:no-any
    public on(
        eventName: 'tick' | 'stop' | 'done' | 'error',
        listener: Listener,
    ) {
        this.emitter.on(eventName, listener);
    }

    private async tick() {
        try {
            const iterateeResult = this.iteratee();
            this.emitter.emit('tick', iterateeResult);
            const done = await iterateeResult;
            if (!this.running) {
                return;
            }
            if (done === true) {
                this.emitter.emit('done');
                return;
            }
        } catch (err) {
            this.emitter.emit('error', err);
            return;
        }
        this.start();
    }
}
