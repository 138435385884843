import { ActionCreator } from 'redux';

import { ISaveShippingFlatRateCostAction } from '../../../../../../../../../../reducers/websiteConfigReducer';
import { SAVE_SHIPPING_FLAT_RATE_COST } from '../../../../../../../../../../store/actionConsts';

export const saveFlatRateCostToStore: ActionCreator<
    ISaveShippingFlatRateCostAction
> = (id: string, rate: string) => ({
    payload: {
        id,
        rate,
    },
    type: SAVE_SHIPPING_FLAT_RATE_COST,
});
