import React from 'react';
import { FormattedMessage } from 'react-intl';

const DescriptionText = function(props) {
    const styles = props.styles;

    return (
        <React.Fragment>
            <h2 className={styles.header2}>
                <FormattedMessage id="attributeMapping.header_1" />
            </h2>
            <p className={styles[`font-medium`]}>
                <FormattedMessage id="attributeMapping.text_1" />
            </p>
        </React.Fragment>
    );
};

export default DescriptionText;
