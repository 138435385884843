import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { yuiRequest } from 'api';
import { get, isEmpty, has, reduce, map, some } from 'lodash';

import { AdForms } from 'component/AdForms';
import EditCampaignActions from './components';
import Notification from 'component/notification';
import styles from './EditAdCampaign.module.css';

import { redirectToYuiLogin } from 'helpers/redirectToYuiLogin';

export class EditAdCampaign extends Component {
    static propTypes = {
        adsCreation: PropTypes.object.isRequired,
        handleCloseSidebar: PropTypes.func.isRequired,
        toggleUpdateCampaignTable: PropTypes.func.isRequired,
        showLoader: PropTypes.func.isRequired,
        hideLoader: PropTypes.func.isRequired,
    };
    state = {
        gmcAccountError: false,
        countryToAdvertiseError: false,
        productFilterError: false,
        campaignNameError: false,
        dailyBudgetError: false,
        reditectToLanding: false,
        isErrorNotificationActive: false,
    };

    constructor(props) {
        super(props);
        this.campaignMainFormFieldIds = {
            gmcAccount: 'gmcAccount',
            countryToAdvertise: 'countryToAdvertise',
            campaignName: 'campaignName',
            dailyBudget: 'dailyBudget',
        };
    }

    showNotification = error => {
        const isSessionExpired = has(error, 'ajaxExpired');

        isSessionExpired
            ? redirectToYuiLogin()
            : this.setState({
                  isErrorNotificationActive: true,
              });
    };

    setFieldError = fieldId => {
        this.setState({
            [`${fieldId}Error`]: true,
        });
    };

    unsetFieldError = fieldId => {
        this.setState({
            [`${fieldId}Error`]: false,
        });
    };

    validateCampaignProductsFilterFields() {
        const { adsCreation } = this.props;
        const isSelectAllRadioSelected =
            adsCreation.activeRadioButtonId !== 'selectUsingFilters';

        if (
            isSelectAllRadioSelected ||
            some(
                ['selectedFilterCategories', 'selectedFilterBrands'],
                id => !isEmpty(adsCreation[id]),
            )
        ) {
            this.unsetFieldError('productFilter');
            return true;
        }
        this.setFieldError('productFilter');
        return false;
    }

    validateCampaignMainFormFields() {
        return isEmpty(
            reduce(
                this.campaignMainFormFieldIds,
                (errors, fieldId) => {
                    if (!isEmpty(this.props.adsCreation[fieldId])) {
                        this.unsetFieldError(fieldId);
                    } else {
                        this.setFieldError(fieldId);
                        errors.push(fieldId);
                    }
                    return errors;
                },
                [],
            ),
        );
    }

    handleSaveChanges = () => {
        const isMainFormValid = this.validateCampaignMainFormFields();
        const isProductFilterValid = this.validateCampaignProductsFilterFields();

        if (isMainFormValid && isProductFilterValid) {
            this.saveChangesRequest();
        }
    };

    async saveChangesRequest() {
        const {
            channelId,
            campaignName,
            dailyBudget,
            activeRadioButtonId,
            selectedFilterBrands,
            selectedFilterCategories,
            allSKUsAreSelected,
            includedProductsBySKU,
            excludedProductsBySKU,
            editCampaignData: { campaignId, status },
        } = this.props.adsCreation;
        const radioIdsMap = {
            selectAll: 'all',
            selectUsingFilters: 'brandCategory',
            selectUsingSKU: 'sku',
        };
        const filters = [
            ...map(selectedFilterBrands, item => ({
                type: 'brand',
                value: item.label,
            })),
            ...map(selectedFilterCategories, item => ({
                type: 'category',
                value: item.value,
            })),
        ];
        const payload = {
            edit: true,
            campaignId,
            status,
            name: campaignName,
            budget: dailyBudget,
            currencyCode: get(window.googleAppConfig.adConfig, 'currency', ''),
            filterType: radioIdsMap[activeRadioButtonId],
            filters:
                activeRadioButtonId === 'selectUsingFilters' ? filters : [],
            skuSelectedAll:
                activeRadioButtonId === 'selectUsingSKU'
                    ? allSKUsAreSelected
                    : '',
            productList:
                activeRadioButtonId === 'selectUsingSKU'
                    ? !allSKUsAreSelected
                        ? includedProductsBySKU
                        : excludedProductsBySKU
                    : [],
        };

        this.props.showLoader();

        try {
            await yuiRequest({
                method: 'put',
                uri: `channels/${channelId}/campaigns`,
                payload,
            });

            this.props.toggleUpdateCampaignTable();
            this.props.handleCloseSidebar();
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.props.hideLoader();
        }
    }

    render() {
        const {
            state: {
                gmcAccountError,
                countryToAdvertiseError,
                campaignNameError,
                dailyBudgetError,
                reditectToLanding,
                productFilterError,
                isErrorNotificationActive,
            },
            props: { handleCloseSidebar },
        } = this;

        if (reditectToLanding) {
            return <Redirect to="/" />;
        }

        return (
            <div className={styles.wrapper}>
                <h1 className={styles['main-header']}>
                    <FormattedMessage id="editAdCampaign.mainHeader" />
                </h1>
                <EditCampaignActions
                    handleCloseSidebar={handleCloseSidebar}
                    handleSaveChanges={this.handleSaveChanges}
                />
                <Notification
                    type="error"
                    defaultMessageType="requestError"
                    isActive={isErrorNotificationActive}
                />
                <AdForms
                    isEditMode
                    campaignMainFormFieldIds={this.campaignMainFormFieldIds}
                    setFieldError={this.setFieldError}
                    unsetFieldError={this.unsetFieldError}
                    gmcAccountError={gmcAccountError}
                    countryToAdvertiseError={countryToAdvertiseError}
                    campaignNameError={campaignNameError}
                    dailyBudgetError={dailyBudgetError}
                    productFilterError={productFilterError}
                    showLoader={this.props.showLoader}
                    hideLoader={this.props.hideLoader}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    adsCreation: state.adsCreation,
});

export default connect(mapStateToProps)(EditAdCampaign);
