import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { get, find } from 'lodash';
import currencies from 'config/currencies';

import { classnames } from '../../../../util';

import { MainActionsPanel } from './components';
import Notification from 'component/notification';
import spinner from 'design/assets/Spinner-lg.gif';
import commonStyles from 'design/styles/common.module.css';
import styles from './ReviewCampaignContent.module.css';

export class ReviewCampaignContent extends Component {
    static propTypes = {
        adsSettings: PropTypes.object.isRequired,
        adsCreation: PropTypes.object.isRequired,
        handleCloseSidebar: PropTypes.func.isRequired,
        publishCampaignHandler: PropTypes.func.isRequired,
        fetchProductsNumber: PropTypes.func.isRequired,
        productsNumber: PropTypes.number.isRequired,
        isDataLoaded: PropTypes.bool.isRequired,
        isButtonActive: PropTypes.bool.isRequired,
        isReviewCampaignErrorActive: PropTypes.bool.isRequired,
        sidebarErrorMessage: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]).isRequired,
    };
    state = {
        code: 'USD',
        symbol: '$',
    };

    componentDidMount() {
        const { currency } = this.props.adsSettings;

        this.setCurrency(
            currency || get(window.googleAppConfig, 'adConfig.currency', ''),
        );
        this.props.fetchProductsNumber();
    }

    setCurrency(code) {
        const currency = find(currencies, { code });

        if (currency) {
            this.setState({ ...currency });
        }
    }

    render() {
        const wrapperStyles = classnames([styles['content-wrapper']]);
        const mainHeaderStyles = classnames([
            commonStyles['header1'],
            styles['header1'],
        ]);
        const headerStyles = classnames([
            commonStyles.header3,
            commonStyles['font-semibold'],
            styles.header3,
        ]);
        const {
            props: {
                adsCreation,
                handleCloseSidebar,
                publishCampaignHandler,
                isDataLoaded,
                isButtonActive,
                productsNumber,
                isReviewCampaignErrorActive,
                sidebarErrorMessage,
            },
            state: { symbol },
        } = this;
        const { campaignName, dailyBudget, activeRadioButtonId } = adsCreation;
        const calculatedSpend = +dailyBudget * 30.4;
        const formattedMonthlySpend = +calculatedSpend.toFixed(2);
        const monthlySpendValue = formattedMonthlySpend.toLocaleString('en-US');
        const dailyBudgetValue = (+dailyBudget).toFixed(2) || '';

        return (
            <div className={wrapperStyles}>
                <h1 className={mainHeaderStyles}>
                    <FormattedMessage id="reviewCampaign.mainHeader" />
                </h1>
                <MainActionsPanel
                    {...{
                        isButtonActive,
                        handleCloseSidebar,
                        publishCampaignHandler,
                    }}
                />
                <Notification
                    type="error"
                    defaultMessageType="requestError"
                    isActive={isReviewCampaignErrorActive}
                >
                    {sidebarErrorMessage}
                </Notification>
                <div className={styles['main-content']}>
                    <div className={styles['info-field']}>
                        <h3 className={headerStyles}>
                            <FormattedMessage id="reviewCampaign.campaignNameHeader" />
                        </h3>
                        <p className={commonStyles['font-medium']}>
                            {campaignName}
                        </p>
                    </div>
                    <div className={styles['info-field']}>
                        <h3 className={headerStyles}>
                            <FormattedMessage id="reviewCampaign.dailyBudgetHeader" />
                        </h3>
                        <p className={commonStyles['font-medium']}>
                            {symbol}
                            {dailyBudgetValue}
                        </p>
                    </div>
                    <div className={styles['info-field']}>
                        <h3 className={headerStyles}>
                            <FormattedMessage id="reviewCampaign.monthlySpendHeader" />
                        </h3>
                        <p className={commonStyles['font-medium']}>
                            {symbol}
                            {monthlySpendValue}
                        </p>
                    </div>
                    <div className={styles['info-field']}>
                        <h3 className={headerStyles}>
                            <FormattedMessage id="reviewCampaign.noOfProductsHeader" />
                        </h3>
                    </div>
                    <div>
                        {isDataLoaded ? (
                            <React.Fragment>
                                <p className={commonStyles['font-medium']}>
                                    <FormattedPlural
                                        value={productsNumber}
                                        one={
                                            <FormattedMessage
                                                id="reviewCampaign.amountOfProductsText_one"
                                                values={{
                                                    count: productsNumber,
                                                }}
                                            />
                                        }
                                        other={
                                            <FormattedMessage
                                                id="reviewCampaign.amountOfProductsText_many"
                                                values={{
                                                    count: productsNumber,
                                                }}
                                            />
                                        }
                                    />
                                    {activeRadioButtonId === 'selectAll' ? (
                                        <FormattedMessage id="reviewCampaign.amountOfProductsText.selectedAll" />
                                    ) : null}
                                </p>
                                <div
                                    className={styles['no-of-products-remark']}
                                >
                                    <FormattedMessage id="reviewCampaign.noOfProductsText" />
                                </div>
                            </React.Fragment>
                        ) : (
                            <div
                                className={
                                    styles['fetch-products-amount-block']
                                }
                            >
                                <img
                                    className={styles.spinner}
                                    src={spinner}
                                    alt=""
                                />
                                <p className={commonStyles['font-medium']}>
                                    <FormattedMessage id="reviewCampaign.block.fetchingProductsText" />
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    adsSettings: state.adsSettings,
    adsCreation: state.adsCreation,
});

export default connect(mapStateToProps)(ReviewCampaignContent);
