import selectStyles from 'component/form/Select/ConfigurableSelect.styles';

const customSelectStyles = { ...selectStyles };

customSelectStyles.control = base => ({
    ...selectStyles.control(base),
    width: 307,
});

customSelectStyles.menu = base => ({
    ...selectStyles.menu(base),
    width: 500,
});

customSelectStyles.valueContainer = base => ({
    ...selectStyles.valueContainer(base),
    fontSize: '14px',
});

customSelectStyles.option = (base, state) => ({
    ...selectStyles.option(base, state),
    height: 'auto',
    borderBottom: '1px solid #e3e3e3',
    paddingBottom: '2px',
});

export default customSelectStyles;
