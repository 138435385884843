import { isEmpty, map, find, get } from 'lodash';
import { countryOfSales } from 'config/countryOfSales';
import states from 'config/us_states.json';

export const mapShippingAndTaxResponseData = (responseData, storeViews) => {
    if (isEmpty(responseData)) {
        return {};
    }

    const { shipmentSettings, taxSettings } = responseData.channelAttributes;

    const isShipmentEmpty = isEmpty(shipmentSettings);
    const isTaxEmpty = isEmpty(taxSettings);

    const shippingCost = {
        activeRadioButtonId: !isShipmentEmpty ? 'flatRateCost' : 'setCostOnGmc',
        radioButtons: {
            flatRateCost: {
                tableData: map(shipmentSettings, item => ({
                    uniqueRowId: get(
                        find(storeViews, {
                            country: {
                                value: item.deliveryCountry,
                            },
                        }),
                        'uniqueRowId',
                    ),
                    countryName: get(
                        find(countryOfSales, { value: item.deliveryCountry }),
                        'label',
                    ),
                    countryCode: item.deliveryCountry,
                    currencyCode: item.currency,
                    rate: item.price,
                })),
            },
            setCostOnGmc: {
                value: isShipmentEmpty,
            },
        },
    };

    const shippingTax = {
        activeRadioButtonId: !isTaxEmpty ? 'autoTax' : 'vatIncluded',
        radioButtons: {
            autoTax: {
                isValid: !isTaxEmpty,
                value: map(taxSettings, item =>
                    find(states, { value: parseInt(item.locationId) }),
                ),
            },
            vatIncluded: {
                value: isTaxEmpty,
            },
        },
    };

    return {
        shippingCost,
        shippingTax,
    };
};
