import React, { Component } from 'react';

import Table from 'component/table';

import { FormattedMessage } from 'react-intl';

import commonStyles from 'design/styles/common.module.css';
import styles from './AccountErrors.module.css';

class AccountErrors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    Header: 'Issue',
                    accessor: 'issue',
                },
                {
                    Header: 'Description',
                    accessor: 'description',
                },
                {
                    Header: 'Country',
                    accessor: 'country',
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                },
            ],
        };
    }

    mapData = data =>
        data.map(item => {
            const issue = item.severity
                ? item.severity[0].toUpperCase() +
                  item.severity.slice(1).toLowerCase()
                : '';

            return {
                issue:
                    item.severity === 'critical' ? (
                        <span className={styles['critical-error-color']}>
                            {issue}
                        </span>
                    ) : (
                        issue
                    ),
                description: item.description,
                country: 'United States' /* Mock for now */,
                actions: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="about:blank"
                    >
                        Resolve
                    </a>
                ),
            };
        });

    render() {
        const viewData = this.props.data ? this.mapData(this.props.data) : [];

        const pageSize =
            !this.props.loading && viewData.length !== 0 ? viewData.length : 2;

        const table = (
            <Table
                data={viewData}
                columns={this.state.columns}
                loading={this.props.loading}
                showCounter={false}
                showPaginationTop={false}
                pageSize={pageSize}
            />
        );

        return (
            <div>
                <h2
                    className={[commonStyles.header2, styles.header2].join(' ')}
                >
                    <FormattedMessage id="accountErrors.title" />
                </h2>

                <p
                    className={[
                        commonStyles[`font-medium`],
                        styles['description-text'],
                    ].join(' ')}
                >
                    <FormattedMessage id="accountErrors.description" />
                </p>
                {table}
            </div>
        );
    }
}

export default AccountErrors;
