import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { yuiRequest, OperationRequest } from 'api';
import { operationStatuses } from 'config/operationStatuses';
import { get, isEmpty, has } from 'lodash';
import * as allActionCreators from './actions';
import {
    ChannelDataProcessor,
    MappingDataProcessor,
    CreateAttribute,
} from 'helpers/gmcPayloadProcessors';
import { redirectToYuiLogin } from 'helpers/redirectToYuiLogin';

import Loader from 'component/Loader';
import Notification from 'component/notification';
import FixedHeader from 'component/FixedHeader';
import Wizard from 'component/Wizard';
import WizardActions from 'component/WizardActions';
import AttributeMappingForm from 'component/AttributeMappingForm';

import { DescriptionText } from './components';
import commonStyles from 'design/styles/common.module.css';
import styles from './AttributeMapping.module.css';

/**
 * An attribute mapping page scene.
 */
export class AttributeMapping extends Component {
    static propTypes = {
        websiteConfig: PropTypes.object.isRequired,
        attributeMapping: PropTypes.object.isRequired,
        resetConfigState: PropTypes.func.isRequired,
        resetAttributesState: PropTypes.func.isRequired,
    };
    state = {
        redirectToLanding: false,
        isLoaderActive: false,
        isRequestErrorNotifyActive: false,
        errorMessage: '',
        isSaving: false,
    };
    loadersStack = [];

    constructor(props) {
        super(props);
        this.wizardConfig = {
            activeElementIndex: 3,
            elements: [
                {
                    caption: <FormattedMessage id="onboarding.wizard.terms" />,
                    link: '/terms',
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.url_verification" />
                    ),
                    link: '/url-verification',
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.shipping_and_tax" />
                    ),
                    link: '/shipping-and-tax',
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.attribute_mapping" />
                    ),
                },
            ],
            backButton: {
                caption: <FormattedMessage id="wizard.back" />,
                link: '/shipping-and-tax',
            },
            nextButton: {
                caption: (
                    <FormattedMessage id="onboarding.wizard.google_complete_setup" />
                ),
            },
        };
    }

    showLoader = () => {
        this.loadersStack.push('loading');
        this.setState({ isLoaderActive: true });
    };

    hideLoader = () => {
        this.loadersStack.pop();

        if (isEmpty(this.loadersStack)) {
            this.setState({
                isLoaderActive: false,
            });
        }
    };

    async publishDataToGmc() {
        const channelDataProcessor = new ChannelDataProcessor();
        const mappingDataProcessor = new MappingDataProcessor();
        const createAttribute = new CreateAttribute();
        const { attributes } = this.props.attributeMapping;
        const { websiteConfig } = this.props;

        this.setState({
            isRequestErrorNotifyActive: false,
        });
        this.showLoader();

        attributes['categoryAttrRadioId'].data === 'newYuiCategoryAttr' &&
            (await createAttribute.createCategoriesAttribute());

        try {
            const response = await yuiRequest({
                method: 'post',
                uri: 'channels',
                payload: {
                    ...channelDataProcessor.createPayload(websiteConfig.new),
                    ...mappingDataProcessor.createPayload(attributes),
                },
            });
            const data = JSON.parse(response.body);

            this.operationsRequest(data.operationId);
        } catch (error) {
            this.showErrorNotification(error);
        } finally {
            this.hideLoader();
        }
    }

    showErrorNotification(error) {
        const isSessionExpired = has(error, 'ajaxExpired');

        isSessionExpired
            ? redirectToYuiLogin()
            : this.setState({
                  isRequestErrorNotifyActive: true,
              });
    }

    operationsRequest(operationId) {
        const {
            attributesStoringSuccess,
            gmcAccountCreationFail,
            channelStoringFail,
            attributesStoringFail,
        } = operationStatuses;

        this.showLoader();

        new OperationRequest({
            operationId,
            delay: 5000,
            successStatusHandler: this.operationRequestSuccessHandler,
            failureStatusHandler: this.operationFailureStatusHandler,
            requestErrorHandler: this.operationRequestFailHandler,
            successStatus: attributesStoringSuccess,
            failureStatuses: [
                gmcAccountCreationFail,
                channelStoringFail,
                attributesStoringFail,
            ],
        });
    }

    operationRequestSuccessHandler = () => {
        localStorage.setItem('gmcAccountSuccess', 'true');
        this.hideLoader();
        this.indexerInvalidationRequest();
    };

    operationFailureStatusHandler = fail => {
        const {
            gmcAccountCreationFail,
            channelStoringFail,
            attributesStoringFail,
        } = operationStatuses;
        const status = get(fail, 'status', '');
        const isAttributeStoringError = status === attributesStoringFail;
        const isAccountChannelError =
            status === gmcAccountCreationFail || status === channelStoringFail;

        this.hideLoader();

        isAttributeStoringError &&
            localStorage.setItem('gmcAccountSuccess', 'true');

        this.setState({
            redirectToLanding: isAttributeStoringError,
            isRequestErrorNotifyActive: isAccountChannelError,
            errorMessage: get(fail, 'comments', ''),
            isSaving: false,
        });
    };

    operationRequestFailHandler = error => {
        this.hideLoader();
        this.showErrorNotification(error);
    };

    async indexerInvalidationRequest() {
        const { invalidateIndexesUrl } = window.googleAppConfig;
        this.showLoader();

        try {
            const response = await fetch(invalidateIndexesUrl, {
                method: 'get',
            });

            if (!response.ok) {
                localStorage.setItem('indexerInvalidationError', 'true');
            }
        } catch (error) {
            localStorage.setItem('indexerInvalidationError', 'true');
        } finally {
            this.props.resetConfigState();
            this.props.resetAttributesState();
            this.hideLoader();
            this.setState({
                redirectToLanding: true,
            });
        }
    }

    handleNext = () => {
        this.setState({
            isSaving: true,
        });
    };

    createWizardActions = () => {
        return (
            <WizardActions
                back={this.wizardConfig.backButton}
                next={this.wizardConfig.nextButton}
                nextAction={this.handleNext}
            />
        );
    };

    createWizard() {
        return (
            <Wizard
                elements={this.wizardConfig.elements}
                activeElementIndex={this.wizardConfig.activeElementIndex}
            />
        );
    }

    confirmSaving = () => {
        this.publishDataToGmc();
    };

    rejectSaving = () => {
        this.setState({
            isSaving: false,
        });
    };

    render() {
        const wizard = this.createWizard();
        const {
            state: {
                redirectToLanding,
                isLoaderActive,
                isRequestErrorNotifyActive,
                errorMessage,
            },
        } = this;

        if (redirectToLanding && !isLoaderActive) {
            return <Redirect to="/" />;
        }

        return (
            <React.Fragment>
                <Loader isVisible={this.state.isLoaderActive} />
                <FixedHeader right={this.createWizardActions}>
                    {wizard}
                </FixedHeader>
                <div className={styles['notifications-container']}>
                    <Notification
                        isActive={isRequestErrorNotifyActive}
                        type="error"
                        defaultMessageType="requestError"
                    >
                        {errorMessage}
                    </Notification>
                </div>
                <div className={commonStyles.sceneMainContent}>
                    <DescriptionText styles={commonStyles} />
                    <AttributeMappingForm
                        isSaving={this.state.isSaving}
                        confirmSaving={this.confirmSaving}
                        rejectSaving={this.rejectSaving}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    websiteConfig: state.websiteConfig,
    attributeMapping: state.attributeMapping,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(AttributeMapping);
