import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'component/Tooltip';
import Input from '../../../../component/form/Input';
import commonStyles from 'design/styles/common.module.css';
import styles from './StoreNameField.module.css';
import { connect } from 'react-redux';
import { classnames } from '../../../../util';

export class StoreNameField extends Component {
    static inputName = 'storeName';

    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    inputChangeHandler = event => {
        const value = event.target.value;

        this.setValue(value);
    };

    setValue(value) {
        this.props.validateRequiredField(value, StoreNameField.inputName);
        this.props.saveFieldValueToStore(value, StoreNameField.inputName);
        this.setState({
            value,
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.websiteUrl !== prevProps.websiteUrl &&
            this.props.websiteUrl
        ) {
            const website = window.googleAppConfig.websites.filter(website => {
                if (this.props.websiteUrl === website.url) {
                    return true;
                }
                return false;
            })[0];
            this.setValue(website.name);
        }
    }

    componentDidMount() {
        this.prefillInputedValue();
    }

    prefillInputedValue() {
        const prefilledValue = this.props[StoreNameField.inputName].value;
        const predefinedValue = this.props.websiteName;

        if (prefilledValue) {
            this.setValue(prefilledValue);
        } else if (predefinedValue) {
            this.setValue(predefinedValue);
        }
    }

    render() {
        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['medium-field-header-right-required'],
            commonStyles['font-semibold'],
        ]);

        return (
            <React.Fragment>
                <div className={styles['store-name-header-wrapper']}>
                    <span className={fieldHeadingStyles}>
                        <FormattedMessage id="urlVerification.input.storeNameHeader" />
                    </span>
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/url-verify.html"
                        className={commonStyles['cs-tooltip-left-margin']}
                    >
                        <FormattedMessage id="help.urlVerification.storeName" />
                    </Tooltip>
                </div>
                <Input
                    {...{
                        errorMessage: this.props[StoreNameField.inputName]
                            .error && (
                            <FormattedMessage
                                id={[
                                    'urlVerification',
                                    'input',
                                    'storeNameErrorMessage',
                                ].join('.')}
                            />
                        ),
                        name: StoreNameField.inputName,
                        onChange: this.inputChangeHandler,
                        renderInput: input => (
                            <div className={styles['store-name-input-wrapper']}>
                                {input}
                            </div>
                        ),
                        value: this.state.value,
                    }}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    websiteUrl: state.websiteConfig.new.urlVerification.websiteUrl.value,
    websiteName: state.websiteConfig.new.urlVerification.storeName.value,
});

export default connect(mapStateToProps)(StoreNameField);
