import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { RadioButton, ConfigurableSelect } from 'component/form';
import Tooltip from 'component/Tooltip';
import customSelectStyles from './selectComponent.styles';
import commonStyles from 'design/styles/common.module.css';

export class OneGenderForAllOption extends Component {
    static propTypes = {
        attributeMapping: PropTypes.shape({
            isEditMode: PropTypes.bool.isRequired,
            attributes: PropTypes.shape({
                gender: PropTypes.shape({
                    error: PropTypes.bool.isRequired,
                    data: PropTypes.oneOfType([
                        PropTypes.array,
                        PropTypes.object,
                    ]),
                }),
            }),
        }),
        activeRadioButtonId: PropTypes.string.isRequired,
        optionContentStyle: PropTypes.string.isRequired,
        radioButtonsConfig: PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
        setActiveRadioButtonId: PropTypes.func.isRequired,
        saveAttributeToStore: PropTypes.func.isRequired,
        validateAttribute: PropTypes.func.isRequired,
    };

    state = {
        options: [
            {
                value: 'male',
                label: 'Male',
            },
            {
                value: 'female',
                label: 'Female',
            },
            {
                value: 'unisex',
                label: 'Unisex',
            },
        ],
        selectedOption: [],
    };

    radioButtonChangeHandler = e => {
        this.props.setActiveRadioButtonId(e.target.id);
    };

    selectChangeHandler = option => {
        this.setState({ selectedOption: option });
        this.props.saveAttributeToStore('gender', option);
        this.props.validateAttribute([option], 'gender');
    };

    render() {
        const {
            props: {
                activeRadioButtonId,
                optionContentStyle,
                radioButtonsConfig,
                attributeMapping: {
                    attributes: { gender },
                },
            },
            state: { options },
        } = this;
        const { id, label } = radioButtonsConfig;
        const isRadioChecked = activeRadioButtonId === id;
        const error = isRadioChecked && gender.error;

        return (
            <React.Fragment>
                <div className={commonStyles['flex-container']}>
                    <RadioButton
                        id={id}
                        onChange={this.radioButtonChangeHandler}
                        checked={isRadioChecked}
                        labelFor={id}
                        label={label}
                    />
                    <Tooltip
                        direction="left"
                        link="sales-channels/google-ads/attribute-mapping.html"
                    >
                        <FormattedMessage id="help.attributeMapping.oneGenderForAllProducts" />
                    </Tooltip>
                </div>
                <div className={optionContentStyle}>
                    <ConfigurableSelect
                        styles={customSelectStyles}
                        onChange={this.selectChangeHandler}
                        value={gender.data}
                        {...{
                            options,
                            isMulti: false,
                            isSearchable: false,
                            blurInputOnSelect: true,
                            placeholder: (
                                <FormattedMessage id="attributeMapping.genderAttribute.select.placeholder_1" />
                            ),
                            errorMessage: (
                                <FormattedMessage id="attributeMapping.specificAttributeCommon.select_1.error" />
                            ),
                            showError: error,
                            isDisabled: !isRadioChecked,
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    attributeMapping: state.attributeMapping,
});

export default connect(mapStateToProps)(OneGenderForAllOption);
