import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { yuiRequest } from 'api';
import { find, get, noop } from 'lodash';

import currencies from 'config/currencies';

import { classnames } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './GoogleAds.module.css';

export default class GoogleAds extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        setPaymentConfiguredState: PropTypes.func.isRequired,
        showLoader: PropTypes.func.isRequired,
        hideLoader: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
    };

    setPaymentMethodHandler = event => {
        const payload = {
            paymentConfigured: event.target.checked,
        };

        this.setPaymentMethodRequest(payload);
    };

    async setPaymentMethodRequest(payload) {
        this.props.showLoader();

        try {
            await yuiRequest({
                method: 'post',
                uri: 'adwords/account/payment',
                payload,
            });

            this.props.setPaymentConfiguredState();
        } catch (error) {
            this.props.showNotification(error);
        } finally {
            this.props.hideLoader();
        }
    }

    render() {
        const {
            props: {
                data: { accountId, currency, timezone, paymentConfigured },
            },
        } = this;
        const currencyLabel = get(
            find(currencies, item => item.code === currency),
            'name',
        );

        const rowClasses = classnames([
            commonStyles['font-medium'],
            styles['field-row'],
        ]);

        return (
            <React.Fragment>
                <div className={styles['field-block']}>
                    <div className={rowClasses}>
                        <span className={styles['field-name']}>
                            <FormattedMessage id="settings.googleAds.accId" />
                        </span>
                        <span className={styles['acc-id-field']}>
                            {accountId || '—'}
                        </span>
                    </div>
                    <div className={rowClasses}>
                        <span className={styles['field-name']}>
                            <FormattedMessage id="settings.googleAds.currency" />
                        </span>
                        {currencyLabel ? `${currencyLabel} (${currency})` : '—'}
                    </div>
                    <div className={rowClasses}>
                        <span className={styles['field-name']}>
                            <FormattedMessage id="settings.googleAds.timezone" />
                        </span>
                        {timezone || '—'}
                    </div>
                </div>
                <div
                    className={classnames([
                        commonStyles['font-medium'],
                        commonStyles['font-semibold'],
                        styles['payment-text'],
                    ])}
                >
                    <FormattedMessage id="settings.googleAds.paymentText" />
                </div>
                <label className={styles.label}>
                    <input
                        type="checkbox"
                        className={styles.checkbox}
                        name="payment-confirmation"
                        onClick={this.setPaymentMethodHandler}
                        onChange={noop}
                        checked={paymentConfigured}
                    />
                    <span className={styles['label-text']}>
                        <FormattedMessage id="settings.googleAds.label" />
                    </span>
                </label>
                <div className={styles['buttons-block']}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://ads.google.com/"
                        className={styles['google-ads-link']}
                    >
                        <FormattedMessage id="settings.googleAds.visitAccButton" />
                    </a>
                </div>
            </React.Fragment>
        );
    }
}
