import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import { isEmpty, some, map } from 'lodash';
import PropTypes from 'prop-types';

import { ShippingCostForm, ShippingTaxForm } from './components';

export class ShippingAndTaxForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shippingCostError: false,
            flatRateFieldError: false,
            shippingTaxError: false,
            statesError: false,
        };
        this.radioButtonIds = {
            flatRateCost: 'flatRateCost',
            setCostOnGmc: 'setCostOnGmc',
            autoTax: 'autoTax',
            setTaxOnGmc: 'setTaxOnGmc',
            vatIncluded: 'vatIncluded',
        };
        this.fieldIds = {
            flatRateCost: 'flatRateCost',
            autoTax: 'autoTax',
        };
    }

    static propTypes = {
        isSaving: PropTypes.bool.isRequired,
        confirmSaving: PropTypes.func.isRequired,
        rejectSaving: PropTypes.func.isRequired,
    };

    showShippingCostError = () => {
        this.setState({
            shippingCostError: true,
        });
    };

    removeShippingCostError = () => {
        this.setState({
            shippingCostError: false,
        });
    };

    showShippingCostFlatRateError() {
        this.setState({
            flatRateFieldError: true,
        });
    }

    removeShippingCostFlatRateError = () => {
        this.setState({
            flatRateFieldError: false,
        });
    };

    showShippingTaxError = () => {
        this.setState({
            shippingTaxError: true,
        });
    };

    removeShippingTaxError = () => {
        this.setState({
            shippingTaxError: false,
        });
    };

    showShippingTaxStatesError = () => {
        this.setState({
            statesError: true,
        });
    };

    removeShippingTaxStatesError = () => {
        this.setState({
            statesError: false,
        });
    };

    validateShippingCostRadio() {
        const activeRadioButtonId = this.props.shippingCost.activeRadioButtonId;

        if (isEmpty(activeRadioButtonId)) {
            this.showShippingCostError();
        }

        return !isEmpty(activeRadioButtonId);
    }

    validateShippingTaxRadio() {
        const activeRadioButtonId = this.props.shippingTax.activeRadioButtonId;

        if (isEmpty(activeRadioButtonId)) {
            this.showShippingTaxError();
        }

        return !isEmpty(activeRadioButtonId);
    }

    validateActiveRadioButtons() {
        const isShippingCostRadiosValid = this.validateShippingCostRadio();
        const isShippingTaxRadiosValid = this.validateShippingTaxRadio();

        return isShippingCostRadiosValid && isShippingTaxRadiosValid;
    }

    validateShippingCostFlatRate() {
        const { tableData } = this.props.shippingCost.radioButtons.flatRateCost;

        if (some(tableData, row => isEmpty(row.rate))) {
            this.showShippingCostFlatRateError();
            return false;
        }
        return true;
    }

    validateShippingTaxStates() {
        const isValidField = this.props.shippingTax.radioButtons.autoTax
            .isValid;

        if (!isValidField) {
            this.showShippingTaxStatesError();
        }

        return isValidField;
    }

    validateField(activeRadioButtonId) {
        switch (activeRadioButtonId) {
            case this.fieldIds.flatRateCost: {
                return this.validateShippingCostFlatRate();
            }
            case this.fieldIds.autoTax: {
                return this.validateShippingTaxStates();
            }
            default:
                return true;
        }
    }

    validateRequiredFields() {
        const activeRadioButtonIds = [
            this.props.shippingCost.activeRadioButtonId,
            this.props.shippingTax.activeRadioButtonId,
        ];
        const errors = [];

        map(activeRadioButtonIds, fieldName => {
            const isValid = this.validateField(fieldName);

            if (!isValid) {
                errors.push(fieldName);
            }
        });

        return isEmpty(errors);
    }

    validateForm() {
        const isRadiosValid = this.validateActiveRadioButtons();
        const isFieldsValid = this.validateRequiredFields();

        return isRadiosValid && isFieldsValid;
    }

    handleNext = () => {
        const isValid = this.validateForm();

        if (isValid) {
            this.props.history.push('/attribute-mapping');
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isSaving !== this.props.isSaving && this.props.isSaving) {
            const isValid = this.validateForm();

            if (isValid) {
                // this.props.history.push('/attribute-mapping'); //@todo: !!!
                this.props.confirmSaving();
            } else {
                this.props.rejectSaving();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <ShippingCostForm
                    radioButtonIds={this.radioButtonIds}
                    fieldIds={this.fieldIds}
                    shippingCost={this.props.shippingCost}
                    shippingCostError={this.state.shippingCostError}
                    removeShippingCostError={this.removeShippingCostError}
                    flatRateFieldError={this.state.flatRateFieldError}
                    removeShippingCostFlatRateError={
                        this.removeShippingCostFlatRateError
                    }
                    saveActiveRadioButtonIdToStore={
                        this.props.saveActiveRadioButtonIdToStore
                    }
                    saveFlatRateCostToStore={this.props.saveFlatRateCostToStore}
                    checkFlatRateCostIsValid={
                        this.props.checkFlatRateCostIsValid
                    }
                    saveSetupCostOnGmc={this.props.saveSetupCostOnGmc}
                    removeSetCostOnGmc={this.props.removeSetCostOnGmc}
                />
                <ShippingTaxForm
                    shippingTax={this.props.shippingTax}
                    radioButtonIds={this.radioButtonIds}
                    fieldIds={this.fieldIds}
                    saveActiveTaxRadioIdToStore={
                        this.props.saveActiveTaxRadioIdToStore
                    }
                    shippingTaxError={this.state.shippingTaxError}
                    removeShippingTaxError={this.removeShippingTaxError}
                    removeShippingTaxStatesError={
                        this.removeShippingTaxStatesError
                    }
                    saveOperateStatesToStore={
                        this.props.saveOperateStatesToStore
                    }
                    statesError={this.state.statesError}
                    saveVatIncluded={this.props.saveVatIncluded}
                    removeVatIncluded={this.props.removeVatIncluded}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    shippingCost: state.websiteConfig.new.shippingCost,
    shippingTax: state.websiteConfig.new.shippingTax,
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(allActionCreators, dispatch),
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShippingAndTaxForm);
