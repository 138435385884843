import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';

import Notification from 'component/notification';
import Modal from 'component/modal';

import { classnames, createYuiDocsLink } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './ApiKeyInstructionModal.module.css';

const ApiKeyInstructionModal = props => {
    const headerStyles = classnames([commonStyles.header2, styles.header2]);
    const isKeyValid = get(window.googleAppConfig, 'keyIsValid') || false;
    const isApiKeyError =
        !isKeyValid && !isEmpty(get(window.googleAppConfig, 'key', ''));
    const faqLink = createYuiDocsLink(
        'sales-channels/google-ads/google-faq.html',
    );
    const keySettingsLink = get(window.googleAppConfig, 'keySettingsUrl', '');

    return (
        <Modal
            modalContainerStyle={styles['modal-container']}
            isVisible={props.isVisible}
        >
            <div className={styles['header-container']}>
                <div className={styles['header-logo']} />
                <h2 className={headerStyles}>
                    <FormattedMessage id="dashboardLanding.apiKeyOverviewModalContent.header" />
                </h2>
            </div>
            <div className={styles['step-info']}>
                <p className={styles['step-info-note']}>
                    <FormattedMessage id="dashboardLanding.apiKeyOverviewModalContent.description_1" />
                </p>
                <p className={styles['step-info-note']}>
                    <FormattedMessage id="dashboardLanding.apiKeyOverviewModalContent.description_2" />
                </p>
            </div>
            <Notification
                type="error"
                defaultMessageType="invalidApiKey"
                isActive={isApiKeyError}
            />
            <div className={styles['step-info']}>
                <p className={styles['step-info-header']}>
                    <FormattedMessage id="dashboardLanding.apiKeyOverviewModalContent.step1.header" />
                </p>
                <p>
                    <FormattedMessage
                        values={{
                            link: (
                                <a
                                    href="https://account.yui.sk/apiportal/index/index/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <FormattedMessage id="dashboardLanding.apiKeyOverviewModalContent.step1.link" />
                                </a>
                            ),
                        }}
                        id="dashboardLanding.apiKeyOverviewModalContent.step1.description"
                    />
                </p>
            </div>
            <div className={styles['step-info']}>
                <p className={styles['step-info-header']}>
                    <FormattedMessage id="dashboardLanding.apiKeyOverviewModalContent.step2.header" />
                </p>
                <p>
                    <FormattedMessage
                        values={{
                            link: (
                                <a
                                    href={keySettingsLink}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <FormattedMessage id="dashboardLanding.apiKeyOverviewModalContent.step2.link" />
                                </a>
                            ),
                        }}
                        id="dashboardLanding.apiKeyOverviewModalContent.step2.description"
                    />
                </p>
            </div>
            <a
                className={styles['faq-link']}
                href={faqLink}
                rel="noopener noreferrer"
                target="_blank"
            >
                <FormattedMessage id="dashboardLanding.overviewModalContent.faqLink" />
            </a>
        </Modal>
    );
};

ApiKeyInstructionModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
};

export default ApiKeyInstructionModal;
