import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { RadioButton } from 'component/form';

import Tooltip from 'component/Tooltip';
import commonStyles from 'design/styles/common.module.css';

export class NoAttributesForProducts extends Component {
    static propTypes = {
        activeRadioButtonId: PropTypes.string.isRequired,
        optionContentStyle: PropTypes.string.isRequired,
        radioButtonsConfig: PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
        setActiveRadioButtonId: PropTypes.func.isRequired,
        unsetAttributeAsRequired: PropTypes.func.isRequired,
    };

    radioButtonChangeHandler = e => {
        this.props.setActiveRadioButtonId(e.target.id);
        this.props.unsetAttributeAsRequired('identifierExists');
    };

    render() {
        const {
            props: { activeRadioButtonId, radioButtonsConfig },
        } = this;
        const { id, label } = radioButtonsConfig;
        const isRadioChecked = activeRadioButtonId === id;

        return (
            <React.Fragment>
                <div className={commonStyles['flex-container']}>
                    <RadioButton
                        id={id}
                        onChange={this.radioButtonChangeHandler}
                        checked={isRadioChecked}
                        labelFor={id}
                        label={label}
                    />
                    <Tooltip
                        direction="left"
                        link="google-ads-attribute-mapping.html"
                    >
                        <FormattedMessage id="help.attributeMapping.identifierExists.radio_1" />
                    </Tooltip>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    attributeMapping: state.attributeMapping,
});

export default connect(mapStateToProps)(NoAttributesForProducts);
