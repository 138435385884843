import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styles from './Loader.module.css';

class Loader extends Component {
    static propTypes = {
        isVisible: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.modalRoot = document.getElementById('modal_root');
        this.loaderContainer = document.createElement('div');
        this.loaderContainer.setAttribute('id', 'google-connector-loader');
    }

    componentDidMount() {
        this.modalRoot.appendChild(this.loaderContainer);
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.loaderContainer);
    }

    createLoaderElements(amount) {
        return Array.from({ length: amount }, (value, key) => (
            <div key={key.toString()} />
        ));
    }

    createLoader = () => {
        return (
            <div className={styles['loader-overlay']}>
                <div className={styles['loader-spinner']}>
                    {this.createLoaderElements(12)}
                </div>
            </div>
        );
    };

    render() {
        return (
            this.props.isVisible &&
            ReactDOM.createPortal(this.createLoader(), this.loaderContainer)
        );
    }
}

export default Loader;
