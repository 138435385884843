import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { classnames } from '../../util';

import styles from './ActionSelect.module.css';

export default class ActionSelect extends Component {
    state = {
        isOpened: false,
    };

    static propTypes = {
        labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        noOptionsText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]),
        options: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.element,
                ]),
                action: PropTypes.func,
                active: PropTypes.bool,
            }),
        ).isRequired,
    };

    static defaultProps = {
        labelText: <FormattedMessage id="actionSelect.labelText" />,
        noOptionsText: <FormattedMessage id="actionSelect.noOptions" />,
    };

    handleClick = () => {
        this.setState(prevState => ({
            isOpened: !prevState.isOpened,
        }));
    };

    handleBlur = () => this.setState({ isOpened: false });

    handleDisabledOptionClick = e => e.stopPropagation();

    getOptionClasses = active =>
        classnames([styles.option, !active && styles['option_disabled']]);

    render() {
        const { options, labelText, noOptionsText } = this.props;
        const { isOpened } = this.state;

        const optionsClasses = classnames([
            styles['options'],
            !isOpened && styles['options_hidden'],
        ]);
        const labelTextClasses = classnames([
            styles['label-text'],
            isOpened && styles['label-text_reversed'],
        ]);

        return (
            <div
                onClick={this.handleClick}
                className={styles['action-select']}
                onBlur={this.handleBlur}
                tabIndex="0"
            >
                <span className={labelTextClasses}>{labelText}</span>
                <ul className={optionsClasses}>
                    {!isEmpty(options) ? (
                        options.map((item, index) => (
                            <li
                                key={index}
                                className={this.getOptionClasses(item.active)}
                                onClick={
                                    item.active
                                        ? item.action
                                        : this.handleDisabledOptionClick
                                }
                            >
                                {item.text}
                            </li>
                        ))
                    ) : (
                        <div className={styles['no-options']}>
                            {noOptionsText}
                        </div>
                    )}
                </ul>
            </div>
        );
    }
}
