import {
    compact,
    each,
    endsWith,
    isEmpty,
    reduce,
    map,
    includes,
} from 'lodash';

export default class MappingDataProcessor {
    static EXCLUDED_ATTRS = [
        'ageGroupAttrRadioId',
        'ageGroup',
        'categoryAttrRadioId',
        'gender',
        'genderAttrRadioId',
        'googleCategoryAttr',
        'yuiCategoryAttr',
        'newYuiCategoryAttr',
        'condition',
        'conditionAttrRadioId',
        'identifierExists',
        'identifierExistsAttrRadioId',
    ];
    specificGoogleAttributes = {
        ageGroup: ['adult', 'infant', 'newborn', 'kids', 'toddler'],
        condition: ['new', 'refurbished', 'used'],
        gender: ['male', 'female', 'unisex'],
    };
    constructor() {
        this.saasAttributeMappingPayload = {
            mappings: [
                {
                    channelAttributeCode: 'offerId',
                    yuiAttributes: [
                        {
                            yuiAttributeCode: 'sku',
                        },
                    ],
                },
                {
                    channelAttributeCode: 'customLabel0',
                    yuiAttributes: [
                        {
                            yuiAttributeCode: 'category_name',
                        },
                    ],
                },
                {
                    channelAttributeCode: 'link',
                    yuiAttributes: [
                        {
                            yuiAttributeCode: 'product_url',
                        },
                    ],
                },
                {
                    channelAttributeCode: 'imageLink',
                    yuiAttributes: [
                        {
                            yuiAttributeCode: 'image_url',
                        },
                    ],
                },
                {
                    channelAttributeCode: 'isBundle',
                    yuiAttributes: [
                        {
                            yuiAttributeCode: 'is_bundle',
                        },
                    ],
                },
                {
                    channelAttributeCode: 'itemGroupId',
                    yuiAttributes: [
                        {
                            yuiAttributeCode: 'item_group_id',
                        },
                    ],
                },
            ],
        };
    }

    createPayload(requestPayload) {
        const { EXCLUDED_ATTRS } = MappingDataProcessor;
        const { mappings } = this.saasAttributeMappingPayload;

        each(requestPayload, (attribute, key) => {
            if (!includes(EXCLUDED_ATTRS, key)) {
                mappings.push({
                    channelAttributeCode: key,
                    yuiAttributes: map(
                        attribute.data,
                        yuiAttribute => ({
                            yuiAttributeCode: yuiAttribute.value,
                        }),
                    ),
                });
            }
        });

        mappings.push(
            ...compact([
                !isEmpty(requestPayload.categoryAttrRadioId.data) &&
                    !endsWith(
                        requestPayload.categoryAttrRadioId.data,
                        'NotApplicable',
                    ) &&
                    this.getGoogleProductCategory(requestPayload),
                !isEmpty(requestPayload.conditionAttrRadioId.data) &&
                    !endsWith(
                        requestPayload.conditionAttrRadioId.data,
                        'NotApplicable',
                    ) &&
                    this.getConditionMappingPayload(requestPayload),
                !isEmpty(requestPayload.ageGroupAttrRadioId.data) &&
                    !endsWith(
                        requestPayload.ageGroupAttrRadioId.data,
                        'NotApplicable',
                    ) &&
                    this.getAgeGroupMappingPayload(requestPayload),
                !isEmpty(requestPayload.genderAttrRadioId.data) &&
                    !endsWith(
                        requestPayload.genderAttrRadioId.data,
                        'NotApplicable',
                    ) &&
                    this.getGenderMappingPayload(requestPayload),
                !isEmpty(requestPayload.identifierExistsAttrRadioId.data) &&
                    !endsWith(
                        requestPayload.identifierExistsAttrRadioId.data,
                        'NotApplicable',
                    ) &&
                    this.getIdentifierMappingPayload(requestPayload),
            ]),
        );

        return this.saasAttributeMappingPayload;
    }

    getIdentifierMappingPayload(requestPayload) {
        const channelAttributeCode = 'identifierExists';
        const radioId = requestPayload['identifierExistsAttrRadioId'].data;
        const attribute = requestPayload[channelAttributeCode];

        switch (radioId) {
            case 'noAttributesForProducts':
                return {
                    channelAttributeCode,
                    channelAttributeValue: false,
                };
            case 'identifyYuiAttributes':
                return {
                    channelAttributeCode,
                    yuiAttributes: map(
                        attribute.data,
                        yuiAttribute => ({
                            yuiAttributeCode: yuiAttribute.value,
                        }),
                    ),
                };
            default:
                break;
        }
    }

    getGoogleProductCategory(requestPayload) {
        const yuiAttributes = [];
        let channelAttributeValue = '';
        const radioId = requestPayload['categoryAttrRadioId'].data;

        switch (radioId) {
            case 'googleCategoryAttr':
                channelAttributeValue =
                    requestPayload['googleCategoryAttr'].data.label;
                break;
            case 'yuiCategoryAttr':
                yuiAttributes.push(
                    ...map(
                        requestPayload['yuiCategoryAttr'].data,
                        yuiAttribute => ({
                            yuiAttributeCode: yuiAttribute.value,
                        }),
                    ),
                );
                break;
            case 'newYuiCategoryAttr':
                yuiAttributes.push({
                    yuiAttributeCode: 'merchant_center_category',
                });
                break;
            default:
                break;
        }

        const result = {
            channelAttributeCode: 'googleProductCategory',
        };

        if (!isEmpty(channelAttributeValue)) {
            result.channelAttributeValue = channelAttributeValue;
        } else {
            result.yuiAttributes = yuiAttributes;
        }

        return result;
    }

    createYuiOptions = params => {
        const {
            specificGoogleAttributeNames,
            selectedAttribute,
            channelAttributeCode,
            requestPayload,
        } = params;

        return reduce(
            specificGoogleAttributeNames,
            (result, specificAttrName) => {
                const { data } = requestPayload[channelAttributeCode][
                    specificAttrName
                ];
                const option = {
                    channelOptionValue: specificAttrName,
                    yuiOptions: this.createYuiOptionValues(
                        data,
                        selectedAttribute,
                    ),
                };

                return !isEmpty(option.yuiOptions)
                    ? [...result, { ...option }]
                    : result;
            },
            [],
        );
    };

    createYuiOptionValues = (data, selectedAttribute) => {
        return reduce(
            data,
            (result, option) => {
                const isOptionsSelected =
                    selectedAttribute.label === option.parentAttributeLabel;

                return isOptionsSelected
                    ? [...result, { yuiOptionValue: option.label }]
                    : result;
            },
            [],
        );
    };

    createYuiAttributes(params) {
        const {
            selectedYuiAttributes,
            specificGoogleAttributeNames,
            channelAttributeCode,
            requestPayload,
        } = params;

        return reduce(
            selectedYuiAttributes,
            (result, selectedAttribute) => {
                const yuiAttribute = {
                    yuiAttributeCode: selectedAttribute.value,
                    options: this.createYuiOptions({
                        specificGoogleAttributeNames,
                        selectedAttribute,
                        channelAttributeCode,
                        requestPayload,
                    }),
                };
                return !isEmpty(yuiAttribute.options)
                    ? [...result, { ...yuiAttribute }]
                    : result;
            },
            [],
        );
    }

    getAgeGroupMappingPayload(requestPayload) {
        const channelAttributeCode = 'ageGroup';
        const payload = {
            channelAttributeCode: channelAttributeCode,
        };
        const radioId = requestPayload['ageGroupAttrRadioId'].data;
        const specificGoogleAttributeNames = this.specificGoogleAttributes[
            channelAttributeCode
        ];

        switch (radioId) {
            case 'ageGroupCommon':
                return {
                    ...payload,
                    channelAttributeValue:
                        requestPayload[channelAttributeCode].data.value,
                };
            case 'ageGroupMultiple':
                const selectedYuiAttributes =
                    requestPayload[channelAttributeCode].specificData;

                payload.yuiAttributes = this.createYuiAttributes({
                    selectedYuiAttributes,
                    specificGoogleAttributeNames,
                    channelAttributeCode,
                    requestPayload,
                });
                break;
            default:
                break;
        }

        return payload;
    }

    getGenderMappingPayload(requestPayload) {
        const channelAttributeCode = 'gender';
        const payload = {
            channelAttributeCode: channelAttributeCode,
        };
        const radioId = requestPayload['genderAttrRadioId'].data;
        const specificGoogleAttributeNames = this.specificGoogleAttributes[
            channelAttributeCode
        ];

        switch (radioId) {
            case 'genderCommon':
                return {
                    ...payload,
                    channelAttributeValue:
                        requestPayload[channelAttributeCode].data.value,
                };
            case 'genderMultiple':
                const selectedYuiAttributes =
                    requestPayload[channelAttributeCode].specificData;

                payload.yuiAttributes = this.createYuiAttributes({
                    selectedYuiAttributes,
                    specificGoogleAttributeNames,
                    channelAttributeCode,
                    requestPayload,
                });
                break;
            default:
                break;
        }

        return payload;
    }

    getConditionMappingPayload(requestPayload) {
        const channelAttributeCode = 'condition';
        const payload = {
            channelAttributeCode: channelAttributeCode,
        };
        const radioId = requestPayload['conditionAttrRadioId'].data;
        const specificGoogleAttributeNames = this.specificGoogleAttributes[
            channelAttributeCode
        ];

        switch (radioId) {
            case 'conditionCommon':
                return {
                    ...payload,
                    channelAttributeValue:
                        requestPayload[channelAttributeCode].data.value,
                };
            case 'conditionMultiple':
                const selectedYuiAttributes =
                    requestPayload[channelAttributeCode].specificData;

                payload.yuiAttributes = this.createYuiAttributes({
                    selectedYuiAttributes,
                    specificGoogleAttributeNames,
                    channelAttributeCode,
                    requestPayload,
                });
                break;
            default:
                break;
        }

        return payload;
    }
}
