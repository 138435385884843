import { ActionCreator } from 'redux';

import {
    ISaveListOfSpecificAttributeOptionsAction,
    ISaveSpecificAttributeOptionAction,
    ISaveSpecificAttributeValueAction,
    ISetSpecificAttributeErrorAction,
    IUnsetSpecificAttributeErrorAction,
    IUnsetSpecificAttributeTableErrorAction,
} from '../../../../../../../../reducers/attributeMappingReducer';
import {
    SAVE_LIST_SPECIFIC_ATTRIBUTE_OPTIONS,
    SAVE_SPECIFIC_ATTRIBUTE_OPTION,
    SAVE_SPECIFIC_ATTRIBUTE_VALUE,
    SET_SPECIFIC_ATTRIBUTE_ERROR,
    UNSET_SPECIFIC_ATTRIBUTE_ERROR,
    UNSET_SPECIFIC_ATTRIBUTE_TABLE_ERROR,
} from '../../../../../../../../store/actionConsts';

export const saveSpecificAttributeToStore: ActionCreator<
    ISaveSpecificAttributeValueAction
> = (attributeName: string, payload: string) => ({
    attributeName,
    payload,
    type: SAVE_SPECIFIC_ATTRIBUTE_VALUE,
});

export const saveListOfSpecificAttributeOptionsToStore: ActionCreator<
    ISaveListOfSpecificAttributeOptionsAction
> = (
    attributeName: string,
    payload: Array<{
        label: string;
        value: string;
    }>,
) => ({
    attributeName,
    payload,
    type: SAVE_LIST_SPECIFIC_ATTRIBUTE_OPTIONS,
});

export const saveSpecificAttributeOptionToStore: ActionCreator<
    ISaveSpecificAttributeOptionAction
> = (
    attributeName: string,
    specificAttributeName: string,
    payload: Array<{
        label: string;
        value: string;
    }>,
) => ({
    attributeName,
    payload,
    specificAttributeName,
    type: SAVE_SPECIFIC_ATTRIBUTE_OPTION,
});

export const validateSpecificYuiAttribute: ActionCreator<
    ISetSpecificAttributeErrorAction | IUnsetSpecificAttributeErrorAction
> = (attributeName: string, selectedOption: Array<{ value: string }>) =>
    selectedOption.length
        ? {
              attributeName,
              type: UNSET_SPECIFIC_ATTRIBUTE_ERROR,
          }
        : {
              attributeName,
              type: SET_SPECIFIC_ATTRIBUTE_ERROR,
          };

export const unsetSpecificMappingTableError: ActionCreator<
    IUnsetSpecificAttributeTableErrorAction
> = (attributeName: string) => ({
    attributeName,
    type: UNSET_SPECIFIC_ATTRIBUTE_TABLE_ERROR,
});
