import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from 'component/modal';
import { CheckBox } from 'component/form';
import { classnames } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './WhatsNewModal.module.css';

const WhatsNewModal = props => {
    const { isVisible, onClose, onCheck, checked, features } = props;

    const headerStyles = classnames([commonStyles['header2'], styles.header2]);
    const buttonContainerStyles = classnames([
        commonStyles['flex-container'],
        commonStyles['space-between'],
        commonStyles['align-end'],
    ]);

    return (
        <Modal
            modalContainerStyle={styles['modal-container']}
            showCloseSign={false}
            isVisible={isVisible}
        >
            <h2 className={headerStyles}>
                <FormattedMessage id="whatsNewModal.header" />
            </h2>
            <ul className={styles['list-block']}>
                {features.map(feature => (
                    <li key={`${feature}`} className={styles['list-item']}>
                        <span className={styles['list-item_text']}>
                            {feature}
                        </span>
                    </li>
                ))}
            </ul>
            <div className={buttonContainerStyles}>
                <CheckBox
                    id={'doNotShowModal'}
                    onChange={onCheck}
                    checked={checked}
                    labelText={<FormattedMessage id="whatsNewModal.checkbox" />}
                />
                <button className={styles.button} onClick={onClose}>
                    <FormattedMessage id="whatsNewModal.button.text" />
                </button>
            </div>
        </Modal>
    );
};

WhatsNewModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCheck: PropTypes.func.isRequired,
    features: PropTypes.arrayOf(PropTypes.string),
};

export default WhatsNewModal;
