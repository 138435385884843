const selectStyles = {
    placeholder: base => ({
        ...base,
        'margin-left': 10,
        font: '14px/19px Open Sans, serif',
        color: '#767676',
        'letter-spacing': '0.22px',
    }),
    control: base => ({
        ...base,
        width: 342,
        'min-height': 33,
        border: '1px solid #ADADAD',
        'border-radius': 0,
        'box-shadow': 'none',
        '&:hover': {
            'border-color': '#ADADAD',
        },
    }),
    multiValue: base => ({
        ...base,
        margin: '2px 3px',
        height: 27,
        border: '1px solid #AEADAC',
        'border-radius': 0,
        'background-color': '#F5F5F5',
    }),
    multiValueLabel: base => ({
        ...base,
        border: 2,
        font: '600 13px/18px Open Sans, serif',
    }),
    input: base => ({
        ...base,
        marginLeft: '10px',
    }),
    multiValueRemove: base => ({
        ...base,
        'border-radius': 0,
        color: '#979797',
        '&:hover': {
            'background-color': 'transparent',
            color: '#979797',
            cursor: 'pointer',
        },
    }),
    valueContainer: base => ({
        ...base,
        padding: '0',
    }),
    indicatorsContainer: base => ({
        ...base,
        width: 33,
        position: 'relative',
        'border-width': '0 0 0 1px',
        'border-style': 'solid',
        'border-color': '#ADADAD',
        'background-color': '#E3E3E3',
    }),
    clearIndicator: () => ({
        display: 'none',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        width: 0,
        height: 0,
        padding: 0,
        position: 'absolute',
        left: 12,
        'border-style': 'solid',
        'border-width': state.isFocused ? '0 4px 5px 4px' : '5px 4px 0 4px',
        'border-color': state.isFocused
            ? 'transparent transparent #033333 transparent'
            : '#333333 transparent transparent transparent',
        '& > svg': {
            display: 'none',
        },
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...base,
            opacity,
            transition,
            'margin-left': 10,
        };
    },
    menu: base => ({
        ...base,
        minWidth: 232,
        maxWidth: 500,
        width: 'auto',
        'border-radius': 0,
        'margin-top': 1,
    }),
    menuList: base => ({
        ...base,
        'padding-right': 10,
        'max-height': 200,
        '&::-webkit-scrollbar-track': {
            'background-color': 'transparent',
        },
        '&::-webkit-scrollbar': {
            width: 6,
        },
        '&::-webkit-scrollbar-thumb': {
            'border-radius': 3,
            'background-color': '#463d37',
        },
    }),
    option: (base, state) => ({
        ...base,
        'background-color': state.isFocused ? '#dff7ff' : 'white',
        font: '600 11px Open Sans, serif',
        color: !state.isFocused ? '#41362f' : 'inherit',
        padding: '2px 0 0 7px',
        minHeight: 20,
        height: 'auto',
        '&:active': {
            backgroundColor: '#dff7ff',
        },
    }),
};

export default selectStyles;
