import { ActionCreator } from 'redux';

import { ISaveFlatRateTableData } from '../../../../../../../../reducers/websiteConfigReducer';
import { SAVE_FLAT_RATE_TABLE_DATA } from '../../../../../../../../store/actionConsts';

export const saveFlatRateTableDataToStore: ActionCreator<
    ISaveFlatRateTableData
> = (
    payload: Array<{
        countryCode: string;
        countryName: string;
        currencyCode: string;
        uniqueRowId: string;
        rate: string;
    }>,
) => ({
    payload,
    type: SAVE_FLAT_RATE_TABLE_DATA,
});
