import googleCategories from 'config/googleCategories.json';
import map from 'lodash/map';

export default class CreateAttribute {
    getAttributeData(label, code, options) {
        return {
            attribute: {
                extension_attributes: {},
                is_wysiwyg_enabled: false,
                is_html_allowed_on_front: false,
                used_for_sort_by: false,
                is_filterable: false,
                is_filterable_in_search: false,
                is_used_in_grid: false,
                is_visible_in_grid: false,
                is_filterable_in_grid: false,
                position: 0,
                apply_to: [
                    'simple',
                    'downloadable',
                    'virtual',
                    'bundle',
                    'configurable',
                ],
                is_searchable: false,
                is_visible_in_advanced_search: false,
                is_comparable: false,
                is_used_for_promo_rules: false,
                is_visible_on_front: false,
                used_in_product_listing: false,
                is_visible: true,
                scope: 'global',
                attribute_id: 0,
                attribute_code: code,
                frontend_input: 'select',
                entity_type_id: 4,
                is_required: false,
                options: [],
                is_user_defined: true,
                default_frontend_label: label,
                frontend_labels: [],
                backend_type: 'int',
                source_model:
                    'Yui\\Eav\\Model\\Entity\\Attribute\\Source\\Table',
                is_unique: 0,
                validation_rules: [],
            },
        };
    }

    async createAttribute(label, code, options) {
        let formData = new FormData();

        formData.append('form_key', window.FORM_KEY);
        formData.append(
            'attributeData',
            JSON.stringify(this.getAttributeData(label, code, options)),
        );

        return fetch(
            window.googleAppConfig.createAttributeUrl + '?isAjax=true',
            {
                method: 'POST',
                body: formData,
            },
        )
            .then(response => response.json())
            .then(response => {
                response.error &&
                    !response.error ===
                        'Attribute with the same code already exists.' &&
                    localStorage.setItem('isNewAttributeCreationError', 'true');
            })
            .catch(() => {
                localStorage.setItem('isNewAttributeCreationError', 'true');
            });
    }

    async createCategoriesAttribute() {
        return this.createAttribute(
            'Google Merchant Center Category',
            'merchant_center_category',
            map(googleCategories, 'label'),
        );
    }
}
