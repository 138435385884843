import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { RadioButton, ConfigurableSelect } from 'component/form';
import customSelectStyles from './selectComponent.styles';

import Tooltip from 'component/Tooltip';
import commonStyles from 'design/styles/common.module.css';
import { parseAttributesByDataType } from 'helpers/attributeMapping';

export class IdentifyYuiAttributes extends Component {
    static propTypes = {
        attributeMapping: PropTypes.shape({
            attributes: PropTypes.shape({
                identifierExists: PropTypes.shape({
                    error: PropTypes.bool.isRequired,
                    data: PropTypes.oneOfType([
                        PropTypes.array,
                        PropTypes.object,
                    ]),
                }).isRequired,
            }).isRequired,
        }).isRequired,
        activeRadioButtonId: PropTypes.string.isRequired,
        optionContentStyle: PropTypes.string.isRequired,
        radioButtonsConfig: PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
        setActiveRadioButtonId: PropTypes.func.isRequired,
        saveAttributeToStore: PropTypes.func.isRequired,
        validateAttribute: PropTypes.func.isRequired,
    };
    state = {
        options: [],
        selectedOption: [],
    };
    static selectConfig = {
        name: 'identifierExists',
        requiredDataType: ['text', 'select'],
    };

    componentDidMount() {
        this.createOptions();
    }

    createOptions() {
        this.setState({
            options: parseAttributesByDataType(
                IdentifyYuiAttributes.selectConfig.requiredDataType,
            ),
        });
    }

    radioButtonChangeHandler = e => {
        this.props.setActiveRadioButtonId(e.target.id);
    };

    selectChangeHandler = option => {
        const { name } = IdentifyYuiAttributes.selectConfig;
        this.setState({ selectedOption: option });
        this.props.saveAttributeToStore(name, option);
        this.props.validateAttribute([option], name);
    };

    render() {
        const {
            props: {
                activeRadioButtonId,
                optionContentStyle,
                radioButtonsConfig,
                attributeMapping: {
                    attributes: { identifierExists },
                },
            },
            state: { options },
        } = this;
        const { id, label } = radioButtonsConfig;
        const isRadioChecked = activeRadioButtonId === id;
        const error = isRadioChecked && identifierExists.error;

        return (
            <React.Fragment>
                <div className={commonStyles['flex-container']}>
                    <RadioButton
                        id={id}
                        onChange={this.radioButtonChangeHandler}
                        checked={isRadioChecked}
                        labelFor={id}
                        label={label}
                    />
                    <Tooltip
                        direction="left"
                        link="google-ads-attribute-mapping.html"
                    >
                        <FormattedMessage id="help.attributeMapping.identifierExists.radio_2" />
                    </Tooltip>
                </div>
                <div className={optionContentStyle}>
                    <ConfigurableSelect
                        styles={customSelectStyles}
                        onChange={this.selectChangeHandler}
                        value={identifierExists.data}
                        {...{
                            options,
                            isMulti: true,
                            isSearchable: true,
                            blurInputOnSelect: false,
                            placeholder: (
                                <FormattedMessage id="attributeMapping.identifierExistsAttribute.select_1.placeholder" />
                            ),
                            errorMessage: (
                                <FormattedMessage id="attributeMapping.select.errorMessage" />
                            ),
                            showError: error,
                            isDisabled: !isRadioChecked,
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    attributeMapping: state.attributeMapping,
});

export default connect(mapStateToProps)(IdentifyYuiAttributes);
