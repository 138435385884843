import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    compact,
    find,
    forEach,
    get,
    has,
    includes,
    isEmpty,
    map,
    partialRight,
} from 'lodash';
import PropTypes from 'prop-types';

import FixedHeader from 'component/FixedHeader';

import { GoogleAds, ResendingProductsModal } from './components';
import * as allActionCreators from './actions';
import { clearEditType } from '../../actions/settings/actions';

import Table from 'component/table';
import Loader from 'component/Loader';
import Notification from 'component/notification';
import ActionSelect from 'component/actionSelect';
import { Sidebar } from 'component/sidebar';

import UrlVerificationForm from 'component/UrlVerificationForm';
import ShippingAndTaxForm from 'component/ShippingAndTaxForm';
import AttributeMappingForm from 'component/AttributeMappingForm';

import { mapUrlVerificationData } from 'helpers/urlVerification';
import { mapShippingAndTaxResponseData } from 'helpers/shippingAndTax';
import { mapAttributeMappingResponseData } from 'helpers/attributeMapping';
import { yuiRequest, OperationRequest } from 'api';
import { redirectToYuiLogin } from 'helpers/redirectToYuiLogin';

import { classnames } from '../../util';

import {
    ChannelDataProcessor,
    MappingDataProcessor,
    CreateAttribute,
} from 'helpers/gmcPayloadProcessors';
import { operationStatuses } from 'config/operationStatuses';
import commonStyles from 'design/styles/common.module.css';
import styles from './Settings.module.css';

export class Settings extends Component {
    state = {
        gmcData: [],
        adsData: {
            paymentConfigured: false,
        },
        loading: false,
        isLoaderActive: false,
        pageSize: 2,
        isGmcDataEmpty: false,
        isAdsDataEmpty: false,
        yuiEmail: '—',
        googleEmail: '—',
        isSidebarOpened: false,
        isSaving: false,
        selectedRowItem: {},
        editType: '',
        isErrorNotificationActive: false,
        isSidebarErrorActive: false,
        sidebarErrorMessage: '',
        isResendingModalVisible: false,
        isResendingSuccessful: false,
    };

    static loadersStack = [];
    operationRequestsStack = [];

    static propTypes = {
        websiteConfig: PropTypes.object.isRequired,
        attributeMapping: PropTypes.object.isRequired,
        saveFieldValueToStore: PropTypes.func.isRequired,
        toggleEditMode: PropTypes.func.isRequired,
        resetChannelData: PropTypes.func.isRequired,
        saveTableDataToStore: PropTypes.func.isRequired,
        saveWebsiteStoresToStore: PropTypes.func.isRequired,
        saveActiveRadioButtonIdToStore: PropTypes.func.isRequired,
        saveActiveTaxRadioIdToStore: PropTypes.func.isRequired,
        saveSetupCostOnGmc: PropTypes.func.isRequired,
        saveOperateStatesToStore: PropTypes.func.isRequired,
        saveVatIncluded: PropTypes.func.isRequired,
        saveFlatRateTableDataToStore: PropTypes.func.isRequired,
        resetAttributesState: PropTypes.func.isRequired,
        saveAttributeToStore: PropTypes.func.isRequired,
        toggleMappingEditMode: PropTypes.func.isRequired,
        saveSpecificAttributeToStore: PropTypes.func.isRequired,
        saveSpecificAttributeOptionToStore: PropTypes.func.isRequired,
        clearEditType: PropTypes.func.isRequired,
        settings: PropTypes.shape({
            editType: PropTypes.string,
        }).isRequired,
        storedChannelId: PropTypes.string.isRequired,
    };

    editTypeMap = {
        url: {
            renderComponent: isSaving => (
                <UrlVerificationForm
                    isSaving={isSaving}
                    confirmSaving={this.confirmSaving}
                    rejectSaving={this.rejectSaving}
                />
            ),
            requestData: (item, editType) => {
                this.getChannelData(item, editType);
            },
            saveChanges: () => this.sendChannelData(),
            clearDataFromStore: this.props.resetChannelData,
        },
        shipping: {
            renderComponent: isSaving => (
                <ShippingAndTaxForm
                    isSaving={isSaving}
                    confirmSaving={this.confirmSaving}
                    rejectSaving={this.rejectSaving}
                />
            ),
            requestData: (item, editType) => {
                this.getChannelData(item, editType);
            },
            saveChanges: () => this.sendChannelData(),
            clearDataFromStore: this.props.resetChannelData,
        },
        mapping: {
            renderComponent: isSaving => (
                <AttributeMappingForm
                    isSaving={isSaving}
                    confirmSaving={this.confirmSaving}
                    rejectSaving={this.rejectSaving}
                />
            ),
            requestData: (item, editType) => {
                this.getMappingData(item, editType);
            },
            saveChanges: () => this.sendMappingData(),
            clearDataFromStore: this.props.resetAttributesState,
        },
    };

    async componentDidMount() {
        await Promise.all([this.fetchTableData(), this.fetchGoogleAdsData()]);

        this.processInitialEditing();
    }

    componentWillUnmount() {
        forEach(this.operationRequestsStack, operation =>
            operation.poller.stop(false),
        );
    }

    processInitialEditing = () => {
        const storedEditType = this.props.settings.editType;

        const rowItem = find(
            this.state.gmcData,
            item => item.channelId === this.props.storedChannelId,
        );

        if (!storedEditType || !rowItem) {
            return;
        }

        this.editTypeMap[storedEditType].requestData(rowItem, storedEditType);
        this.props.clearEditType();
    };

    showNotification = error => {
        const isSessionExpired = has(error, 'ajaxExpired');

        isSessionExpired
            ? redirectToYuiLogin()
            : this.setState({
                  isErrorNotificationActive: true,
              });
    };

    showSidebarNotification = error => {
        const isSessionExpired = has(error, 'ajaxExpired');

        isSessionExpired
            ? redirectToYuiLogin()
            : this.setState({
                  isSidebarErrorActive: true,
                  isSaving: false,
              });
    };

    fetchTableData = async () => {
        this.setState({ loading: true });
        this.showLoader();

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: 'channels',
            });

            const gmcData = compact(JSON.parse(response.body));

            this.setState({
                gmcData: gmcData || [],
                isGmcDataEmpty: isEmpty(gmcData),
                pageSize: !isEmpty(gmcData) ? gmcData.length : 2,
            });

            if (!isEmpty(gmcData)) {
                this.fetchEmails(gmcData[0].channelId);
            }
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.setState({ loading: false });
            this.hideLoader();
        }
    };

    fetchEmails = async channelId => {
        this.showLoader();
        try {
            const channelResponse = await yuiRequest({
                method: 'get',
                uri: `channels/${channelId}`,
            });

            const channelData = JSON.parse(channelResponse.body);

            this.setState({
                yuiEmail: channelData.yuiEmail || '—',
                googleEmail: channelData.email || '—',
            });
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    fetchGoogleAdsData = async () => {
        this.showLoader();

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: 'adwords/account',
            });

            const adsData = JSON.parse(response.body);

            this.setState({
                adsData: !isEmpty(adsData) ? adsData : {},
                isAdsDataEmpty: isEmpty(adsData),
            });
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    setPaymentConfiguredState = () => {
        this.setState(prevState => ({
            adsData: {
                ...prevState.adsData,
                paymentConfigured: !prevState.adsData.paymentConfigured,
            },
        }));
    };

    showLoader = () => {
        Settings.loadersStack.push('loading');
        this.setState({ isLoaderActive: true });
    };

    hideLoader = () => {
        const { loadersStack } = Settings;
        loadersStack.pop();

        if (isEmpty(loadersStack)) {
            this.setState({
                isLoaderActive: false,
            });
        }
    };

    mapTableData = partialRight(map, item => {
        if (!item) {
            return;
        }

        const { baseUrl, channelName, merchantId } = item;

        return {
            baseUrl,
            channelName,
            merchantId,
            actions: item,
        };
    });

    renderNavControls = () => {
        const backButtonStyles = classnames([
            styles.link,
            styles.arrow,
            styles['back-button'],
        ]);

        return (
            <Link to="/" className={backButtonStyles}>
                <FormattedMessage id="settings.backButtonText" />
            </Link>
        );
    };

    renderSidebarNavControls = () => {
        const {
            websiteStoreViews: { tableData },
            storeName,
        } = this.props.websiteConfig.new.urlVerification;
        const isSaveButtonDisabled = storeName.value && isEmpty(tableData);
        const cancelButtonStyles = classnames([
            styles.link,
            styles['cancel-button'],
        ]);
        const saveAdButtonStyles = classnames([
            styles.link,
            styles['save-button'],
            isSaveButtonDisabled && styles['save-button_disabled'],
        ]);

        return (
            <div className={styles['nav-container']}>
                <button
                    className={cancelButtonStyles}
                    onClick={this.handleCloseSidebar}
                >
                    <FormattedMessage id="settings.sidebar.cancelButtonText" />
                </button>
                <button
                    className={saveAdButtonStyles}
                    onClick={this.handleSaveClick}
                >
                    <FormattedMessage id="settings.sidebar.saveButtonText" />
                </button>
            </div>
        );
    };

    getChannelData = async (rowItem, editType) => {
        this.showLoader();

        try {
            const channelResponse = await yuiRequest({
                method: 'get',
                uri: `channels/${rowItem.channelId}`,
            });

            const channelData = JSON.parse(channelResponse.body);

            const mappedUrlData = mapUrlVerificationData(channelData);
            const mappedShippingAndTaxData = mapShippingAndTaxResponseData(
                channelData,
                mappedUrlData.tableData,
            );

            if (!isEmpty(mappedUrlData) && !isEmpty(mappedShippingAndTaxData)) {
                this.setUrlVerificationDataToStore(mappedUrlData);
                this.setShippingAndTaxDataToStore(mappedShippingAndTaxData);
            }

            this.setState({
                selectedRowItem: rowItem,
                editType,
                isSidebarOpened: true,
            });
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    getMappingData = async (rowItem, editType) => {
        this.showLoader();

        try {
            const mappingResponse = await yuiRequest({
                method: 'get',
                uri: `channels/${rowItem.channelId}/feed/attributeMapping`,
            });

            const mappingData = JSON.parse(mappingResponse.body);

            const mappedAttributesData = mapAttributeMappingResponseData(
                mappingData,
            );

            if (!isEmpty(mappedAttributesData)) {
                this.setMappingDataToStore(mappedAttributesData);
            }

            this.setState({
                selectedRowItem: rowItem,
                editType,
                isSidebarOpened: true,
            });
        } catch (error) {
            this.showNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    setUrlVerificationDataToStore = data => {
        this.props.toggleEditMode(true);
        this.props.saveFieldValueToStore(data.websiteUrl, 'websiteUrl');
        this.props.saveFieldValueToStore(data.storeName, 'storeName');
        this.props.saveTableDataToStore(data.tableData);
        this.props.saveWebsiteStoresToStore(data.stores);
    };

    setShippingAndTaxDataToStore = data => {
        const {
            shippingCost: {
                activeRadioButtonId: shippingCostRadioId,
                radioButtons: { flatRateCost, setCostOnGmc },
            },
            shippingTax: {
                activeRadioButtonId: shippingTaxRadioId,
                radioButtons: { autoTax, vatIncluded },
            },
        } = data;

        this.props.saveActiveRadioButtonIdToStore(shippingCostRadioId);
        this.props.saveActiveTaxRadioIdToStore(shippingTaxRadioId);
        setCostOnGmc.value && this.props.saveSetupCostOnGmc();
        vatIncluded.value && this.props.saveVatIncluded();
        this.props.saveOperateStatesToStore(autoTax.value, autoTax.isValid);
        this.props.saveFlatRateTableDataToStore(flatRateCost.tableData);
    };

    setMappingDataToStore = data => {
        this.props.toggleMappingEditMode(true);

        const specificAttributes = ['condition', 'ageGroup', 'gender'];
        const specificAttributesMap = {
            condition: ['new', 'refurbished', 'used'],
            ageGroup: ['adult', 'infant', 'kids', 'newborn', 'toddler'],
            gender: ['female', 'male', 'unisex'],
        };

        forEach(data, (value, key) => {
            if (includes(specificAttributes, key)) {
                if (!isEmpty(value.data)) {
                    this.props.saveAttributeToStore(key, value.data);
                    return;
                }

                forEach(specificAttributesMap[key], option => {
                    if (!isEmpty(value[option])) {
                        this.props.saveSpecificAttributeOptionToStore(
                            key,
                            option,
                            value[option].data,
                        );
                    }
                });
                this.props.saveSpecificAttributeToStore(
                    key,
                    value.specificData,
                );

                return;
            }

            this.props.saveAttributeToStore(key, value.data);
        });
    };

    makeOperationRequest = config => {
        this.showLoader();
        this.operationRequestsStack.push(new OperationRequest(config));
    };

    operationRequestFailureHandler = error => {
        this.setState({
            isSidebarErrorActive: true,
            sidebarErrorMessage: get(error, 'comments', ''),
            isSaving: false,
        });
        this.hideLoader();
    };

    operationRequestErrorHandler = error => {
        this.showSidebarNotification(error);
        this.hideLoader();
    };

    sendChannelData = async () => {
        this.showLoader();

        const channelDataProcessor = new ChannelDataProcessor();
        const { channelId } = this.state.selectedRowItem;
        const storeData = this.props.websiteConfig.new;

        const {
            channelAttrUpdateSuccess,
            channelAttrUpdateFail,
        } = operationStatuses;

        const payload = {
            name: storeData.urlVerification.storeName.value,
            storeSettings: channelDataProcessor.getStoreSettings(storeData),
            shipmentSettings: channelDataProcessor.getShippingCostSettings(
                storeData,
            ),
            taxSettings: channelDataProcessor.getShippingTaxSettings(storeData),
        };

        try {
            const response = await yuiRequest({
                method: 'post',
                uri: `channels/${channelId}`,
                payload,
            });

            const operationId = get(
                JSON.parse(response.body),
                'operationId',
                '',
            );

            this.makeOperationRequest({
                operationId,
                delay: 2000,
                successStatusHandler: () => {
                    this.handleCloseSidebar();
                    this.hideLoader();
                    this.state.editType === 'url' && this.fetchTableData();
                    this.indexerInvalidationRequest();
                },
                failureStatusHandler: this.operationRequestFailureHandler,
                requestErrorHandler: this.operationRequestErrorHandler,
                successStatus: channelAttrUpdateSuccess,
                failureStatuses: [channelAttrUpdateFail],
            });
        } catch (error) {
            this.showSidebarNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    sendMappingData = async () => {
        this.showLoader();

        const createAttribute = new CreateAttribute();
        const mappingDataProcessor = new MappingDataProcessor();
        const { channelId } = this.state.selectedRowItem;
        const { attributes } = this.props.attributeMapping;

        const {
            attributesStoringSuccess,
            attributesStoringFail,
        } = operationStatuses;

        attributes['categoryAttrRadioId'].data === 'newYuiCategoryAttr' &&
            (await createAttribute.createCategoriesAttribute());

        try {
            const response = await yuiRequest({
                method: 'post',
                uri: `channels/${channelId}/feed/attributeMapping`,
                payload: {
                    mapping: mappingDataProcessor.createPayload(attributes)
                        .mappings,
                },
            });

            const operationId = get(
                JSON.parse(response.body),
                'operationId',
                '',
            );

            this.makeOperationRequest({
                operationId,
                delay: 2000,
                successStatusHandler: () => {
                    this.handleCloseSidebar();
                    this.hideLoader();
                    this.indexerInvalidationRequest();
                },
                failureStatusHandler: this.operationRequestFailureHandler,
                requestErrorHandler: this.operationRequestErrorHandler,
                successStatus: attributesStoringSuccess,
                failureStatuses: [attributesStoringFail],
            });
        } catch (error) {
            this.showSidebarNotification(error);
        } finally {
            this.hideLoader();
        }
    };

    indexerInvalidationRequest = async () => {
        const { invalidateIndexesUrl } = window.googleAppConfig;
        this.showLoader();

        try {
            const response = await fetch(invalidateIndexesUrl, {
                method: 'get',
            });
            response.ok
                ? localStorage.removeItem('indexerInvalidationError')
                : localStorage.setItem('indexerInvalidationError', 'true');
        } catch (e) {
            localStorage.setItem('indexerInvalidationError', 'true');
        } finally {
            this.hideLoader();
        }
    };

    handleCloseSidebar = () => {
        const { editType } = this.state;
        this.editTypeMap[editType].clearDataFromStore();
        this.setState({
            isSidebarOpened: false,
            isSaving: false,
            isSidebarErrorActive: false,
        });
    };

    handleCloseResendingModal = () =>
        this.setState({ isResendingModalVisible: false });

    resendingProductsAction = async () => {
        const lastSendingTime = parseInt(
            localStorage.getItem('resendingProductsTimestamp'),
        );
        const currentSendingTime = Date.now();
        const deltaTime = Math.abs(
            Math.floor((currentSendingTime - lastSendingTime) / 3600000),
        );
        const shouldResendProducts = !lastSendingTime || deltaTime >= 24;

        if (shouldResendProducts) {
            await this.indexerInvalidationRequest();
            localStorage.setItem(
                'resendingProductsTimestamp',
                currentSendingTime.toString(),
            );
        }

        this.setState({
            isResendingModalVisible: true,
            isResendingSuccessful: shouldResendProducts,
        });
    };

    getActions = rowItem => {
        const actions = map(this.editTypeMap, (item, key) => ({
            text: <FormattedMessage id={`settings.editActions.${key}`} />,
            action: () => this.editTypeMap[key].requestData(rowItem, key),
            active: true,
        }));

        actions.push({
            text: <FormattedMessage id="settings.editActions.resending" />,
            action: this.resendingProductsAction,
            active: true,
        });

        return actions;
    };

    renderNoDataBlock = () => (
        <span className={styles['gmc-no-data']}>
            <FormattedMessage id="settings.noData" />
        </span>
    );

    handleSaveClick = () => {
        this.setState({
            isSaving: true,
        });
    };

    confirmSaving = () => {
        const { editType } = this.state;

        this.editTypeMap[editType].saveChanges();
    };

    rejectSaving = () => {
        this.setState({
            isSaving: false,
        });
    };

    render() {
        const {
            gmcData,
            adsData,
            pageSize,
            loading,
            isGmcDataEmpty,
            isAdsDataEmpty,
            yuiEmail,
            googleEmail,
            isSidebarOpened,
            isSaving,
            selectedRowItem,
            editType,
            isErrorNotificationActive,
            isSidebarErrorActive,
            sidebarErrorMessage,
            isResendingModalVisible,
            isResendingSuccessful,
        } = this.state;

        const columns = [
            {
                Header: 'Site URL',
                accessor: 'baseUrl',
                maxWidth: 214,
            },
            {
                Header: 'Google Store Name',
                accessor: 'channelName',
            },
            {
                Header: 'Google Merchant Center Accounts ID',
                accessor: 'merchantId',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                maxWidth: 140,
                Cell: row => (
                    <div className={styles['action-container']}>
                        <ActionSelect options={this.getActions(row.value)} />
                    </div>
                ),
                sortable: false,
            },
        ];

        const gmcBlockClasses = classnames([
            styles['gmc-block'],
            styles.section,
        ]);
        const header2Classes = classnames([
            commonStyles.header2,
            styles.header2,
        ]);
        const emailFieldClasses = classnames([
            commonStyles['font-medium'],
            styles['email-field'],
        ]);
        const sidebarHeaderClasses = classnames([
            header2Classes,
            commonStyles['font-semibold'],
            styles['sidebar-header'],
        ]);
        const versionInfoRowClasses = classnames([
            commonStyles['font-medium'],
            styles['version-info-row'],
        ]);

        return (
            <React.Fragment>
                <Loader isVisible={this.state.isLoaderActive} />
                <FixedHeader right={this.renderNavControls} />
                <Notification
                    type="error"
                    defaultMessageType="requestError"
                    isActive={isErrorNotificationActive}
                />
                <div className={gmcBlockClasses}>
                    <h2 className={header2Classes}>
                        <FormattedMessage id="settings.gmcHeader" />
                    </h2>
                    {isGmcDataEmpty && !loading ? (
                        this.renderNoDataBlock()
                    ) : (
                        <Table
                            data={this.mapTableData(gmcData)}
                            {...{
                                columns,
                                pageSize,
                                loading,
                            }}
                            resizable={false}
                            showCounter={false}
                            showPaginationTop={false}
                        />
                    )}
                </div>
                <div className={styles.section}>
                    <h2 className={header2Classes}>
                        <FormattedMessage id="settings.googleAdsHeader" />
                    </h2>
                    {!isAdsDataEmpty ? (
                        <GoogleAds
                            data={adsData}
                            setPaymentConfiguredState={
                                this.setPaymentConfiguredState
                            }
                            showLoader={this.showLoader}
                            hideLoader={this.hideLoader}
                            showNotification={this.showNotification}
                        />
                    ) : (
                        this.renderNoDataBlock()
                    )}
                </div>
                <div className={styles.section}>
                    <h2 className={header2Classes}>
                        <FormattedMessage id="settings.linkedAccHeader" />
                    </h2>
                    {/* <div className={styles['email-block']}>
                        <h3 className={styles.header3}>
                            <FormattedMessage id="settings.linkedAcc.yui" />
                        </h3>
                        <div className={emailFieldClasses}>
                            <FormattedMessage id="settings.linkedAcc.emailField" />
                            {yuiEmail}
                        </div>
                    </div> */}
                    <div className={styles['email-block']}>
                        <h3 className={styles.header3}>
                            <FormattedMessage id="settings.linkedAcc.google" />
                        </h3>
                        <div className={emailFieldClasses}>
                            <FormattedMessage id="settings.linkedAcc.emailField" />
                            {googleEmail}
                        </div>
                    </div>
                </div>
                <div className={styles.section}>
                    <h2 className={header2Classes}>
                        <FormattedMessage id="settings.versionInfo.header" />
                    </h2>
                    <div className={versionInfoRowClasses}>
                        <span className={styles['field-name']}>
                            <FormattedMessage id="settings.versionInfo.fieldName" />
                        </span>
                        {window.googleAppConfig.metadata.moduleVersion}
                    </div>
                </div>
                <Sidebar
                    isActive={isSidebarOpened}
                    position="right"
                    onOverlayClick={this.handleCloseSidebar}
                >
                    <div className={styles['sidebar-content']}>
                        <h2 className={sidebarHeaderClasses}>
                            <FormattedMessage id="settings.sidebar.header" />
                            {`${selectedRowItem.baseUrl} | ID ${
                                selectedRowItem.merchantId
                            } : `}
                            <FormattedMessage
                                id={`settings.editActions.${editType}`}
                            />
                        </h2>
                        <FixedHeader right={this.renderSidebarNavControls} />
                        <Notification
                            type="error"
                            defaultMessageType="requestError"
                            isActive={isSidebarErrorActive}
                        >
                            {sidebarErrorMessage}
                        </Notification>
                        {editType &&
                            this.editTypeMap[editType].renderComponent(
                                isSaving,
                            )}
                    </div>
                </Sidebar>
                <ResendingProductsModal
                    isVisible={isResendingModalVisible}
                    onClose={this.handleCloseResendingModal}
                    isSuccess={isResendingSuccessful}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    websiteConfig: state.websiteConfig,
    attributeMapping: state.attributeMapping,
    settings: state.settings,
    storedChannelId: state.adsCreation.channelId,
});

export default connect(
    mapStateToProps,
    {
        clearEditType,
        ...allActionCreators,
    },
)(Settings);
