import { ActionCreator } from 'redux';

import {
    ISaveTableDataToStoreAction,
    ITableData,
    IUnsetStoreViewRequiredErrorAction,
} from '../../../../reducers/websiteConfigReducer';
import {
    SAVE_TABLE_DATA_TO_STORE,
    UNSET_STORE_VIEW_REQUIRED_ERROR,
} from '../../../../store/actionConsts';

export const saveTableDataToStore: ActionCreator<
    ISaveTableDataToStoreAction
> = (tableData: ITableData) => ({
    payload: {
        tableData,
    },
    type: SAVE_TABLE_DATA_TO_STORE,
});

export const hideStoreViewRequiredError: ActionCreator<
    IUnsetStoreViewRequiredErrorAction
> = () => ({
    type: UNSET_STORE_VIEW_REQUIRED_ERROR,
});
