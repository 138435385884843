import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import { FormattedMessage } from 'react-intl';
import { get, pickBy } from 'lodash';
import { countryOfSales } from 'config/countryOfSales';

import ConfigurableSelect from 'component/form/Select';
import customSelectStyles from '../../StoreViewSelect.style';

export class CountryField extends Component {
    static propTypes = {
        tableData: PropTypes.array.isRequired,
        data: PropTypes.object.isRequired,
        saveCountryDataToStore: PropTypes.func.isRequired,
        resetStoreViewLanguageInStore: PropTypes.func.isRequired,
        hideStoreViewRequiredError: PropTypes.func.isRequired,
        hideStoreViewsLengthError: PropTypes.func.isRequired,
        isEdit: PropTypes.bool.isRequired,
    };
    state = {
        options: [],
        selectedOption: [],
        isPrefilled: false,
    };

    componentDidMount() {
        this.prefillSelectedOption(countryOfSales);
    }

    componentDidUpdate(prevProps) {
        const { country, uniqueRowId } = this.props.data;
        const prevCountry = prevProps.data.country;
        const languageResetPayload = {
            value: '',
            label: '',
            uniqueRowId,
        };

        if (prevCountry.value !== country.value) {
            this.props.resetStoreViewLanguageInStore(languageResetPayload);
        }
    }

    prefillSelectedOption(options) {
        const selectedOption = get(this.props.data, 'country') || [];
        const country = get(selectedOption, 'value') || '';

        this.setState(
            pickBy({
                options,
                selectedOption: country && selectedOption,
                isPrefilled: !!country,
            }),
        );
    }

    selectChangeHandler = selectedOption => {
        const value = get(selectedOption, 'value') || '';
        const label = get(selectedOption, 'label') || '';
        const languages = get(selectedOption, 'languages') || [];
        const { uniqueRowId } = this.props.data;
        const payload = {
            value,
            label,
            languages,
            uniqueRowId,
        };

        this.setState({
            selectedOption,
            isPrefilled: false,
        });
        this.props.saveCountryDataToStore(payload);
        this.props.hideStoreViewRequiredError();
        this.props.hideStoreViewsLengthError();
    };

    render() {
        const {
            selectChangeHandler,
            state: { options, selectedOption, isPrefilled },
            props: { isEdit },
        } = this;

        return (
            <div>
                <ConfigurableSelect
                    isDisabled={isEdit && isPrefilled}
                    styles={customSelectStyles}
                    options={options}
                    onChange={selectChangeHandler}
                    value={selectedOption}
                    {...{
                        isMulti: false,
                        isSearchable: true,
                        blurInputOnSelect: true,
                        name: 'storeViewCountry',
                        placeholder: (
                            <FormattedMessage id="urlVerification.storeViewsTable.selectPlaceholder" />
                        ),
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tableData:
        state.websiteConfig.new.urlVerification.websiteStoreViews.tableData,
    isEdit: state.websiteConfig.new.isEdit,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(CountryField);
