import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import { classnames } from '../../util';

import { ConfigurableSelect } from 'component/form';

import styles from './AttributeOptionsMappingForm.module.css';
import commonStyles from 'design/styles/common.module.css';
import customSelectStyles from './select.styles';

export class AttributeOptionsMappingForm extends Component {
    static propTypes = {
        attributeName: PropTypes.string.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        showError: PropTypes.bool.isRequired,
        googleColumnHeader: PropTypes.object.isRequired,
        yuiColumnHeader: PropTypes.object.isRequired,
        attributes: PropTypes.object.isRequired,
        rows: PropTypes.array.isRequired,
        handleChange: PropTypes.func.isRequired,
    };

    renderItems = () => {
        const {
            attributeName,
            handleChange,
            attributes,
            isDisabled,
            rows,
        } = this.props;
        const { specificOptions } = attributes[attributeName];

        return map(rows, (row, index) => (
            <tr key={index}>
                <td className={styles['options-mapping-form-data-grid-td']}>
                    <p>{row.googleAttributeLabel}</p>
                </td>
                <td className={styles['options-mapping-form-data-grid-td']}>
                    <ConfigurableSelect
                        styles={customSelectStyles}
                        onChange={option =>
                            handleChange(row.specificAttributeName, option)
                        }
                        value={
                            attributes[attributeName][row.specificAttributeName]
                                .data
                        }
                        options={specificOptions}
                        {...{
                            isMulti: true,
                            isSearchable: true,
                            placeholder: row.placeholder,
                            isDisabled,
                        }}
                    />
                </td>
            </tr>
        ));
    };

    render() {
        const {
            isDisabled,
            showError,
            googleColumnHeader,
            yuiColumnHeader,
            errorMessage,
        } = this.props;

        const tableStyles = classnames([
            commonStyles.table,
            styles['options-mapping-form'],
            !isDisabled || styles['options-mapping-form-disabled'],
        ]);
        const tableHeaderStyles = classnames([
            commonStyles['data-grid-th'],
            commonStyles['font-small'],
            commonStyles['font-semibold'],
        ]);
        const bottomError = classnames([
            commonStyles['bottom-error'],
            styles['bottom-error'],
        ]);

        return (
            <React.Fragment>
                <table className={tableStyles}>
                    <thead>
                        <tr>
                            <th className={tableHeaderStyles}>
                                {googleColumnHeader}
                            </th>
                            <th className={tableHeaderStyles}>
                                {yuiColumnHeader}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.renderItems()}</tbody>
                </table>
                {showError && (
                    <span className={bottomError}>{errorMessage}</span>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    attributes: state.attributeMapping.attributes,
});

export default connect(mapStateToProps)(AttributeOptionsMappingForm);
