import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { find, get } from 'lodash';
import currencies from 'config/currencies';
import { yuiRequest } from 'api';

import { classnames } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './StatusBar.module.css';

export default class StatusBar extends Component {
    state = {
        symbol: '$',
        cost: 0,
        roas: 0,
        conversionValue: 0,
        conversions: 0,
    };

    componentDidUpdate = prevProps => {
        const { shouldUpdateStatusBar } = this.props;
        if (
            prevProps.shouldUpdateStatusBar !== shouldUpdateStatusBar &&
            shouldUpdateStatusBar
        ) {
            this.requestStatusesData();
        }
    };

    setStatusesData(bodyData) {
        const parsedData = JSON.parse(bodyData);
        const currency = find(
            currencies,
            currency => currency.code === parsedData.accountCurrencyCode,
        );

        this.setState({
            ...parsedData,
            symbol: currency.symbol || currency.code,
        });
    }

    requestStatusesData = async () => {
        this.props.showLoader();

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: `adwords/advertisingpage/statusbar`,
            });

            this.setStatusesData(response.body);
        } catch (error) {
            this.props.showNotification(error);
        } finally {
            this.props.hideLoader();
            this.props.toggleUpdateStatusBar();
        }
    };

    render() {
        const currentTime = new Date();
        const prevTime = new Date();
        prevTime.setDate(prevTime.getDate() - 14);
        currentTime.setDate(currentTime.getDate() - 1);
        const dateFrom = prevTime.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
        });
        const dateTo = currentTime.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
        const statusValueStyles = classnames([
            styles['status-item'],
            styles['status-value'],
        ]);
        const statusNameStyles = classnames([
            styles['status-item'],
            commonStyles['font-medium'],
        ]);
        const {
            state: { symbol, cost, roas, conversionValue, conversions },
        } = this;
        const reportLink = get(
            window.googleAppConfig,
            'biEssentialsUrl',
            'about:blank',
        );

        return (
            <div>
                <h2 className={styles.header}>
                    <FormattedMessage id="dashboardLanding.statusBar.header" />
                </h2>
                <div>
                    <div className={styles['status-period']}>
                        <FormattedMessage id="dashboardLanding.statusBar.period" />
                        {`: ${dateFrom} - ${dateTo}`}
                    </div>
                    <div className={styles['status-bar']}>
                        <div className={styles['status-item-container']}>
                            <span className={statusValueStyles}>
                                {`${symbol}${cost}`}
                            </span>
                            <span className={statusNameStyles}>
                                <FormattedMessage id="dashboardLanding.statusBar.cost" />
                            </span>
                        </div>
                        <div className={styles['status-item-container']}>
                            <span className={statusValueStyles}>
                                {`${symbol}${roas}`}
                            </span>
                            <span className={statusNameStyles}>
                                <FormattedMessage id="dashboardLanding.statusBar.returnOnAdSpend" />
                            </span>
                        </div>
                        <div className={styles['status-item-container']}>
                            <span className={statusValueStyles}>
                                {`${symbol}${conversionValue}`}
                            </span>
                            <span className={statusNameStyles}>
                                <FormattedMessage id="dashboardLanding.statusBar.conversionValue" />
                            </span>
                        </div>
                        <div className={styles['status-item-container']}>
                            <span className={statusValueStyles}>
                                {conversions}
                            </span>
                            <span className={statusNameStyles}>
                                <FormattedMessage id="dashboardLanding.statusBar.conversions" />
                            </span>
                        </div>
                        <div className={styles['reports-link-container']}>
                            <span
                                className={
                                    styles['reports-link-container-header']
                                }
                            >
                                <FormattedMessage id="dashboardLanding.statusBar.reportingLinks.header" />
                            </span>
                            <span>
                                <FormattedMessage
                                    id="dashboardLanding.statusBar.reportingLinks.text_1"
                                    values={{
                                        signUpLink: (
                                            <a
                                                href={reportLink}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                <FormattedMessage id="dashboardLanding.statusBar.reportingLinks.signUpLink" />
                                            </a>
                                        ),
                                    }}
                                />
                            </span>
                            <span>
                                <FormattedMessage
                                    id="dashboardLanding.statusBar.reportingLinks.text_2"
                                    values={{
                                        requestReportsLink: (
                                            <a
                                                href="https://support.yui.sk/hc/en-us"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                <FormattedMessage id="dashboardLanding.statusBar.reportingLinks.requestReportsLink" />
                                            </a>
                                        ),
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
