import { isString } from 'lodash';

export const toLocalTime = (
    value: string,
    locales: string | string[] = 'en-US',
    options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        month: '2-digit',
        year: 'numeric',
    },
) => {
    if (!isString(value) || isNaN(Date.parse(value))) {
        return '';
    }

    return new Date(value).toLocaleString(locales, options);
};
