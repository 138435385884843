import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as AllActionCreators from './actions';
import { FormattedMessage } from 'react-intl';
import { get, map, uniqBy, find, noop } from 'lodash';
import { countryOfSales } from 'config/countryOfSales';

import { classnames } from '../../../../../../../../util';

import FlatRateRow from './components';
import commonStyles from 'design/styles/common.module.css';
import styles from './FlatRateTable.module.css';

export class FlatRateTable extends Component {
    static propTypes = {
        isRadioChecked: PropTypes.bool.isRequired,
        flatRateFieldError: PropTypes.bool.isRequired,
        saveFlatRateTableDataToStore: PropTypes.func.isRequired,
        removeShippingCostFlatRateError: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.createRows()
            .then(payload => {
                this.props.saveFlatRateTableDataToStore(payload);
            })
            .catch(noop);
    }

    async createRows() {
        const {
            urlVerification: { websiteStoreViews },
            flatRateCost: { tableData: flatRateTableData },
        } = this.props;
        const storeViewsTableData = get(websiteStoreViews, 'tableData') || [];

        return await [
            ...uniqBy(
                map(storeViewsTableData, item => ({
                    uniqueRowId: item.uniqueRowId,
                    countryName: item.country.label,
                    countryCode: item.country.value,
                    currencyCode: find(
                        countryOfSales,
                        salesCountry =>
                            salesCountry.value === item.country.value,
                    ).currencyValue,
                    rate:
                        get(
                            find(
                                flatRateTableData,
                                row =>
                                    row.uniqueRowId === item.uniqueRowId &&
                                    row.countryCode === item.country.value,
                            ),
                            'rate',
                        ) || '',
                })),
                'countryName',
            ),
        ];
    }

    render() {
        const {
            props: {
                isRadioChecked,
                flatRateCost: { tableData },
                flatRateFieldError,
            },
        } = this;
        const tableStyles = classnames([
            commonStyles.table,
            commonStyles['data-grid'],
            styles['flat-rate-table'],
            isRadioChecked || styles['flat-rate-table-disabled'],
        ]);
        const gridStyles = classnames([
            commonStyles['data-grid-th'],
            styles['data-grid-th'],
        ]);

        return (
            <div className={styles['flat-rate-table-wrapper']}>
                <table className={tableStyles}>
                    <thead>
                        <tr>
                            <th className={gridStyles}>
                                <FormattedMessage id="shippingAndTax.rateTable.countryHeader" />
                            </th>
                            <th className={gridStyles}>
                                <FormattedMessage id="shippingAndTax.rateTable.rateHeader" />
                                <br />
                                <FormattedMessage id="shippingAndTax.rateTable.rateHeaderDescription" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {map(tableData, row => (
                            <FlatRateRow
                                key={row.uniqueRowId}
                                data={row}
                                isRadioChecked={isRadioChecked}
                                removeShippingCostFlatRateError={
                                    this.props.removeShippingCostFlatRateError
                                }
                            />
                        ))}
                    </tbody>
                </table>
                <FormattedMessage
                    id="shippingAndTax.cost.freeShipping"
                    defaultMessage="To set up free shipping, enter 0 for the flat rate"
                />
                <div className={styles['error-container']}>
                    {flatRateFieldError && (
                        <span className={commonStyles['bottom-error']}>
                            <FormattedMessage id="shippingAndTax.shippingCostInput.error" />
                        </span>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    urlVerification: state.websiteConfig.new.urlVerification,
    flatRateCost:
        state.websiteConfig.new.shippingCost.radioButtons.flatRateCost,
});

export default connect(
    mapStateToProps,
    AllActionCreators,
)(FlatRateTable);
