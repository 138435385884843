import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as AllActionCreators from './actions';
import { compact, get, find, map, isEmpty, partialRight } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as PendingIcon } from 'design/assets/pending.svg';
import { ReactComponent as EndedIcon } from 'design/assets/ended.svg';
import { ReactComponent as EnabledIcon } from 'design/assets/approved.svg';

import Table from 'component/table';
import Modal from 'component/modal';
import ModalContent from 'component/modalContent';
import ActionSelect from 'component/actionSelect';

import currencies from 'config/currencies';

import styles from './AdCampaignTable.module.css';

import { toLocalTime } from 'helpers/toLocalTime';

import { yuiRequest } from 'api';

const ACTIONS = {
    edit: 'edit',
    enable: 'enable',
    pause: 'pause',
    end: 'end',
};

const STATUSES = {
    removed: 'removed',
    paused: 'paused',
    enabled: 'enabled',
};

export class AdCampaignTable extends Component {
    static propTypes = {
        setPaymentMethodStatus: PropTypes.func.isRequired,
        saveAllCampaignNamesToStore: PropTypes.func.isRequired,
        editCampaignHandler: PropTypes.func.isRequired,
        shouldUpdateTable: PropTypes.bool.isRequired,
        toggleUpdateCampaignTable: PropTypes.func.isRequired,
        showLoader: PropTypes.func.isRequired,
        hideLoader: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
    };
    state = {
        data: [],
        campaignsUpdatedAt: '—',
        campaignNames: [],
        pageSize: 2,
        loading: false,
        currencyCode: 'USD',
        currencySymbol: '$',
        isModalVisible: false,
        currActionData: {},
    };

    componentDidUpdate = prevProps => {
        const { shouldUpdateTable } = this.props;
        if (
            prevProps.shouldUpdateTable !== shouldUpdateTable &&
            shouldUpdateTable
        ) {
            this.fetchData();
        }
    };

    fetchData = async () => {
        this.setState({ loading: true });
        this.props.showLoader();

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: 'adwords/advertisingpage/campaigntable',
            });

            const responseData = JSON.parse(response.body);
            const data = compact(get(responseData, 'campaigns', []));
            const campaignsUpdatedAt =
                toLocalTime(get(responseData, 'campaignsUpdatedAt', '')) || '—';

            const campaignNames = map(data, 'name');
            const campaignNamesPayload = {
                allCampaignNames: campaignNames,
            };

            this.props.saveAllCampaignNamesToStore(campaignNamesPayload);

            const code = get(
                window.googleAppConfig.adConfig,
                'currency',
                'USD',
            );
            const currency = find(currencies, { code });

            this.setState({
                data: data || [],
                campaignsUpdatedAt,
                campaignNames,
                pageSize: !isEmpty(data)
                    ? data.length < 10
                        ? data.length
                        : 10
                    : 2,
                currencyCode: currency.code,
                currencySymbol: currency.symbol,
                loading: false,
            });
        } catch (error) {
            this.props.showNotification(error);
            this.setState({ loading: false });
        } finally {
            this.props.hideLoader();
            this.props.toggleUpdateCampaignTable();
        }
    };

    async componentDidMount() {
        const tableBodyNode = document.querySelector(
            '.ad-campaign-table .rt-table',
        );

        tableBodyNode &&
            tableBodyNode.addEventListener('scroll', this.moveActionSelect);
    }

    mapData = partialRight(map, item => {
        if (!item) {
            return;
        }

        const status = item.status.toLowerCase();
        const date = compact(item.launchDate.split('-'));

        return {
            name: item.name,
            gmcId: item.gmcId,
            countOfProducts: item.countOfProducts,
            dailyBudget: item.dailyBudget,
            cost: item.cost,
            clicks: item.clicks,
            conversions: item.conversions,
            conversionValue: item.conversionValue,
            launchDate: !isEmpty(date)
                ? `${date[1]}/${date[2]}/${date[0]}`
                : '—',
            status,
            action: {
                id: item.campaignId,
                status,
                channelId: item.channelId,
            },
        };
    });

    openModal = () =>
        this.setState({
            isModalVisible: true,
        });

    closeModal = () =>
        this.setState({
            isModalVisible: false,
        });

    handleActionSelect = currActionData => {
        this.setState({ currActionData });
        this.openModal();
    };

    getActions = action => {
        const { id, channelId, status } = action;

        const activeMap = {
            [ACTIONS.edit]: status !== STATUSES.removed,
            [ACTIONS.end]: status !== STATUSES.removed,
            [ACTIONS.enable]: status === STATUSES.paused,
            [ACTIONS.pause]: status === STATUSES.enabled,
        };

        return map(ACTIONS, action => ({
            text: (
                <FormattedMessage
                    id={`dashboardLanding.actionSelect.${action}`}
                />
            ),
            action:
                action === ACTIONS.edit
                    ? () =>
                          this.props.editCampaignHandler({
                              id,
                              channelId,
                              status,
                          })
                    : () => this.handleActionSelect({ id, action, channelId }),
            active: activeMap[action],
        }));
    };

    handleConfirmModal = async () => {
        this.setState({
            loading: true,
            isModalVisible: false,
        });

        const {
            data,
            currActionData: { id, action, channelId },
        } = this.state;

        const statusMap = {
            [ACTIONS.end]: STATUSES.removed.toUpperCase(),
            [ACTIONS.enable]: STATUSES.enabled.toUpperCase(),
            [ACTIONS.pause]: STATUSES.paused.toUpperCase(),
        };

        const payload = {
            edit: false,
            campaignId: id,
            status: statusMap[action],
            name: '',
            budget: '',
            currencyCode: '',
            filterType: '',
            filters: [],
            skuSelectedAll: '',
            productList: [],
        };

        this.props.showLoader();

        try {
            const response = await yuiRequest({
                method: 'put',
                uri: `channels/${channelId}/campaigns`,
                payload,
            });

            const responseData = JSON.parse(response.body);

            if (!responseData) {
                this.setState({ loading: false });
                return;
            }

            const { campaignId, campaignStatus, launchDate } = responseData;

            const newData = [...data];

            newData.forEach(item => {
                if (item.campaignId.toString() !== campaignId) {
                    return;
                }

                item.status = campaignStatus.toLowerCase();
                item.launchDate = launchDate;
            });

            this.setState({
                data: newData,
                loading: false,
            });
        } catch (error) {
            this.props.showNotification(error);
            this.setState({ loading: false });
        } finally {
            this.props.hideLoader();
        }
    };

    moveActionSelect = () => {
        const actionCellNode = document.querySelector(
            '.ReactTable .rt-tbody .rt-td:last-child',
        );
        const actionSelectNodeList = document.querySelectorAll(
            `.${styles['action-container']}`,
        );

        const value = `${actionCellNode.getBoundingClientRect().x - 108}px`;

        actionSelectNodeList &&
            actionSelectNodeList.forEach(item => (item.style.left = value));
    };

    renderSvgIcon = status => {
        if (status === 'enabled') return <EnabledIcon />;
        if (status === 'paused') return <PendingIcon />;
        if (status === 'removed') return <EndedIcon />;
        return null;
    };

    render() {
        const {
            data,
            campaignsUpdatedAt,
            pageSize,
            loading,
            currencySymbol,
            isModalVisible,
            currActionData: { action },
        } = this.state;

        const columns = [
            {
                Header: 'Campaign Name',
                accessor: 'name',
            },
            {
                Header: 'GMC ID',
                accessor: 'gmcId',
            },
            {
                Header: 'Products',
                accessor: 'countOfProducts',
            },
            {
                Header: 'Daily Budget',
                accessor: 'dailyBudget',
                Cell: row => `${currencySymbol}${row.value}`,
            },
            {
                Header: 'Cost',
                accessor: 'cost',
                Cell: row => `${currencySymbol}${row.value}`,
            },
            {
                Header: 'Clicks',
                accessor: 'clicks',
            },
            {
                Header: 'Conversions',
                accessor: 'conversions',
            },
            {
                Header: 'Conversion Value',
                accessor: 'conversionValue',
                Cell: row => `${currencySymbol}${row.value}`,
            },
            {
                Header: 'Launch Date',
                accessor: 'launchDate',
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: row => (
                    <span className={styles[`status-${row.value}`]}>
                        {this.renderSvgIcon(row.value)}
                        <FormattedMessage
                            id={`dashboardLanding.adCampaignTable.status.${
                                row.value
                            }`}
                        />
                    </span>
                ),
            },
            {
                Header: 'Actions',
                accessor: 'action',
                sortable: false,
                style: {
                    overflow: 'visible',
                },
                Cell: row => (
                    <div className={styles['action-container']}>
                        <ActionSelect options={this.getActions(row.value)} />
                    </div>
                ),
            },
        ];

        return (
            <React.Fragment>
                <div className={styles['table-wrapper']}>
                    <span className={styles['last-sync-time']}>
                        <FormattedMessage id="lastSynced" />
                        {campaignsUpdatedAt}
                    </span>
                    <Table
                        className="ad-campaign-table"
                        data={this.mapData(data)}
                        columns={columns}
                        loading={loading}
                        pageSize={pageSize}
                        showPageSizeOptions={false}
                        noDataText={
                            <FormattedMessage id="dashboardLanding.adCampaignTable.noDataText" />
                        }
                        onResizedChange={this.moveActionSelect}
                        defaultSorted={[
                            {
                                id: 'status',
                                desc: false,
                            },
                            {
                                id: 'name',
                                desc: false,
                            },
                        ]}
                    />
                </div>
                <Modal
                    modalContainerStyle={styles['modal-container']}
                    onOverlayClick={null}
                    showCloseSign={false}
                    handleCloseModal={this.closeModal}
                    isVisible={isModalVisible}
                >
                    <ModalContent
                        handleCloseModal={this.closeModal}
                        confirmAction={this.handleConfirmModal}
                        header={
                            <FormattedMessage id="dashboardLanding.modal.header" />
                        }
                        text={
                            <FormattedMessage
                                id={`dashboardLanding.modal.${action}_text`}
                            />
                        }
                        confirmButtonText={
                            <FormattedMessage
                                id={`dashboardLanding.modal.${action}_confirmButton`}
                            />
                        }
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

export default connect(
    null,
    AllActionCreators,
)(AdCampaignTable);
