import { isEmpty, map, find, get, filter } from 'lodash';
import { countryOfSales } from 'config/countryOfSales';
import uuidv1 from 'uuid/v1';

export const mapUrlVerificationData = data => {
    if (isEmpty(data)) {
        return {};
    }

    const { storeSettings } = data.channelAttributes;
    const websites = filter(
        window.googleAppConfig.websites,
        element => element.id > 0,
    );
    const stores = get(find(websites, { url: data.baseUrl }), 'stores');

    const tableData = map(storeSettings, (item, key) => {
        const matchedCountry = find(countryOfSales, { value: item.country });

        return {
            uniqueRowId: uuidv1(),
            code: {
                value: key,
                label: get(find(stores, { code: key }), 'name'),
            },
            country: {
                value: item.country,
                label: get(matchedCountry, 'label'),
            },
            language: {
                value: item.language,
                label: get(
                    find(matchedCountry.languages, { value: item.language }),
                    'label',
                ),
            },
            languages: matchedCountry.languages,
        };
    });

    return {
        websiteUrl: data.baseUrl,
        storeName: data.channelName,
        tableData,
        stores,
    };
};
