import React, { Component } from 'react';

import { get } from 'lodash';

import AccountErrors from './components/AccountErrors';
import ProductErrors from './components/ProductErrors';

import { errorDataCachingService } from './ErrorDataCachingService';

/**
 * A merchant dashboard page scene.
 */
class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0,
            loading: true,
            accErrData: [],
            prodErrData: [],
        };
    }

    componentDidMount() {
        let data = [];

        errorDataCachingService
            .init()
            .then(() => {
                data = errorDataCachingService.getData();

                this.setState({
                    ...(data
                        ? {
                              accErrData: data.account || [],
                              prodErrData: get(data, 'products.severity') || [],
                          }
                        : {}),
                    loading: false,
                });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    onTabSelect = tabIndex => {
        this.setState({ tabIndex });
    };

    render() {
        return (
            <React.Fragment>
                <AccountErrors
                    data={this.state.accErrData}
                    loading={this.state.loading}
                />
                <ProductErrors
                    data={this.state.prodErrData}
                    columns={this.state.prodErrColumns}
                    tabIndex={this.state.tabIndex}
                    onTabSelect={this.onTabSelect}
                    loading={this.state.loading}
                />
            </React.Fragment>
        );
    }
}

export default Dashboard;
