import React from 'react';
import { FormattedMessage } from 'react-intl';

const DescriptionText = function(props) {
    const styles = props.styles;

    return (
        <React.Fragment>
            <h2 className={styles.header2}>
                <FormattedMessage id="urlVerification.header_1" />
            </h2>
            <p className={styles[`font-medium`]}>
                <FormattedMessage id="urlVerification.text_1" />
                <a
                    href="https://support.google.com/merchants/answer/176793?hl=en"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FormattedMessage id="urlVerification.autoURLVerificationLink" />
                </a>
                .
            </p>
            <p className={styles[`font-medium`]}>
                <FormattedMessage id="urlVerification.text_2" />
            </p>
        </React.Fragment>
    );
};

export default DescriptionText;
