import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Wizard from 'component/Wizard';
import WizardActions from 'component/WizardActions';
import FixedHeader from 'component/FixedHeader';

import { classnames } from '../../../../util';

import styles from './NavControls.module.css';

export default class NavControls extends Component {
    static propTypes = {
        showWizard: PropTypes.bool.isRequired,
        skipAction: PropTypes.func.isRequired,
        nextAction: PropTypes.func.isRequired,
        cancelAction: PropTypes.func.isRequired,
    };

    createActions = () => {
        const { cancelAction, nextAction } = this.props;

        const cancelButtonStyles = classnames([
            styles.link,
            styles['cancel-button'],
        ]);
        const reviewButtonStyles = classnames([
            styles.link,
            styles['review-button'],
        ]);

        return (
            <div className={styles['nav-container']}>
                <button className={cancelButtonStyles} onClick={cancelAction}>
                    <FormattedMessage id="smartShoppingCampaign.cancelButton" />
                </button>
                <button className={reviewButtonStyles} onClick={nextAction}>
                    <FormattedMessage id="smartShoppingCampaign.reviewButton" />
                </button>
            </div>
        );
    };

    createWizardActions = () => {
        const { skipAction, nextAction } = this.props;

        return (
            <WizardActions
                back={{
                    caption: <FormattedMessage id="wizard.back" />,
                    link: '/account-settings',
                }}
                skip={{
                    caption: <FormattedMessage id="wizard.skip" />,
                }}
                next={{
                    caption: (
                        <FormattedMessage id="smartShoppingCampaign.reviewButton" />
                    ),
                }}
                {...{
                    skipAction,
                    nextAction,
                }}
            />
        );
    };

    createWizard = () => (
        <Wizard
            elements={[
                {
                    caption: <FormattedMessage id="onboarding.wizard.terms" />,
                    link: '/google-ads-terms',
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.account_settings" />
                    ),
                    link: '/account-settings',
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.create_an_ad" />
                    ),
                    isActive: true,
                },
            ]}
            activeElementIndex={2}
        />
    );

    render() {
        const { showWizard } = this.props;

        return (
            <FixedHeader
                right={
                    showWizard ? this.createWizardActions : this.createActions
                }
            >
                {showWizard ? this.createWizard() : null}
            </FixedHeader>
        );
    }
}
