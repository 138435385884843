import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compact, map, isEmpty, partialRight } from 'lodash';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { yuiRequest } from 'api';

import { ReactComponent as PendingIcon } from 'design/assets/pending.svg';
import { ReactComponent as ApprovedIcon } from 'design/assets/approved.svg';

import { connect } from 'react-redux';
import * as allActionCreators from './actions';

import Table from 'component/table';

import styles from './GMCAccountsTable.module.css';

const CLAIM_STATUSES = {
    claimed: 'claimed',
    unclaimed: 'unclaimed',
};

const PRODUCTS_STATUSES = {
    PENDING: 'pending',
    DISAPPROVALS: 'disapproved',
    ALL_APPROVED: 'approved',
};

class GMCAccountsTable extends Component {
    static propTypes = {
        shouldUpdateGMCTable: PropTypes.bool.isRequired,
        saveChannelIdToStore: PropTypes.func.isRequired,
        saveGmcAccountUrlToStore: PropTypes.func.isRequired,
        saveGmcAccountIdToStore: PropTypes.func.isRequired,
        showLoader: PropTypes.func.isRequired,
        hideLoader: PropTypes.func.isRequired,
        processChannelData: PropTypes.func.isRequired,
        toggleUpdateGMCTable: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
    };

    state = {
        data: [],
        pageSize: 2,
        loading: false,
    };

    componentDidUpdate = prevProps => {
        const { shouldUpdateGMCTable } = this.props;
        if (
            prevProps.shouldUpdateGMCTable !== shouldUpdateGMCTable &&
            shouldUpdateGMCTable
        ) {
            this.fetchData();
        }
    };

    fetchData = async () => {
        this.setState({ loading: true });
        this.props.showLoader();

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: 'channels',
            });

            const data = compact(JSON.parse(response.body));

            this.props.processChannelData(data);

            this.setState({
                data: data || [],
                pageSize: !isEmpty(data)
                    ? data.length < 10
                        ? data.length
                        : 10
                    : 2,
            });
        } catch (error) {
            this.props.showNotification(error);
        } finally {
            this.setState({ loading: false });
            this.props.hideLoader();
            this.props.toggleUpdateGMCTable();
        }
    };

    mapData = partialRight(map, item => {
        if (!item) {
            return;
        }

        return {
            siteUrl: item.baseUrl,
            googleStoreName: item.channelName,
            gmcAccountsId: item.merchantId,
            claimStatus: item.websiteClaimed
                ? CLAIM_STATUSES.claimed
                : CLAIM_STATUSES.unclaimed,
            accountStatus: item.accountStatus.toLowerCase(),
            productsStatus: PRODUCTS_STATUSES[item.productStatus],
            actions: {
                channelId: item.channelId,
                gmcId: item.merchantId,
                siteUrl: item.baseUrl,
            },
        };
    });

    handleViewDetailsClick = gmcData => {
        const { channelId, siteUrl, gmcId } = gmcData;

        this.props.saveChannelIdToStore(channelId);
        this.props.saveGmcAccountUrlToStore(siteUrl);
        this.props.saveGmcAccountIdToStore(gmcId);
    };

    renderSvgIcon = status => {
        if (status === 'pending') return <PendingIcon />;
        if (status === 'approved') return <ApprovedIcon />;
        return null;
    };

    render() {
        const { data, pageSize, loading } = this.state;

        const columns = [
            {
                Header: 'Site URL',
                accessor: 'siteUrl',
            },
            {
                Header: 'Google Store Name',
                accessor: 'googleStoreName',
            },
            {
                Header: 'GMC Accounts ID',
                accessor: 'gmcAccountsId',
            },
            {
                Header: 'Claim Status',
                accessor: 'claimStatus',
                Cell: row => (
                    <span
                        className={
                            styles[
                                `${
                                    row.value === 'claimed'
                                        ? 'approved'
                                        : 'disapproved'
                                }-icon`
                            ]
                        }
                    >
                        {row.value === 'claimed'
                            ? this.renderSvgIcon('approved')
                            : null}
                        <FormattedMessage
                            id={`dashboardLanding.GMCAccountsTable.claimStatus.${
                                row.value
                            }`}
                        />
                    </span>
                ),
            },
            {
                Header: 'Account Status',
                accessor: 'accountStatus',
                Cell: row => (
                    <span className={styles[`${row.value}-icon`]}>
                        {this.renderSvgIcon(row.value)}
                        <FormattedMessage
                            id={`dashboardLanding.GMCAccountsTable.accountStatus.${
                                row.value
                            }`}
                        />
                    </span>
                ),
            },
            {
                Header: 'Products Status',
                accessor: 'productsStatus',
                Cell: row => (
                    <span className={styles[`${row.value}-icon`]}>
                        {this.renderSvgIcon(row.value)}
                        <FormattedMessage
                            id={`dashboardLanding.GMCAccountsTable.productsStatus.${
                                row.value
                            }`}
                        />
                    </span>
                ),
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: row => (
                    <Link
                        to={'gmc-details'}
                        onClick={() => this.handleViewDetailsClick(row.value)}
                    >
                        <FormattedMessage id="dashboardLanding.GMCAccountsTable.viewDetails" />
                    </Link>
                ),
                sortable: false,
            },
        ];

        return (
            <Table
                data={this.mapData(data)}
                columns={columns}
                loading={loading}
                pageSize={pageSize}
                showPageSizeOptions={false}
                noDataText={
                    <FormattedMessage id="dashboardLanding.GMCAccountsTable.noDataText" />
                }
            />
        );
    }
}

export default connect(
    null,
    allActionCreators,
)(GMCAccountsTable);
