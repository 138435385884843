// Yui gateway login
export const MAGENTO_KEY_SET = 'MAGENTO_KEY_SET';
export const EMAIL_SET = 'EMAIL_SET';

// Terms
export const CONSENT_ADD = 'CONSENT_ADD';
export const CONSENT_REMOVE = 'CONSENT_REMOVE';
export const CONSENT_ERROR = 'CONSENT_ERROR';

// Url Verification
export const SAVE_URL_VERIFY_FIELD_VALUE = 'SAVE_URL_VERIFY_FIELD_VALUE';
export const SAVE_WEBSITE_ID = 'SAVE_WEBSITE_ID';
export const SET_URL_VERIFY_FIELD_ERROR = 'SET_URL_VERIFY_FIELD_ERROR';
export const UNSET_URL_VERIFY_FIELD_ERROR = 'UNSET_URL_VERIFY_FIELD_ERROR';
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
export const TOGGLE_MAPPING_EDIT_MODE = 'TOGGLE_MAPPING_EDIT_MODE';
export const RESET_CHANNEL_DATA = 'RESET_CHANNEL_DATA';

// Url Verification: store views table
export const SAVE_WEBSITE_STORES_TO_STORE = 'SAVE_WEBSITE_STORES_TO_STORE';
export const SAVE_TABLE_DATA_TO_STORE = 'SAVE_TABLE_DATA_TO_STORE';
export const SAVE_STORE_VIEW_NAME_CODE = 'SAVE_STORE_VIEW_NAME_CODE';
export const RESET_TABLE_DATA_IN_STORE = 'RESET_TABLE_DATA_IN_STORE';
export const SAVE_COUNTRY_DATA_TO_STORE = 'SAVE_COUNTRY_DATA_TO_STORE';
export const SAVE_STORE_VIEW_LANGUAGE_TO_STORE =
    'SAVE_STORE_VIEW_LANGUAGE_TO_STORE';
export const RESET_CONFIG_STATE = 'RESET_CONFIG_STATE';
export const RESET_STORE_VIEW_LANGUAGE_IN_STORE =
    'RESET_STORE_VIEW_LANGUAGE_IN_STORE';
export const SET_STORE_VIEW_REQUIRED_ERROR = 'SET_STORE_VIEW_REQUIRED_ERROR';
export const UNSET_STORE_VIEW_REQUIRED_ERROR =
    'UNSET_STORE_VIEW_REQUIRED_ERROR';
export const SET_STORE_VIEWS_TABLE_REQUIRED = 'SET_STORE_VIEWS_TABLE_REQUIRED';
export const UNSET_STORE_VIEWS_TABLE_REQUIRED =
    'UNSET_STORE_VIEWS_TABLE_REQUIRED';

// Shipping and tax
export const SAVE_FLAT_RATE_TABLE_DATA = 'SAVE_FLAT_RATE_TABLE_DATA';
export const SET_SHIPPING_COST_ACTIVE_RADIO = 'SET_SHIPPING_COST_ACTIVE_RADIO';
export const SAVE_SHIPPING_FLAT_RATE_COST = 'SAVE_SHIPPING_FLAT_RATE_COST';
export const SAVE_SHIPPING_COST_SET_ON_GMC = 'SAVE_SHIPPING_COST_SET_ON_GMC';
export const REMOVE_SHIPPING_COST_SET_ON_GMC =
    'REMOVE_SHIPPING_COST_SET_ON_GMC';
export const SET_SHIPPING_TAX_ACTIVE_RADIO = 'SET_SHIPPING_TAX_ACTIVE_RADIO';
export const TOGGLE_TAX_ON_SHIPPING = 'TOGGLE_TAX_ON_SHIPPING';
export const SAVE_OPERATE_STATES = 'SAVE_OPERATE_STATES';
export const SAVE_SHIPPING_TAX_VAT_INCLUDED = 'SAVE_SHIPPING_TAX_VAT_INCLUDED';
export const REMOVE_SHIPPING_TAX_VAT_INCLUDED =
    'REMOVE_SHIPPING_TAX_VAT_INCLUDED';

// Attribute Mapping
export const SAVE_PREDEFINED_ATTRIBUTE_VALUE =
    'SAVE_PREDEFINED_ATTRIBUTE_VALUE';
export const SAVE_ATTRIBUTE_VALUE = 'SAVE_ATTRIBUTE_VALUE';
export const SET_ATTRIBUTE_AS_REQUIRED = 'SET_ATTRIBUTE_AS_REQUIRED';
export const SAVE_SPECIFIC_ATTRIBUTE_VALUE = 'SAVE_SPECIFIC_ATTRIBUTE_VALUE';
export const SAVE_SPECIFIC_ATTRIBUTE_OPTION = 'SAVE_SPECIFIC_ATTRIBUTE_OPTION';
export const UNSET_ATTRIBUTE_ERROR = 'UNSET_ATTRIBUTE_ERROR';
export const RESET_ATTRIBUTES_STATE = 'RESET_ATTRIBUTES_STATE';
export const SET_ATTRIBUTE_ERROR = 'SET_ATTRIBUTE_ERROR';
export const SET_SPECIFIC_ATTRIBUTE_ERROR = 'SET_SPECIFIC_ATTRIBUTE_ERROR';
export const UNSET_SPECIFIC_ATTRIBUTE_ERROR = 'UNSET_SPECIFIC_ATTRIBUTE_ERROR';
export const SET_SPECIFIC_ATTRIBUTE_TABLE_ERROR =
    'SET_SPECIFIC_ATTRIBUTE_TABLE_ERROR';
export const UNSET_SPECIFIC_ATTRIBUTE_TABLE_ERROR =
    'UNSET_SPECIFIC_ATTRIBUTE_TABLE_ERROR';
export const UNSET_ATTRIBUTE_AS_REQUIRED = 'UNSET_ATTRIBUTE_AS_REQUIRED';
export const SAVE_LIST_SPECIFIC_ATTRIBUTE_OPTIONS =
    'SAVE_LIST_SPECIFIC_ATTRIBUTE_OPTIONS';

// Ads Terms
export const ADS_CONSENT_ADD = 'ADS_CONSENT_ADD';
export const ADS_CONSENT_REMOVE = 'ADS_CONSENT_REMOVE';
export const ADS_CONSENT_ERROR = 'ADS_CONSENT_ERROR';

// Ads Settings
export const ADS_SET_COUNTRY = 'ADS_SET_COUNTRY';
export const ADS_SET_CURRENCY = 'ADS_SET_CURRENCY';
export const ADS_SET_TIMEZONE = 'ADS_SET_TIMEZONE';
export const RESET_ADS_SETTINGS_STATE = 'RESET_ADS_SETTINGS_STATE';

// Ads creation
export const RESET_ADS_CREATION_STATE = 'RESET_ADS_CREATION_STATE';
export const SET_GMC_ACCOUNT_URL = 'SET_GMC_ACCOUNT_URL';
export const SET_GMC_ACCOUNT_ID = 'SET_GMC_ACCOUNT_ID';
export const SET_COUNTRY_TO_ADVERTISE = 'SET_COUNTRY_TO_ADVERTISE';
export const SET_CAMPAIGN_NAME = 'SET_CAMPAIGN_NAME';
export const SET_DAILY_BUDGET = 'SET_DAILY_BUDGET';
export const SAVE_CHANNEL_ID = 'SAVE_CHANNEL_ID';
export const SAVE_CHANNELS_LIST = 'SAVE_CHANNELS_LIST';
export const SAVE_FILTERS_ACTIVE_RADIO = 'SAVE_FILTERS_ACTIVE_RADIO';
export const SAVE_SELECT_ALL_OPTION = 'SAVE_SELECT_ALL_OPTION';
export const SAVE_BRANDS_FILTER = 'SAVE_BRANDS_FILTER';
export const SAVE_CATEGORIES_FILTER = 'SAVE_CATEGORIES_FILTER';
export const TOGGLE_TABLE_SELECT_ALL = 'TOGGLE_TABLE_SELECT_ALL';
export const SAVE_INCLUDED_PRODUCTS = 'SAVE_INCLUDED_PRODUCTS';
export const SAVE_EXCLUDED_PRODUCTS = 'SAVE_EXCLUDED_PRODUCTS';
export const SAVE_CAMPAIGN_NAMES_TO_STORE = 'SAVE_CAMPAIGN_NAMES_TO_STORE';
export const SAVE_EDIT_CAMPAIGN_DATA_TO_STORE =
    'SAVE_EDIT_CAMPAIGN_DATA_TO_STORE';

// Settings
export const SET_EDIT_TYPE = 'SET_EDIT_TYPE';
export const CLEAR_EDIT_TYPE = 'CLEAR_EDIT_TYPE';

// Release Notes
export const SET_RELEASE_NOTES = 'SET_RELEASE_NOTES';
export const SET_RELEASE_NOTES_HIDDEN = 'SET_RELEASE_NOTES_HIDDEN';
