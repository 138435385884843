import { ActionCreator } from 'redux';

import {
    IResetChannelDataAction,
    ISaveUrlVerifyFieldValueAction,
    ISetStoreViewRequiredErrorAction,
    ISetStoreViewsTableRequiredAction,
    ISetUrlVerifyFieldErrorAction,
    IToggleEditModeAction,
    IUnsetStoreViewsTableRequiredAction,
    IUnsetUrlVerifyFieldErrorAction,
} from '../../reducers/websiteConfigReducer';
import {
    RESET_CHANNEL_DATA,
    SAVE_URL_VERIFY_FIELD_VALUE,
    SET_STORE_VIEW_REQUIRED_ERROR,
    SET_STORE_VIEWS_TABLE_REQUIRED,
    SET_URL_VERIFY_FIELD_ERROR,
    TOGGLE_EDIT_MODE,
    UNSET_STORE_VIEWS_TABLE_REQUIRED,
    UNSET_URL_VERIFY_FIELD_ERROR,
} from '../../store/actionConsts';

export const resetChannelData: ActionCreator<IResetChannelDataAction> = () => ({
    type: RESET_CHANNEL_DATA,
});

export const toggleEditMode: ActionCreator<IToggleEditModeAction> = (
    value: boolean,
) => ({
    type: TOGGLE_EDIT_MODE,
    value,
});

export const validateRequiredField: ActionCreator<
    ISetUrlVerifyFieldErrorAction | IUnsetUrlVerifyFieldErrorAction
> = (value: string, fieldName: string) =>
    (value ? unsetFieldError : setFieldError)(fieldName);

export const setFieldError: ActionCreator<ISetUrlVerifyFieldErrorAction> = (
    fieldName: string,
) => ({
    fieldName,
    type: SET_URL_VERIFY_FIELD_ERROR,
});

export const unsetFieldError: ActionCreator<IUnsetUrlVerifyFieldErrorAction> = (
    fieldName: string,
) => ({
    fieldName,
    type: UNSET_URL_VERIFY_FIELD_ERROR,
});

export const showStoreViewRequiredError: ActionCreator<
    ISetStoreViewRequiredErrorAction
> = () => ({
    type: SET_STORE_VIEW_REQUIRED_ERROR,
});

export const saveFieldValueToStore: ActionCreator<
    ISaveUrlVerifyFieldValueAction
> = (value: string, fieldName: string) => ({
    fieldName,
    type: SAVE_URL_VERIFY_FIELD_VALUE,
    value,
});

export const setStoreViewsTableRequired: ActionCreator<
    ISetStoreViewsTableRequiredAction
> = () => ({
    type: SET_STORE_VIEWS_TABLE_REQUIRED,
});

export const unsetStoreViewsTableRequired: ActionCreator<
    IUnsetStoreViewsTableRequiredAction
> = () => ({
    type: UNSET_STORE_VIEWS_TABLE_REQUIRED,
});
