import React, { Component } from 'react';
import RadioButton from 'component/form/RadioButton';
import { FormattedMessage } from 'react-intl';

import Tooltip from 'component/Tooltip';
import styles from './VatIncluded.module.css';

class VatIncluded extends Component {
    constructor(props) {
        super(props);
        this.radioButtonConfig = {
            label: <FormattedMessage id="shippingAndTax.tax.radio_2" />,
            id: this.props.radioButtonIds.vatIncluded,
        };
    }

    radioButtonChangeHandler = event => {
        this.props.setActiveTaxRadioState(event.target.id);
        this.props.saveVatIncluded();
        this.props.removeShippingTaxStatesError();
    };

    render() {
        const isRadioChecked =
            this.props.activeRadioButtonId === this.radioButtonConfig.id;

        return (
            <div className={styles['vat-radio-container']}>
                <RadioButton
                    id={this.radioButtonConfig.id}
                    onChange={this.radioButtonChangeHandler}
                    checked={isRadioChecked}
                    labelFor={this.radioButtonConfig.id}
                    label={this.radioButtonConfig.label}
                />
                <Tooltip
                    direction="right"
                    link="sales-channels/google-ads/shipping-tax.html"
                >
                    <FormattedMessage id="help.shippingAndTax.taxMerchantService" />
                </Tooltip>
            </div>
        );
    }
}

export default VatIncluded;
