import { ActionCreator } from 'redux';

import { ISetDailyBudgetAction } from '../../../../reducers/adsCreationReducer';
import { SET_DAILY_BUDGET } from '../../../../store/actionConsts';

export const saveDailyBudgetToStore: ActionCreator<ISetDailyBudgetAction> = (
    budget: string,
) => ({
    payload: {
        budget,
    },
    type: SET_DAILY_BUDGET,
});
