import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { find, isEmpty, reduce } from 'lodash';

import { classnames } from '../../../../util';
import Tooltip from 'component/Tooltip';

import ConfigurableSelect from 'component/form/Select/ConfigurableSelect';
import commonStyles from 'design/styles/common.module.css';
import customSelectStyles from './websiteUrlField.styles';
import styles from './WebsiteUrlField.module.css';

export class WebsiteUrlField extends Component {
    static propTypes = {
        channelsList: PropTypes.array.isRequired,
        validateRequiredField: PropTypes.func.isRequired,
        saveFieldValueToStore: PropTypes.func.isRequired,
        saveWebsiteIdToStore: PropTypes.func.isRequired,
        saveWebsiteStoresToStore: PropTypes.func.isRequired,
        urlVerification: PropTypes.object.isRequired,
        isEdit: PropTypes.bool.isRequired,
    };
    state = {
        options: this.createOptions(),
        selectedOption: [],
    };
    selectName = 'websiteUrl';

    componentDidMount() {
        this.prefillSelectedValue();
    }

    createOptions() {
        const { websites } = window.googleAppConfig;
        const { channelsList, isEdit } = this.props;

        return reduce(
            websites,
            (result, website) => {
                if (
                    website.id > 0 &&
                    (isEdit ||
                        !channelsList.find(
                            channel => channel.baseUrl === website.url,
                        ))
                ) {
                    result.push({
                        value: website.url,
                        label: website.url,
                        websiteId: website.id,
                        stores: website.stores,
                    });
                }
                return result;
            },
            [],
        );
    }

    prefillSelectedValue() {
        const value = this.props.urlVerification[this.selectName].value;
        const selectedOption = find(this.state.options, { value });

        if (selectedOption) {
            this.setValue(selectedOption);
        }
    }

    selectChangeHandler = selectedOption => {
        this.setValue(selectedOption);
    };

    setValue(selectedOption) {
        const { value, websiteId, stores } = selectedOption;
        const fieldValue = isEmpty(stores) ? '' : value;

        this.props.saveFieldValueToStore(fieldValue, this.selectName);
        this.props.validateRequiredField(fieldValue, this.selectName);
        this.props.saveWebsiteIdToStore(websiteId);
        this.props.saveWebsiteStoresToStore(stores);
        this.setState({
            selectedOption,
        });
    }

    render() {
        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['medium-field-header-right-required'],
            commonStyles['font-semibold'],
        ]);
        const {
            props: {
                urlVerification: {
                    websiteUrl: { value, error },
                    websiteStoreViews: { websiteStores },
                },
                isEdit,
            },
            state: { selectedOption },
        } = this;
        const isStoresError =
            isEmpty(websiteStores) &&
            isEmpty(value) &&
            !isEmpty(selectedOption);
        const errorMessage = (
            <FormattedMessage
                id={`urlVerification.select.websiteUrl${
                    isStoresError ? 'Stores' : ''
                }ErrorMessage`}
            />
        );

        return (
            <React.Fragment>
                <div className={styles['website-url-header-wrapper']}>
                    <span className={fieldHeadingStyles}>
                        <FormattedMessage id="urlVerification.select.websiteUrlHeader" />
                    </span>
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/url-verify.html"
                        className={commonStyles['cs-tooltip-left-margin']}
                    >
                        <FormattedMessage id="help.urlVerification.websiteUrl" />
                    </Tooltip>
                </div>
                <div className={styles['website-url-select-wrapper']}>
                    <ConfigurableSelect
                        isDisabled={isEdit}
                        styles={customSelectStyles}
                        onChange={this.selectChangeHandler}
                        value={this.state.selectedOption}
                        options={this.state.options}
                        showError={error}
                        {...{
                            name: this.selectName,
                            placeholder: (
                                <FormattedMessage id="urlVerification.select.placeholder" />
                            ),
                            isMulti: false,
                            isSearchable: false,
                            errorMessage,
                            blurInputOnSelect: true,
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    urlVerification: state.websiteConfig.new.urlVerification,
    channelsList: state.adsCreation.channelsList,
    isEdit: state.websiteConfig.new.isEdit,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(WebsiteUrlField);
