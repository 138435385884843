import React from 'react';
import { FormattedMessage } from 'react-intl';

import { classnames, createYuiDocsLink } from '../../../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './OverviewContent.module.css';

export default function OverviewContent(props) {
    const headerStyles = classnames([commonStyles.header2, styles.header2]);
    const step1InfoThumbnailStyles = classnames([
        styles['step-info-header-thumbnail'],
        styles['step1-info-header-thumbnail'],
    ]);
    const step2InfoThumbnailStyles = classnames([
        styles['step-info-header-thumbnail'],
        styles['step2-info-header-thumbnail'],
    ]);
    const step3InfoThumbnailStyles = classnames([
        styles['step-info-header-thumbnail'],
        styles['step3-info-header-thumbnail'],
    ]);
    const proceedButtonStyles = classnames([
        styles.button,
        styles['proceed-button'],
    ]);
    const link = createYuiDocsLink(
        'sales-channels/google-ads/google-faq.html',
    );

    return (
        <React.Fragment>
            <h2 className={headerStyles}>
                <FormattedMessage id="dashboardLanding.overviewModalContent.header" />
            </h2>
            <p className={styles.description}>
                <FormattedMessage id="dashboardLanding.overviewModalContent.description" />
            </p>
            <div className={styles.popupWarning}>
                <FormattedMessage id="dashboardLanding.overviewModalContent.popupWarningText" />
            </div>
            <div className={styles['step-info']}>
                <div className={styles['step-info-header-container']}>
                    <div className={step1InfoThumbnailStyles} />
                    <div>
                        <p className={styles['step-info-header']}>
                            <FormattedMessage id="dashboardLanding.overviewModalContent.step1.header" />
                        </p>
                        <ul className={styles['step-info-list']}>
                            <li className={styles['step-info-list-item']}>
                                <FormattedMessage id="dashboardLanding.overviewModalContent.step1.list.item_1" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles['step-info']}>
                <div className={styles['step-info-header-container']}>
                    <div className={step2InfoThumbnailStyles} />
                    <div>
                        <p className={styles['step-info-header']}>
                            <FormattedMessage id="dashboardLanding.overviewModalContent.step2.header" />
                        </p>
                        <ul className={styles['step-info-list']}>
                            <li className={styles['step-info-list-item']}>
                                <FormattedMessage id="dashboardLanding.overviewModalContent.step2.list.item_1" />
                            </li>
                            <li className={styles['step-info-list-item']}>
                                <FormattedMessage id="dashboardLanding.overviewModalContent.step2.list.item_2" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles['step-info']}>
                <div className={styles['step-info-header-container']}>
                    <div className={step3InfoThumbnailStyles} />
                    <div>
                        <p className={styles['step-info-header']}>
                            <FormattedMessage id="dashboardLanding.overviewModalContent.step3.header" />
                        </p>
                        <ul className={styles['step-info-list']}>
                            <li className={styles['step-info-list-item']}>
                                <FormattedMessage id="dashboardLanding.overviewModalContent.step3.list.item_1" />
                            </li>
                            <li className={styles['step-info-list-item']}>
                                <FormattedMessage id="dashboardLanding.overviewModalContent.step3.list.item_2" />
                            </li>
                            <li className={styles['step-info-list-item']}>
                                <FormattedMessage id="dashboardLanding.overviewModalContent.step3.list.item_3" />
                            </li>
                            <li className={styles['step-info-list-item']}>
                                <FormattedMessage id="dashboardLanding.overviewModalContent.step3.list.item_4" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <a
                className={styles['faq-link']}
                href={link}
                rel="noopener noreferrer"
                target="_blank"
            >
                <FormattedMessage id="dashboardLanding.overviewModalContent.faqLink" />
            </a>
            <button
                className={proceedButtonStyles}
                onClick={props.proceedNextStep}
            >
                <FormattedMessage id="dashboardLanding.overviewModalContent.proceedButton" />
            </button>
        </React.Fragment>
    );
}
