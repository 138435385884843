import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import FixedHeader from 'component/FixedHeader';
import Wizard from 'component/Wizard';
import WizardActions from 'component/WizardActions';
import Modal from 'component/modal';
import {
    DescriptionText,
    AccountSettingsForm,
    ConfirmSettingsModalContent,
} from './components';
import commonStyles from 'design/styles/common.module.css';
import styles from './AccountSettings.module.css';

export class AccountSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isCountryErrorVisible: false,
            isTimeZoneErrorVisible: false,
        };
        this.wizardConfig = {
            activeElementIndex: 1,
            elements: [
                {
                    caption: <FormattedMessage id="onboarding.wizard.terms" />,
                    link: '/google-ads-terms',
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.account_settings" />
                    ),
                    isActive: true,
                },
                {
                    caption: (
                        <FormattedMessage id="onboarding.wizard.create_an_ad" />
                    ),
                },
            ],
            backButton: {
                caption: <FormattedMessage id="wizard.back" />,
                link: '/google-ads-terms',
            },
            nextButton: {
                caption: <FormattedMessage id="wizard.next" />,
            },
        };
    }

    handleCloseModal = () => {
        this.setState(state => ({ isModalVisible: !state.isModalVisible }));
    };

    showCountryError = () => {
        this.setState({
            isCountryErrorVisible: true,
        });
    };

    hideCountryError = () => {
        this.setState({
            isCountryErrorVisible: false,
        });
    };

    showTimeZoneError = () => {
        this.setState({
            isTimeZoneErrorVisible: true,
        });
    };

    hideTimeZoneError = () => {
        this.setState({
            isTimeZoneErrorVisible: false,
        });
    };

    validateCountryField() {
        const isValid = !isEmpty(this.props.country);

        if (!isValid) {
            this.showCountryError();
        }

        return isValid;
    }

    validateTimezoneField() {
        const isValid = !isEmpty(this.props.timezone);

        if (!isValid) {
            this.showTimeZoneError();
        }

        return isValid;
    }

    validateForm() {
        const isCountryValid = this.validateCountryField();
        const isTimezoneValid = this.validateTimezoneField();

        return isCountryValid && isTimezoneValid;
    }

    showConfirmSettingsModal = () => {
        this.setState(state => ({ isModalVisible: !state.isModalVisible }));
    };

    handleNext = () => {
        const isValidForm = this.validateForm();

        if (isValidForm) {
            this.showConfirmSettingsModal();
        }
    };

    proceedNextStep = () => {
        this.props.history.push('/create-ad');
    };

    createWizardActions = () => {
        return (
            <WizardActions
                back={this.wizardConfig.backButton}
                next={this.wizardConfig.nextButton}
                nextAction={this.handleNext}
            />
        );
    };

    createWizard = () => {
        return (
            <Wizard
                elements={this.wizardConfig.elements}
                activeElementIndex={this.wizardConfig.activeElementIndex}
            />
        );
    };

    render() {
        const wizard = this.createWizard();

        return (
            <React.Fragment>
                <FixedHeader right={this.createWizardActions}>
                    {wizard}
                </FixedHeader>
                <div className={commonStyles['sceneMainContent']}>
                    <DescriptionText commonStyles={commonStyles} />
                    <AccountSettingsForm
                        country={this.props.country}
                        saveCountryToStore={this.props.saveCountryToStore}
                        saveCurrencyToStore={this.props.saveCurrencyToStore}
                        showCountryError={this.showCountryError}
                        hideCountryError={this.hideCountryError}
                        countryError={this.state.isCountryErrorVisible}
                        timezone={this.props.timezone}
                        saveTimeZoneToStore={this.props.saveTimeZoneToStore}
                        showTimeZoneError={this.showTimeZoneError}
                        hideTimeZoneError={this.hideTimeZoneError}
                        timeZoneError={this.state.isTimeZoneErrorVisible}
                    />
                </div>
                <Modal
                    modalContainerStyle={styles['modal-container']}
                    onOverlayClick={null}
                    isVisible={this.state.isModalVisible}
                >
                    <ConfirmSettingsModalContent
                        commonStyles={commonStyles}
                        handleCloseModal={this.handleCloseModal}
                        proceedNextStep={this.proceedNextStep}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    country: state.adsSettings.country,
    timezone: state.adsSettings.timezone,
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(allActionCreators, dispatch),
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountSettings);
