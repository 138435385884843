import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { classnames } from '../../util';

import styles from './Sidebar.module.css';

export default class Sidebar extends Component {
    static propTypes = {
        isActive: PropTypes.bool.isRequired,
        position: PropTypes.oneOf(['right', 'left']).isRequired,
        onOverlayClick: PropTypes.func,
    };

    handleOverlayClick = e => {
        const { onOverlayClick } = this.props;

        if (e.target === e.currentTarget && onOverlayClick) {
            this.props.onOverlayClick();
        } else {
            e.stopPropagation();
        }
    };

    setBodyOverflow = value =>
        (document.querySelector('body').style.overflow = value);

    componentDidUpdate(prevProps, prevState) {
        const { isActive } = this.props;

        if (prevProps.isActive !== isActive) {
            if (isActive) {
                this.setBodyOverflow('hidden');
            } else {
                this.setBodyOverflow('auto');
            }
        }
    }

    componentWillUnmount() {
        this.setBodyOverflow('auto');
    }

    render() {
        const { isActive, children, position } = this.props;

        const overlayClasses = [
            styles['sidebar-overlay'],
            isActive && styles['sidebar-overlay_active'],
        ];

        const sidebarClasses = [
            styles.sidebar,
            styles[`${position}-anchor`],
            isActive && styles[`${position}-active`],
        ];

        return (
            <div
                className={classnames(overlayClasses)}
                onClick={this.handleOverlayClick}
            >
                <div className={classnames(sidebarClasses)}>
                    {isActive && children}
                </div>
            </div>
        );
    }
}
