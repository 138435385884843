import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import PropTypes from 'prop-types';
import { countryOfSales as countries } from 'config/countryOfSales';
import { FormattedMessage } from 'react-intl';
import { isEmpty, find, map, flatten, uniqBy } from 'lodash';
import { classnames } from '../../../../util';

import Tooltip from 'component/Tooltip';

import ConfigurableSelect from 'component/form/Select';
import commonStyles from 'design/styles/common.module.css';
import customSelectStyles from './AdvertiseCountrySelect.style';
import styles from './AdvertiseCountry.module.css';

export class AdvertiseCountryField extends Component {
    static propTypes = {
        adsCreation: PropTypes.object.isRequired,
        isEditMode: PropTypes.bool.isRequired,
        fieldId: PropTypes.string.isRequired,
        countryToAdvertiseError: PropTypes.bool.isRequired,
        saveAdvertiseCountryToStore: PropTypes.func.isRequired,
        unsetFieldError: PropTypes.func.isRequired,
    };
    state = {
        options: [],
        selectedOption: [],
    };

    componentDidMount() {
        const { gmcAccountId } = this.props.adsCreation;

        if (gmcAccountId) {
            this.setState({ options: this.createOptions() });
        }
        this.setPrefilledCountry(countries);
    }

    componentDidUpdate(prevProps) {
        const prevGmcAccountId = prevProps.adsCreation.gmcAccountId;
        const {
            adsCreation: { gmcAccountId },
            isEditMode,
        } = this.props;

        if (prevGmcAccountId !== gmcAccountId && !isEditMode) {
            this.props.saveAdvertiseCountryToStore('');
            this.setState({
                options: this.createOptions(),
                selectedOption: [],
            });
        }
    }

    createOptions = () => {
        return map(this.getChannelCountryData(), data =>
            find(countries, { value: data.country }),
        );
    };

    getChannelCountryData = () => {
        const { gmcAccountId, channelsList } = this.props.adsCreation;
        const channel = find(channelsList, { merchantId: gmcAccountId });

        return !isEmpty(channel)
            ? uniqBy(
                  flatten(
                      map(
                          channel.channelAttributes.storeSettings,
                          storeCountries => storeCountries,
                      ),
                  ),
                  'country',
              )
            : [];
    };

    setPrefilledCountry(countries) {
        const {
            adsCreation: { countryToAdvertise, editCampaignData },
            isEditMode,
        } = this.props;
        const countryCode = isEditMode
            ? editCampaignData.targetCountry
            : countryToAdvertise;

        const selectedOption = find(
            countries,
            country => country.value === countryCode,
        );

        if (selectedOption) {
            this.setState({ selectedOption });

            if (isEditMode) {
                this.props.saveAdvertiseCountryToStore(countryCode);
            }
        }
    }

    selectChangeHandler = selectedOption => {
        let country = selectedOption.value;

        if (country && this.props.countryToAdvertiseError) {
            this.props.unsetFieldError(this.props.fieldId);
        }

        this.setState({ selectedOption });
        this.props.saveAdvertiseCountryToStore(country);
    };

    render() {
        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['medium-field-header-right-required'],
            commonStyles['font-semibold'],
        ]);
        const {
            props: { isEditMode, countryToAdvertiseError, fieldId },
            state: { options, selectedOption },
        } = this;

        return (
            <React.Fragment>
                <div className={styles['header-wrapper']}>
                    <span className={fieldHeadingStyles}>
                        <FormattedMessage id="smartShoppingCampaign.advertiseCountrySelect.title" />
                    </span>
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/create-campaign.html"
                        className={commonStyles['cs-tooltip-left-margin']}
                    >
                        <FormattedMessage id="help.attributeMapping.adForm.country" />
                    </Tooltip>
                </div>
                <div className={styles['field-wrapper']}>
                    <ConfigurableSelect
                        styles={customSelectStyles}
                        onChange={this.selectChangeHandler}
                        {...{
                            name: fieldId,
                            placeholder: (
                                <FormattedMessage id="smartShoppingCampaign.select.placeholder" />
                            ),
                            isMulti: false,
                            isSearchable: true,
                            blurInputOnSelect: true,
                            closeMenuOnSelect: true,
                            errorMessage: (
                                <FormattedMessage id="smartShoppingCampaign.advertiseCountrySelect.errorMessage" />
                            ),
                        }}
                        value={selectedOption}
                        options={options}
                        showError={countryToAdvertiseError}
                        isDisabled={isEditMode}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adsCreation: state.adsCreation,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(AdvertiseCountryField);
