const currencies = [
    {
        code: 'AED',
        name: 'United Arab Emirates Dirham',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'ARS',
        name: 'Argentine Peso',
        symbol: 'AR$',
        minimumUnit: 10000,
    },
    {
        code: 'AUD',
        name: 'Australian Dollars',
        symbol: '$',
        minimumUnit: 10000,
    },
    {
        code: 'BGN',
        name: 'Bulgarian Lev',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'BND',
        name: 'Brunei Dollar',
        symbol: '$',
        minimumUnit: 10000,
    },
    {
        code: 'BOB',
        name: 'Bolivian Boliviano',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'BRL',
        name: 'Brazilian Real',
        symbol: 'R$',
        minimumUnit: 10000,
    },
    {
        code: 'CAD',
        name: 'Canadian Dollars',
        symbol: '$',
        minimumUnit: 10000,
    },
    {
        code: 'CHF',
        name: 'Swiss Francs',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'CLP',
        name: 'Chilean Peso',
        symbol: 'CL$',
        minimumUnit: 1000000,
    },
    {
        code: 'CNY',
        name: 'Yuan Renminbi',
        symbol: '¥',
        minimumUnit: 10000,
    },
    {
        code: 'COP',
        name: 'Colombian Peso',
        symbol: '',
        minimumUnit: 1000000,
    },
    {
        code: 'CSD',
        name: 'Old Serbian Dinar',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'CZK',
        name: 'Czech Koruna',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'DEM',
        name: 'Deutsche Marks',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'DKK',
        name: 'Denmark Kroner',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'EEK',
        name: 'Estonian Kroon',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'EGP',
        name: 'Egyptian Pound',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'EUR',
        name: 'Euros',
        symbol: '€',
        minimumUnit: 10000,
    },
    {
        code: 'FJD',
        name: 'Fiji Dollar',
        symbol: '$',
        minimumUnit: 10000,
    },
    {
        code: 'FRF',
        name: 'French Franks',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'GBP',
        name: 'British Pounds Sterling',
        symbol: '£',
        minimumUnit: 10000,
    },
    {
        code: 'HKD',
        name: 'Hong Kong Dollars',
        symbol: '$',
        minimumUnit: 10000,
    },
    {
        code: 'HRK',
        name: 'Croatian Kuna',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'HUF',
        name: 'Hungarian Forint',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'IDR',
        name: 'Indonesian Rupiah',
        symbol: '',
        minimumUnit: 1000000,
    },
    {
        code: 'ILS',
        name: 'Israeli Shekel',
        symbol: '₪',
        minimumUnit: 10000,
    },
    {
        code: 'INR',
        name: 'Indian Rupee',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'JOD',
        name: 'Jordanian Dinar',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'JPY',
        name: 'Japanese Yen',
        symbol: '¥',
        minimumUnit: 1000000,
    },
    {
        code: 'KES',
        name: 'Kenyan Shilling',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'KRW',
        name: 'South Korean Won',
        symbol: '₩',
        minimumUnit: 1000000,
    },
    {
        code: 'LKR',
        name: 'Sri Lankan Rupee',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'LTL',
        name: 'Lithuanian Litas',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'MAD',
        name: 'Moroccan Dirham',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'MTL',
        name: 'Maltese Lira',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'MXN',
        name: 'Mexico Peso',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'MYR',
        name: 'Malaysian Ringgit',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'NGN',
        name: 'Nigerian Naira',
        symbol: '₦',
        minimumUnit: 10000,
    },
    {
        code: 'NOK',
        name: 'Norway Kroner',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'NZD',
        name: 'New Zealand Dollars',
        symbol: '$',
        minimumUnit: 10000,
    },
    {
        code: 'PEN',
        name: 'Peruvian Nuevo Sol',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'PHP',
        name: 'Philippine Peso',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'PKR',
        name: 'Pakistan Rupee',
        symbol: '',
        minimumUnit: 1000000,
    },
    {
        code: 'PLN',
        name: 'Polish New Zloty',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'ROL',
        name: 'Romanian Leu',
        symbol: '',
        minimumUnit: 1000000,
    },
    {
        code: 'RON',
        name: 'New Romanian Leu',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'RSD',
        name: 'Serbian Dinar',
        symbol: '',
        minimumUnit: 1000000,
    },
    {
        code: 'RUB',
        name: 'Russian Rouble',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'SAR',
        name: 'Saudi Riyal',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'SEK',
        name: 'Sweden Kronor',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'SGD',
        name: 'Singapore Dollars',
        symbol: '$',
        minimumUnit: 10000,
    },
    {
        code: 'SIT',
        name: 'Slovenian Tolar',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'SKK',
        name: 'Slovak Koruna',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'THB',
        name: 'Thai Baht',
        symbol: '฿',
        minimumUnit: 10000,
    },
    {
        code: 'TND',
        name: 'Tunisian Dinar',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'TRL',
        name: 'Turkish Lira',
        symbol: '',
        minimumUnit: 1000000,
    },
    {
        code: 'TRY',
        name: 'New Turkish Lira',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'TWD',
        name: 'New Taiwan Dollar',
        symbol: '$',
        minimumUnit: 10000,
    },
    {
        code: 'UAH',
        name: 'Ukrainian Hryvnia',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'USD',
        name: 'US Dollars',
        symbol: '$',
        minimumUnit: 10000,
    },
    {
        code: 'UYU',
        name: 'Uruguayan peso',
        symbol: '$U',
        minimumUnit: 10000,
    },
    {
        code: 'VEB',
        name: 'Venezuela Bolivar',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'VEF',
        name: 'Venezuela Bolivar Fuerte',
        symbol: '',
        minimumUnit: 10000,
    },
    {
        code: 'VND',
        name: 'Vietnamese Dong',
        symbol: '₫',
        minimumUnit: 1000000,
    },
    {
        code: 'ZAR',
        name: 'South African Rand',
        symbol: 'R',
        minimumUnit: 10000,
    },
];

export default currencies;
