import React from 'react';
import { FormattedMessage } from 'react-intl';

const DescriptionText = function(props) {
    return (
        <React.Fragment>
            <h2 className={props.commonStyles['header2']}>
                <FormattedMessage id="accountSettings.header" />
            </h2>
            <p className={props.commonStyles['font-medium']}>
                <FormattedMessage id="accountSettings.text" />
            </p>
        </React.Fragment>
    );
};

export default DescriptionText;
