import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import timezones from 'config/timezones';
import { isEmpty, get } from 'lodash';
import { classnames } from '../../../../../../util';

import Tooltip from 'component/Tooltip';

import ConfigurableSelect from 'component/form/Select';
import commonStyles from 'design/styles/common.module.css';
import styles from './TimezoneField.module.css';
import customSelectStyles from './TimezoneField.styles';

class TimezoneField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selectedOption: [],
        };
        this.timezoneSelectConfig = {
            name: 'timezone',
            placeholder: (
                <FormattedMessage id="accountSettings.select.placeholder" />
            ),
            isMulti: false,
            isSearchable: true,
            selectRequiredError: (
                <FormattedMessage id="accountSettings.select.timezoneError" />
            ),
            blurInputOnSelect: true,
        };
    }

    componentDidMount() {
        this.createOptions();
    }

    checkPrefilledOption(options) {
        const selectedOption = this.props.timezone;

        let setTimezone = window.googleAppConfig.websites.find(
            website => website.id <= 0,
        ).timezone;
        if (setTimezone === 'UTC') {
            setTimezone = 'Etc/GMT';
        }

        this.selectChangeHandler(
            options.find(
                option => option.value === (selectedOption || setTimezone),
            ),
        );
    }

    async generateOptions() {
        return await timezones.map(timezone => ({
            value: timezone,
            label: timezone,
        }));
    }

    createOptions() {
        this.generateOptions().then(options => {
            this.setState({ options });
            this.checkPrefilledOption(options);
        });
    }

    setTimezoneOption(selectedOption) {
        this.setState({ selectedOption });
    }

    selectChangeHandler = selectedOption => {
        const hasError = this.props.timeZoneError;
        const value = get(selectedOption, 'value') || '';

        this.props.saveTimeZoneToStore(value);
        this.setTimezoneOption(selectedOption);

        if (hasError && !isEmpty(selectedOption)) {
            this.props.hideTimeZoneError();
        }
    };

    render() {
        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['medium-field-header-right-required'],
            commonStyles['font-semibold'],
        ]);

        return (
            <div className={styles['field-wrapper']}>
                <div className={styles['field-header-wrapper']}>
                    <span className={fieldHeadingStyles}>
                        <FormattedMessage id="accountSettings.select.timezoneHeader" />
                    </span>
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/acct-settings.html"
                        className={commonStyles['cs-tooltip-left-margin']}
                    >
                        <FormattedMessage id="help.attributeMapping.accountSettings.timezone" />
                    </Tooltip>
                </div>
                <ConfigurableSelect
                    styles={customSelectStyles}
                    onChange={this.selectChangeHandler}
                    value={this.state.selectedOption}
                    isMulti={this.timezoneSelectConfig.isMulti}
                    isSearchable={this.timezoneSelectConfig.isSearchable}
                    name={this.timezoneSelectConfig.name}
                    placeholder={this.timezoneSelectConfig.placeholder}
                    options={this.state.options}
                    bottomErrorLayout={this.props.timeZoneError}
                    showError={this.props.timeZoneError}
                    errorMessage={this.timezoneSelectConfig.selectRequiredError}
                    blurInputOnSelect={
                        this.timezoneSelectConfig.blurInputOnSelect
                    }
                    menuIsOpen={true}
                />
            </div>
        );
    }
}

export default TimezoneField;
