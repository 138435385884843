import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty, map, partialRight } from 'lodash';

import { FormattedMessage } from 'react-intl';

import Table from 'component/table';
import { ReactComponent as PendingIcon } from 'design/assets/pending.svg';
import { ReactComponent as ApprovedIcon } from 'design/assets/approved.svg';

import { classnames } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './ProductDisapprovals.module.css';

export default class ProductDisapprovals extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        totalProducts: PropTypes.number.isRequired,
        approvedProducts: PropTypes.number.isRequired,
        disapprovedProducts: PropTypes.number.isRequired,
        lastRefresh: PropTypes.string.isRequired,
        actionClickHandler: PropTypes.func.isRequired,
    };

    mapData = partialRight(map, item => {
        if (!item) {
            return;
        }

        return {
            description: item.description,
            affectedProducts: item.affectedItems,
            issue: item.severity.toLowerCase(),
            action: {
                ...item,
            },
        };
    });

    render() {
        const {
            data,
            loading,
            totalProducts,
            approvedProducts,
            disapprovedProducts,
            lastRefresh,
            actionClickHandler,
        } = this.props;

        const pendingProducts = Math.max(
            totalProducts - approvedProducts - disapprovedProducts,
            0,
        );

        const pageSize = !isEmpty(data) ? Math.min(data.length, 10) : 2;
        const columns = [
            {
                Header: 'Error Description',
                accessor: 'description',
            },
            {
                Header: 'Affected Products',
                accessor: 'affectedProducts',
                width: 180,
            },
            {
                Header: 'Issue',
                accessor: 'issue',
                width: 180,
                Cell: row => (
                    <FormattedMessage
                        id={`gmcDetails.products.table.issues.${row.value}`}
                    />
                ),
            },
            {
                Header: 'Actions',
                accessor: 'action',
                width: 150,
                Cell: row => (
                    <span
                        className={styles.action}
                        onClick={() => actionClickHandler(row.value)}
                    >
                        <FormattedMessage id="gmcDetails.products.table.action" />
                    </span>
                ),
                sortable: false,
            },
        ];

        return (
            <React.Fragment>
                <div
                    className={classnames([
                        commonStyles['font-medium'],
                        styles.text,
                    ])}
                >
                    <FormattedMessage id="gmcDetails.products.text" />
                </div>
                <div className={styles['product-status']}>
                    <div className={styles['last-refresh-time']}>
                        <FormattedMessage id="lastRefreshed" />
                        {lastRefresh}
                    </div>
                    <div className={styles['status-bar']}>
                        <span className={commonStyles['font-semibold']}>
                            <FormattedMessage id="gmcDetails.products.statusBarText" />
                        </span>
                        <span
                            className={classnames([
                                styles['status-element'],
                                styles['total-icon'],
                            ])}
                        >
                            {`${totalProducts} `}
                            <FormattedMessage id="gmcDetails.products.statusBar.total" />
                        </span>
                        <span
                            className={classnames([
                                styles['status-element'],
                                styles['disapproved-icon'],
                            ])}
                        >
                            {`${disapprovedProducts} `}
                            <FormattedMessage id="gmcDetails.products.statusBar.disapproved" />
                        </span>
                        <span className={styles['status-element']}>
                            <ApprovedIcon className={styles['approved-icon']} />
                            {`${approvedProducts} `}
                            <FormattedMessage id="gmcDetails.products.statusBar.approved" />
                        </span>
                        <span className={styles['status-element']}>
                            <PendingIcon className={styles['pending-icon']} />
                            {`${pendingProducts} `}
                            <FormattedMessage id="gmcDetails.products.statusBar.pending" />
                        </span>
                    </div>
                </div>
                <Table
                    data={this.mapData(data)}
                    {...{
                        columns,
                        loading,
                        pageSize,
                    }}
                    showPageSizeOptions={false}
                    noDataText={
                        <FormattedMessage id="gmcDetails.products.table.noDataText" />
                    }
                    defaultSorted={[
                        {
                            id: 'issue',
                            desc: false,
                        },
                        {
                            id: 'description',
                            desc: false,
                        },
                    ]}
                />
            </React.Fragment>
        );
    }
}
