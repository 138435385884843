export const operationStatuses = {
    adCampaignCreationSuccess: 'CREATE_ADWORDS_CAMPAIGN_SUCCEEDED',
    adCampaignCreationFail: 'CREATE_ADWORDS_CAMPAIGN_FAILED',
    adCampaignUpdatingSuccess: 'UPDATE_ADWORDS_CAMPAIGN_SUCCEEDED',
    adCampaignUpdatingFail: 'UPDATE_ADWORDS_CAMPAIGN_FAILED',
    adAccountCreationSuccess: 'CREATE_ADWORDS_ACCOUNT_SUCCEEDED',
    adAccountCreationFail: 'CREATE_ADWORDS_ACCOUNT_FAILED',
    attributesStoringSuccess: 'STORE_ATTRIBUTE_MAPPING_SUCCEEDED',
    attributesStoringFail: 'STORE_ATTRIBUTE_MAPPING_FAILED',
    channelStoringSuccess: 'STORE_GMC_ACCOUNT_DATA_SUCCEEDED',
    channelStoringFail: 'STORE_GMC_ACCOUNT_DATA_FAILED',
    channelAttrUpdateSuccess: 'UPDATE_GMC_ACCOUNT_DATA_SUCCEEDED',
    channelAttrUpdateFail: 'UPDATE_GMC_ACCOUNT_DATA_FAILED',
    gmcAccountCreationSuccess: 'CREATE_GMC_ACCOUNT_SUCCEEDED',
    gmcAccountCreationFail: 'CREATE_GMC_ACCOUNT_FAILED',
    gmcAccountLinked: 'LINK_GMC_ACCOUNT_SUCCEEDED',
    productPullingSuccess: 'UPDATE_GMC_PRODUCTS_SUCCEEDED',
    productPullingFail: 'UPDATE_GMC_PRODUCTS_FAILED',
    productDataMappingSuccess: 'CREATE_OR_UPDATE_PRODUCTS_SUCCEEDED',
    productDataMappingFail: 'CREATE_OR_UPDATE_PRODUCTS_FAILED',
    productRemovingSuccess: 'REMOVE_PRODUCTS_SUCCEEDED',
    productRemovingFail: 'REMOVE_PRODUCTS_FAILED',
    shipmentUpdated: 'SHIPPING_UPDATED',
    submitted: 'SUBMITTED',
    taxUpdated: 'TAX_UPDATED',
};
