import selectStyles from 'component/form/Select/ConfigurableSelect.styles';

const customSelectStyles = { ...selectStyles };

customSelectStyles.control = base => ({
    ...selectStyles.control(base),
    width: 399,
});

customSelectStyles.menu = base => ({
    ...selectStyles.menu(base),
    minWidth: 300,
    width: 'auto',
});

export default customSelectStyles;
