import { ActionCreator } from 'redux';

import { IClearEditType, ISetEditType } from '../../reducers/settingsReducer';
import { CLEAR_EDIT_TYPE, SET_EDIT_TYPE } from '../../store/actionConsts';

export const setEditType: ActionCreator<ISetEditType> = (
    editType: '' | 'url' | 'shipping' | 'mapping',
) => ({
    payload: {
        editType,
    },
    type: SET_EDIT_TYPE,
});

export const clearEditType: ActionCreator<IClearEditType> = () => ({
    type: CLEAR_EDIT_TYPE,
});
