import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setGoogleEmail } from './actions';

export interface GoogleCallbackProps {
    setGoogleEmail: typeof setGoogleEmail;
}

export class GoogleCallback extends Component<GoogleCallbackProps> {
    public render() {
        /* istanbul ignore next */
        if (window.location !== window.parent.location) {
            window.parent.location.assign(window.location.href);
            return null;
        }
        const email = window.location.hash.split('/').slice(-1)[0];
        this.props.setGoogleEmail(email);
        return <Redirect to="/terms" />;
    }
}

export default connect(
    null,
    {
        setGoogleEmail,
    },
)(GoogleCallback);
