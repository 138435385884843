import React, { Component } from 'react';
import styles from './FixedHeader.module.css';

/**
 * A sticky fixed header component.
 */
class FixedHeader extends Component {
    static defaultProps = {
        right: () => null,
    };
    render() {
        return (
            <div className={styles.stick}>
                <div className={styles.mainAction}>
                    <div className={styles.right}>{this.props.right()}</div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default FixedHeader;
