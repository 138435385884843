import Poller from 'helpers/Poller';
import yuiRequest from './yuiRequest';
import { find, reduce, isEmpty } from 'lodash';

export default class OperationRequest {
    constructor(config) {
        this.config = {
            ...config,
            delay: config.delay || 5000,
        };
        this.poller = new Poller(this.request, this.config.delay);
        this.poller.on('done', config.successStatusHandler);
        this.poller.on('error', config.requestErrorHandler);
        this.poller.on('stop', config.failureStatusHandler);
    }

    request = async () => {
        const { operationId } = this.config;

        try {
            const response = await yuiRequest({
                method: 'get',
                uri: `channels/operations?operationId=${operationId}`,
            });
            const data = JSON.parse(response.body);
            const success = data.operations.some(this.findSuccessStatus);
            const fail = this.findFailureStatus(data.operations);

            if (!isEmpty(fail)) {
                this.poller.stop(true, fail);
            } else {
                return !!success;
            }
        } catch (error) {
            throw error;
        }
    };

    findSuccessStatus = operation => {
        return operation.status === this.config.successStatus;
    };

    findFailureStatus(operations) {
        return reduce(
            this.config.failureStatuses,
            (result, failureStatus) => {
                const failData = find(operations, { status: failureStatus });
                return failData ? { ...result, ...failData } : result;
            },
            {},
        );
    }
}
