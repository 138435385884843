import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './ConfirmSettingsModalContent.module.css';

const ConfirmSettingsModalContent = function(props) {
    const headerStyles = [props.commonStyles['header2'], styles.header2];
    const textStyles = [
        props.commonStyles['font-medium'],
        styles['font-medium'],
    ];
    const cancelButtonStyles = [
        props.commonStyles['font-semibold'],
        styles.button,
        styles['cancel-button'],
    ];
    const confirmButtonStyles = [
        props.commonStyles['font-semibold'],
        styles.button,
        styles['confirm-button'],
    ];

    return (
        <React.Fragment>
            <h2 className={headerStyles.join(' ')}>
                <FormattedMessage id="accountSettings.modal.header" />
            </h2>
            <p className={textStyles.join(' ')}>
                <FormattedMessage id="accountSettings.modal.text" />
            </p>
            <button
                className={confirmButtonStyles.join(' ')}
                onClick={props.proceedNextStep}
            >
                <FormattedMessage id="accountSettings.modal.confirmButtonText" />
            </button>
            <button
                className={cancelButtonStyles.join(' ')}
                onClick={props.handleCloseModal}
            >
                <FormattedMessage id="accountSettings.modal.cancelButtonText" />
            </button>
        </React.Fragment>
    );
};

export default ConfirmSettingsModalContent;
