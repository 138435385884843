import { ActionCreator } from 'redux';
import {
    ISaveChannelIdAction,
    ISetGmcAccountIdAction,
    ISetGmcAccountUrlAction,
} from '../../../../reducers/adsCreationReducer';
import {
    SAVE_CHANNEL_ID,
    SET_GMC_ACCOUNT_ID,
    SET_GMC_ACCOUNT_URL,
} from '../../../../store/actionConsts';

export const saveChannelIdToStore: ActionCreator<ISaveChannelIdAction> = (
    id: string,
) => ({
    payload: {
        id,
    },
    type: SAVE_CHANNEL_ID,
});

export const saveGmcAccountUrlToStore: ActionCreator<
    ISetGmcAccountUrlAction
> = (url: string) => ({
    payload: {
        url,
    },
    type: SET_GMC_ACCOUNT_URL,
});

export const saveGmcAccountIdToStore: ActionCreator<ISetGmcAccountIdAction> = (
    id: string,
) => ({
    payload: {
        id,
    },
    type: SET_GMC_ACCOUNT_ID,
});
