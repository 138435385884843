import { ActionCreator } from 'redux';

import {
    ISaveWebsiteIdAction,
    ISaveWebsiteStoresToStoreAction,
} from '../../../../reducers/websiteConfigReducer';
import {
    SAVE_WEBSITE_ID,
    SAVE_WEBSITE_STORES_TO_STORE,
} from '../../../../store/actionConsts';

export const saveWebsiteIdToStore: ActionCreator<ISaveWebsiteIdAction> = (
    id: string,
) => ({
    payload: {
        id,
    },
    type: SAVE_WEBSITE_ID,
});

export const saveWebsiteStoresToStore: ActionCreator<
    ISaveWebsiteStoresToStoreAction
> = (websiteStores: string[]) => ({
    payload: {
        websiteStores,
    },
    type: SAVE_WEBSITE_STORES_TO_STORE,
});
