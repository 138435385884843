import update from 'immutability-helper';
import { filter, map } from 'lodash';
import { Action, Reducer } from 'redux';

import {
    RESET_ATTRIBUTES_STATE,
    SAVE_ATTRIBUTE_VALUE,
    SAVE_LIST_SPECIFIC_ATTRIBUTE_OPTIONS,
    SAVE_PREDEFINED_ATTRIBUTE_VALUE,
    SAVE_SPECIFIC_ATTRIBUTE_OPTION,
    SAVE_SPECIFIC_ATTRIBUTE_VALUE,
    SET_ATTRIBUTE_AS_REQUIRED,
    SET_ATTRIBUTE_ERROR,
    SET_SPECIFIC_ATTRIBUTE_ERROR,
    SET_SPECIFIC_ATTRIBUTE_TABLE_ERROR,
    TOGGLE_MAPPING_EDIT_MODE,
    UNSET_ATTRIBUTE_AS_REQUIRED,
    UNSET_ATTRIBUTE_ERROR,
    UNSET_SPECIFIC_ATTRIBUTE_ERROR,
    UNSET_SPECIFIC_ATTRIBUTE_TABLE_ERROR,
} from '../store/actionConsts';

interface IPredefinedValue
    extends Readonly<{
        predefined?: boolean;
        value: string;
    }> {}

type AttributeData = string | ReadonlyArray<IPredefinedValue> | boolean;

export interface IAttributeMapping
    extends Readonly<{
        isEditMode: boolean;
        attributes: Readonly<{
            [attributeName: string]: Readonly<{
                data: AttributeData;
                error?: boolean;
                yuiSpecificAttrError?: boolean;
                mappingTableError?: boolean;
                specificData?: AttributeData;
                specificOptions?: AttributeData;
                predefinedValues?: ReadonlyArray<IPredefinedValue>;
                required?: boolean;
                [specificAttributeName: string]:
                    | AttributeData
                    | Readonly<{
                          data: AttributeData;
                      }>
                    | undefined;
            }>;
        }>;
    }> {}

export const initialState: IAttributeMapping = {
    attributes: {
        adult: {
            data: [],
            error: false,
            required: false,
        },
        ageGroup: {
            adult: {
                data: [],
            },
            data: [],
            error: false,
            infant: {
                data: [],
            },
            kids: {
                data: [],
            },
            yuiSpecificAttrError: false,
            mappingTableError: false,
            newborn: {
                data: [],
            },
            required: false,
            specificData: [],
            specificOptions: [],
            toddler: {
                data: [],
            },
        },
        ageGroupAttrRadioId: {
            data: '',
            error: false,
            required: false,
        },
        brand: {
            data: [],
            error: false,
            required: false,
        },
        categoryAttrRadioId: {
            data: '',
            error: false,
            required: false,
        },
        color: {
            data: [],
            error: false,
            required: false,
        },
        condition: {
            data: [],
            error: false,
            yuiSpecificAttrError: false,
            mappingTableError: false,
            new: {
                data: [],
            },
            refurbished: {
                data: [],
            },
            required: false,
            specificData: [],
            specificOptions: [],
            used: {
                data: [],
            },
        },
        conditionAttrRadioId: {
            data: '',
            error: false,
            required: false,
        },
        description: {
            data: [],
            error: false,
            predefinedValues: [
                {
                    predefined: false,
                    value: 'description',
                },
            ],
            required: true,
        },
        gender: {
            data: [],
            error: false,
            female: {
                data: [],
            },
            yuiSpecificAttrError: false,
            male: {
                data: [],
            },
            mappingTableError: false,
            required: false,
            specificData: [],
            specificOptions: [],
            unisex: {
                data: [],
            },
        },
        genderAttrRadioId: {
            data: '',
            error: false,
            required: false,
        },
        googleCategoryAttr: {
            data: [],
            error: false,
            required: false,
        },
        gtin: {
            data: [],
            error: false,
            required: false,
        },
        identifierExists: {
            data: [],
            error: false,
            required: false,
        },
        identifierExistsAttrRadioId: {
            data: '',
            error: false,
            required: false,
        },
        yuiCategoryAttr: {
            data: [],
            error: false,
            required: false,
        },
        mpn: {
            data: [],
            error: false,
            required: false,
        },
        newYuiCategoryAttr: {
            data: false,
            required: false,
        },
        sizes: {
            data: [],
            error: false,
            required: false,
        },
        title: {
            data: [],
            error: false,
            predefinedValues: [
                {
                    predefined: false,
                    value: 'name',
                },
            ],
            required: true,
        },
    },
    isEditMode: false,
};

const attributeMapping: Reducer = (
    state: IAttributeMapping = initialState,
    action:
        | IResetAttributesStateAction
        | ISaveAttributeValueAction
        | ISaveListOfSpecificAttributeOptionsAction
        | ISavePredefinedAttributeValueAction
        | ISaveSpecificAttributeValueAction
        | ISaveSpecificAttributeOptionAction
        | ISetAttributeAsRequiredAction
        | ISetAttributeErrorAction
        | ISetSpecificAttributeErrorAction
        | ISetSpecificAttributeTableErrorAction
        | IToggleMappingEditModeAction
        | IUnsetAttributeErrorAction
        | IUnsetAttributeAsRequiredAction
        | IUnsetSpecificAttributeErrorAction
        | IUnsetSpecificAttributeTableErrorAction,
) => {
    switch (action.type) {
        case RESET_ATTRIBUTES_STATE: {
            return initialState;
        }
        case SAVE_ATTRIBUTE_VALUE: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        data: { $set: action.payload },
                    },
                },
            });
        }
        case SAVE_LIST_SPECIFIC_ATTRIBUTE_OPTIONS: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        specificOptions: { $set: action.payload },
                    },
                },
            });
        }
        case SAVE_SPECIFIC_ATTRIBUTE_VALUE: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        specificData: { $set: action.payload },
                    },
                },
            });
        }
        case SAVE_SPECIFIC_ATTRIBUTE_OPTION: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        [action.specificAttributeName]: {
                            data: { $set: action.payload },
                        },
                    },
                },
            });
        }
        case SAVE_PREDEFINED_ATTRIBUTE_VALUE: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        data: { $set: action.payload },
                        predefinedValues: {
                            $set: map(
                                filter(
                                    state.attributes[action.attributeName]
                                        .predefinedValues,
                                    { value: action.payload[0].value },
                                ),
                                item =>
                                    update(item, {
                                        predefined: { $set: true },
                                    }),
                            ),
                        },
                    },
                },
            });
        }
        case SET_ATTRIBUTE_ERROR: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        error: { $set: true },
                    },
                },
            });
        }
        case SET_ATTRIBUTE_AS_REQUIRED: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        required: { $set: true },
                    },
                },
            });
        }
        case SET_SPECIFIC_ATTRIBUTE_ERROR: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        yuiSpecificAttrError: { $set: true },
                    },
                },
            });
        }
        case SET_SPECIFIC_ATTRIBUTE_TABLE_ERROR: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        mappingTableError: { $set: true },
                    },
                },
            });
        }
        case UNSET_ATTRIBUTE_AS_REQUIRED: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        required: { $set: false },
                    },
                },
            });
        }
        case UNSET_SPECIFIC_ATTRIBUTE_ERROR: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        yuiSpecificAttrError: { $set: false },
                    },
                },
            });
        }
        case UNSET_SPECIFIC_ATTRIBUTE_TABLE_ERROR: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        mappingTableError: { $set: false },
                    },
                },
            });
        }
        case TOGGLE_MAPPING_EDIT_MODE: {
            return update(state, {
                isEditMode: { $set: action.value },
            });
        }
        case UNSET_ATTRIBUTE_ERROR: {
            return update(state, {
                attributes: {
                    [action.attributeName]: {
                        error: { $set: false },
                    },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default attributeMapping;

export interface IResetAttributesStateAction extends Action {
    type: typeof RESET_ATTRIBUTES_STATE;
}

export interface ISaveAttributeValueAction extends Action {
    attributeName: string;
    payload: AttributeData;
    type: typeof SAVE_ATTRIBUTE_VALUE;
}

export interface ISaveListOfSpecificAttributeOptionsAction extends Action {
    attributeName: string;
    payload: AttributeData;
    type: typeof SAVE_LIST_SPECIFIC_ATTRIBUTE_OPTIONS;
}

export interface ISaveSpecificAttributeValueAction extends Action {
    attributeName: string;
    payload: AttributeData;
    type: typeof SAVE_SPECIFIC_ATTRIBUTE_VALUE;
}

export interface ISaveSpecificAttributeOptionAction extends Action {
    attributeName: string;
    specificAttributeName: string;
    payload: ReadonlyArray<{ value: string; label: string }>;
    type: typeof SAVE_SPECIFIC_ATTRIBUTE_OPTION;
}

export interface ISavePredefinedAttributeValueAction extends Action {
    attributeName: string;
    payload: ReadonlyArray<IPredefinedValue>;
    type: typeof SAVE_PREDEFINED_ATTRIBUTE_VALUE;
}

export interface ISetAttributeAsRequiredAction extends Action {
    attributeName: string;
    type: typeof SET_ATTRIBUTE_AS_REQUIRED;
}

export interface IUnsetAttributeAsRequiredAction extends Action {
    attributeName: string;
    type: typeof UNSET_ATTRIBUTE_AS_REQUIRED;
}

export interface ISetAttributeErrorAction extends Action {
    attributeName: string;
    type: typeof SET_ATTRIBUTE_ERROR;
}

export interface ISetSpecificAttributeErrorAction extends Action {
    attributeName: string;
    type: typeof SET_SPECIFIC_ATTRIBUTE_ERROR;
}

export interface ISetSpecificAttributeTableErrorAction extends Action {
    attributeName: string;
    type: typeof SET_SPECIFIC_ATTRIBUTE_TABLE_ERROR;
}

export interface IToggleMappingEditModeAction extends Action {
    value: boolean;
    type: typeof TOGGLE_MAPPING_EDIT_MODE;
}

export interface IUnsetAttributeErrorAction extends Action {
    attributeName: string;
    type: typeof UNSET_ATTRIBUTE_ERROR;
}

export interface IUnsetSpecificAttributeErrorAction extends Action {
    attributeName: string;
    type: typeof UNSET_SPECIFIC_ATTRIBUTE_ERROR;
}

export interface IUnsetSpecificAttributeTableErrorAction extends Action {
    attributeName: string;
    type: typeof UNSET_SPECIFIC_ATTRIBUTE_TABLE_ERROR;
}
