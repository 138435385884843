import React, { Component } from 'react';

import { DescriptionText, ShippingCost, SetCostOnGmc } from './components';
import commonStyles from 'design/styles/common.module.css';
import styles from './ShippingCostForm.module.css';

export class ShippingCostForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeRadioButtonId: '',
        };
    }

    componentDidMount() {
        this.checkActiveRadioButton();
    }

    checkActiveRadioButton() {
        const { activeRadioButtonId } = this.props.shippingCost;

        if (activeRadioButtonId) {
            this.setActiveRadioButton(activeRadioButtonId);
        }
    }

    setActiveRadioButtonState = activeRadioButtonId => {
        if (this.props.shippingCostError) {
            this.props.removeShippingCostError();
        }

        this.setActiveRadioButton(activeRadioButtonId);
        this.props.saveActiveRadioButtonIdToStore(activeRadioButtonId);
    };

    setActiveRadioButton(activeRadioButtonId) {
        this.setState({
            activeRadioButtonId,
        });
    }

    render() {
        return (
            <React.Fragment>
                <DescriptionText
                    commonStyles={commonStyles}
                    styles={styles}
                    shippingCostError={this.props.shippingCostError}
                />
                <div>
                    <ShippingCost
                        radioButtonIds={this.props.radioButtonIds}
                        fieldIds={this.props.fieldIds}
                        flatRateCost={
                            this.props.shippingCost.radioButtons.flatRateCost
                        }
                        setActiveRadioButtonState={
                            this.setActiveRadioButtonState
                        }
                        activeRadioButtonId={this.state.activeRadioButtonId}
                        saveFlatRateCostToStore={
                            this.props.saveFlatRateCostToStore
                        }
                        checkFlatRateCostIsValid={
                            this.props.checkFlatRateCostIsValid
                        }
                        flatRateFieldError={this.props.flatRateFieldError}
                        removeShippingCostFlatRateError={
                            this.props.removeShippingCostFlatRateError
                        }
                        removeSetCostOnGmc={this.props.removeSetCostOnGmc}
                    />
                    <SetCostOnGmc
                        radioButtonIds={this.props.radioButtonIds}
                        setActiveRadioButtonState={
                            this.setActiveRadioButtonState
                        }
                        activeRadioButtonId={this.state.activeRadioButtonId}
                        saveSetupCostOnGmc={this.props.saveSetupCostOnGmc}
                        removeShippingCostFlatRateError={
                            this.props.removeShippingCostFlatRateError
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default ShippingCostForm;
