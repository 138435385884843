import { useEffect } from 'react';

export default function WootricSurvey() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.wootric.com/wootric-sdk.js';

        if (process.env.NODE_ENV === 'development') {
            window.wootric_survey_immediately = true;
        }

        window.wootricSettings = {
            email: null,
            created_at: Math.floor(Date.now() / 1000),
            account_token: 'NPS-ffb24225',
        };
        script.async = true;
        document.head.appendChild(script);
        script.onload = () => window.wootric('run');
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return null;
}
