import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

/**
 * A protected router component that disallows rendering if guards are not passed.
 */
const ProtectedRoute = ({ component: Component, ...rest }) => {
    //Going through all defined guards and executing all of them with app state as an argument
    for (let i in rest.guards) {
        if (!rest.guards[i](rest.state)) {
            return <Redirect to="/" />;
        }
    }
    //If guards are passed rendering route as usual
    return <Route {...rest} render={props => <Component {...props} />} />;
};

const mapStateToProps = state => ({
    state: state,
});

export default connect(mapStateToProps)(ProtectedRoute);
