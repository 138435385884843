import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import { FormattedMessage } from 'react-intl';
import { reduce, isEmpty, get } from 'lodash';

import ConfigurableSelect from 'component/form/Select';
import customSelectStyles from '../../StoreViewSelect.style';

export class StoreViewNameField extends Component {
    static propTypes = {
        websiteStoreViews: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired,
        saveStoreViewNameCodeToStore: PropTypes.func.isRequired,
        showDuplicateError: PropTypes.func.isRequired,
        hideDuplicateError: PropTypes.func.isRequired,
        hideStoreViewRequiredError: PropTypes.func.isRequired,
        hideStoreViewsLengthError: PropTypes.func.isRequired,
        storeViewNameOptions: PropTypes.array.isRequired,
        updateViewNameOptionList: PropTypes.func.isRequired,
        isEdit: PropTypes.bool.isRequired,
    };
    state = {
        selectedOption: [],
        isPrefilled: false,
    };

    componentDidMount() {
        this.prefillSelectedOption();
    }

    prefillSelectedOption() {
        const { code } = this.props.data;

        if (!isEmpty(code.value)) {
            this.setState({
                selectedOption: code,
                isPrefilled: true,
            });
            this.props.updateViewNameOptionList();
        }
    }

    validateDuplicate(value) {
        const { data, websiteStoreViews } = this.props;

        return !isEmpty(
            reduce(
                websiteStoreViews.tableData,
                (errors, rowData) => {
                    const { code, uniqueRowId } = rowData;
                    const isCodeDuplicate =
                        !isEmpty(code.value) && value === code.value;
                    const isCurrentRow = data.uniqueRowId === uniqueRowId;

                    if (!isCurrentRow && isCodeDuplicate) {
                        errors.push(rowData);
                        this.props.showDuplicateError();
                        return errors;
                    }
                    return errors;
                },
                [],
            ),
        );
    }

    selectChangeHandler = async selectedOption => {
        const value = get(selectedOption, 'value') || '';
        const label = get(selectedOption, 'label') || '';
        const { uniqueRowId } = this.props.data;
        const payload = {
            value,
            label,
            uniqueRowId,
        };
        const isDuplicate = this.validateDuplicate(value);

        if (!isDuplicate) {
            this.setState({
                selectedOption,
                isPrefilled: false,
            });
            await this.props.saveStoreViewNameCodeToStore(payload);
            this.props.updateViewNameOptionList();
            this.props.hideDuplicateError();
        }
        this.props.hideStoreViewRequiredError();
        this.props.hideStoreViewsLengthError();
    };

    render() {
        const {
            selectChangeHandler,
            state: { selectedOption, isPrefilled },
            props: { isEdit, storeViewNameOptions },
        } = this;

        return (
            <div>
                <ConfigurableSelect
                    isDisabled={isEdit && isPrefilled}
                    styles={customSelectStyles}
                    options={storeViewNameOptions}
                    onChange={selectChangeHandler}
                    value={selectedOption}
                    {...{
                        isMulti: false,
                        blurInputOnSelect: true,
                        isSearchable: true,
                        name: 'storeViewName',
                        placeholder: (
                            <FormattedMessage id="urlVerification.storeViewsTable.selectPlaceholder" />
                        ),
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    websiteStoreViews:
        state.websiteConfig.new.urlVerification.websiteStoreViews,
    isEdit: state.websiteConfig.new.isEdit,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(StoreViewNameField);
