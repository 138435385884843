import React from 'react';
import { FormattedMessage } from 'react-intl';

const DescriptionText = function(props) {
    const headerStyles = [
        props.commonStyles.header3,
        props.commonStyles['font-semibold'],
        props.styles['required-header3'],
    ];
    const textStyles = [
        props.commonStyles['font-medium'],
        props.styles['font-medium'],
    ];

    return (
        <React.Fragment>
            <div className={props.styles['required-header-container']}>
                <span className={headerStyles.join(' ')}>
                    <FormattedMessage id="shippingAndTax.header_1" />
                </span>
                {props.shippingCostError && (
                    <span className={props.styles['required-header-error']}>
                        <FormattedMessage id="shippingAndTax.header_1.error" />
                    </span>
                )}
            </div>
            <p className={textStyles.join(' ')}>
                <FormattedMessage id="shippingAndTax.text_1" />
            </p>
        </React.Fragment>
    );
};

export default DescriptionText;
