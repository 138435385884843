import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty, map, partialRight } from 'lodash';

import { FormattedMessage, FormattedPlural } from 'react-intl';

import Table from 'component/table';

import { classnames } from '../../../../util';

import commonStyles from 'design/styles/common.module.css';
import styles from './AccountErrors.module.css';

export default class AccountErrors extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        lastRefresh: PropTypes.string.isRequired,
    };

    mapData = partialRight(map, item => {
        if (!item) {
            return;
        }

        return {
            description: item.detail,
            issue: item.severity,
            action: item.documentation,
        };
    });

    render() {
        const { data, loading, lastRefresh } = this.props;
        const errorsNumber = data.length;

        const pageSize = !isEmpty(data) ? errorsNumber : 2;
        const columns = [
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Issue',
                accessor: 'issue',
                Cell: row => (
                    <span className={styles[`issue-${row.value}`]}>
                        <FormattedMessage
                            id={`gmcDetails.account.table.issue.${row.value}`}
                        />
                    </span>
                ),
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: row => (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={row.value}
                    >
                        <FormattedMessage id="gmcDetails.account.table.action" />
                    </a>
                ),
                sortable: false,
            },
        ];

        return (
            <React.Fragment>
                <div
                    className={classnames([
                        commonStyles['font-medium'],
                        styles.text,
                    ])}
                >
                    <FormattedMessage id="gmcDetails.account.text" />
                </div>
                <div className={styles['account-status']}>
                    <div className={styles['last-refresh-time']}>
                        <FormattedMessage id="lastRefreshed" />
                        {lastRefresh}
                    </div>
                    {errorsNumber > 0 ? (
                        <div className={styles['status-bar']}>
                            <span className={commonStyles['font-semibold']}>
                                <FormattedMessage id="gmcDetails.account.statusBarText" />
                            </span>
                            <span className={styles['error-icon']}>
                                <FormattedPlural
                                    value={errorsNumber}
                                    one={
                                        <FormattedMessage
                                            id="gmcDetails.account.statusBar.error_one"
                                            values={{
                                                count: errorsNumber,
                                            }}
                                        />
                                    }
                                    other={
                                        <FormattedMessage
                                            id="gmcDetails.account.statusBar.error_many"
                                            values={{
                                                count: errorsNumber,
                                            }}
                                        />
                                    }
                                />
                            </span>
                        </div>
                    ) : null}
                </div>
                <Table
                    data={this.mapData(data)}
                    {...{
                        columns,
                        loading,
                        pageSize,
                    }}
                    defaultSorted={[
                        {
                            id: 'issue',
                            desc: false,
                        },
                    ]}
                    showCounter={false}
                    showPaginationTop={false}
                    noDataText={
                        <FormattedMessage id="gmcDetails.account.table.noDataText" />
                    }
                />
            </React.Fragment>
        );
    }
}
