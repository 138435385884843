import { Action, Reducer } from 'redux';

import {
    SET_RELEASE_NOTES,
    SET_RELEASE_NOTES_HIDDEN,
} from '../store/actionConsts';

export interface ReleaseNotes
    extends Readonly<{
        features: ReadonlyArray<string>;
        hideReleaseNotesChecked: boolean;
        id: string;
    }> {}

export const initialState: ReleaseNotes = {
    features: [],
    hideReleaseNotesChecked: false,
    id: '',
};

function releaseNotesReducer(
    state: ReleaseNotes = initialState,
    action: ISetReleaseNotes | ISetReleaseNotesCheckbox,
) {
    switch (action.type) {
        case SET_RELEASE_NOTES: {
            return { ...state, ...action.payload };
        }
        case SET_RELEASE_NOTES_HIDDEN: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
}

export default releaseNotesReducer as Reducer;

export interface ISetReleaseNotes extends Action {
    payload: {
        id: string;
        features: ReadonlyArray<string>;
    };
    type: typeof SET_RELEASE_NOTES;
}

export interface ISetReleaseNotesCheckbox extends Action {
    payload: {
        hideReleaseNotesChecked: boolean;
    };
    type: typeof SET_RELEASE_NOTES_HIDDEN;
}
