import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as allActionCreators from './actions';
import { FormattedMessage } from 'react-intl';
import { parseAttributesByDataType } from 'helpers/attributeMapping';
import { filter, isEmpty, flatten, map } from 'lodash';
import {
    createSpecificAttributeOptions,
    getSelectedSpecificAttributeOptions,
    updateSelectedSpecificAttributeValues,
} from 'helpers/attributeMapping';

import { RadioButton, ConfigurableSelect } from 'component/form';
import AttributeOptionsMappingForm from 'component/AttributeOptionsMappingForm';
import customSelectStyles from './select.styles';

import Tooltip from 'component/Tooltip';
import commonStyles from 'design/styles/common.module.css';

export class UseYuiAttributesOption extends Component {
    state = {
        options: [],
        selectedOptions: [],
        specificAttributeOptionsMap: [],
    };
    static propTypes = {
        attributeMapping: PropTypes.shape({
            attributes: PropTypes.shape({
                condition: PropTypes.shape({
                    error: PropTypes.bool.isRequired,
                    data: PropTypes.oneOfType([
                        PropTypes.array,
                        PropTypes.object,
                    ]),
                    specificData: PropTypes.array.isRequired,
                    yuiSpecificAttrError: PropTypes.bool.isRequired,
                }),
            }),
        }),
        saveSpecificAttributeToStore: PropTypes.func.isRequired,
        saveSpecificAttributeOptionToStore: PropTypes.func.isRequired,
        validateSpecificYuiAttribute: PropTypes.func.isRequired,
        unsetSpecificMappingTableError: PropTypes.func.isRequired,
        saveListOfSpecificAttributeOptionsToStore: PropTypes.func.isRequired,
    };
    static useYuiAttributesSelectConfig = {
        name: 'condition',
        specificAttributeNames: ['new', 'used', 'refurbished'],
        requiredDataType: ['text', 'select'],
    };

    componentDidMount() {
        const {
            requiredDataType,
        } = UseYuiAttributesOption.useYuiAttributesSelectConfig;
        this.setState({
            options: filter(
                parseAttributesByDataType(requiredDataType),
                attribute => !isEmpty(attribute.attributeOptions),
            ),
        });
        this.updateSpecificAttributeOptions();
    }

    radioButtonChangeHandler = e => {
        this.props.setActiveRadioButtonId(e.target.id);
    };

    attributeSelectHandler = async options => {
        const { useYuiAttributesSelectConfig } = UseYuiAttributesOption;
        const { name } = useYuiAttributesSelectConfig;
        const { specificData } = this.props.attributeMapping.attributes[name];
        const isRemovedAttribute = specificData.length > options.length;
        const updatedMap = flatten(
            map(options, option => option.attributeOptions),
        );

        await Promise.all([
            this.props.saveSpecificAttributeToStore(name, options),
            this.props.validateSpecificYuiAttribute(name, options),
        ]);

        if (isRemovedAttribute) {
            updateSelectedSpecificAttributeValues(
                useYuiAttributesSelectConfig,
                updatedMap,
                this.props,
            );
        }

        this.setState({ specificAttributeOptionsMap: updatedMap });
        this.updateSpecificAttributeOptions();
    };

    specificAttributeSelectHandler = async (specificAttributeName, options) => {
        const {
            name,
        } = UseYuiAttributesOption.useYuiAttributesSelectConfig;

        this.props.unsetSpecificMappingTableError(name);
        await this.props.saveSpecificAttributeOptionToStore(
            name,
            specificAttributeName,
            options,
        );

        this.updateSpecificAttributeOptions();
    };

    updateSpecificAttributeOptions() {
        const { useYuiAttributesSelectConfig } = UseYuiAttributesOption;
        const { name } = useYuiAttributesSelectConfig;
        const selectedOptions = getSelectedSpecificAttributeOptions(
            useYuiAttributesSelectConfig,
            this.props,
        );
        const specificAttributeOptions = createSpecificAttributeOptions(
            useYuiAttributesSelectConfig,
            selectedOptions,
            this.props,
        );

        this.props.saveListOfSpecificAttributeOptionsToStore(
            name,
            specificAttributeOptions,
        );
    }

    render() {
        const { id, label } = this.props.radioButtonsConfig;
        const isRadioChecked = this.props.activeRadioButtonId === id;
        const { optionContentStyle } = this.props;
        const {
            name,
        } = UseYuiAttributesOption.useYuiAttributesSelectConfig;
        const {
            props: {
                attributeMapping: { attributes },
            },
            state: { options },
        } = this;
        const { yuiSpecificAttrError, mappingTableError } = attributes[
            name
        ];
        const error = isRadioChecked && yuiSpecificAttrError;
        const showTableError = isRadioChecked && mappingTableError;
        const selectedAttributes = attributes[name].specificData;

        return (
            <React.Fragment>
                <div className={commonStyles['flex-container']}>
                    <RadioButton
                        id={id}
                        onChange={this.radioButtonChangeHandler}
                        checked={isRadioChecked}
                        labelFor={id}
                        label={label}
                    />
                    <Tooltip
                        direction="left"
                        link="google-ads-attribute-mapping.html"
                    >
                        <FormattedMessage id="help.attributeMapping.specifyCondition" />
                    </Tooltip>
                </div>
                <div className={optionContentStyle}>
                    <ConfigurableSelect
                        styles={customSelectStyles}
                        onChange={this.attributeSelectHandler}
                        value={selectedAttributes}
                        options={options}
                        {...{
                            isMulti: true,
                            isSearchable: true,
                            placeholder: (
                                <FormattedMessage id="attributeMapping.conditionAttribute.select_2.placeholder" />
                            ),
                            errorMessage: (
                                <FormattedMessage id="attributeMapping.specificAttributeCommon.select_2.error" />
                            ),
                            showError: error,
                            isDisabled: !isRadioChecked,
                        }}
                    />
                </div>
                <AttributeOptionsMappingForm
                    {...{
                        attributeName: name,
                        isDisabled: !isRadioChecked,
                        showError: showTableError,
                        googleColumnHeader: (
                            <FormattedMessage id="attributeMapping.conditionAttributeMappingTable.googleHeader" />
                        ),
                        yuiColumnHeader: (
                            <FormattedMessage id="attributeMapping.conditionAttributeMappingTable.yuiHeader" />
                        ),
                        errorMessage: (
                            <FormattedMessage id="attributeMapping.specificAttributeCommon.table.error" />
                        ),
                        rows: [
                            {
                                specificAttributeName: 'new',
                                googleAttributeLabel: (
                                    <FormattedMessage id="attributeMapping.conditionAttributeMappingTable.newHeader" />
                                ),
                                placeholder: (
                                    <FormattedMessage id="attributeMapping.conditionAttributeMappingTable.selectPlaceholder" />
                                ),
                            },
                            {
                                specificAttributeName: 'used',
                                googleAttributeLabel: (
                                    <FormattedMessage id="attributeMapping.conditionAttributeMappingTable.usedHeader" />
                                ),
                                placeholder: (
                                    <FormattedMessage id="attributeMapping.conditionAttributeMappingTable.selectPlaceholder" />
                                ),
                            },
                            {
                                specificAttributeName: 'refurbished',
                                googleAttributeLabel: (
                                    <FormattedMessage id="attributeMapping.conditionAttributeMappingTable.refurbishedHeader" />
                                ),
                                placeholder: (
                                    <FormattedMessage id="attributeMapping.conditionAttributeMappingTable.selectPlaceholder" />
                                ),
                            },
                        ],
                        handleChange: this.specificAttributeSelectHandler,
                    }}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    attributeMapping: state.attributeMapping,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(UseYuiAttributesOption);
