import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import { isEmpty, reduce } from 'lodash';

import { WebsiteUrlField, StoreViewsTable, StoreNameField } from './components';
import styles from './UrlVerificationForm.module.css';

export class UrlVerificationForm extends Component {
    static propTypes = {
        urlVerification: PropTypes.object.isRequired,
        validateRequiredField: PropTypes.func.isRequired,
        showStoreViewRequiredError: PropTypes.func.isRequired,
        saveFieldValueToStore: PropTypes.func.isRequired,
        setStoreViewsTableRequired: PropTypes.func.isRequired,
        unsetStoreViewsTableRequired: PropTypes.func.isRequired,
        isSaving: PropTypes.bool.isRequired,
        confirmSaving: PropTypes.func.isRequired,
        rejectSaving: PropTypes.func.isRequired,
    };

    setStoreViewFormRequiredState(websiteStores) {
        if (!isEmpty(websiteStores)) {
            this.props.setStoreViewsTableRequired();
        } else {
            this.props.unsetStoreViewsTableRequired();
        }
    }

    validateRequiredFields() {
        const { websiteUrl, storeName } = this.props.urlVerification;
        const fields = {
            websiteUrl,
            storeName,
        };

        return isEmpty(
            reduce(
                fields,
                (errors, field, fieldName) => {
                    if (field.required && !field.isValid) {
                        this.props.validateRequiredField(
                            field.value,
                            fieldName,
                        );
                        errors.push(fieldName);
                    }
                    return errors;
                },
                [],
            ),
        );
    }

    validateStoreViewTable() {
        const {
            required,
            tableData,
        } = this.props.urlVerification.websiteStoreViews;

        return (
            !required ||
            isEmpty(
                reduce(
                    tableData,
                    (errors, item) => {
                        if (
                            isEmpty(item.code.value) ||
                            isEmpty(item.language.value) ||
                            isEmpty(item.country.value)
                        ) {
                            this.props.showStoreViewRequiredError();
                            errors.push(item);
                            return errors;
                        }
                        return errors;
                    },
                    [],
                ),
            )
        );
    }

    validateForm() {
        const isFieldsValid = this.validateRequiredFields();
        const isStoreViewTableValid = this.validateStoreViewTable();

        return isFieldsValid && isStoreViewTableValid;
    }

    componentDidMount() {
        const { websiteStores } = this.props.urlVerification.websiteStoreViews;

        this.setStoreViewFormRequiredState(websiteStores);
    }

    componentDidUpdate(prevProps) {
        const prevWebsiteUrl = prevProps.urlVerification.websiteUrl.value;
        const websiteUrl = this.props.urlVerification.websiteUrl.value;
        const { websiteStores } = this.props.urlVerification.websiteStoreViews;

        if (prevWebsiteUrl !== websiteUrl) {
            this.setStoreViewFormRequiredState(websiteStores);
        }

        if (prevProps.isSaving !== this.props.isSaving && this.props.isSaving) {
            const isFormValid = this.validateForm();

            if (isFormValid) {
                this.props.confirmSaving();
            } else {
                this.props.rejectSaving();
            }
        }
    }

    render() {
        const fieldWrapperStyle = styles['url-verification-form-field-wrapper'];
        const {
            props: {
                saveFieldValueToStore,
                validateRequiredField,
                urlVerification: { storeName, websiteStoreViews },
            },
        } = this;
        const isStoreViewsTableRequired = websiteStoreViews.required;

        return (
            <div className={styles['url-verification-form-wrapper']}>
                <div className={fieldWrapperStyle}>
                    <WebsiteUrlField
                        saveFieldValueToStore={saveFieldValueToStore}
                        validateRequiredField={validateRequiredField}
                    />
                </div>
                {isStoreViewsTableRequired && <StoreViewsTable />}
                <div className={fieldWrapperStyle}>
                    <StoreNameField
                        storeName={storeName}
                        saveFieldValueToStore={saveFieldValueToStore}
                        validateRequiredField={validateRequiredField}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    urlVerification: state.websiteConfig.new.urlVerification,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(UrlVerificationForm);
