import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, includes, without } from 'lodash';

import { FormattedMessage } from 'react-intl';

import { classnames } from '../../../../../../util';

import {
    SellOneCategoryOption,
    SellMultipleCategoriesOption,
    CreateNewAttributeOption,
} from './components';
import { RadioButton } from 'component/form';

import commonStyles from 'design/styles/common.module.css';
import styles from './CategoryAttribute.module.css';

export default class CategoryAttribute extends Component {
    static propTypes = {
        attributeMapping: PropTypes.object.isRequired,
        saveAttributeToStore: PropTypes.func.isRequired,
        validateAttribute: PropTypes.func.isRequired,
        setAttributeAsRequired: PropTypes.func.isRequired,
        unsetAttributeAsRequired: PropTypes.func.isRequired,
    };
    state = {
        activeRadioButtonId: '',
    };

    notApplicableRadioId = 'categoryNotApplicable';

    renderLabel(textId) {
        return (
            <span className={styles['radio-label']}>
                <FormattedMessage id={textId} />
            </span>
        );
    }

    setActiveRadioButtonId = id => {
        const requiredRadioIds = ['googleCategoryAttr', 'yuiCategoryAttr'];
        const attributeName = 'categoryAttrRadioId';
        const required = get(
            this.props.attributeMapping.attributes,
            `${attributeName}.required`,
            false,
        );
        const inactiveRadioIds = without(requiredRadioIds, id);

        inactiveRadioIds.forEach(radioId => {
            this.props.unsetAttributeAsRequired(radioId);
        });

        if (includes(requiredRadioIds, id)) {
            this.props.setAttributeAsRequired(id);
        }

        this.setState({ activeRadioButtonId: id });
        this.props.saveAttributeToStore(attributeName, id);

        if (required) {
            this.props.validateAttribute(id, attributeName);
        }
    };

    radioButtonChangeHandler = e => this.setActiveRadioButtonId(e.target.id);

    componentDidMount() {
        const {
            data: storedId,
        } = this.props.attributeMapping.attributes.categoryAttrRadioId;

        if (storedId) {
            this.setState({ activeRadioButtonId: storedId });
        }
    }

    render() {
        const {
            error,
            required,
        } = this.props.attributeMapping.attributes.categoryAttrRadioId;

        const descriptionClasses = [
            commonStyles['font-medium'],
            styles.description,
        ];
        const header3Classes = [
            commonStyles['medium-field-header'],
            commonStyles['font-semibold'],
            required && commonStyles['medium-field-header-left-required'],
            styles.header3,
        ];

        return (
            <tr>
                <td>
                    <h2 className={commonStyles.header2}>
                        <FormattedMessage id="attributeMapping.categoryAttribute.header" />
                    </h2>
                    <p className={classnames(descriptionClasses)}>
                        <FormattedMessage id="attributeMapping.categoryAttribute.descriptionText" />
                    </p>
                    <p className={commonStyles['font-medium']}>
                        <b>
                            <FormattedMessage id="attributeMapping.attribute.requirementsHeader" />
                        </b>
                        <FormattedMessage id="attributeMapping.categoryAttribute.requirementsText" />
                        <a
                            href="https://support.google.com/merchants/answer/6324436"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FormattedMessage id="attributeMapping.categoryAttribute.requirementsLink" />
                        </a>
                    </p>
                </td>
                <td>
                    <div className={styles['attributes-container']}>
                        <h3 className={classnames(header3Classes)}>
                            <FormattedMessage id="attributeMapping.categoryAttribute.header_3" />
                            {error && (
                                <span className={styles['header3-error']}>
                                    <FormattedMessage id="attributeMapping.categoryAttribute.header_3.error" />
                                </span>
                            )}
                        </h3>
                        <div className={styles['option-block']}>
                            <SellOneCategoryOption
                                radioButtonsConfig={{
                                    id: 'googleCategoryAttr',
                                    label: this.renderLabel(
                                        'attributeMapping.categoryAttribute.radio_1',
                                    ),
                                }}
                                activeRadioButtonId={
                                    this.state.activeRadioButtonId
                                }
                                setActiveRadioButtonId={
                                    this.setActiveRadioButtonId
                                }
                                optionContentStyle={styles['option-content']}
                                attributeMapping={this.props.attributeMapping}
                                saveAttributeToStore={
                                    this.props.saveAttributeToStore
                                }
                                validateAttribute={this.props.validateAttribute}
                            />
                        </div>
                        <div className={styles['option-block']}>
                            <SellMultipleCategoriesOption
                                radioButtonsConfig={{
                                    id: 'yuiCategoryAttr',
                                    label: this.renderLabel(
                                        'attributeMapping.categoryAttribute.radio_2',
                                    ),
                                }}
                                activeRadioButtonId={
                                    this.state.activeRadioButtonId
                                }
                                setActiveRadioButtonId={
                                    this.setActiveRadioButtonId
                                }
                                optionContentStyle={styles['option-content']}
                                attributeMapping={this.props.attributeMapping}
                                saveAttributeToStore={
                                    this.props.saveAttributeToStore
                                }
                                validateAttribute={this.props.validateAttribute}
                            />
                        </div>
                        <div className={styles['option-block']}>
                            <CreateNewAttributeOption
                                radioButtonsConfig={{
                                    id: 'newYuiCategoryAttr',
                                    label: this.renderLabel(
                                        'attributeMapping.categoryAttribute.radio_3',
                                    ),
                                }}
                                activeRadioButtonId={
                                    this.state.activeRadioButtonId
                                }
                                setActiveRadioButtonId={
                                    this.setActiveRadioButtonId
                                }
                                optionContentStyle={styles['option-content']}
                                attributeMapping={this.props.attributeMapping}
                                saveAttributeToStore={
                                    this.props.saveAttributeToStore
                                }
                            />
                        </div>
                        <div className={styles['option-block']}>
                            <RadioButton
                                id={this.notApplicableRadioId}
                                onChange={this.radioButtonChangeHandler}
                                checked={
                                    this.state.activeRadioButtonId ===
                                    this.notApplicableRadioId
                                }
                                labelFor={this.notApplicableRadioId}
                                label={this.renderLabel(
                                    'attributeMapping.radio.notApplicable',
                                )}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        );
    }
}
