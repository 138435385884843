import React, { Component } from 'react';

import { CountryField, TimezoneField } from './components';

class AccountSettingsForm extends Component {
    render() {
        return (
            <React.Fragment>
                <CountryField
                    country={this.props.country}
                    saveCountryToStore={this.props.saveCountryToStore}
                    saveCurrencyToStore={this.props.saveCurrencyToStore}
                    showCountryError={this.props.showCountryError}
                    hideCountryError={this.props.hideCountryError}
                    countryError={this.props.countryError}
                />
                <TimezoneField
                    timezone={this.props.timezone}
                    saveTimeZoneToStore={this.props.saveTimeZoneToStore}
                    showTimeZoneError={this.props.showTimeZoneError}
                    hideTimeZoneError={this.props.hideTimeZoneError}
                    timeZoneError={this.props.timeZoneError}
                />
            </React.Fragment>
        );
    }
}

export default AccountSettingsForm;
