import React from 'react';
import { FormattedMessage } from 'react-intl';
import FixedHeader from 'component/FixedHeader';

import styles from './EditCampaignActions.module.css';

export default function EditCampaignActions(props) {
    return (
        <FixedHeader>
            <div className={styles['action-controls']}>
                <button
                    className={styles['cancel-button']}
                    onClick={props.handleCloseSidebar}
                >
                    <FormattedMessage id="editAdCampaign.controlPanel.cancelButton" />
                </button>
                <button
                    className={styles['save-button']}
                    onClick={props.handleSaveChanges}
                >
                    <FormattedMessage id="editAdCampaign.controlPanel.saveButton" />
                </button>
            </div>
        </FixedHeader>
    );
}
