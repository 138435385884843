import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { classnames } from '../../../../util';

import {
    AgeGroupAttribute,
    GenderAttribute,
    ColorAttribute,
    SizeAttribute,
} from './components';

import commonStyles from 'design/styles/common.module.css';
import styles from './ApparelAndAccessoriesForm.module.css';

export class ApparelAndAccessoriesForm extends Component {
    static propTypes = {
        saveAttributeToStore: PropTypes.func.isRequired,
        setAttributeAsRequired: PropTypes.func.isRequired,
        unsetAttributeAsRequired: PropTypes.func.isRequired,
        validateAttribute: PropTypes.func.isRequired,
    };
    render() {
        const tableStyles = classnames([
            commonStyles.table,
            commonStyles['data-grid'],
            styles['apparel-accessories-data-grid'],
        ]);
        const tableHeaderStyles = classnames([
            commonStyles['data-grid-th'],
            commonStyles['font-small'],
            commonStyles['font-semibold'],
        ]);
        const {
            props: {
                saveAttributeToStore,
                setAttributeAsRequired,
                unsetAttributeAsRequired,
                validateAttribute,
            },
        } = this;

        return (
            <React.Fragment>
                <table className={tableStyles}>
                    <thead>
                        <tr>
                            <th className={tableHeaderStyles}>
                                <FormattedMessage id="attributeMapping.GoogleAttributesHeader" />
                            </th>
                            <th className={tableHeaderStyles}>
                                <FormattedMessage id="attributeMapping.YuiAttributesHeader" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <AgeGroupAttribute
                            saveAttributeToStore={saveAttributeToStore}
                            setAttributeAsRequired={setAttributeAsRequired}
                            unsetAttributeAsRequired={unsetAttributeAsRequired}
                            validateAttribute={validateAttribute}
                        />
                        <GenderAttribute
                            saveAttributeToStore={saveAttributeToStore}
                            setAttributeAsRequired={setAttributeAsRequired}
                            unsetAttributeAsRequired={unsetAttributeAsRequired}
                            validateAttribute={validateAttribute}
                        />
                        <ColorAttribute
                            saveAttributeToStore={saveAttributeToStore}
                            validateAttribute={validateAttribute}
                        />
                        <SizeAttribute
                            saveAttributeToStore={saveAttributeToStore}
                            validateAttribute={validateAttribute}
                        />
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default ApparelAndAccessoriesForm;
