import React, { Component } from 'react';
import RadioButton from 'component/form/RadioButton';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { classnames } from '../../../../../../util';

import Tooltip from 'component/Tooltip';
import ConfigurableSelect from 'component/form/Select';
import commonStyles from 'design/styles/common.module.css';
import customSelectStyles from './AutoTaxSelect.style';
import styles from './AutoTaxEstimation.module.css';

import states from 'config/us_states.json';

class AutoTaxEstimation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: states,
            selectedOption: [],
        };
        this.radioButtonConfig = {
            label: <FormattedMessage id="shippingAndTax.tax.radio_1" />,
            id: this.props.radioButtonIds.autoTax,
        };
        this.selectConfig = {
            name: this.props.fieldIds.states,
            placeholder: (
                <FormattedMessage id="shippingAndTax.tax.select.placeholder" />
            ),
            isMulti: true,
            isSearchable: true,
            selectRequiredError: (
                <FormattedMessage id="shippingAndTax.tax.select.errorMessage" />
            ),
        };
    }

    componentDidMount() {
        this.checkPrefilledStates();
    }

    checkPrefilledStates() {
        const options = this.props.autoTax.value;

        if (!isEmpty(options)) {
            this.setOperateStates(options);
        }
    }

    radioButtonChangeHandler = event => {
        this.props.setActiveTaxRadioState(event.target.id);
        this.props.removeVatIncluded();
    };

    setOperateStates(options) {
        const isValid = !isEmpty(options);

        if (isValid) {
            this.props.removeShippingTaxStatesError();
        }

        this.setState({
            selectedOption: options,
        });
        this.props.saveOperateStatesToStore(options, isValid);
    }

    selectChangeHandler = options => {
        this.setOperateStates(options);
    };

    render() {
        const isRadioChecked =
            this.props.activeRadioButtonId === this.radioButtonConfig.id;
        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['font-semibold'],
            styles['select-header'],
        ]);

        return (
            <div>
                <RadioButton
                    id={this.radioButtonConfig.id}
                    onChange={this.radioButtonChangeHandler}
                    checked={isRadioChecked}
                    labelFor={this.radioButtonConfig.id}
                    label={this.radioButtonConfig.label}
                />
                <Tooltip
                    direction="right"
                    link="sales-channels/google-ads/shipping-tax.html"
                >
                    <FormattedMessage id="help.shippingAndTax.autoTaxEstimation" />
                </Tooltip>
                <div className={styles['fields-container']}>
                    <div className={styles['select-container']}>
                        <div
                            className={
                                styles['select-container-header-wrapper']
                            }
                        >
                            <span className={fieldHeadingStyles}>
                                <FormattedMessage id="shippingAndTax.tax.select.header" />
                            </span>
                            <Tooltip
                                direction="right"
                                link="sales-channels/google-ads/shipping-tax.html"
                            >
                                <FormattedMessage id="help.shippingAndTax.selectStates" />
                            </Tooltip>
                        </div>
                        <ConfigurableSelect
                            styles={customSelectStyles}
                            onChange={this.selectChangeHandler}
                            value={this.state.selectedOption}
                            isMulti={this.selectConfig.isMulti}
                            isSearchable={this.selectConfig.isSearchable}
                            name={this.selectConfig.name}
                            placeholder={this.selectConfig.placeholder}
                            options={this.state.options}
                            showError={this.props.statesError}
                            errorMessage={this.selectConfig.selectRequiredError}
                            isDisabled={!isRadioChecked}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default AutoTaxEstimation;
