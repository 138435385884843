import React from 'react';
import { FormattedMessage } from 'react-intl';
import Notification from 'component/notification';

export default function IndexerInvalidationNotification(props) {
    const link = (
        <a
            href="target:blank"
            target="_blank"
            rel="noopener noreferrer"
            onClick={props.indexerInvalidationRequest}
        >
            <FormattedMessage id="notification.indexerInvalidationError.link" />
        </a>
    );

    return (
        <Notification isActive={props.isActive} type="warning">
            <p>
                <FormattedMessage
                    id="notification.indexerInvalidationError"
                    values={{ link }}
                />
            </p>
        </Notification>
    );
}
