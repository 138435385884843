import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { classnames } from '../../../../../../util';

import {
    NoAttributesForProducts,
    IdentifyYuiAttributes,
} from './components';
import { RadioButton } from 'component/form';
import commonStyles from 'design/styles/common.module.css';
import styles from './IdentifierExistsAttribute.module.css';

export class IdentifierExistsAttribute extends Component {
    static propTypes = {
        attributeMapping: PropTypes.shape({
            attributes: PropTypes.shape({
                identifierExistsAttrRadioId: PropTypes.shape({
                    data: PropTypes.string,
                }).isRequired,
            }).isRequired,
        }).isRequired,
        saveAttributeToStore: PropTypes.func.isRequired,
        validateAttribute: PropTypes.func.isRequired,
        setAttributeAsRequired: PropTypes.func.isRequired,
        unsetAttributeAsRequired: PropTypes.func.isRequired,
    };

    notApplicableRadioId = 'identifierExistsNotApplicable';

    state = {
        activeRadioButtonId: this.props.attributeMapping.attributes
            .identifierExistsAttrRadioId.data,
    };

    renderLabel(textId) {
        return (
            <span className={styles['radio-label']}>
                <FormattedMessage id={textId} />
            </span>
        );
    }

    setActiveRadioButtonId = id => {
        const attributeName = 'identifierExistsAttrRadioId';

        this.setState({ activeRadioButtonId: id });
        this.props.saveAttributeToStore(attributeName, id);
        this.props.validateAttribute(id, attributeName);
        id === 'identifyYuiAttributes'
            ? this.props.setAttributeAsRequired('identifierExists')
            : this.props.unsetAttributeAsRequired('identifierExists');
    };

    radioButtonChangeHandler = e => this.setActiveRadioButtonId(e.target.id);

    componentDidMount() {
        const {
            data: storedId,
        } = this.props.attributeMapping.attributes.identifierExistsAttrRadioId;

        if (storedId) {
            storedId === 'identifyYuiAttributes'
                ? this.props.setAttributeAsRequired('identifierExists')
                : this.props.unsetAttributeAsRequired('identifierExists');
        }
    }

    render() {
        const {
            state: { activeRadioButtonId },
            props: {
                attributeMapping,
                saveAttributeToStore,
                validateAttribute,
            },
        } = this;
        const {
            error,
            required,
        } = attributeMapping.attributes.identifierExistsAttrRadioId;
        const descriptionClasses = classnames([
            commonStyles['font-medium'],
            styles.description,
        ]);
        const header3Classes = classnames([
            commonStyles['medium-field-header'],
            commonStyles['font-semibold'],
            required && commonStyles['medium-field-header-left-required'],
            styles.header3,
        ]);

        return (
            <tr>
                <td className={styles['table-data-grid-td']}>
                    <h2 className={commonStyles.header2}>
                        <FormattedMessage id="attributeMapping.identifierExistsAttribute.header" />
                    </h2>
                    <p className={descriptionClasses}>
                        <FormattedMessage id="attributeMapping.identifierExistsAttribute.descriptionText" />
                    </p>
                </td>
                <td className={styles['condition-data-grid-td']}>
                    <div className={styles['attributes-container']}>
                        <h3 className={header3Classes}>
                            <FormattedMessage id="attributeMapping.identifierExistsAttribute.requiredHeader" />
                            {error && (
                                <span className={styles['header3-error']}>
                                    <FormattedMessage id="attributeMapping.specificAttributeCommon.header_3.error" />
                                </span>
                            )}
                        </h3>
                        <div className={styles['option-block']}>
                            <NoAttributesForProducts
                                radioButtonsConfig={{
                                    id: 'noAttributesForProducts',
                                    label: this.renderLabel(
                                        'attributeMapping.identifierExistsAttribute.radio_1',
                                    ),
                                }}
                                activeRadioButtonId={activeRadioButtonId}
                                setActiveRadioButtonId={
                                    this.setActiveRadioButtonId
                                }
                                unsetAttributeAsRequired={
                                    this.props.unsetAttributeAsRequired
                                }
                                optionContentStyle={styles['option-content']}
                                attributeMapping={attributeMapping}
                            />
                        </div>
                        <div className={styles['option-block']}>
                            <IdentifyYuiAttributes
                                radioButtonsConfig={{
                                    id: 'identifyYuiAttributes',
                                    label: this.renderLabel(
                                        'attributeMapping.identifierExistsAttribute.radio_2',
                                    ),
                                }}
                                activeRadioButtonId={activeRadioButtonId}
                                setActiveRadioButtonId={
                                    this.setActiveRadioButtonId
                                }
                                optionContentStyle={styles['option-content']}
                                attributeMapping={attributeMapping}
                                saveAttributeToStore={saveAttributeToStore}
                                validateAttribute={validateAttribute}
                            />
                        </div>
                        <div className={styles['option-block']}>
                            <RadioButton
                                id={this.notApplicableRadioId}
                                onChange={this.radioButtonChangeHandler}
                                checked={
                                    this.state.activeRadioButtonId ===
                                    this.notApplicableRadioId
                                }
                                labelFor={this.notApplicableRadioId}
                                label={this.renderLabel(
                                    'attributeMapping.radio.notApplicable',
                                )}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        );
    }
}

const mapStateToProps = state => ({
    attributeMapping: state.attributeMapping,
});

export default connect(mapStateToProps)(IdentifierExistsAttribute);
