import React from 'react';
import ReactDOM from 'react-dom';

import '@babel/polyfill';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'whatwg-fetch';

import App from './App';
import './index.css';
import createStore from './store';

window.renderGoogleApp = function renderGoogleApp() {
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'development') {
        if (process.env.REACT_APP_RUN_INSIDE_ADMIN !== 'true') {
            if (process.env.REACT_APP_GOOGLE_APP_CONFIG) {
                window.googleAppConfig = {
                    ...JSON.parse(process.env.REACT_APP_GOOGLE_APP_CONFIG),
                    ...require('./config/proxies.json'),
                };
            }
            window.FORM_KEY = process.env.REACT_APP_FORM_KEY!;
            window.websiteConfig = {
                new: {
                    email: process.env.REACT_APP_GOOGLE_LOGIN_EMAIL!,
                },
            };
        }
    }
    ReactDOM.render(
        <App store={createStore()} />,
        document.getElementById('google_app'),
    );
};
