import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { find, floor, get, toNumber, endsWith, toString } from 'lodash';
import currencies from 'config/currencies';
import { isNumeric } from '../../../../util';
import { classnames } from '../../../../util';

import Tooltip from 'component/Tooltip';

import Input from '../../../../component/form/Input';
import commonStyles from 'design/styles/common.module.css';
import styles from './DailyBudgetField.module.css';

export class DailyBudgetField extends Component {
    static propTypes = {
        isEditMode: PropTypes.bool.isRequired,
        fieldId: PropTypes.string.isRequired,
        dailyBudgetError: PropTypes.bool.isRequired,
        unsetFieldError: PropTypes.func.isRequired,
        saveDailyBudgetToStore: PropTypes.func.isRequired,
    };
    state = {
        code: 'USD',
        symbol: '$',
        cost: '',
    };

    componentDidMount() {
        const currencyCode = this.props.adsSettings.currency;

        this.setCurrency(
            currencyCode ||
                get(window.googleAppConfig, 'adConfig.currency', ''),
        );
        this.prefillSelectedValue();
    }

    setCurrency(currencyCode) {
        const currency = find(
            currencies,
            currencyItem => currencyItem.code === currencyCode,
        );

        this.setState({ ...currency });
    }

    prefillSelectedValue() {
        const {
            isEditMode,
            adsCreation: { dailyBudget, editCampaignData },
        } = this.props;
        const cost = isEditMode ? editCampaignData.budget : dailyBudget;

        if (cost) {
            this.setState({ cost });

            if (isEditMode) {
                this.props.saveDailyBudgetToStore(cost);
            }
        }
    }

    inputChangeHandler = event => {
        const { value } = event.target;

        if (value !== '' && !isNumeric(value)) return;

        this.setState({
            cost:
                endsWith(value, '.') || endsWith(value, '.0')
                    ? value
                    : toString(floor(value, 2)),
        });
    };

    inputBlurHandler = event => {
        const valueAsNumber = toNumber(event.target.value);
        const processedValue = !isNaN(valueAsNumber)
            ? Math.max(0.01, Math.min(valueAsNumber, 100000)).toString()
            : '';

        if (processedValue && this.props.dailyBudgetError) {
            this.props.unsetFieldError(this.props.fieldId);
        }

        this.props.saveDailyBudgetToStore(processedValue);
        this.setState({ cost: processedValue });
    };

    render() {
        const inputRightContentStyles = classnames([
            styles['input-right-content'],
            commonStyles['font-regular'],
        ]);
        const inputLeftContentStyles = classnames([
            styles['input-left-content'],
            commonStyles['font-regular'],
        ]);
        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['medium-field-header-right-required'],
            commonStyles['font-semibold'],
        ]);
        const {
            props: { dailyBudgetError, fieldId },
            state: { code, cost, symbol },
        } = this;

        return (
            <React.Fragment>
                <div className={styles['header-wrapper']}>
                    <span className={fieldHeadingStyles}>
                        <FormattedMessage id="smartShoppingCampaign.dailyBudgetInput.title" />
                    </span>
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/create-campaign.html"
                        className={commonStyles['cs-tooltip-left-margin']}
                    >
                        <FormattedMessage id="help.attributeMapping.adForm.dailyBudget" />
                    </Tooltip>
                </div>
                <div className={styles['input-container']}>
                    <Input
                        {...{
                            className: styles.input,
                            errorMessage: dailyBudgetError && (
                                <FormattedMessage
                                    id={[
                                        'smartShoppingCampaign',
                                        'dailyBudgetInput',
                                        'errorMessage',
                                    ].join('.')}
                                />
                            ),
                            name: fieldId,
                            onBlur: this.inputBlurHandler,
                            onChange: this.inputChangeHandler,
                            renderInput: input => (
                                <div className={styles['input-wrapper']}>
                                    {input}
                                </div>
                            ),
                            value: cost,
                        }}
                    />
                    <span className={inputLeftContentStyles}>{symbol}</span>
                    <span className={inputRightContentStyles}>{code}</span>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adsCreation: state.adsCreation,
    adsSettings: state.adsSettings,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(DailyBudgetField);
