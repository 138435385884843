import { noop } from 'lodash';

import { isNumeric } from '../../util';

class ErrorDataCachingService {
    constructor() {
        this._isTimerRunning = false;
        this._updatingPeriod = 900000;
    }

    init() {
        if (!this._isTimerRunning) {
            this._isTimerRunning = true;

            const fetchTime = window.sessionStorage.getItem('fetchTime');

            if (
                fetchTime &&
                Date.now() - parseFloat(fetchTime) < this._updatingPeriod
            ) {
                const run = () => {
                    window.sessionStorage.setItem('fetchTime', Date.now());

                    this.fetchData().catch(noop);

                    setTimeout(run, this._updatingPeriod);
                };

                const fetchingDeltaTime =
                    this._updatingPeriod - (Date.now() - parseFloat(fetchTime));

                setTimeout(run, fetchingDeltaTime);

                return Promise.resolve();
            } else {
                window.sessionStorage.setItem('fetchTime', Date.now());

                return this.fetchData()
                    .then(() => {
                        const run = () => {
                            window.sessionStorage.setItem(
                                'fetchTime',
                                Date.now(),
                            );

                            this.fetchData().catch(noop);

                            setTimeout(run, this._updatingPeriod);
                        };

                        setTimeout(run, this._updatingPeriod);

                        return Promise.resolve();
                    })
                    .catch(noop);
            }
        }

        return Promise.resolve();
    }

    async fetchData() {
        let formData = new FormData();
        formData.append('form_key', window.FORM_KEY);
        formData.append('action', 'getErrors');
        formData.append(
            'payload',
            JSON.stringify({
                channelId:
                    window.googleAppConfig.configs.websiteChannels[1].channelId,
            }),
        );

        try {
            const response = await fetch(
                window.googleAppConfig.yuiMiddlewareUrl + '?isAjax=true',
                {
                    method: 'POST',
                    body: formData,
                },
            );
            const json = await response.json();

            window.sessionStorage.setItem('errorData', JSON.stringify(json));

            return Promise.resolve();
        } catch (msg) {
            return Promise.reject(msg);
        }
    }

    getData() {
        const localData = JSON.parse(
            window.sessionStorage.getItem('errorData'),
        );

        return localData;
    }

    get updatingPeriod() {
        return this._updatingPeriod;
    }

    set updatingPeriod(value) {
        if (!isNumeric(value)) {
            throw new Error('Expected numeric value for period.');
        }
        this._updatingPeriod = value;
    }
}

export const errorDataCachingService = new ErrorDataCachingService();
