import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Notification from 'component/notification';

export default function PaymentNotification(props) {
    return (
        <Notification isActive={props.isActive} type="warning">
            <p>
                <FormattedMessage id="dashboardLanding.notify.payment.addPaymentText" />{' '}
                <a
                    href="https://google.com/adwords"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FormattedMessage id="dashboardLanding.notify.payment.addPaymentLink" />
                </a>
                {'. '}
                <FormattedMessage id="dashboardLanding.notify.payment.confirmText" />{' '}
                <Link to="settings">
                    <FormattedMessage id="dashboardLanding.notify.payment.confirmLink" />
                </Link>
            </p>
        </Notification>
    );
}
