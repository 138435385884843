import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as allActionCreators from './actions';
import uuidv1 from 'uuid/v1';
import { FormattedMessage } from 'react-intl';
import { map, filter, isEmpty, reduce, some } from 'lodash';

import { classnames } from '../../../../util';

import { TableHead, StoreViewRow } from './components';
import Tooltip from 'component/Tooltip';

import commonStyles from 'design/styles/common.module.css';
import styles from './StoreViewsTable.module.css';

export class StoreViewsTable extends Component {
    state = {
        storeViewNameOptions: this.createViewNameOptions(),
        isStoreViewsLengthError: false,
        isStoreViewsDuplicateError: false,
    };

    static propTypes = {
        websiteUrl: PropTypes.object.isRequired,
        websiteStoreViews: PropTypes.object.isRequired,
        saveTableDataToStore: PropTypes.func.isRequired,
        hideStoreViewRequiredError: PropTypes.func.isRequired,
        isEdit: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        this.prefillSelectedStoreViews();
    }

    componentDidUpdate(prevProps) {
        const previousWebsiteUrl = prevProps.websiteUrl.value;
        const prevAmountOfRows = prevProps.websiteStoreViews.tableData.length;
        const currentAmountOfRows = this.props.websiteStoreViews.tableData
            .length;
        const newWebsiteUrl = this.props.websiteUrl.value;

        if (newWebsiteUrl !== previousWebsiteUrl) {
            this.resetRows();
            this.updateViewNameOptionList();
            this.hideStoreViewsLengthError();
        }
        if (prevAmountOfRows !== currentAmountOfRows) {
            this.updateViewNameOptionList();
        }
    }

    createViewNameOptions() {
        const { websiteStores } = this.props.websiteStoreViews;

        return map(websiteStores, view => ({
            label: view.name,
            value: view.code,
        }));
    }

    updateViewNameOptionList = () => {
        const {
            websiteStoreViews: { tableData, websiteStores },
        } = this.props;

        this.setState({
            storeViewNameOptions: reduce(
                websiteStores,
                (options, storeView) =>
                    some(tableData, row => row.code.value === storeView.code)
                        ? options
                        : [
                              ...options,
                              { label: storeView.name, value: storeView.code },
                          ],
                [],
            ),
        });
    };

    showDuplicateError = () => {
        this.setState({ isStoreViewsDuplicateError: true });
    };

    hideDuplicateError = () => {
        this.setState({ isStoreViewsDuplicateError: false });
    };

    resetRows() {
        const tableData = [this.createEmptyRowData()];

        this.setState({ tableData });
        this.props.saveTableDataToStore(tableData);
    }

    prefillSelectedStoreViews() {
        const { tableData } = this.props.websiteStoreViews;

        if (!isEmpty(tableData)) {
            this.setState({ tableData });
        } else {
            this.addEmptyRow();
        }
    }

    createEmptyRowData() {
        const uniqueRowId = uuidv1();

        return {
            uniqueRowId,
            code: {
                value: '',
                label: '',
            },
            country: {
                value: '',
                label: '',
            },
            language: {
                value: '',
                label: '',
            },
            languages: [],
        };
    }

    hideStoreViewsLengthError = () => {
        this.setState({ isStoreViewsLengthError: false });
    };

    addEmptyRow = () => {
        const { tableData } = this.props.websiteStoreViews;
        const storeViewsLength = tableData.length;

        if (storeViewsLength <= 49) {
            const newTableData = [...tableData, this.createEmptyRowData()];

            this.setState({ tableData: newTableData });
            this.props.saveTableDataToStore(newTableData);
        } else {
            this.setState({ isStoreViewsLengthError: true });
        }
    };

    removeRow = uniqueRowId => {
        const { tableData } = this.props.websiteStoreViews;
        const newTableData = filter(tableData, storeViewRow => {
            if (storeViewRow.uniqueRowId !== uniqueRowId) {
                return storeViewRow;
            }
        });

        if (this.state.isStoreViewsLengthError) {
            this.setState({ isStoreViewsLengthError: false });
        }

        this.props.saveTableDataToStore(newTableData);
    };

    render() {
        const tableStyles = classnames([
            commonStyles.table,
            styles.table,
            commonStyles['data-grid'],
        ]);
        const fieldHeadingStyles = classnames([
            commonStyles['medium-field-header'],
            commonStyles['medium-field-header-right-required'],
            commonStyles['font-semibold'],
        ]);
        const addButtonStyles = classnames([
            styles['add-button'],
            commonStyles['font-regular'],
        ]);
        const storeViewsError = classnames([
            commonStyles['bottom-error'],
            styles['bottom-error'],
        ]);
        const {
            removeRow,
            showDuplicateError,
            hideDuplicateError,
            hideStoreViewsLengthError,
            props: {
                hideStoreViewRequiredError,
                websiteStoreViews: { requiredError, tableData },
                isEdit,
            },
            state: { storeViewNameOptions },
        } = this;

        return (
            <React.Fragment>
                <div className={styles['store-views-header-wrapper']}>
                    <span className={fieldHeadingStyles}>
                        <FormattedMessage id="urlVerification.storeViewsTable.mainHeader" />
                    </span>
                    <Tooltip
                        direction="right"
                        link="sales-channels/google-ads/url-verify.html"
                        className={commonStyles['cs-tooltip-left-margin']}
                    >
                        <FormattedMessage id="help.urlVerification.storeViews" />
                    </Tooltip>
                </div>
                <table className={tableStyles}>
                    <TableHead />
                    <tbody>
                        {map(tableData, (data, index) => (
                            <StoreViewRow
                                isEdit={isEdit}
                                key={data.uniqueRowId}
                                index={index}
                                data={data}
                                removeRow={removeRow}
                                storeViewNameOptions={storeViewNameOptions}
                                updateViewNameOptionList={
                                    this.updateViewNameOptionList
                                }
                                showDuplicateError={showDuplicateError}
                                hideDuplicateError={hideDuplicateError}
                                hideStoreViewRequiredError={
                                    hideStoreViewRequiredError
                                }
                                hideStoreViewsLengthError={
                                    hideStoreViewsLengthError
                                }
                            />
                        ))}
                    </tbody>
                </table>
                {requiredError && (
                    <span className={storeViewsError}>
                        <FormattedMessage id="urlVerification.storeViewsTable.requiredDataError" />
                    </span>
                )}
                {this.state.isStoreViewsDuplicateError && (
                    <span className={storeViewsError}>
                        <FormattedMessage id="urlVerification.storeViewsTable.uniqueCountryAndLanguageError" />
                    </span>
                )}
                {this.state.isStoreViewsLengthError && (
                    <span className={storeViewsError}>
                        <FormattedMessage id="urlVerification.storeViewsTable.viewsLengthError" />
                    </span>
                )}
                {isEmpty(tableData) && (
                    <span className={storeViewsError}>
                        <FormattedMessage id="urlVerification.storeViewsTable.minViewsLengthError" />
                    </span>
                )}
                <button className={addButtonStyles} onClick={this.addEmptyRow}>
                    <FormattedMessage id="urlVerification.storeViewsTable.addViewButton" />
                </button>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    websiteStoreViews:
        state.websiteConfig.new.urlVerification.websiteStoreViews,
    websiteUrl: state.websiteConfig.new.urlVerification.websiteUrl,
    isEdit: state.websiteConfig.new.isEdit,
});

export default connect(
    mapStateToProps,
    allActionCreators,
)(StoreViewsTable);
