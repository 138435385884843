import React, { Component } from 'react';
import styles from './CheckBox.module.css';

class CheckBox extends Component {
    render() {
        return (
            <div
                className={
                    this.props.disabled ? styles['checkbox-disabled'] : ''
                }
            >
                <input
                    type="checkbox"
                    className={styles.checkbox}
                    id={this.props.id}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    checked={this.props.checked}
                    disabled={this.props.disabled}
                />
                <label htmlFor={this.props.id} className={styles.label}>
                    {this.props.labelText}
                </label>
                {this.props.showError && (
                    <div className={styles.checkboxError}>
                        {this.props.errorMessage}
                    </div>
                )}
            </div>
        );
    }
}

export default CheckBox;
