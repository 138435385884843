import React from 'react';
import Notification from 'component/notification';
import { FormattedMessage } from 'react-intl';
import { createYuiDocsLink } from '../../../../util';

export default function AttributeCreationErrorNotify(props) {
    const link = createYuiDocsLink(
        'sales-channel/google-ads/products-edit-mappings.html',
    );

    return (
        <Notification type="error" isActive={props.isActive}>
            <p>
                <FormattedMessage id="gmcDetails.attributeCreationError.text" />
                .{' '}
                <a href={link} rel="noopener noreferrer" target="_blank">
                    <FormattedMessage id="gmcDetails.attributeCreationError.link" />
                </a>
                .
            </p>
        </Notification>
    );
}
