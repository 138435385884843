import update from 'immutability-helper';
import { Action, Reducer } from 'redux';

import {
    RESET_ADS_CREATION_STATE,
    SAVE_BRANDS_FILTER,
    SAVE_CAMPAIGN_NAMES_TO_STORE,
    SAVE_CATEGORIES_FILTER,
    SAVE_CHANNEL_ID,
    SAVE_CHANNELS_LIST,
    SAVE_EDIT_CAMPAIGN_DATA_TO_STORE,
    SAVE_EXCLUDED_PRODUCTS,
    SAVE_FILTERS_ACTIVE_RADIO,
    SAVE_INCLUDED_PRODUCTS,
    SAVE_SELECT_ALL_OPTION,
    SET_CAMPAIGN_NAME,
    SET_COUNTRY_TO_ADVERTISE,
    SET_DAILY_BUDGET,
    SET_GMC_ACCOUNT_ID,
    SET_GMC_ACCOUNT_URL,
    TOGGLE_TABLE_SELECT_ALL,
} from '../store/actionConsts';

export interface IAdsCreation
    extends Readonly<{
        activeRadioButtonId: string;
        allCampaignNames: ReadonlyArray<string>;
        allSKUsAreSelected: boolean;
        campaignName: string;
        channelId: string;
        channelsList: ReadonlyArray<IChannelsListData>;
        countryToAdvertise: string;
        dailyBudget: string;
        editCampaignData: ReadonlyArray<IEditCampaignData>;
        excludedProductsBySKU: ReadonlyArray<string>;
        gmcAccount: string;
        gmcAccountId: string;
        includedProductsBySKU: ReadonlyArray<string>;
        selectedAll: boolean;
        selectedFilterBrands: ReadonlyArray<string>;
        selectedFilterCategories: ReadonlyArray<string>;
    }> {}

export interface IChannelsListData
    extends Readonly<{
        id: string;
        gmcId: string;
        name: string;
        url: string;
        storeSettings: Readonly<IChannelStoresList>;
    }> {}

export interface IChannelStoresList
    extends Readonly<{
        [key: string]: Readonly<{
            country: string;
            language: string;
        }>;
    }> {}

export interface IEditCampaignData
    extends Readonly<{
        gmcId: string;
        campaignId: string;
        targetCountry: string;
        name: string;
        budget: string;
        filterType: string;
        filters: ReadonlyArray<IEditCampaignFiltersData>;
        skuSelectedAll: string;
        status: string;
        productList: ReadonlyArray<string>;
    }> {}

export const initialState: IAdsCreation = {
    activeRadioButtonId: 'selectAll',
    allCampaignNames: [],
    allSKUsAreSelected: false,
    campaignName: '',
    channelId: '',
    channelsList: [],
    countryToAdvertise: '',
    dailyBudget: '',
    editCampaignData: [],
    excludedProductsBySKU: [],
    gmcAccount: '',
    gmcAccountId: '',
    includedProductsBySKU: [],
    selectedAll: true,
    selectedFilterBrands: [],
    selectedFilterCategories: [],
};

const adsCreationReducer: Reducer = (
    state: IAdsCreation = initialState,
    action:
        | IResetAdsCreationStateAction
        | ISaveBrandsFilterAction
        | ISaveCampaignNamesToStore
        | ISaveCategoriesFilterAction
        | ISaveChannelIdAction
        | ISaveChannelsAction
        | ISaveEditCampaignDataToStore
        | ISaveExcludedProductsAction
        | ISaveFiltersActiveRadioAction
        | ISaveIncludedProductsAction
        | ISaveSelectAllOptionAction
        | ISetCampaignName
        | ISetCountryToAdvertiseAction
        | ISetDailyBudgetAction
        | ISetGmcAccountIdAction
        | ISetGmcAccountUrlAction
        | IToggleTableSelectAllAction,
) => {
    switch (action.type) {
        case RESET_ADS_CREATION_STATE: {
            return update(state, {
                $merge: initialState,
                allCampaignNames: { $set: state.allCampaignNames },
                channelsList: { $set: state.channelsList },
            });
        }
        case SET_GMC_ACCOUNT_URL: {
            return update(state, {
                gmcAccount: { $set: action.payload.url },
            });
        }
        case SET_GMC_ACCOUNT_ID: {
            return update(state, {
                gmcAccountId: { $set: action.payload.id },
            });
        }
        case SET_COUNTRY_TO_ADVERTISE: {
            return update(state, {
                countryToAdvertise: { $set: action.payload.country },
            });
        }
        case SET_CAMPAIGN_NAME: {
            return update(state, {
                campaignName: { $set: action.payload.name },
            });
        }
        case SET_DAILY_BUDGET: {
            return update(state, {
                dailyBudget: { $set: action.payload.budget },
            });
        }
        case SAVE_BRANDS_FILTER: {
            return update(state, {
                selectedFilterBrands: { $set: action.payload.brands },
            });
        }
        case SAVE_CAMPAIGN_NAMES_TO_STORE: {
            return update(state, {
                allCampaignNames: { $set: action.payload.allCampaignNames },
            });
        }
        case SAVE_EDIT_CAMPAIGN_DATA_TO_STORE: {
            return update(state, {
                editCampaignData: { $set: action.payload },
            });
        }
        case SAVE_CHANNEL_ID: {
            return update(state, {
                channelId: { $set: action.payload.id },
            });
        }
        case SAVE_CHANNELS_LIST: {
            return update(state, {
                channelsList: { $set: action.payload.channels },
            });
        }
        case SAVE_FILTERS_ACTIVE_RADIO: {
            return update(state, {
                activeRadioButtonId: { $set: action.payload.id },
            });
        }
        case SAVE_SELECT_ALL_OPTION: {
            return update(state, {
                selectedAll: { $set: action.payload.selectedAll },
            });
        }
        case SAVE_CATEGORIES_FILTER: {
            return update(state, {
                selectedFilterCategories: { $set: action.payload.categories },
            });
        }
        case TOGGLE_TABLE_SELECT_ALL: {
            return update(state, {
                allSKUsAreSelected: { $set: action.payload.selectAll },
            });
        }
        case SAVE_INCLUDED_PRODUCTS: {
            return update(state, {
                includedProductsBySKU: {
                    $set: action.payload.includedProducts,
                },
            });
        }
        case SAVE_EXCLUDED_PRODUCTS: {
            return update(state, {
                excludedProductsBySKU: {
                    $set: action.payload.excludedProducts,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default adsCreationReducer;

export interface IResetAdsCreationStateAction extends Action {
    type: typeof RESET_ADS_CREATION_STATE;
}

export interface ISetGmcAccountUrlAction extends Action {
    type: typeof SET_GMC_ACCOUNT_URL;
    payload: {
        url: string;
    };
}

export interface ISetGmcAccountIdAction extends Action {
    type: typeof SET_GMC_ACCOUNT_ID;
    payload: {
        id: string;
    };
}

export interface ISetCountryToAdvertiseAction extends Action {
    type: typeof SET_COUNTRY_TO_ADVERTISE;
    payload: {
        country: string;
    };
}

export interface ISetCampaignName extends Action {
    type: typeof SET_CAMPAIGN_NAME;
    payload: {
        name: string;
    };
}

export interface ISetDailyBudgetAction extends Action {
    type: typeof SET_DAILY_BUDGET;
    payload: {
        budget: string;
    };
}

export interface ISaveBrandsFilterAction extends Action {
    type: typeof SAVE_BRANDS_FILTER;
    payload: {
        brands: ReadonlyArray<string>;
    };
}

export interface IEditCampaignFiltersData {
    type: string;
    value: string;
}

export interface ISaveCampaignNamesToStore extends Action {
    type: typeof SAVE_CAMPAIGN_NAMES_TO_STORE;
    payload: {
        allCampaignNames: ReadonlyArray<string>;
    };
}

export interface ISaveChannelIdAction extends Action {
    type: typeof SAVE_CHANNEL_ID;
    payload: {
        id: string;
    };
}

export interface ISaveChannelsAction extends Action {
    type: typeof SAVE_CHANNELS_LIST;
    payload: {
        channels: ReadonlyArray<IChannelsListData>;
    };
}

export interface ISaveEditCampaignDataToStore extends Action {
    type: typeof SAVE_EDIT_CAMPAIGN_DATA_TO_STORE;
    payload: ReadonlyArray<IEditCampaignData>;
}

export interface ISaveFiltersActiveRadioAction extends Action {
    type: typeof SAVE_FILTERS_ACTIVE_RADIO;
    payload: {
        id: string;
    };
}

export interface ISaveSelectAllOptionAction extends Action {
    type: typeof SAVE_SELECT_ALL_OPTION;
    payload: {
        selectedAll: boolean;
    };
}

export interface ISaveCategoriesFilterAction extends Action {
    type: typeof SAVE_CATEGORIES_FILTER;
    payload: {
        categories: ReadonlyArray<string>;
    };
}

export interface IToggleTableSelectAllAction extends Action {
    type: typeof TOGGLE_TABLE_SELECT_ALL;
    payload: {
        selectAll: boolean;
    };
}

export interface ISaveIncludedProductsAction extends Action {
    type: typeof SAVE_INCLUDED_PRODUCTS;
    payload: {
        includedProducts: ReadonlyArray<string>;
    };
}

export interface ISaveExcludedProductsAction extends Action {
    type: typeof SAVE_EXCLUDED_PRODUCTS;
    payload: {
        excludedProducts: ReadonlyArray<string>;
    };
}
