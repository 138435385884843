import { ActionCreator } from 'redux';

import { ISaveStoreViewNameCodeAction } from '../../../../../../../../reducers/websiteConfigReducer';
import { SAVE_STORE_VIEW_NAME_CODE } from '../../../../../../../../store/actionConsts';

export const saveStoreViewNameCodeToStore: ActionCreator<
    ISaveStoreViewNameCodeAction
> = (payload: { label: string; uniqueRowId: string; value: string }) => ({
    payload,
    type: SAVE_STORE_VIEW_NAME_CODE,
});
